import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    display: 'flex',
  },
  barsList: {
    overflow: 'visible !important',
  },
}));
