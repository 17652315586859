import cn from 'clsx';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip } from '@eas/common-web';
import { UnitMenu, UnitMenuHandle } from '@modules/plan/unit-menu/unit-menu';
import { Absence } from '@models';
import { PlanCalendarDataType } from '../../calendar-types';
import { useStyles } from './legend-styles';

interface UnitProps {
  item: PlanCalendarDataType;
  style: React.CSSProperties;
}

export function LegendDay({ item, style }: UnitProps) {
  const classes = useStyles();
  const menuRef = useRef<UnitMenuHandle>(null);

  const isToday = dayjs().isSame(dayjs.unix(item.date), 'day');

  const absences: Absence[] = item.absences ?? [];

  const absenceTooltip = absences
    .filter((absence) => absence.allowed)
    .map((absence) => {
      let dateValue = '';
      if (absence.fromDate) {
        dateValue = dayjs(absence.fromDate).format('DD. MM. YYYY HH:mm');
      }

      if (absence.toDate) {
        dateValue += ` - ${(dateValue = dayjs(absence.toDate).format(
          'DD. MM. YYYY HH:mm'
        ))}`;
      }

      return `${absence.absenceType?.name} (${dateValue})`;
    })
    .join(', ');

  const tooltipMessages = [
    !!absenceTooltip && `Absencie: ${absenceTooltip}`,
  ].filter(Boolean);

  return (
    <div
      className={cn(classes.legendListItem, {
        [classes.legendListItemToday]: isToday,
      })}
      style={style}
      onContextMenu={(e) => {
        e.preventDefault();
        menuRef.current?.open(e);
      }}
    >
      <div className={classes.legendListItemInnerWrapper}>
        {!!tooltipMessages.length && (
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
                {tooltipMessages.join('\n')}
              </div>
            }
          >
            <WarningIcon className={classes.legendIcon} fontSize="small" />
          </Tooltip>
        )}
        {item.rowLabel}
      </div>

      {item.shifts?.some((shift) => shift?.isHoliday) && (
        <div className={classes.legendIcon}>🥳</div>
      )}
      <UnitMenu
        unit={{
          id: item.id,
          name: item.name,
          unitType: item.unitType,
          latitude: item.latitude,
          longitude: item.longitude,
        }}
        workOrders={item.workOrders}
        date={item.date}
        ref={menuRef}
      />
    </div>
  );
}
