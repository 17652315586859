import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';

export const Version = () => (
  <Typography
    variant="caption"
    style={{
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      position: 'absolute',
      right: 10,
      bottom: 0,
      padding: 1,
      display: 'block',
      zIndex: 9,
    }}
  >
    (
    <FormattedMessage id="ZSD__APP_VERSION" defaultMessage="verzia" />:{' '}
    {process.env.LAST_BUILD})
  </Typography>
);
