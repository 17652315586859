import dayjs from 'dayjs';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';
import { getSecondsInPixel } from './get-seconds-in-pixel';

export function getInitialScrollOffset(
  currentDateUnix: number,
  settings: Partial<CalendarSettingsType>,
  targetDateUnix?: number
) {
  const {
    dimension = CalendarDimensions.WEEK,
    legendWidth = 200,
    startingHour = 0,
    endingHour = 24,
  } = settings;

  const secondsInPixel = getSecondsInPixel(settings);

  const currentDate = dayjs.unix(currentDateUnix);
  const now = targetDateUnix ? dayjs.unix(targetDateUnix) : dayjs(new Date());
  const hoursOffset = 6;

  switch (dimension) {
    case CalendarDimensions.DAY: {
      if (
        !currentDate.isSame(now, 'day') ||
        now.hour() < startingHour ||
        now.hour() > endingHour
      ) {
        return 0;
      }

      const start = dayjs(`${now.hour()}:${now.minute()}`, 'HH:mm');
      const end = dayjs('00:00', 'HH:mm');

      const diff = dayjs.duration(start.diff(end)).asHours();

      return (60 * 60 * (diff - hoursOffset)) / secondsInPixel + legendWidth;
    }

    case CalendarDimensions.WEEK: {
      const dayDiff = now.diff(currentDate, 'day');
      if (dayDiff > 7 || dayDiff < 0) {
        return 0;
      }

      let start = targetDateUnix
        ? dayjs.unix(targetDateUnix)
        : dayjs(new Date());
      if (start.hour() >= endingHour) {
        start = start.startOf('day').add(endingHour, 'hour');
      }

      let end = targetDateUnix
        ? dayjs.unix(targetDateUnix).startOf('day')
        : dayjs(new Date()).subtract(1, 'day').startOf('day');
      if (end.hour() < startingHour) {
        end = end.startOf('day').add(startingHour, 'hour');
      }

      const diff = dayjs.duration(start.diff(end)).asHours();

      const hiddenHours = (24 - (endingHour - startingHour)) * dayDiff;

      return (
        (60 * 60 * (diff - hiddenHours - hoursOffset)) / secondsInPixel +
        legendWidth
      );
    }

    case CalendarDimensions.MONTH: {
      if (!currentDate.isSame(now, 'month')) {
        return 0;
      }

      const start = dayjs(new Date());
      const end = dayjs(new Date()).startOf('month');

      const diff = dayjs.duration(start.diff(end)).asHours();

      return (60 * 60 * (diff - hoursOffset)) / secondsInPixel + legendWidth;
    }

    default:
      return 0;
  }
}
