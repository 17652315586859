import dayjs from 'dayjs';
import { MobileUnit, ZsdUser } from '@models';

export const getUnitName = (
  unit: MobileUnit | ZsdUser | undefined,
  unitType: 'mobileUnit' | 'user'
): string => {
  if (!unit) {
    return '';
  }
  if (unitType === 'mobileUnit') {
    return (unit as MobileUnit)?.name ?? '';
  }
  const user = unit as ZsdUser;
  return `${user.firstName} ${user.lastName}`;
};

export const getCalendarLegendRowLabel = (day: dayjs.Dayjs): string =>
  new Date(day.toDate()).toLocaleDateString('sk-SK', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

//const capitalize = (s:string):string =
