import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  step: {
    display: 'flex',
    borderBottom: `1px solid #ECECEC`,
    borderRight: 'none',
    borderTop: 'none',
    position: 'relative',
  },
}));
