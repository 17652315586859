import { Collision } from '@dnd-kit/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { CalendarDndData } from '@modules/planner/calendar/calendar-types';
import { WorkOrder } from '@models';
import { getPlannedFrom } from './get-planned-from';

dayjs.extend(utc);
dayjs.extend(timezone);

export type WorkOrderDto = {
  id: string;
  unitId: string;
  unitType: 'MOBILE_UNIT' | 'TECHNICIAN';
  plannedFrom: string;
  plannedTo: string;
};

type PrepareDtoProps = {
  currentDate: number;
  collision: Collision;
  workOrder: WorkOrder;
  offset?: number;
};

const unitTypeMapping = {
  mobileUnit: 'MOBILE_UNIT' as const,
  user: 'TECHNICIAN' as const,
};

export function prepareDto({
  currentDate,
  collision,
  workOrder,
  offset = 0,
}: PrepareDtoProps): WorkOrderDto {
  const collisionData: CalendarDndData =
    collision?.data?.droppableContainer?.data?.current;
  const settings = collisionData?.settings;

  const collisionValue: number = collision?.data?.value;

  const id = workOrder.id;
  const duration = workOrder.duration;

  const unitId = collisionData?.unitId;
  const unitType = unitTypeMapping[collisionData?.unitType] || 'MOBILE_UNIT';

  const plannedFrom = getPlannedFrom(
    currentDate,
    collisionValue,
    settings,
    offset
  );
  const plannedTo = dayjs
    .tz(plannedFrom)
    .add(duration ?? 0, 'second')
    .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  return {
    id,
    unitId,
    unitType,
    plannedFrom,
    plannedTo,
  };
}
