import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  Autocomplete,
  Filter,
  FormContext,
  FormFieldWrapper,
  useFormSelector,
} from '@eas/common-web';
import { useCollectionSource } from '@components/collection-field/collection-api';
import { ZsdUser } from '@models';
import { EvidenceApiUrl } from '@enums';
import { userLabelMapper } from '@utils';

export function useValidationSchema() {
  return Yup.object({
    id: Yup.string().required('Povinné pole'),
  });
}

async function getUser(id: string) {
  const response = await fetch(`${EvidenceApiUrl.USERS}/${id}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const json = await response.json();

  return json;
}

export const assignDialogFactory = (
  refKey: string,
  refFilters?: Filter[],
  currentRefId?: string,
  multiple?: boolean
) =>
  function UserAssignDialog() {
    const source = useCollectionSource<ZsdUser>(
      EvidenceApiUrl.USERS,
      `${refKey}.id`,
      currentRefId,
      refFilters
    );

    const { setFieldValues, errors, resetValidation } = useContext(FormContext);
    const user = useFormSelector((values: ZsdUser) =>
      values?.id ? values : null
    );

    return (
      <FormFieldWrapper
        required={true}
        disabled={false}
        label=""
        labelOptions={{ hide: true }}
        layoutOptions={{}}
        errorOptions={{}}
        errors={errors}
      >
        <Autocomplete<ZsdUser>
          source={source}
          labelMapper={userLabelMapper}
          onChange={async (values) => {
            resetValidation();
            const formData = values as ZsdUser;

            const user = await getUser(formData.id);

            setFieldValues({
              ...user,
              [refKey]: multiple
                ? [
                    ...user.roles,
                    {
                      id: currentRefId,
                    },
                  ]
                : {
                    id: currentRefId,
                  },
            });
          }}
          value={user}
        />
      </FormFieldWrapper>
    );
  };
