import { GeoJSON as LeafletGeoJSON } from 'leaflet';
import React, { forwardRef } from 'react';
import * as ReactLeaflet from 'react-leaflet';

export const GeoJSON = forwardRef<LeafletGeoJSON, any>(function GeoJSON(
  props,
  ref
) {
  return (
    <ReactLeaflet.GeoJSON
      ref={ref}
      data={{
        coordinates: [],
        type: 'LineString',
      }}
      style={{
        color: 'red',
        weight: 8,
        opacity: 0.65,
      }}
    />
  );
});
