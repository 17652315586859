import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DomainObject,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { CollectionDialog } from '../collection-dialog';
import { useValidationSchema } from '../collection-schema';
import { CollectionToolbarAction } from '../collection-toolbar';

export function useCreateCollectionDialog<T extends DomainObject>(
  getUrl: (id: string) => string
) {
  const callApi = useEventCallback((id: string, formData: T) => {
    return abortableFetch(`${getUrl(id)}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formData),
    });
  });

  return {
    callApi,
  };
}

export function resolveCreateAction<T extends DomainObject>(
  props: CollectionToolbarAction<typeof useCreateCollectionDialog<T>, T>
) {
  return {
    hide: false,
    useApi: useCreateCollectionDialog,
    label: (
      <FormattedMessage
        id="ZSD__COLLECTIONS__CREATE_LABEL"
        defaultMessage="Nový"
      />
    ),
    title: (
      <FormattedMessage
        id="ZSD__COLLECTIONS__CREATE_TITLE"
        defaultMessage="Vytvorenie"
      />
    ),
    text: (
      <FormattedMessage
        id="ZSD__COLLECTIONS__CREATE_TEXT"
        defaultMessage="Vyplňte formulár"
      />
    ),
    initValues: (values: T) => values,
    Dialog: CollectionDialog,
    dialogWidth: 400,
    useSchema: useValidationSchema,
    ...props,
  };
}
