import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormCheckbox,
  FormContext,
  useFormSelector,
  useUpdateEffect,
} from '@eas/common-web';
import { ZsdUser } from '@models';

export function UserTechnicianFields() {
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((data: ZsdUser) => ({
    type: data?.type,
  }));

  const isTechnician = values?.type === 'TECHNICIAN';

  useUpdateEffect(() => {
    if (!isTechnician) {
      setFieldValue('customerSuitable', null);
    }
  }, [isTechnician]);

  if (!isTechnician) {
    return <></>;
  }

  return (
    <>
      <FormCheckbox
        name="customerSuitable"
        label={
          <FormattedMessage
            id="ZSD__USER__FIELD_LABEL__CUSTOMER_SUITABLE"
            defaultMessage="Vhodný pre zákazníka"
          />
        }
      />
    </>
  );
}
