import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '500px',
  },
  divider: {
    margin: '1rem 0',
  },
  searchInput: {
    paddingRight: 0,
  },
  searchButton: {
    width: 36,
    minWidth: 36,
    height: 'calc(1.1876em + 19px)',
    marginRight: 1,
    marginLeft: 8,
  },
}));
