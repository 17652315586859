import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormContext, FormPanel, FormTextField } from '@eas/common-web';
import { useDynamicFields } from '../work-order-dynamic-api';
import { DynamicField } from './dynamic-field';

export function DynamicFields({ workOrderId }: { workOrderId: string }) {
  const [allFields] = useDynamicFields(workOrderId);
  // necessary filter because api returns also deleted fields
  const fields = allFields?.filter((field) => !field.attributeName.deleted);

  const { getFieldValues } = useContext(FormContext);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__PANEL__DYNAMIC_ATRIIBUTES"
            defaultMessage="Ostatné atributy"
          />
        }
      >
        {getFieldValues().capacitorDisconnectionName && (
          <FormTextField
            name="capacitorDisconnectionName"
            label={
              <FormattedMessage
                id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CAPACITOR_DISCONNECTION_NAME"
                defaultMessage="Názov odpojenia kondenzátora"
              />
            }
            disabled
          />
        )}
        {getFieldValues().disconnectionStatusName && (
          <FormTextField
            name="disconnectionStatusName"
            label={
              <FormattedMessage
                id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DISCONNECTION_STATUS_NAME"
                defaultMessage="Názov stavu odpojenia"
              />
            }
            disabled
          />
        )}

        {fields?.map((field) => (
          <DynamicField key={field.id} {...field} />
        ))}
      </FormPanel>
    </>
  );
}
