import dayjs from 'dayjs';
import * as Yup from 'yup';
import { MobileUnitAssignment, ZsdUser } from '@models';

export function useValidationSchema() {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return Yup.object<MobileUnitAssignment>().shape({
    leadTechnician: Yup.mixed<ZsdUser>(),
    technicians: Yup.mixed<ZsdUser[]>(),
    validFrom: Yup.date()
      .min(yesterday, 'Vyberte dnešný dátum alebo dátum v budúcnosti')
      .required('Povinné pole'),
    validTo: Yup.date()
      .when('validFrom', (validFrom, schema) =>
        schema.test({
          test: (validTo: Date) =>
            dayjs(validFrom).isSame(validTo) ||
            dayjs(validTo).isAfter(validFrom),
          message: 'Čas ukončenia nemôže byť pred začiatkom platnosti',
        })
      )
      .required('Povinné pole'),
  });
}
