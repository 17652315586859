import React from 'react';
import { useIntl } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import Typography from '@material-ui/core/Typography';
import { TableField } from '@eas/common-web';
import {
  useGetTechnicianWarnings,
  useUnassignedTechniciansInDepartment,
} from '@modules/user/user-api';
import { useColumns } from '@modules/user/user-field-columns';
import { Department, MobileUnitWarnings } from '@models';
import { TechnicianRow } from './technician-row';

export function TechnicianTables({
  department,
  date,
}: {
  department: Department;
  date: string;
}) {
  const [availableTechnicians] = useUnassignedTechniciansInDepartment(
    department.id,
    date
  );

  const [technicianWarnings] = useGetTechnicianWarnings(department.id, date);
  const unavailableTechnicians = Object.values(
    technicianWarnings ?? {}
  )?.flatMap((warnings: MobileUnitWarnings[]) =>
    warnings.map((warning) => warning.user)
  );

  const techColumns = useColumns();
  const emptyColumn = {
    name: '',
    datakey: 'empty',
    width: 20,
  };

  const intl = useIntl();

  return (
    <AutoSizer disableWidth={true} style={{ height: '100%' }}>
      {({ height }) => (
        <div
          style={{
            display: 'grid',
            gridAutoColumns: 'minmax(0, 1fr)',
            gridAutoFlow: 'column',
            gap: 16,
            marginTop: 16,
            height: height,
          }}
        >
          <div>
            <Typography variant="h6">
              {intl.formatMessage({
                id: `MU_SWITCHER__TECHNICIAN_TABLE_AVAILABLE`,
                defaultMessage: `Dostupní technici (${availableTechnicians?.length})`,
              })}
            </Typography>
            <TableField
              columns={[emptyColumn, ...techColumns]}
              value={availableTechnicians}
              onChange={() => {}}
              disabled={true}
              showToolbar={false}
              RowComponent={TechnicianRow}
            />
          </div>
          <div>
            <Typography variant="h6">
              {intl.formatMessage({
                id: `MU_SWITCHER__TECHNICIAN_TABLE_UNAVAILABLE`,
                defaultMessage: `Nedostupní technici (${unavailableTechnicians?.length})`,
              })}
            </Typography>
            <TableField
              columns={techColumns}
              value={unavailableTechnicians}
              onChange={() => {}}
              disabled={true}
              showToolbar={false}
              visibleActionsColumn={false}
              onRowClick={() => {}}
            />
          </div>
        </div>
      )}
    </AutoSizer>
  );
}
