import * as Yup from 'yup';
import { FilledFormSapAttribute } from '@models';

export function useValidationSchema() {
  return Yup.object<FilledFormSapAttribute>().shape({
    formTemplate:
      Yup.mixed<FilledFormSapAttribute['formTemplate']>().required(
        'Povinné pole'
      ),
    filledFormName: Yup.string().nullable().required(),
    sapName: Yup.string().nullable().required(),
  }) as Yup.Schema<FilledFormSapAttribute>;
}
