import { useEffect, useMemo, useState } from 'react';
import { abortableFetch, useEventCallback } from '@eas/common-web';
import { EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';
import { AuthContextType, AuthType, AuthTypeResponse } from './auth-types';

/**
 *  Endpoint for getting information about types of currently supported authentications
 */
function authTypeCall() {
  return abortableFetch(EvidenceApiUrl.AUTH_TYPE, {
    method: 'GET',
  });
}

export function useAuth(): AuthContextType {
  const [authType, setAuthType] = useState<AuthTypeResponse>({});

  const reloadAuthType = useEventCallback(async () => {
    try {
      const response = authTypeCall();
      const data = (await response.json()) as AuthTypeResponse;

      if (data.type === AuthType.FORM) {
        setAuthType({ ...data, loginUrl: EvidenceBrowserUrl.LOGIN });
      } else {
        setAuthType(data);
      }
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    reloadAuthType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => ({
      loginUrl: authType.loginUrl,
      type: authType.type,
      // logout,
      // twoFactorEnabled: authType.twoFactorEnabled,
    }),
    [authType]
  );
}
