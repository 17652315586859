import dayjs from 'dayjs';
import { Holiday } from '@models';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

export function getScaleLabel(
  currentDateUnix: number,
  settings: Partial<CalendarSettingsType>,
  holidays: Holiday[]
) {
  const { dimension = CalendarDimensions.WEEK } = settings;

  const currentDate = dayjs.unix(currentDateUnix);

  switch (dimension) {
    case CalendarDimensions.DAY: {
      const isHoliday = holidays
        .map((h) => h.day)
        .includes(currentDate.format('YYYY-MM-DD'));

      const label = currentDate.format('ddd, D MMMM, YYYY');

      if (isHoliday) {
        return `🥳 ${label} 🥳`;
      } else {
        return label;
      }
    }

    case CalendarDimensions.WEEK: {
      const startOfWeek = currentDate;
      const endOfWeek = currentDate.add(7, 'day');

      return `${startOfWeek.format('D MMMM')} - ${endOfWeek.format('D MMMM')}`;
    }

    case CalendarDimensions.MONTH: {
      return currentDate.format('MMMM, YYYY');
    }

    default:
      return '';
  }
}
