import React, { forwardRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MapIcon from '@material-ui/icons/Map';
import { PromptContext } from '@eas/common-web';
import { WorkOrder } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
};

export const RouteMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function RouteMenuItem({ closeMenu, workOrder }, ref) {
    const { testPrompt } = useContext(PromptContext);

    if (!workOrder?.latitude || !workOrder?.longitude) {
      return null;
    }

    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          closeMenu();

          testPrompt({
            key: `TRAVEL_TO_${workOrder.id}`,
            submitCallback: () => {},
          });
        }}
      >
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <FormattedMessage
          id="WORK_ORDER_MENU_ITEM_TRAVEL"
          defaultMessage="Trasa"
        />
      </MenuItem>
    );
  }
);
