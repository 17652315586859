import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  FilesProvider,
  FormAnchorProvider,
  LocaleName,
  LocaleProvider,
  NavigationProvider,
  PromptProvider,
  SnackbarProvider,
  UserProvider,
  WebsocketProvider,
  useEventCallback,
} from '@eas/common-web';
import { AuthProvider } from '@components/auth/auth-provider';
import { ThemeProvider } from '@components/theme/theme-provider';
import { Version } from '@components/version/version';
import { AppPublic } from './app-public';
import { AppSecured } from './app-secured';
import { sk } from './lang/sk';
import { ZsdUser } from './models/user';

export function App() {
  const checkAuthority = useEventCallback(
    (user: ZsdUser, permission: string) => {
      if (user.firstName === 'admin') {
        return true;
      }

      return !!user.roles?.find((role) =>
        role.permissions?.find((p) => p === permission)
      );
    }
  );

  return (
    <LocaleProvider defaultLocale={LocaleName.sk} messages={{ SLOVAK: sk }}>
      <ThemeProvider>
        <SnackbarProvider timeout={3000}>
          <BrowserRouter>
            <FormAnchorProvider>
              <AuthProvider>
                <UserProvider
                  meUrl="/api/zsd/user/me"
                  logoutUrl="/api/zsd/logout"
                  checkPermission={checkAuthority}
                >
                  <FilesProvider url="/api/zsd/file">
                    <NavigationProvider>
                      <Switch>
                        <Route path="/zsd">
                          <PromptProvider>
                            <WebsocketProvider
                              wsUrl="/api/zsd/stomp"
                              destinations={[]}
                              debug={false}
                            >
                              <Version />
                              <AppSecured />
                            </WebsocketProvider>
                          </PromptProvider>
                        </Route>
                        <Route path="/">
                          <AppPublic />
                        </Route>
                      </Switch>
                    </NavigationProvider>
                  </FilesProvider>
                </UserProvider>
              </AuthProvider>
            </FormAnchorProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </LocaleProvider>
  );
}
