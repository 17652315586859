import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Filter, ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { CalendarException, DictionaryAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './calendar-exception-columns';
import { useFilterFields } from './calendar-exception-filter-fields';
import { useValidationSchema } from './calendar-exception-schema';
import { CalendarExceptionCreateDialog } from './dialogs/calendar-exception-create-dialog';
import { CalendarExceptionUpdateDialog } from './dialogs/calendar-exception-update-dialog';

interface CalendarExceptionFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<CalendarException>>
  > {
  refKey: string;
  refFilters?: Filter[];
  currentRef: DictionaryAutocomplete;
  source: ScrollableSource<CalendarException>;
}

export function CalendarExceptionField({
  refKey,
  refFilters,
  currentRef,
  source,
  ...overrides
}: CalendarExceptionFieldProps) {
  const filters = useFilterFields();

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__CALENDAR_EXCEPTIONS"
          defaultMessage="Výnimky"
        />
      }
      defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/multiple`,
        Dialog: CalendarExceptionCreateDialog,
        initValues: () => ({
          [refKey]: currentRef,
        }),
        useSchema: useValidationSchema,
        dialogWidth: 700,
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/${collectionId}`,
        Dialog: CalendarExceptionUpdateDialog,
        useSchema: useValidationSchema,
        dialogWidth: 700,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/${collectionId}`,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/${collectionId}`,
        initValues: (calendar) => ({
          ...calendar,
          section: null,
        }),
      }}
      filters={filters}
      {...overrides}
    />
  );
}
