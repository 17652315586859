import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DomainObject,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { CollectionToolbarAction } from '../collection-toolbar';

export function useDeleteCollectionDialog(
  getUrl: (id: string, collectionIds: string | string[]) => string
) {
  const callApi = useEventCallback(
    (id: string, collectionIds: string | string[]) => {
      return abortableFetch(`${getUrl(id, collectionIds)}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
    }
  );

  return {
    callApi,
  };
}

export function resolveDeleteAction<T extends DomainObject>(
  props: CollectionToolbarAction<typeof useDeleteCollectionDialog, T>,
  count: number
) {
  return {
    hide: false,
    useApi: useDeleteCollectionDialog,
    label: (
      <FormattedMessage
        id="ZSD__COLLECTIONS__DELETE_LABEL"
        defaultMessage="Zmazať"
      />
    ),
    title: (
      <FormattedMessage
        id="ZSD__COLLECTIONS__DELETE_TITLE"
        defaultMessage="Pozor"
      />
    ),
    text: (
      <FormattedMessage
        id="ZSD__COLLECTIONS__DELETE_TEXT"
        defaultMessage="Skutočne chcete zmazať položky? ({count})"
        values={{ count }}
      />
    ),
    ...props,
  };
}
