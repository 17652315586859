import dayjs from 'dayjs';
import { Holiday } from '@models';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

export function getScaleItems(
  currentDateUnix: number,
  settings: Partial<CalendarSettingsType>,
  holidays: Holiday[]
) {
  const {
    dimension = CalendarDimensions.WEEK,
    endingHour = 24,
    startingHour = 0,
  } = settings;

  switch (dimension) {
    case CalendarDimensions.DAY: {
      const hours = endingHour - startingHour;

      return new Array(hours).fill(0).map((_item, index) => {
        return `${index + startingHour}:00`;
      });
    }

    case CalendarDimensions.WEEK: {
      const days = 7;

      return new Array(days).fill(0).map((_item, index) => {
        const day = dayjs.unix(currentDateUnix).add(index, 'day');

        const isHoliday = holidays
          .map((h) => h.day)
          .includes(day.format('YYYY-MM-DD'));

        const label = day.format('D.MM. (dddd)');

        if (isHoliday) {
          return `🥳 ${label} 🥳`;
        } else {
          return label;
        }
      });
    }

    case CalendarDimensions.MONTH: {
      const days = dayjs.unix(currentDateUnix).daysInMonth();

      return new Array(days).fill(0).map((item, index) => {
        const day = dayjs.unix(currentDateUnix).add(index, 'day');

        const isHoliday = holidays
          .map((h) => h.day)
          .includes(day.format('YYYY-MM-DD'));

        const label = day.format('D.MM. (dd)');

        if (isHoliday) {
          return `🥳 ${label} 🥳`;
        } else {
          return label;
        }
      });
    }

    default:
      return [];
  }
}
