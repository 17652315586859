import { debounce } from 'lodash';
import React, { CSSProperties, ReactNode, useCallback } from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { GPSPoint, WorkOrder } from '@models';

export type PointMapData = {
  id?: string;
  point?: GPSPoint;
  pointLabel?: string;
  pointStyle?: CSSProperties;
  tooltip?: {
    content: string | ReactNode;
    permanent?: boolean;
  };
};

export type TravelMapData = {
  startingPoint?: GPSPoint;
  workOrders?: (WorkOrder | undefined)[];
  unitId: string;
  addStartingPoint?: boolean;
  addReturnPoint?: boolean;
};

type MapData =
  | {
      variant: 'points';
      points: PointMapData[];
    }
  | {
      variant: 'travel';
      travel: TravelMapData;
    };

type PinnedMapContextType = {
  data?: MapData;
  otherData?: Record<string, unknown>;
  setOtherData?: Dispatch<SetStateAction<Record<string, unknown>>>;
  updateMapData: (data?: MapData) => void;
  clearAllData: () => void;
  mapContainerKey?: number;
  updateMapContainerKey?: () => void;
};

export const PinnedMapContext = createContext<PinnedMapContextType>(
  undefined as any
);

export function PinnedMapContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [data, setData] = useState<MapData>();
  const [otherData, setOtherData] = useState<Record<string, unknown>>({});
  const [mapContainerKey, setMapContainerKey] = useState<number>(0);
  const updateMapData = useCallback((data?: MapData) => {
    setData(data);
  }, []);

  const clearAllData = useCallback(() => {
    setData(undefined);
    setOtherData({});
  }, []);

  const updateMapContainerKey = debounce(
    () => setMapContainerKey((p) => p + 1),
    300
  );

  return (
    <PinnedMapContext.Provider
      value={{
        updateMapData,
        data,
        otherData,
        setOtherData,
        updateMapContainerKey,
        mapContainerKey,
        clearAllData,
      }}
    >
      {children}
    </PinnedMapContext.Provider>
  );
}
