import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Absence } from '@models';

export function useValidationSchema() {
  return Yup.object<Absence>().shape({
    fromDate: Yup.date().nullable().required('Povinné pole'),
    absenceType: Yup.object().nullable().required('Povinné pole'),
    toDate: Yup.date()
      .nullable()
      .when('fromDate', (fromDate, schema) =>
        schema.test({
          test: (toDate: Date) => dayjs(toDate).isAfter(fromDate),
          message:
            'Čas konca platnosti nemôže byť rovnaký alebo pred začiatkom platnosti',
        })
      )
      .required('Povinné pole'),
  });
}
