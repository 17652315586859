import { useContext } from 'react';
import { DetailContext, NavigationContext } from '@eas/common-web';
import { FilledForm } from '@models';
import { EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';

export function useDesignForm() {
  const { source, isExisting } = useContext(DetailContext);
  const { navigate } = useContext(NavigationContext);

  const openFormDesigner = () => {
    navigate(`${EvidenceBrowserUrl.FORM_TEMPLATES}/${source.data.id}/designer`);
  };

  const openFormFiller = async () => {
    if (source.data.id != null) {
      const definitionResponse = await fetch(
        `${EvidenceApiUrl.FORM_TEMPLATES}/${source.data.id}/definition`,
        {
          method: 'GET',
        }
      );

      const definition = await definitionResponse.text();

      const newFilledFormResponse = await fetch(EvidenceApiUrl.FILLED_FORMS, {
        method: 'POST',
        body: JSON.stringify({
          template: {
            id: source.data.id,
          },
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const newFilledForm: FilledForm = await newFilledFormResponse.json();

      await fetch(
        `${EvidenceApiUrl.FILLED_FORMS}/${newFilledForm.id}/definition`,
        {
          method: 'POST',
          body: definition,
        }
      );

      navigate(`${EvidenceBrowserUrl.FILLED_FORMS}/${newFilledForm.id}/filler`);
    }
  };
  const showBtn = isExisting === true;

  return { openFormDesigner, openFormFiller, showBtn };
}
