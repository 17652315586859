import noop from 'lodash/noop';
import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import {
  DetailContext,
  DetailMode,
  Form,
  FormHandle,
  PromptProvider,
  useCrudSource,
} from '@eas/common-web';
import { Fields } from '@modules/work-order/work-order-fields';
import { mergeAttributes } from '@modules/work-order/work-order-utils';
import { WorkOrder } from '@models';
import { EvidenceApiUrl } from '@enums';
import { multiAbortableFetch } from './../../../utils/multi-abortable-fetch';

export type WorkOrderDetailsHandle = {
  getData: () => WorkOrder | null;
};

export const WorkOrderDetailsForm = memo(
  forwardRef(function WorkOrderDetailsForm(
    {
      id,
    }: {
      id?: string;
    },
    ref: React.Ref<WorkOrderDetailsHandle>
  ) {
    const formRef = useRef<FormHandle>(null);
    const source = useCrudSource<WorkOrder>({
      url: EvidenceApiUrl.WORK_ORDERS,
      getItem: (api, itemId) =>
        multiAbortableFetch(
          [
            {
              request: `${api}/${itemId}`,
              opts: {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json' }),
              },
            },
            {
              request: `${EvidenceApiUrl.WORK_ORDER_DYNAMIC_ATTRIBUTES}/${itemId}`,
              opts: {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json' }),
              },
            },
          ],
          mergeAttributes
        ),
    });

    useEffect(() => {
      if (id) {
        source.get(id);
      }
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        getData: () => formRef.current?.getFieldValues(),
      }),
      []
    );

    if (!source.data) {
      return null;
    }

    return (
      <PromptProvider>
        <DetailContext.Provider
          value={{
            source,
            isExisting: true,
            mode: DetailMode.VIEW,
            setMode: noop,
            showErrorPanel: false,
            setActive: noop,
            refresh: noop,
            refreshAll: noop,
            onPersisted: noop,
            startNew: noop,
            startEditing: noop,
            cancelEditing: noop,
            validate: async () => [],
            save: noop,
            del: noop,
            addRefreshListener: noop,
            removeRefreshListener: noop,
            isLocked: false,
            openExportDialog: noop,
            closeExportDialog: noop,
          }}
        >
          <Form
            ref={formRef}
            initialValues={source.data}
            onSubmit={noop}
            editing={true}
          >
            <Fields />
          </Form>
        </DetailContext.Provider>
      </PromptProvider>
    );
  })
);
