import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useSections } from '@modules/section/section-api';
import { Team } from '@models';

export function useColumns(): TableColumn<Team>[] {
  const intl = useIntl();
  const { columnName, columnCreated, columnUpdated } =
    DictionaryEvidence.useDictionaryColumns<Team>();

  return [
    {
      datakey: 'section',
      sortkey: 'section.name',
      filterkey: 'section.id',
      name: intl.formatMessage({
        id: 'ZSD__TEAMS__TABLE__COLUMN__SECTION',
        defaultMessage: 'Úsek',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useSections),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    columnName,
    {
      datakey: 'automaticallyPlan',
      sortkey: 'automaticallyPlan',
      filterkey: 'automaticallyPlan',
      name: intl.formatMessage({
        id: 'ZSD__TEAMS__TABLE__COLUMN__AUTOMATICALLY_PLAN',
        defaultMessage: 'Podlieha automatickému plánovaniu',
      }),
      width: 200,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    },
    columnCreated,
    columnUpdated,
  ];
}
