import React from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { DatedEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { CalendarTemplate, TemplateShift } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './calendar-template-columns';
import { Fields } from './calendar-template-fields';
import { useValidationSchema } from './calendar-template-schema';

export function CalendarTemplates() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const defaultCalendarTemplate: CalendarTemplate = {
    id: uuidv4(),
    // create 7 dayls
    shifts: Array.from(
      { length: 7 },
      (s, i) =>
        ({
          id: uuidv4(),
          order: i,
        } as TemplateShift)
    ),
  };

  const evidence = DatedEvidence.useDatedEvidence<CalendarTemplate>({
    identifier: 'CALENDAR_TEMPLATE',
    apiProps: {
      url: EvidenceApiUrl.CALENDAR_TEMPLATES,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__CALENDAR_TEMPLATE__TABLE__TITLE',
        defaultMessage: 'Kalendárové šablóny',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__CALENDAR_TEMPLATE__DETAIL__TITLE',
          defaultMessage: 'Kalendarová šablóna',
        }),
        subtitle: (unit) => unit?.name ?? '',
      },
      initNewItem: () => defaultCalendarTemplate,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
