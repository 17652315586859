import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    background: theme.palette.primary.main,
    width: 3,
    zIndex: 9,
    opacity: 0.5,
  },
}));
