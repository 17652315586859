import React, { ReactNode } from 'react';
import { FormDictionaryField } from '@eas/common-web';
import {
  filterTechniciansWithDepartment,
  useTechniciansByDepartment,
  useTechniciansByDepartmentScrollable,
} from '@modules/user/user-api';
import { useColumns } from '@composite/user-collection/user-columns';
import { useFilterFields } from '@composite/user-collection/user-filter-fields';
import { ZsdUser } from '@models';
import { universalUserLabelMapper } from '@utils';

export function TechnicianDictionaryField({
  name,
  label,
  departmentId,
  multiple = false,
  required = false,
  excludeId,
}: {
  name: string;
  label: ReactNode;
  departmentId: string;
  multiple?: boolean;
  required?: boolean;
  excludeId?: string;
}) {
  const techniciansAutocompleteSource = useTechniciansByDepartment(
    departmentId,
    excludeId
  );
  const techniciansDialogSource = useTechniciansByDepartmentScrollable(
    departmentId,
    excludeId
  );

  const technicianFilter = filterTechniciansWithDepartment(
    departmentId,
    excludeId
  );

  const userColumns = useColumns<ZsdUser>(false);
  const userFilters = useFilterFields();

  return (
    <>
      <FormDictionaryField<ZsdUser>
        name={name}
        autocompleteSource={techniciansAutocompleteSource}
        dialogSource={techniciansDialogSource}
        filters={userFilters}
        dialogPreFilters={[technicianFilter]}
        labelMapper={universalUserLabelMapper}
        columns={userColumns}
        label={label}
        required={required}
        multiple={multiple}
      />
    </>
  );
}
