import React, { ReactNode } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { useTheme } from './theme-hook';

export function ThemeProvider({ children }: { children: ReactNode }) {
  const theme = useTheme({
    primaryColor: '#D9202D',
    secondaryColor: '#E94E1B',
    tertiaryColor: '#11367e',
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
