import { BRATISLAVA_COORDS } from '@composite/map';
import { WorkOrder } from '@models';

export function getPoints(items: (WorkOrder | undefined)[]) {
  return items.map(
    (item) =>
      [
        item?.latitude ?? BRATISLAVA_COORDS[1],
        item?.longitude ?? BRATISLAVA_COORDS[0],
      ] as [number, number]
  );
}

export function getPointsWithId(items: (WorkOrder | undefined)[]) {
  return items.map((item, index) => ({
    point: [
      item?.latitude ?? BRATISLAVA_COORDS[1],
      item?.longitude ?? BRATISLAVA_COORDS[0],
    ] as [number, number],
    id: item?.id,
    label: index + 1,
  }));
}
