import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormCheckbox,
  FormNumberField,
  FormSelect,
  FormTextField,
} from '@eas/common-web';
import { useRoles } from '@modules/role/role-api';
import { UserDepartmentFields } from '@modules/user/fields/user-department-fields';
import { UserSectionFields } from '@modules/user/fields/user-section-fields';
import { UserTeamField } from '@modules/user/fields/user-team-field';
import { useUserTypes } from '@modules/user/user-api';
import { labelMapper } from '@utils';

export function UserCreateDialog() {
  const rolesSource = useRoles();
  const userTypesSource = useUserTypes();

  return (
    <>
      <FormNumberField
        name="hrId"
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__HR_ID"
            defaultMessage="Osobné číslo"
          />
        }
      />
      <FormTextField
        name="firstName"
        required={true}
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__FIRSTNAME"
            defaultMessage="Meno"
          />
        }
      />
      <FormTextField
        name="lastName"
        required={true}
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__LASTNAME"
            defaultMessage="Priezvisko"
          />
        }
      />
      <FormTextField
        name="phoneNumber"
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__PHONE_NUMBER"
            defaultMessage="Telefónne číslo"
          />
        }
      />
      <FormTextField
        name="username"
        required={true}
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__USERNAME"
            defaultMessage="KID"
          />
        }
      />
      <FormSelect
        name="type"
        source={userTypesSource}
        valueIsId={true}
        required={true}
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__TYPE"
            defaultMessage="Typ používateľa"
          />
        }
      />

      <UserDepartmentFields />
      <UserTeamField />
      <UserSectionFields />

      <FormAutocomplete
        name="roles"
        source={rolesSource}
        multiple={true}
        labelMapper={labelMapper}
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__ROLE"
            defaultMessage="Skupina oprávnení"
          />
        }
      />
      <FormCheckbox
        name="isExternal"
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL__FIELD_LABEL__IS_EXTERNAL"
            defaultMessage="Externá osoba"
          />
        }
      />
    </>
  );
}
