import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { DetailContext, Tabs, TabsConfig } from '@eas/common-web';
import { DetailFields } from './fields/detail';
import { DynamicFields } from './fields/dynamic';
import { GeneralFields } from './fields/general';
import { LocationFields } from './fields/location';
import { RequirementsFields } from './fields/requirements';
import { TimeFields } from './fields/time';

export function Fields() {
  const { isExisting, source } = useContext(DetailContext);
  const workOrderId = source?.data?.id;

  const config: TabsConfig = [
    {
      label: (
        <FormattedMessage
          id="ZSD__WORK_ORDERS__DETAIL__PANEL_TITLE__GENERAL"
          defaultMessage="Všeobecné"
        />
      ),
      key: 'GENERAL',
      validationKeys: [],
      content: <GeneralFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__WORK_ORDERS__DETAIL__PANEL_TITLE__DETAIL"
          defaultMessage="Detail"
        />
      ),
      key: 'DETAIL',
      validationKeys: [],
      content: <DetailFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__WORK_ORDERS__DETAIL__PANEL_TITLE__TIME"
          defaultMessage="Časové údaje"
        />
      ),
      key: 'TIME',
      validationKeys: [],
      content: <TimeFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__WORK_ORDERS__DETAIL__PANEL_TITLE__LOCATION"
          defaultMessage="Poloha"
        />
      ),
      key: 'LOCATION',
      validationKeys: [],
      content: <LocationFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__WORK_ORDERS__DETAIL__PANEL_TITLE__REQUIREMENTS"
          defaultMessage="Požiadavky"
        />
      ),
      key: 'REQUIREMENTS',
      validationKeys: [],
      content: <RequirementsFields />,
    },
    {
      label: (
        <FormattedMessage
          id="ZSD__WORK_ORDERS__DETAIL__PANEL_TITLE__DYNAMIC"
          defaultMessage="Ostatné"
        />
      ),
      key: 'DYNAMIC',
      validationKeys: [],
      content:
        isExisting && workOrderId ? (
          <DynamicFields workOrderId={workOrderId} />
        ) : (
          <div>
            <Typography variant="body1">Nedostupné</Typography>
          </div>
        ),
    },
  ];

  return (
    <Tabs
      config={config}
      onClick={(setActiveTab, clickedTab) => {
        const index = config.findIndex((tab) => tab.key === clickedTab.key);

        setActiveTab(index);
      }}
    />
  );
}
