import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import get from 'lodash/get';
import { useMemo } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { DomainObject } from '@eas/common-web';
import { useHolidayList } from '@modules/holiday/holiday-api';
import { useMobileUnitList } from '@modules/mobile-unit/mobile-unit-api';
import { useTechnicianList } from '@modules/user/user-api';
import {
  Absence,
  AvailabilityResult,
  Calendar,
  CalendarException,
  Department,
  GPSPoint,
  Holiday,
  MobileUnit,
  ShiftIntervalType,
  WorkOrder,
  ZsdUser,
} from '@models';
import { CalendarDimensions } from '@enums';
import {
  useAbsenceList,
  useAvailabilityList,
  useCalendarExceptionList,
  useCalendarList,
  useWorkOrderList,
} from '../planner-api';
import { CalendarDataType } from './calendar-types';
import { useDataSourceFilters } from './hooks';
import { getCalendar, getShift } from './utils';

dayjs.extend(duration);

interface DataSourceObject extends DomainObject {
  startingPoint?: GPSPoint;
  currentLocation?: GPSPoint;
}

function createDataSource<T extends DataSourceObject>({
  units,
  unitType,
  nameMapper,
  departmentMapper,
  workOrders,
  calendars,
  calendarExceptions,
  absences,
  holidays,
  availability,
  currentDayTimestamp,
  dimension,
}: {
  units: T[];
  unitType: 'mobileUnit' | 'user';
  nameMapper: (unit: T) => string;
  departmentMapper: (unit: T) => Department;
  workOrders: WorkOrder[];
  calendars: Calendar[];
  calendarExceptions: CalendarException[];
  absences: Absence[];
  holidays: Holiday[];
  availability: AvailabilityResult[];
  currentDayTimestamp: number;
  dimension?: CalendarDimensions;
}): CalendarDataType[] {
  return units.map((unit) => {
    const workOrderForUnit = workOrders.filter(
      (workOrder) => get(workOrder, `${unitType}.id`) === unit.id
    );

    const calendarForUnit = calendars.filter(
      (calendar) => get(calendar, `${unitType}.id`) === unit.id
    );

    const calendarExceptionsForUnit = calendarExceptions.filter(
      (calendarException) =>
        get(calendarException, `${unitType}.id`) === unit.id
    );

    const absenceForUnit = absences.filter(
      (absence) => get(absence, `${unitType}.id`) === unit.id
    );

    const availabilityForUnit = availability?.filter(
      (availability) => get(availability, 'id') === unit.id
    );

    let shiftCount = 0;
    switch (dimension) {
      case 'day':
        shiftCount = 1;
        break;
      case 'week':
        shiftCount = 7;
        break;
      case 'month':
        shiftCount = dayjs.unix(currentDayTimestamp).daysInMonth();
        break;
      default:
        break;
    }

    const findShiftForDay = (offset: number) => {
      const currentDay = dayjs
        .unix(currentDayTimestamp)
        .add(offset, 'day')
        .format('YYYY-MM-DD');

      const calendar = getCalendar(calendarForUnit, currentDay);

      if (calendar) {
        const shift = getShift(calendar, currentDay);

        if (!shift) return undefined;

        const isHoliday = holidays.map((h) => h.day).includes(currentDay);
        const exception = calendarExceptionsForUnit.find((exception) =>
          dayjs(currentDay).isBetween(
            exception.validFrom,
            exception.validTo,
            'day',
            '[]'
          )
        );

        return {
          ...shift,
          isHoliday,
          isException: !!exception,
          isBlockingException: !!exception?.isBlockedForFixedTermsPlanning,
          exceptionIntervals: exception?.intervals ?? [],
        };
      }
    };

    const shifts = Array.from({ length: shiftCount }).map((_, index) =>
      findShiftForDay(index)
    );
    const overflowShift = findShiftForDay(-1);

    return {
      id: unit.id,
      unitType,
      latitude: unit.startingPoint?.latitude,
      longitude: unit.startingPoint?.longitude,
      currentLocation: unit.currentLocation,
      name: nameMapper(unit),
      department: departmentMapper(unit),
      overflowShift,
      shifts,
      absences: absenceForUnit,
      workOrders: workOrderForUnit,
      availability: (availabilityForUnit ?? []).map((availability) => ({
        ...availability,
        intervals: availability.intervals.map((interval) => ({
          ...interval,
          type: 'A' as ShiftIntervalType,
        })),
      })),
    };
  });
}

export type CalendarSource = ReturnType<typeof useCalendarDataSource>;

export function useCalendarDataSource(
  currentDay: number,
  dimension?: CalendarDimensions,
  clearWebsocketCachedData?: () => void,
  movingWorkOrders: WorkOrder[] = []
) {
  const filters = useDataSourceFilters();

  const mobileUnitSource = useMobileUnitList({
    skip: !filters.unitsSource.selected?.length,
    query: '',
    mobileUnitIds: filters.unitsSource.selected?.map((unit) => unit.id),
  });

  const technicianSource = useTechnicianList({
    skip: !filters.unitsSource.selected?.length,
    query: '',
    userIds: filters.unitsSource.selected?.map((unit) => unit.id),
  });

  const params = {
    mobileUnits: mobileUnitSource?.result?.items ?? [],
    technicians: technicianSource?.result?.items ?? [],
    skip:
      (!mobileUnitSource.result?.items && !technicianSource.result?.items) ||
      mobileUnitSource.loading ||
      technicianSource.loading ||
      !currentDay ||
      !dimension,
    currentDay,
    dimmension: dimension,
  };

  const availability = useAvailabilityList({
    ...params,
    skip: !movingWorkOrders?.length,
    workOrder: movingWorkOrders?.[0],
  });

  const workOrderSource = useWorkOrderList(params);
  const calendarSource = useCalendarList(params);
  const calendarExceptionSource = useCalendarExceptionList(params);
  const absenceSource = useAbsenceList(params);
  const holidaySource = useHolidayList({
    ...params,
    skip: params.mobileUnits.length === 0 && params.technicians.length === 0,
  });

  const workOrders = useMemo(
    () => workOrderSource.result?.items ?? [],
    [workOrderSource.result?.items]
  );

  const mobileUnitData = useMemo(
    () =>
      createDataSource({
        units: mobileUnitSource?.result?.items ?? [],
        unitType: 'mobileUnit',
        nameMapper: (unit: MobileUnit) => unit.name,
        departmentMapper: (unit: MobileUnit) => unit.department,
        workOrders,
        calendars: calendarSource?.result?.items ?? [],
        calendarExceptions: calendarExceptionSource?.result?.items ?? [],
        absences: absenceSource?.result?.items ?? [],
        holidays: holidaySource?.result?.items ?? [],
        availability: availability?.result ?? [],
        currentDayTimestamp: currentDay,
        dimension,
      }),
    [
      absenceSource?.result?.items,
      availability?.result,
      calendarExceptionSource?.result?.items,
      calendarSource?.result?.items,
      currentDay,
      dimension,
      holidaySource?.result?.items,
      mobileUnitSource?.result?.items,
      workOrders,
    ]
  );

  const technicianData = useMemo(
    () =>
      createDataSource({
        units: technicianSource?.result?.items ?? [],
        unitType: 'user',
        nameMapper: (unit: ZsdUser) => `${unit.firstName} ${unit.lastName}`,
        departmentMapper: (unit: ZsdUser) => unit.department!,
        workOrders,
        calendars: calendarSource?.result?.items ?? [],
        calendarExceptions: calendarExceptionSource?.result?.items ?? [],
        absences: absenceSource?.result?.items ?? [],
        holidays: holidaySource?.result?.items ?? [],
        availability: availability?.result ?? [],
        currentDayTimestamp: currentDay,
        dimension,
      }),
    [
      absenceSource?.result?.items,
      availability?.result,
      calendarExceptionSource?.result?.items,
      calendarSource?.result?.items,
      currentDay,
      dimension,
      holidaySource?.result?.items,
      technicianSource?.result?.items,
      workOrders,
    ]
  );

  const yAxis = useMemo(() => {
    return {
      data: [...mobileUnitData, ...technicianData],
      refreshData: () => {
        unstable_batchedUpdates(() => {
          mobileUnitSource.reset(true);
          technicianSource.reset(true);
          clearWebsocketCachedData?.();
        });
      },
    };
  }, [
    mobileUnitData,
    mobileUnitSource,
    technicianData,
    technicianSource,
    clearWebsocketCachedData,
  ]);

  return {
    yAxis,

    // holidays
    holidays: holidaySource?.result?.items ?? [],

    // filters
    ...filters,
  };
}
