import React, { useContext, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  DatedEvidence,
  DetailContext,
  DetailHandle,
  EvidenceContext,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormPanel,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { useDepartmentAutocomplete } from '@modules/department/department-api';
import { useWorkOrdersList } from '@modules/work-order/work-order-api';
import { AddressFields } from '@composite/address/address-fields';
import { CalendarField } from '@composite/calendar-collection/calendar-field';
import { CalendarExceptionField } from '@composite/calendar-exception-collection/calendar-exception-field';
import { MobileUnitAssignmentField } from '@composite/mobile-unit-assignment-collection/mobile-unit-assignment-field';
import { MobileUnit } from '@models';
import { Permission, regionApiErrors } from '@enums';
import { useMUAssignmentList } from './mobile-unit-api';
import {
  CALENDARS_SOURCE,
  CALENDAR_EXCEPTIONS_SOURCE,
  MOBILE_UNIT_ASSIGNMENTS_SOURCE,
} from './mobile-unit-hooked-sources';

export function Fields() {
  const departments = useDepartmentAutocomplete();
  const { hookedSources } = useContext(EvidenceContext);
  const { setFieldValue } = useContext(FormContext);

  const { source, isExisting } =
    useContext<DetailHandle<MobileUnit>>(DetailContext);

  const mobileUnitAssignmentSource =
    hookedSources[MOBILE_UNIT_ASSIGNMENTS_SOURCE]?.source;
  const mobileUnitAssignmentPreFilters = useMemo(
    () => mobileUnitAssignmentSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { hasPermission } = useContext(UserContext);

  const calendarSource = hookedSources[CALENDARS_SOURCE]?.source;
  const calendarPreFilters = useMemo(
    () => calendarSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const calendarExceptionSource =
    hookedSources[CALENDAR_EXCEPTIONS_SOURCE]?.source;
  const calendarExceptionPreFilters = useMemo(
    () => calendarSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [indivisibleWorkOrders] = useWorkOrdersList([
    {
      operation: ApiFilterOperation.AND,
      filters: [
        {
          field: 'mobileUnit.id',
          operation: ApiFilterOperation.EQ,
          value: source.data?.id,
        },
        {
          field: 'indivisible',
          operation: ApiFilterOperation.EQ,
          value: 'true',
        },
      ],
    },
  ]);

  const { result: assignments } = useMUAssignmentList({
    filters: [
      {
        field: 'mobileUnit.id',
        operation: ApiFilterOperation.EQ,
        value: source.data?.id,
      },
    ],
  });

  /* Neprerušiteľná práca
   * - readonly atribut
   * - plní sa automaticky pri práci na takejto úlohe
   */
  useEffect(() => {
    setFieldValue(
      'uninterruptibleWork',
      indivisibleWorkOrders?.items && indivisibleWorkOrders.items.length > 0
    );
  }, [indivisibleWorkOrders, setFieldValue]);

  /* Zručnosti
   * - readonly atribut
   * - stiahnuté z členov posádky
   */
  useEffect(() => {
    const certifications = new Set();
    assignments?.items?.forEach((assignment) => {
      assignment.leadTechnician?.certifications?.forEach((cert) => {
        certifications.add(cert.certification.name);
      });
      assignment.technicians?.forEach((tech) => {
        tech.certifications?.forEach((userCert) => {
          certifications.add(userCert.certification.name);
        });
      });
    });
    setFieldValue('certifications', Array.from(certifications).join(', '));
  }, [assignments, setFieldValue]);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__MOBILE_UNIT__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
        />
        <FormAutocomplete
          name="department"
          source={departments}
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__DEPARTMENT"
              defaultMessage="Pracovisko"
            />
          }
        />
        <FormTextField
          name="callFirst"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__CALL_FIRST"
              defaultMessage="Volať ako prvého"
            />
          }
        />
        <FormCheckbox
          name="morningFailureSuitable"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__MORNING_FAILURE_SUITABLE"
              defaultMessage="Vhodný pre poruchy 7-15"
            />
          }
        />
        <FormCheckbox
          name="customerSuitable"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__CUSTOMER_SUITABLE"
              defaultMessage="Vhodný pre zákazníka"
            />
          }
        />
        <FormCheckbox
          name="active"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__ACTIVE"
              defaultMessage="Aktívna"
            />
          }
        />
        <FormCheckbox
          name="uninterruptibleWork"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__UNINTERRUPTIBLE_WORK"
              defaultMessage="Neprerušiteľná práca"
            />
          }
          disabled
        />
        <FormTextField
          name="certifications"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__CERTIFICATIONS"
              defaultMessage="Zručnosti"
            />
          }
          disabled
        />
        <DatedEvidence.DatedFields />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__MOBILE_UNIT__DETAIL__BUSINESS_ADDRESS"
            defaultMessage="Služobná adresa"
          />
        }
        defaultExpanded={false}
        expandable={true}
      >
        <AddressFields addressKey="businessAddress" />
        <FormTextField
          name="startingPoint.latitude"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__LATITUDE"
              defaultMessage="Zemepisná šírka"
            />
          }
        />
        <FormTextField
          name="startingPoint.longitude"
          label={
            <FormattedMessage
              id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__LONGITUDE"
              defaultMessage="Zemepisná dĺžka"
            />
          }
        />
      </FormPanel>

      {isExisting && (
        <>
          {hasPermission(Permission.MOBILE_UNIT_LIST) && (
            <MobileUnitAssignmentField
              source={mobileUnitAssignmentSource}
              currentRef={{
                id: source.data?.id ?? '',
                name: source.data?.name ?? '',
              }}
              preFilters={mobileUnitAssignmentPreFilters}
              refKey="mobileUnit"
              departmentId={source.data?.department?.id ?? ''}
              exceptions={regionApiErrors.MOBILE_UNIT_ASSIGNMENT}
            />
          )}
          {hasPermission(Permission.CALENDAR_LIST) && (
            <CalendarField
              source={calendarSource}
              currentRef={{
                id: source.data?.id ?? '',
                label: source.data?.name ?? '',
              }}
              preFilters={calendarPreFilters}
              refKey="mobileUnit"
            />
          )}
          {hasPermission(Permission.CALENDAR_EXCEPTION_LIST) && (
            <CalendarExceptionField
              source={calendarExceptionSource}
              currentRef={{
                id: source.data?.id ?? '',
                label: source.data?.name ?? '',
              }}
              preFilters={calendarExceptionPreFilters}
              refKey="mobileUnit"
            />
          )}
        </>
      )}
    </>
  );
}
