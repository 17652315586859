import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailContext,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
} from '@eas/common-web';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { activateCalamity, deactivateCalamity } from './department-api';

export function ActionBar() {
  const { isExisting, source } = useContext(DetailContext);
  const { user } = useContext(UserContext);

  const departmentId = source.data?.id as string | undefined;

  if (!isExisting || !departmentId) {
    return null;
  }

  const canControlCalamity =
    user.type === 'ADMIN' ||
    (user.type === 'COORDINATOR' &&
      [
        user.coordinatorPrimaryDepartment,
        ...user.additionalDepartments,
      ].includes(departmentId));

  const isCalamityActive = source.data?.isCalamity;

  if (!canControlCalamity) {
    return null;
  }

  return isCalamityActive ? (
    <DeactivateCalamityButton
      id={departmentId}
      refresh={() => source.refresh()}
    />
  ) : (
    <ActivateCalamityButton
      id={departmentId}
      refresh={() => source.refresh()}
    />
  );
}

const ActivateCalamityButton = ({
  id,
  refresh,
}: {
  id: string;
  refresh: () => Promise<any>;
}) => {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);

  return (
    <TertiaryDetailActionbarButton
      label={intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__ACTIVATE_CALAMITY',
        defaultMessage: 'Aktivovať kalamitu',
      })}
      onClick={async () => {
        const res = await activateCalamity(id);
        if (res.ok) {
          showSnackbar(
            intl.formatMessage({
              id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_ACTIVATED',
              defaultMessage: 'Kalamita bola úspešne aktivovaná.',
            }),
            SnackbarVariant.SUCCESS
          );
        } else {
          showSnackbar(
            intl.formatMessage({
              id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_ACTIVATION_FAILED',
              defaultMessage: 'Aktivácia kalamity zlyhala.\n\n',
            }),
            SnackbarVariant.ERROR
          );
        }
        refresh();
      }}
    />
  );
};

const DeactivateCalamityButton = ({
  id,
  refresh,
}: {
  id: string;
  refresh: () => Promise<any>;
}) => {
  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);

  return (
    <TertiaryDetailActionbarButton
      label={intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__DEACTIVATE_CALAMITY',
        defaultMessage: 'Deaktivovať kalamitu',
      })}
      onClick={async () => {
        const res = await deactivateCalamity(id);
        if (res.ok) {
          showSnackbar(
            intl.formatMessage({
              id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_DEACTIVATED',
              defaultMessage: 'Kalamita bola úspešne deaktivovaná.',
            }),
            SnackbarVariant.SUCCESS
          );
        } else {
          showSnackbar(
            intl.formatMessage({
              id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_DEACTIVATION_FAILED',
              defaultMessage: 'Deaktivácia kalamity zlyhala.\n\n',
            }),
            SnackbarVariant.ERROR
          );
        }
        refresh();
      }}
    />
  );
};
