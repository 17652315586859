import React, { forwardRef, useContext } from 'react';
import { VariableSizeList as List } from 'react-window';
import { CalendarContext } from '../../calendar-context';
import { PlanCalendarDataType } from '../../calendar-types';
import { LegendDay } from './legend-day';
import { useStyles } from './legend-styles';

interface LegentProps {
  height: number;
  data: PlanCalendarDataType[];
}

export const Legend = forwardRef<List, LegentProps>(function Legend(
  { height, data },
  ref
) {
  const classes = useStyles();
  const { settings } = useContext(CalendarContext);

  return (
    <div
      className={classes.wrapper}
      style={{
        width: settings.legendWidth,
        marginTop: -1 * settings.headerHeight,
      }}
    >
      <List
        ref={ref}
        className={classes.legendList}
        layout="vertical"
        width="100%"
        height={height + settings.headerHeight}
        itemCount={data.length}
        itemSize={() => settings.rowHeight}
        itemData={data}
        innerElementType={({ children, props }) => {
          return (
            <div
              style={{
                height:
                  data.length * settings.rowHeight + settings.headerHeight,
              }}
              className={classes.legendInnerElement}
              {...props}
            >
              <div
                className={classes.legendTitle}
                style={{ height: settings.headerHeight }}
              />
              <div
                className={classes.legendInnerWrapper}
                style={{
                  height: data.length * settings.rowHeight,
                  minHeight: `calc(100% - ${settings.headerHeight}px)`,
                }}
              >
                {children}
              </div>
            </div>
          );
        }}
      >
        {({ style, index, data }) => {
          const item = data[index];
          return <LegendDay key={item.id} item={item} style={style} />;
        }}
      </List>
    </div>
  );
});
