import * as Yup from 'yup';
import { Department, Team } from '@models';

export function useValidationSchema() {
  return Yup.object<Department>().shape({
    name: Yup.string().nullable().required(),
    team: Yup.mixed<Team>().nullable().required(),
    code: Yup.string().nullable().required(),
  }) as Yup.Schema<Department>;
}
