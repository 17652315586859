import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottom: `1px solid #ECECEC`,
    backgroundSize: 2000,
  },
  steps: {
    display: 'inline-flex',
    overflow: 'hidden',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  step: {
    display: 'flex',
    borderBottom: `1px solid #ECECEC`,
    borderRight: 'none',
    borderTop: 'none',
    position: 'relative',
  },
  currentDay: {
    borderBottom: `1px solid #d9202d9c`,
    borderTop: `1px solid #d9202d9c`,
  },
}));
