import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DetailContext,
  DetailHandle,
  DetailMode,
  DictionaryEvidence,
  EvidenceContext,
  FormPanel,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { CertificationField } from '@composite/user-certification-collection/certification/certification-field';
import { Certification } from '@models';
import { Permission } from '@enums';
import { USERS_SOURCE } from './certification-hooked-sources';

export function Fields() {
  const { mode, source, isExisting } =
    useContext<DetailHandle<Certification>>(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { hookedSources } = useContext(EvidenceContext);

  const userSource = hookedSources[USERS_SOURCE]?.source;
  const userPreFilters = useMemo(
    () => userSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__CERTIFICATIONS__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__CERTIFICATIONS__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
        />
        <DictionaryEvidence.FieldCode />
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
      {isExisting && mode === DetailMode.VIEW && (
        <>
          {hasPermission(Permission.USER_LIST) && (
            <CertificationField
              source={userSource}
              currentRef={{
                id: source.data?.id ?? '',
              }}
              preFilters={userPreFilters}
              ToolbarComponent={() => <div></div>}
            />
          )}
        </>
      )}
    </>
  );
}
