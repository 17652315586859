import { useDraggable } from '@dnd-kit/core';
import React from 'react';
import { DomainObject } from '@eas/common-web';

export function Draggable<OBJECT extends DomainObject>({
  children,
  id,
  data,
  dragOrigin,
}: {
  children: React.ReactNode;
  id: string;
  data?: OBJECT;
  dragOrigin: string;
}) {
  const mergedId = `${id}:${dragOrigin}`;
  const { attributes, isDragging, listeners, setNodeRef } = useDraggable({
    id: mergedId,
    data: { dragOrigin, ...data },
  });

  return (
    <div
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
}
