import { DragStartEvent } from '@dnd-kit/core';
import { Dispatch, SetStateAction } from 'react';
import { ZsdUser } from '@models';

export function useDragStart({
  setMovingTechnician,
}: {
  setMovingTechnician: Dispatch<SetStateAction<ZsdUser | undefined>>;
}) {
  return (event: DragStartEvent) => {
    const technician = event.active.data.current as ZsdUser;

    setMovingTechnician(technician);
  };
}
