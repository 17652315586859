import React from 'react';
import { WorkOrderDynamicAttribute } from 'src/models/work-order-dynamic-attribute';
import {
  FormCheckbox,
  FormLocalDateTimeField,
  FormNumberField,
  FormTextField,
} from '@eas/common-web';

export function DynamicField(attribute: WorkOrderDynamicAttribute) {
  const props = {
    name: `dynamicAttributes[${attribute.attributeName.name}]`,
    label: attribute.attributeName.displayName ?? attribute.attributeName.name,
    disabled: true,
  };

  if (attribute.stringValue) {
    return <FormTextField {...props} />;
  }

  if (attribute.intValue) {
    return <FormNumberField {...props} />;
  }

  if (attribute.booleanValue) {
    return <FormCheckbox {...props} />;
  }

  if (attribute.dateTimeValue) {
    return <FormLocalDateTimeField {...props} />;
  }

  return <></>;
}
