import { useContext, useState } from 'react';
import {
  ListSource,
  UserSettingsContext,
  useUpdateEffect,
} from '@eas/common-web';
import { useDepartmentList } from '@modules/department/department-api';
import { useMobileUnitList } from '@modules/mobile-unit/mobile-unit-api';
import { useTechnicianList } from '@modules/user/user-api';
import { Department, MobileUnit, ZsdUser } from '@models';

/**
 * Constants
 */
export const calendarSettingsVersion = 6;
export const calendarSettingsId = 'CALENDAR_FILTERS';

/**
 * Shape of the calendar settings
 */
type CalendarSettings = {
  /**
   * Version of the settings
   */
  version: number;

  /**
   * List of selected departments
   */
  departments: Department[] | undefined;

  /**
   * List of selected units
   */
  units: (ZsdUser | MobileUnit)[] | undefined;
};

type SourceHookProps<T> = {
  useListSource: (props: any) => {
    result: ListSource<T> | undefined;
    loading: boolean;
    reset: (resetData?: boolean | undefined) => void;
    setLoading: (loading: boolean) => void;
  };
  hookProps?: any;
  defaultFilters: T[] | undefined;
};

/**
 * Hook to manage the source of the calendar filters
 */
function useSource<T>({
  useListSource,
  hookProps,
  defaultFilters,
}: SourceHookProps<T>) {
  const [loaded, setLoaded] = useState(false);
  const [selected, setSelected] = useState<T[] | undefined>(defaultFilters);

  const source = useListSource(hookProps);

  useUpdateEffect(() => {
    if (!source.loading) {
      if (defaultFilters?.length === undefined) {
        setSelected(source.result?.items);
      }
      setLoaded(true);
    }
  }, [source.loading]);

  return {
    loaded,
    selected,
    setSelected,
  };
}

/**
 * Hook to manage the source of the mobile units and technicians composed
 */
function useUnitSource({
  departmentsSource,
}: {
  departmentsSource: ReturnType<typeof useSource<Department>>;
}) {
  const mobileUnitsSource = useMobileUnitList({
    skip: !departmentsSource.loaded,
    query: '',
    departmentIds: departmentsSource.selected?.map((d) => d.id) ?? [],
  });

  const technicianSource = useTechnicianList({
    skip: !departmentsSource.loaded,
    query: '',
    departmentIds: departmentsSource.selected?.map((d) => d.id) ?? [],
  });

  return {
    loading: mobileUnitsSource.loading || technicianSource.loading,
    result: {
      items: [
        ...(mobileUnitsSource?.result?.items ?? []),
        ...(technicianSource?.result?.items ?? []),
      ],
    } as ListSource<ZsdUser | MobileUnit>,
    reset: () => {
      mobileUnitsSource.reset();
      technicianSource.reset();
    },
    setLoading: (loading: boolean) => {
      mobileUnitsSource.setLoading(loading);
      technicianSource.setLoading(loading);
    },
  };
}

/**
 * Hook to manage all sources of the calendar filters + sync with the user settings
 */
export function useDataSourceFilters() {
  const { getCustomSettings, setCustomSettings } =
    useContext(UserSettingsContext);

  const defaultFilters = getCustomSettings(
    calendarSettingsId,
    calendarSettingsVersion
  ) as CalendarSettings;

  const departmentsSource = useSource({
    useListSource: useDepartmentList,
    defaultFilters: defaultFilters?.departments,
  });

  const unitsSource = useSource({
    useListSource: useUnitSource,
    hookProps: {
      departmentsSource,
    },
    defaultFilters: defaultFilters?.units,
  });

  useUpdateEffect(() => {
    const newSettings: CalendarSettings = {
      departments: departmentsSource.selected,
      units: unitsSource.selected,
      version: calendarSettingsVersion,
    };

    setCustomSettings(calendarSettingsId, newSettings);
  }, [departmentsSource.selected, unitsSource.selected]);

  return {
    departmentsSource,
    unitsSource,
  };
}
