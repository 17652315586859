import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { DepartmentAutocomplete, TeamAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './department-columns';
import { useFilterFields } from './department-filter-fields';
import {
  assignDialogFactory,
  useValidationSchema as useAssignValidationSchema,
} from './dialogs/department-assign-dialog';

interface DepartmentFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<DepartmentAutocomplete>>
  > {
  currentTeam: TeamAutocomplete;
  source: ScrollableSource<DepartmentAutocomplete>;
}

export function DepartmentField({
  currentTeam,
  source,
  ...overrides
}: DepartmentFieldProps) {
  const AssignDialog = useMemo(
    () => assignDialogFactory(currentTeam.id),
    [currentTeam]
  );
  const filters = useFilterFields();

  return (
    <CollectionField<DepartmentAutocomplete>
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__DEPARTMENTS"
          defaultMessage="Pracoviská"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: true,
        getUrl: () => EvidenceApiUrl.DEPARTMENTS,
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.DEPARTMENTS}/${collectionId}`,
      }}
      updateAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEPARTMENTS}/${collectionId}`,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEPARTMENTS}/${collectionId}`,
      }}
      assignAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEPARTMENTS}/${collectionId}`,
        Dialog: AssignDialog,
        useSchema: useAssignValidationSchema,
      }}
      unassignAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.DEPARTMENTS}/${collectionId}`,
        initValues: (department) => ({
          ...department,
          team: null,
        }),
      }}
      filters={filters}
      {...overrides}
    />
  );
}
