import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormTextField } from '@eas/common-web';

export function CollectionDialog() {
  return (
    <>
      <FormTextField
        name="name"
        label={
          <FormattedMessage
            id="ZSD__COLLECTIONS__FIELD_LABEL__NAME"
            defaultMessage="Název"
          />
        }
        autoFocus={true}
      />
    </>
  );
}
