import DayjsUtils from '@date-io/dayjs';
import cn from 'clsx';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useStyles } from './styles';

const defaultLabelMapper = (day: number) =>
  dayjs.unix(day).format('ddd, D MMMM, YYYY');

export function DayPicker({
  currentDate,
  onChange,
  labelMapper = defaultLabelMapper,
}: {
  currentDate: number;
  onChange: (value: number) => void;
  labelMapper?: (value: number) => string;
}) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const intl = useIntl();

  const handleChange = useCallback(
    (value: MaterialUiPickersDate) => {
      if (!value) {
        return;
      }
      const date = value.unix();

      onChange(date);

      setOpen(false);
    },
    [onChange]
  );

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <DatePicker
        value={new Date(currentDate * 1000)}
        onChange={handleChange}
        open={open}
        openTo="date"
        cancelLabel={intl.formatMessage({
          id: 'ZSD__DATEPICKER__BUTTON_CANCEL',
          defaultMessage: 'Zrušiť',
        })}
        okLabel={intl.formatMessage({
          id: 'ZSD__DATEPICKER__BUTTON_OK',
          defaultMessage: 'Potvrdiť',
        })}
        onClose={() => setOpen(false)}
        TextFieldComponent={function Input() {
          return (
            <Button
              variant="outlined"
              className={cn(classes.button, classes.basicButton)}
              endIcon={<CalendarTodayIcon fontSize="small" />}
              onClick={() => setOpen(true)}
            >
              {labelMapper(currentDate)}
            </Button>
          );
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
