import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Role } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const USERS_SOURCE = 'USERS_SOURCE';

function useUserSource(): HookedSource<Role> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [USERS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.USER_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.USERS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'roles.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useRoleHookedSources(): HookedSource<Role> {
  const users = useUserSource();

  return users;
}
