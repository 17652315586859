import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { Calendar } from '@models';
import { substractDate } from './get-template';

dayjs.extend(weekday);

/**
 * Find shift for current day.
 *
 * For more information, see: `./get-shift.test.ts`
 */
export function getShift(calendar: Calendar, currentDay: string) {
  const calendarStart = dayjs(calendar?.fromDate).format('YYYY-MM-DD');
  if (substractDate(currentDay, calendarStart) < 0) {
    throw new Error(
      `Current day (${currentDay}) is before calendar start date (${calendarStart})`
    );
  }

  const calendarEnd = dayjs(calendar?.toDate).format('YYYY-MM-DD');
  if (substractDate(calendarEnd, currentDay) < 0) {
    throw new Error(
      `Current day (${currentDay}) is after calendar end date (${calendarEnd})`
    );
  }

  const period = calendar?.template?.shifts?.length ?? 0;
  const numOfWeeks = Math.floor(period / 7);

  const calendarStartWeek = dayjs(calendar?.fromDate).startOf('week');

  const currenDayWeek = dayjs(currentDay).startOf('week');

  const day = dayjs(currentDay).weekday();
  const weekDiff = Math.abs(
    dayjs.duration(calendarStartWeek.diff(currenDayWeek)).asDays()
  );

  const weekIndex = Math.floor(weekDiff / 7) % numOfWeeks;

  const shiftIndex = day + weekIndex * 7;

  return calendar?.template?.shifts?.[shiftIndex]?.shift;
}
