import { useIntl } from 'react-intl';
import {
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableColumnAlign,
} from '@eas/common-web';
import { Section } from '@models';

export function useColumns(): TableColumn<Section>[] {
  const { columnName, columnCreated, columnUpdated } =
    DictionaryEvidence.useDictionaryColumns<Section>();

  const intl = useIntl();

  return [
    columnName,
    {
      datakey: 'nightPlannerDaysCount',
      sortkey: 'nightPlannerDaysCount',
      filterkey: 'nightPlannerDaysCount',
      name: intl.formatMessage({
        id: 'ZSD__SECTIONS__TABLE__COLUMN__NIGHT_PLANNER_DAYS_COUNT',
        defaultMessage: 'Počet dní dopredu pre nočný plánovač',
      }),
      width: 300,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      align: TableColumnAlign.LEFT,
    },
    columnCreated,
    columnUpdated,
  ];
}
