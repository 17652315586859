import { DndContext } from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { UserContext } from '@eas/common-web';
import {
  useDepartmentListByTeam,
  useGetDepartment,
} from '@modules/department/department-api';
import {
  useMUAssignmentListValidated,
  useMobileUnitList,
} from '@modules/mobile-unit/mobile-unit-api';
import { useGetTeam } from '@modules/team/team-api';
import { useGetTechnicianWarnings } from '@modules/user/user-api';
import { Department, Team, ZsdUser } from '@models';
import { SwitchHeader } from './controls/switcher-header';
import { Dropppable } from './dnd/droppable';
import { useDragEnd } from './dnd/hooks/drag-end';
import { useDragStart } from './dnd/hooks/drag-start';
import { MobileUnitCard } from './mobile-unit/mobile-unit-card';
import { getParam } from './param-utils';
import { useStyles } from './switcher-styles';
import { TechnicianBar } from './technician-table/technician-bar';
import { TechnicianTables } from './technician-table/technician-tables';

export const TECHNICIAN_TABLE = 'technician-table';

export function Switcher() {
  const classes = useStyles();

  const [movingTechnician, setMovingTechnician] = useState<
    ZsdUser | undefined
  >();

  const { user } = useContext(UserContext);

  const userPrimaryDepartment = user?.coordinatorPrimaryDepartment;
  const [primaryDepartmentDetail] = useGetDepartment(userPrimaryDepartment?.id);
  const userPrimaryTeam = primaryDepartmentDetail?.team;

  const [paramTeam] = useGetTeam(getParam('tim'));
  const [team, setTeam] = useState<Team | undefined>(
    paramTeam ?? userPrimaryTeam
  );
  useEffect(() => {
    if (paramTeam) {
      setTeam(paramTeam);
    } else if (userPrimaryTeam) {
      setTeam(userPrimaryTeam);
    }
  }, [paramTeam, userPrimaryTeam]);

  const defaultDate = getParam('datum')
    ? parseInt(getParam('datum')!)
    : dayjs().unix();
  const [selectedDate, setSelectedDate] = useState<number>(defaultDate);

  const [defaultDepartment] = useGetDepartment(getParam('pracovisko'));
  const [selectedDepartment, setSelectedDepartment] = useState<
    Department | undefined
  >(defaultDepartment ?? userPrimaryDepartment);

  // determine if the team has only 1 department -> autoselect it if no other default value is set
  const departmentSrc = useDepartmentListByTeam(team?.id ?? null);
  useEffect(() => {
    if (defaultDepartment) {
      setSelectedDepartment(defaultDepartment);
    } else if (userPrimaryDepartment) {
      setSelectedDepartment(userPrimaryDepartment);
    } else if (departmentSrc?.result?.items.length === 1) {
      setSelectedDepartment(departmentSrc?.result?.items[0]);
    }
  }, [defaultDepartment, departmentSrc, userPrimaryDepartment]);

  const [technicianWarnings] = useGetTechnicianWarnings(
    selectedDepartment?.id,
    dayjs.unix(selectedDate).format('YYYY-MM-DD')
  );

  const assignmentSrc = useMUAssignmentListValidated({
    departmentId: selectedDepartment?.id,
    date: dayjs.unix(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  });
  const assignments = assignmentSrc?.result?.items;

  const { result: mobileUnitsList } = useMobileUnitList({
    skip: !selectedDepartment?.id,
    departmentIds: selectedDepartment ? [selectedDepartment.id] : undefined,
  });
  const mobileUnits = mobileUnitsList?.items;

  const handleDragStart = useDragStart({
    setMovingTechnician,
  });

  const handleDragEnd = useDragEnd({
    assignments: assignments ?? [],
    dataSources: [assignmentSrc],
    date: dayjs.unix(selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
  });

  const [reloadtechnicianTable, setRealodTechnicianTable] = useState(0);

  return (
    <div className={classes.root}>
      <SwitchHeader
        team={team}
        // allow setTeam only if user doesnt have default team
        setTeam={setTeam}
        date={selectedDate}
        setDate={setSelectedDate}
        department={selectedDepartment}
        setDepartment={setSelectedDepartment}
        departmentList={departmentSrc?.result}
      />

      {selectedDepartment && selectedDate ? (
        <>
          {mobileUnits?.length ? (
            <DndContext
              modifiers={[snapCenterToCursor]}
              onDragStart={handleDragStart}
              onDragEnd={(e) => {
                setTimeout(
                  () => setRealodTechnicianTable((prev) => prev + 1),
                  100
                );
                return handleDragEnd(e);
              }}
              autoScroll={false}
            >
              <div className={classes.contentWrapper}>
                {mobileUnits.map((mobileUnit) => {
                  const assignment = assignments?.find(
                    (assignment) => assignment.mobileUnit.id === mobileUnit.id
                  );
                  return (
                    <MobileUnitCard
                      key={mobileUnit.id}
                      mobileUnit={mobileUnit}
                      assignment={assignment}
                      mobileUnitWarnings={
                        assignment &&
                        technicianWarnings &&
                        assignment.id in technicianWarnings
                          ? technicianWarnings[
                              assignment.id as keyof typeof technicianWarnings
                            ]
                          : []
                      }
                    />
                  );
                })}
              </div>
              <TechnicianBar dataSource={movingTechnician} />

              <Dropppable
                id={TECHNICIAN_TABLE}
                className={classes.bottomWindow}
              >
                <TechnicianTables
                  key={reloadtechnicianTable}
                  department={selectedDepartment}
                  date={dayjs.unix(selectedDate).format('YYYY-MM-DD')}
                />
              </Dropppable>
            </DndContext>
          ) : (
            <Typography variant="h2" className={classes.missingSelectedTitle}>
              <FormattedMessage
                id="ZSD__MU_SWITCHER__NO_DATA"
                defaultMessage="Pre vybrané pracovisko a dátum nie sú dostupné žiadne dáta."
              />
            </Typography>
          )}
        </>
      ) : (
        <>
          <Typography variant="h2" className={classes.missingSelectedTitle}>
            <FormattedMessage
              id="ZSD__MU_SWITCHER__NOT_SELECTED"
              defaultMessage="Pre zobrazenie dát vyberte pracovisko a dátum."
            />
          </Typography>
        </>
      )}
    </div>
  );
}
