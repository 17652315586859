import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  Autocomplete,
  FormContext,
  FormFieldWrapper,
  useFormSelector,
} from '@eas/common-web';
import { useCollectionSource } from '@components/collection-field/collection-api';
import { DepartmentAutocomplete, MobileUnitAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { labelMapper } from '@utils';

export function useValidationSchema() {
  return Yup.object({
    id: Yup.string().required('Povinné pole'),
  });
}

async function getMobileUnit(id: string) {
  const response = await fetch(`${EvidenceApiUrl.MOBILE_UNITS}/${id}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const json = await response.json();

  return json;
}

export const assignDialogFactory = (currentDepartmentId?: string) =>
  function MobileUnitAssignDialog() {
    const source = useCollectionSource<MobileUnitAutocomplete>(
      EvidenceApiUrl.MOBILE_UNITS,
      'department.id',
      currentDepartmentId
    );

    const { setFieldValues, errors, resetValidation } = useContext(FormContext);
    const mobileUnit = useFormSelector((values: MobileUnitAutocomplete) =>
      values?.id ? values : null
    );

    return (
      <FormFieldWrapper
        required={true}
        disabled={false}
        label=""
        labelOptions={{ hide: true }}
        layoutOptions={{}}
        errorOptions={{}}
        errors={errors}
      >
        <Autocomplete<DepartmentAutocomplete>
          source={source}
          labelMapper={labelMapper}
          onChange={async (values) => {
            resetValidation();
            const formData = values as DepartmentAutocomplete;

            const mobileUnit = await getMobileUnit(formData.id);

            setFieldValues({
              ...mobileUnit,
              department: {
                id: currentDepartmentId,
              },
            });
          }}
          value={mobileUnit}
        />
      </FormFieldWrapper>
    );
  };
