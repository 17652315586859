import { GeoJSON as LeafletGeoJSON } from 'leaflet';
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Typography from '@material-ui/core/Typography';
import { Panel, PromptProvider } from '@eas/common-web';
import { BRATISLAVA_COORDS_GPS_POINT } from '@composite/map';
import {
  CatastralTileLayer,
  GeoJSON,
  MapContainer,
  WorkOrderMarker,
} from '@composite/map';
import { GPSPoint, WorkOrder } from '@models';
import { getDrivingInfo } from '../planner-api';
import { PlannerContext } from '../planner-context';
import { Leg } from '../planner-types';
import {
  addStartingPointCoords,
  getPointsWithId,
  stringifyCoords,
} from './utils';
import { formatMeters, formatSeconds } from './utils/format';

type EnhancedPoint = {
  point: [number, number];
  id?: string;
  label?: string | number;
  markerStyle?: CSSProperties;
  workOrderId?: number;
};

export function UnitPlanForm({
  id,
  workOrders,
  unitStartingPoint,
  addReturnPoint,
  addStartingPoint,
}: {
  id: string;
  workOrders: WorkOrder[];
  unitStartingPoint?: GPSPoint;
  addStartingPoint?: boolean;
  addReturnPoint?: boolean;
}) {
  const geoJsonRef = useRef<LeafletGeoJSON>(null);

  const [legs, setLegs] = useState<Leg[]>([]);
  const [points, setPoints] = useState<EnhancedPoint[]>([]);
  const { calendarRef } = useContext(PlannerContext);

  useEffect(() => {
    if (!workOrders.length) {
      return;
    }

    let coords = '';
    coords = stringifyCoords(workOrders);
    let points = [] as EnhancedPoint[];
    const startingPoint =
      unitStartingPoint?.latitude && unitStartingPoint.longitude
        ? unitStartingPoint
        : BRATISLAVA_COORDS_GPS_POINT;

    if (!addReturnPoint && !addStartingPoint) {
      points = getPointsWithId(workOrders);
      if (workOrders.length === 1) {
        coords = addStartingPointCoords(
          coords,
          BRATISLAVA_COORDS_GPS_POINT,
          'start'
        );
      }
    } else {
      points = getPointsWithId(workOrders);
      if (addStartingPoint) {
        points = [
          {
            point: [startingPoint.latitude!, startingPoint.longitude!],
            label: 'S',
            markerStyle: { borderColor: 'blue' },
          },
          ...points,
        ];
        coords = addStartingPointCoords(coords, startingPoint, 'start');
      }
      if (addReturnPoint) {
        points = [
          ...points,
          {
            point: [startingPoint.latitude!, startingPoint.longitude!],
            label: 'S',
            markerStyle: { borderColor: 'blue' },
          },
        ];

        coords = addStartingPointCoords(coords, startingPoint, 'end');
      }
    }
    setPoints(points);

    getDrivingInfo(coords).then((data) => {
      geoJsonRef.current?.clearLayers().addData(data.geometry);

      setLegs(data.legs);
    });
  }, [workOrders, unitStartingPoint, id, addStartingPoint, addReturnPoint]);

  if (calendarRef.current?.settings.pinnedMap) {
    return (
      <MapContainer>
        <CatastralTileLayer />
        <GeoJSON ref={geoJsonRef} />
        {points.map((point, index) => (
          <WorkOrderMarker
            key={index}
            label={`${point.label ?? ''}`}
            point={point.point}
            workOrderId={point.id}
            markerSyle={point.markerStyle}
            tooltip={{
              permanent: false,
              content:
                point.label === 'S' ? (
                  'Východiskový bod'
                ) : (
                  <span>
                    Pracovný príkaz: <b>{point.workOrderId}</b>
                  </span>
                ),
            }}
          />
        ))}
      </MapContainer>
    );
  }

  return (
    <PromptProvider>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div style={{ width: 900, height: 500 }}>
          <MapContainer>
            <CatastralTileLayer />
            <GeoJSON ref={geoJsonRef} />
            {points.map((point, index) => (
              <WorkOrderMarker
                key={index}
                label={`${point.label ?? ''}`}
                point={point.point}
                workOrderId={point.id}
                markerSyle={point.markerStyle}
                tooltip={{
                  permanent: false,
                  content:
                    point.label === 'S' ? (
                      'Východiskový bod'
                    ) : (
                      <span>
                        Pracovný príkaz: <b>{point.workOrderId}</b>
                      </span>
                    ),
                }}
              />
            ))}
          </MapContainer>
        </div>
        <div style={{ maxHeight: 500, overflow: 'auto' }}>
          {legs.map((leg, index) => (
            <Panel
              key={index}
              label={`Trasa ${points[index].label ?? index + 1}  ... ${
                points[index + 1]?.label ?? index + 2
              }`}
            >
              <div style={{ width: '300px' }}>
                <Typography variant="body1" style={{ marginLeft: 16 }}>
                  <b style={{ display: 'inline-block', width: 100 }}>Čas:</b>
                  {formatSeconds(leg.duration)}
                </Typography>
                <Typography variant="body1" style={{ marginLeft: 16 }}>
                  <b style={{ display: 'inline-block', width: 100 }}>
                    Vzdialenosť:
                  </b>
                  {formatMeters(leg.distance)}
                </Typography>
              </div>
            </Panel>
          ))}
        </div>
      </div>
    </PromptProvider>
  );
}
