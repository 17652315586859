import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { DepartmentAutocomplete, MobileUnitAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import {
  assignDialogFactory,
  useValidationSchema as useAssignValidationSchema,
} from './dialogs/mobile-unit-assign-dialog';
import { useColumns } from './mobile-unit-columns';
import { useFilterFields } from './mobile-unit-filter-fields';

interface MobileUnitFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<MobileUnitAutocomplete>>
  > {
  currentDepartment: DepartmentAutocomplete;
  source: ScrollableSource<MobileUnitAutocomplete>;
}

export function MobileUnitField({
  currentDepartment,
  source,
  ...overrides
}: MobileUnitFieldProps) {
  const AssignDialog = useMemo(
    () => assignDialogFactory(currentDepartment.id),
    [currentDepartment]
  );

  const filters = useFilterFields();

  return (
    <CollectionField<MobileUnitAutocomplete>
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__MOBILE_UNITS"
          defaultMessage="Mobilné jednotky"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: true,
        getUrl: () => EvidenceApiUrl.MOBILE_UNITS,
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNITS}/${collectionId}`,
      }}
      updateAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNITS}/${collectionId}`,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNITS}/${collectionId}`,
      }}
      assignAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNITS}/${collectionId}`,
        Dialog: AssignDialog,
        useSchema: useAssignValidationSchema,
      }}
      unassignAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNITS}/${collectionId}`,
        initValues: (mobileUnit) => ({
          ...mobileUnit,
          department: null,
        }),
      }}
      filters={filters}
      {...overrides}
    />
  );
}
