import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DatedEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useDepartmentAutocomplete } from '@modules/department/department-api';
import { MobileUnit } from '@models';

export function useColumns(): TableColumn<MobileUnit>[] {
  const intl = useIntl();
  const { columnCreated, columnUpdated } =
    DatedEvidence.useDatedColumns<MobileUnit>();

  return [
    {
      datakey: 'name',
      sortkey: 'name',
      filterkey: 'name',
      name: intl.formatMessage({
        id: 'ZSD__MOBILE_UNITS__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'department',
      sortkey: 'department.name',
      filterkey: 'department.id',
      name: intl.formatMessage({
        id: 'ZSD__MOBILE_UNITS__TABLE__COLUMN__DEPARTMENT',
        defaultMessage: 'Pracovisko',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useDepartmentAutocomplete
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'active',
      sortkey: 'active',
      filterkey: 'active',
      name: intl.formatMessage({
        id: 'ZSD__TEAMS__TABLE__COLUMN__ACTIVE',
        defaultMessage: 'Aktívna',
      }),
      width: 200,
      CellComponent: TableCells.BooleanCell,
      FilterComponent: TableFilterCells.FilterBooleanCell,
      sortable: true,
      filterable: true,
    },
    columnCreated,
    columnUpdated,
  ];
}
