import React, { RefObject, createContext } from 'react';
import { ScrollableSource } from '@eas/common-web';
import { WorkOrder } from '@models';
import { PlanCalendarSource } from './calendar/calendar-data-source';
import { CalendarHandle } from './calendar/calendar-types';

type PlanContextProps = {
  calendarSource: PlanCalendarSource | null;
  workOrderSource: ScrollableSource<WorkOrder> | null;
  calendarRef: RefObject<CalendarHandle>;
};

export const PlanContext = createContext<PlanContextProps>({
  calendarSource: null,
  workOrderSource: null,
  calendarRef: React.createRef(),
});
