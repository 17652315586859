import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormPanel, FormTextField } from '@eas/common-web';
import { CalendarTemplatePlanner } from './planner/planner';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_TEMPLATE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__CALENDAR_TEMPLATE__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_TEMPLATE__DETAIL__PANEL__DETAILS"
            defaultMessage="Plánovanie zmien"
          />
        }
      >
        <CalendarTemplatePlanner />
      </FormPanel>
    </>
  );
}
