import React from 'react';
import TextField from '@material-ui/core/TextField';
import { TextFieldProps, useEventCallback } from '@eas/common-web';

export function formTextFieldFactory({
  label,
  type,
}: {
  label?: string;
  type?: string;
}) {
  return function Field({
    onChange,
    value,
    name,
    className,
    error,
    helperText,
    form,
    disabled,
    size,
    endAdornment,
    classes,
  }: Pick<
    TextFieldProps,
    'onChange' | 'value' | 'name' | 'error' | 'form' | 'disabled'
  > & {
    helperText?: string;
    size?: 'medium' | 'small';
    endAdornment?: React.ReactNode;
    classes?: Record<string, string>;
  } & React.HTMLAttributes<HTMLInputElement>) {
    const handleChange = useEventCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }
    );

    return (
      <TextField
        variant="outlined"
        size={size}
        fullWidth
        label={label}
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        type={type}
        className={className}
        error={error}
        helperText={helperText}
        inputProps={{
          form,
        }}
        InputProps={{
          classes,
          endAdornment,
        }}
      />
    );
  };
}
