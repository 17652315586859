import { get } from 'lodash';
import React, { useContext } from 'react';
import { renderToString } from 'react-dom/server';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  DetailContext,
  DetailMode,
  LocaleName,
  LocaleProvider,
  TableFieldColumn,
  useFormSelector,
} from '@eas/common-web';

export const useStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: 14,
    fontWeight: 600,
    marginRight: 10,
  },
  link: {
    color: theme.palette.primary.main,
    marginRight: 10,
  },
}));

/**
 * Function to strip html tags.
 *
 * @param source
 */
function extractContent(source: string) {
  const span = document.createElement('span');
  span.innerHTML = source;

  let content = [span.textContent || span.innerText].toString();

  if (content.includes('\n')) {
    content = `"${content}"`;
  }

  return content.replace(/ +/g, ' ');
}

/**
 * Function to convert table cells into CSV.
 *
 * @param items
 * @param columns
 */
function toCSV<T>(items: T[], columns: TableFieldColumn<T>[]) {
  let csv = '';

  const keys = columns.map((col) => col.name);
  csv += keys.join(';') + '\n';

  for (const item of items) {
    csv +=
      columns
        .map((col) => {
          if (col.CellComponent) {
            const stringCell = renderToString(
              <LocaleProvider
                defaultLocale={LocaleName.cs}
                translationsUrl="/api/crzp/translations"
              >
                <col.CellComponent
                  index={0}
                  value={get(item, col.datakey, '')}
                  rowValue={item}
                  column={col}
                />
              </LocaleProvider>
            );

            return extractContent(stringCell);
          }
          return get(item, col.datakey, '-');
        })
        .join(';') + '\n';
  }
  return csv;
}

export function FormPanelCountByName<T>({
  name,
  columns = [],
  count,
  items,
}: {
  name: string;
  columns?: TableFieldColumn<T>[];
  count?: number; // if specified, countByName is overriden
  items?: T[]; // if specified, formik[name] is overriden
}) {
  const classes = useStyles();

  const detailCtx = useContext(DetailContext);

  const { length, data } = useFormSelector(
    (data: { [name: string]: any[] }) => ({
      length: get(data, `[${name}].length`, 0),
      data: get(data, name, []),
    })
  );

  const showButton = detailCtx?.mode === DetailMode.VIEW && columns.length > 0;

  return (
    <>
      {showButton && (
        <Button
          download={true}
          size="small"
          classes={{ root: classes.link }}
          variant="text"
          href=""
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            const rows = toCSV(items ?? data, columns);

            const csvContent = 'data:text/csv;charset=utf-8,' + '\ufeff' + rows;

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'export.csv');
            document.body.appendChild(link); // Required for FF

            link.click();
          }}
        >
          <span className={classes.textButton}>CSV</span>
          <GetAppIcon fontSize="small" />
        </Button>
      )}
      <FormPanelCount count={count ?? (length as number)} />
    </>
  );
}

export function FormPanelCount({ count }: { count: number | string }) {
  return (
    <>
      [{' '}
      <span
        style={{
          fontFamily: 'montserrat',
          fontWeight: 600,
        }}
      >
        {count}
      </span>{' '}
      ]
    </>
  );
}
