import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AuthoredEvidence,
  DictionaryAutocomplete,
  FormAutocomplete,
  FormPanel,
  FormTextField,
  useAutocompleteSource,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function Fields() {
  const workOrderTypesSource = useAutocompleteSource<DictionaryAutocomplete>({
    url: `${EvidenceApiUrl.WORK_ORDER_TYPES}/autocomplete`,
  });
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__SPHL__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__SPHL__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
          required
        />

        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__SPHL__DETAIL__PANEL__WORK_ORDER_TYPES"
            defaultMessage="Typy pracovných príkazov"
          />
        }
      >
        <FormAutocomplete
          source={workOrderTypesSource}
          name="workOrderTypes"
          multiple
        />
      </FormPanel>
    </>
  );
}
