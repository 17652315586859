import clsx from 'clsx';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { mobileUnitAssignmentDateLabelMapper } from 'src/utils/label-mapper';
import Typography from '@material-ui/core/Typography';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import WarningIcon from '@material-ui/icons/Warning';
import { NavigationContext, Tooltip } from '@eas/common-web';
import {
  MobileUnit,
  MobileUnitAssignmentValidated,
  MobileUnitWarnings,
} from '@models';
import { EvidenceBrowserUrl, mobileUnitErrors } from '@enums';
import { Dropppable } from '../dnd/droppable';
import { useStyles } from '../switcher-styles';
import { TechnicianSimpleRow } from '../technician-table/technician-simple-row';

export function MobileUnitCard({
  mobileUnit,
  assignment,
  mobileUnitWarnings,
}: {
  mobileUnit: MobileUnit;
  assignment: MobileUnitAssignmentValidated | undefined;
  mobileUnitWarnings: MobileUnitWarnings[];
}) {
  const classes = useStyles();
  const { navigate } = useContext(NavigationContext);

  return (
    <div className={classes.box}>
      <div className={classes.boxHeadings}>
        <div className={classes.boxTitleRow}>
          <Tooltip
            title={
              <FormattedMessage
                id="MU_SWITCHER__MOBILE_UNIT_ASSIGNMENT_SELECTED__OPEN_IN_BROWSER_TOOLTIP"
                defaultMessage="Otvorí sa detail mobilnej jednotky"
              />
            }
          >
            <OpenInBrowserIcon
              fontSize="small"
              color="primary"
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() =>
                navigate(`${EvidenceBrowserUrl.MOBILE_UNITS}/${mobileUnit.id}`)
              }
            />
          </Tooltip>
          <Typography variant="h2" className={classes.boxTitle}>
            {mobileUnit.name}
          </Typography>
        </div>
        {assignment && (
          <Typography variant="h2" className={classes.boxSubTitle}>
            {mobileUnitAssignmentDateLabelMapper(assignment)}
          </Typography>
        )}
      </div>

      <div className={classes.boxContent}>
        <Dropppable
          id={
            assignment
              ? `leader:assignment:${assignment.id}`
              : `leader:mu:${mobileUnit.id}`
          }
          className={classes.droppable}
        >
          <div className={classes.boxContentTitle}>
            <FormattedMessage
              id={'ZSD__MOBILE_UNIT_ASSIGNMENT_SELECTED_LEAD'}
              defaultMessage={'Vedúci technik:'}
            />
          </div>
          {assignment && (
            <TechnicianSimpleRow
              value={assignment.leadTechnician}
              dragOrigin={assignment.id}
              isLeader={true}
              warnings={
                mobileUnitWarnings.find(
                  (warn) => warn.user.id === assignment.leadTechnician?.id
                )?.warnings
              }
            />
          )}
        </Dropppable>
        <Dropppable
          id={
            assignment
              ? `other:assignment:${assignment.id}`
              : `other:mu:${mobileUnit.id}`
          }
          className={clsx(classes.droppable, classes.droppableOther)}
        >
          <div className={classes.boxContentTitle}>
            <FormattedMessage
              id={'ZSD__MOBILE_UNIT_ASSIGNMENT_SELECTED_OTHERS'}
              defaultMessage={'Ostatní technici:'}
            />
          </div>

          {assignment?.technicians.map((technician) => (
            <TechnicianSimpleRow
              key={technician.id}
              value={technician}
              warnings={
                mobileUnitWarnings.find(
                  (warn) => warn.user.id === technician.id
                )?.warnings
              }
              dragOrigin={assignment.id}
            />
          ))}
        </Dropppable>

        {assignment && assignment.validationErrors.length > 0 && (
          <div className={classes.boxErrors}>
            {assignment.validationErrors.map((error) => (
              <div key={error} className={classes.boxErrorsRow}>
                <WarningIcon className={classes.icon} />
                <div className={classes.boxErrorsTitle}>
                  {error in mobileUnitErrors
                    ? mobileUnitErrors[error as keyof typeof mobileUnitErrors]
                    : error}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
