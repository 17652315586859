import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import LayersClear from '@material-ui/icons/LayersClear';
import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
import { UnitPlanForm } from '@modules/planner/unit-menu/unit-plan-form';
import { WorkOrder } from '@models';
import { MapWithMarkers } from './map-with-markers';
import { PinnedMapContext } from './pinned-map-context';
import { useStyles } from './pinned-map-styles';

export function PinnedMap() {
  const { data, mapContainerKey, updateMapData, updateMapContainerKey } =
    useContext(PinnedMapContext);

  const classes = useStyles();

  if (!data) {
    return (
      <div className={classes.mapWrapper}>
        <MapWithMarkers
          key={`POINTS_MAP_KEY_${mapContainerKey}`}
          markers={[]}
        />
      </div>
    );
  }

  return (
    <>
      <div className={classes.mapWrapper}>
        <div className={classes.customControlButtons}>
          <Tooltip title="Resetovať mapu" placement="left">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => updateMapData(undefined)}
              style={{ minWidth: 0 }}
            >
              <LayersClear />
            </Button>
          </Tooltip>
          <Tooltip title="Resetovať zoom" placement="left">
            <Button
              variant="contained"
              color="secondary"
              onClick={updateMapContainerKey}
              style={{ minWidth: 0 }}
            >
              <ZoomOutMap />
            </Button>
          </Tooltip>
        </div>
        {data.variant === 'travel' && (
          <UnitPlanForm
            key={`TRAVEL_MAP_KEY_${mapContainerKey}`}
            id={data.travel.unitId}
            workOrders={
              (data.travel.workOrders?.filter((wo) => !!wo) ??
                []) as WorkOrder[]
            }
            addStartingPoint={data.travel.addStartingPoint}
            addReturnPoint={data.travel.addReturnPoint}
            unitStartingPoint={data.travel.startingPoint}
          />
        )}
        {data.variant === 'points' && (
          <MapWithMarkers
            key={`POINTS_MAP_KEY_${mapContainerKey}`}
            markers={data.points}
          />
        )}
      </div>
    </>
  );
}
