import { PermissionGroup } from 'src/models/permission';
import { useAutocompleteSource, useFetch } from '@eas/common-web';
import { RoleAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function usePermissions() {
  return useFetch<PermissionGroup[]>(`${EvidenceApiUrl.PERMISSIONS}`, {
    method: 'GET',
  });
}

export function useRoles() {
  return useAutocompleteSource<RoleAutocomplete>({
    url: `${EvidenceApiUrl.ROLES}/autocomplete`,
  });
}
