import React from 'react';
import { useIntl } from 'react-intl';
import { DatedEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { AbsenceType } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils';
import { useColumns } from './absence-type-columns';
import { Fields } from './absence-type-fields';
import { useValidationSchema } from './absence-type-schema';

export function AbsenceTypes() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.ABSENCE_TYPE_WRITE],
      EDIT: [Permission.ABSENCE_TYPE_WRITE],
      REMOVE: [Permission.ABSENCE_TYPE_WRITE],
    },
  });

  const evidence = DatedEvidence.useDatedEvidence<AbsenceType>({
    identifier: 'ABSENCE_TYPE',
    apiProps: {
      url: EvidenceApiUrl.ABSENCE_TYPES,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'ZSD__ABSENCE_TYPE__TABLE__TITLE',
        defaultMessage: 'Typy absencií',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__ABSENCE_TYPE__DETAIL__TITLE',
          defaultMessage: 'Typ absencie',
        }),
        subtitle: (state) => state?.name ?? '',
        showBtn: btnPermissions,
      },
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
