import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Filter, ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { Calendar, DictionaryAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './calendar-columns';
import { useFilterFields } from './calendar-filter-fields';
import { useValidationSchema } from './calendar-schema';
import { CalendarCreateDialog } from './dialogs/calendar-create-dialog';

interface CalendarFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField<Calendar>>> {
  refKey: string;
  refFilters?: Filter[];
  currentRef: DictionaryAutocomplete;
  source: ScrollableSource<Calendar>;
}

export function CalendarField({
  refKey,
  refFilters,
  currentRef,
  source,
  ...overrides
}: CalendarFieldProps) {
  const filters = useFilterFields();

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__CALENDARS"
          defaultMessage="Šablóny"
        />
      }
      defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => EvidenceApiUrl.CALENDARS,
        Dialog: CalendarCreateDialog,
        initValues: () => ({
          [refKey]: currentRef,
        }),
        useSchema: useValidationSchema,
      }}
      readAction={{
        getUrl: (collectionId) => `${EvidenceApiUrl.CALENDARS}/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDARS}/${collectionId}`,
        Dialog: CalendarCreateDialog,
        useSchema: useValidationSchema,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDARS}/${collectionId}`,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDARS}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.CALENDARS}/${collectionId}`,
        initValues: (calendar) => ({
          ...calendar,
          section: null,
        }),
      }}
      filters={filters}
      {...overrides}
    />
  );
}
