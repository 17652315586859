import dayjs from 'dayjs';
import React, { useRef } from 'react';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PersonIcon from '@material-ui/icons/Person';
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip } from '@eas/common-web';
import { UnitMenu, UnitMenuHandle } from '@modules/planner/unit-menu/unit-menu';
import { Absence } from '@models';
import { CalendarDataType } from '../../calendar-types';
import { useStyles } from './legend-styles';

interface UnitProps {
  item: CalendarDataType;
  style: React.CSSProperties;
}

export function Unit({ item, style }: UnitProps) {
  const classes = useStyles();

  const menuRef = useRef<UnitMenuHandle>(null);

  const absences: Absence[] = item.absences ?? [];
  const absenceTooltip = absences
    .filter((absence) => absence.allowed)
    .map((absence) => {
      let dateValue = '';
      if (absence.fromDate) {
        dateValue = dayjs(absence.fromDate).format('DD. MM. YYYY HH:mm');
      }
      if (absence.toDate) {
        dateValue += ` - ${(dateValue = dayjs(absence.toDate).format(
          'DD. MM. YYYY HH:mm'
        ))}`;
      }

      return `${absence.absenceType?.name} (${dateValue})`;
    })
    .join(', ');

  const tooltipMessages = [
    item.department?.isCalamity &&
      'Pracovisko sa aktuálne nachádza v stave kalamity',
    !!absenceTooltip && `Absencie: ${absenceTooltip}`,
  ].filter(Boolean);

  return (
    <div
      className={classes.legendListItem}
      style={style}
      onContextMenu={(e) => {
        e.preventDefault();

        menuRef.current?.open(e);
      }}
    >
      {item.name}
      <div>
        {!!tooltipMessages.length && (
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
                {tooltipMessages.join('\n')}
              </div>
            }
          >
            <WarningIcon className={classes.legendIcon} fontSize="small" />
          </Tooltip>
        )}
        {item.unitType === 'user' && (
          <PersonIcon className={classes.legendIcon} fontSize="small" />
        )}
        {item.unitType === 'mobileUnit' && (
          <DirectionsCarIcon className={classes.legendIcon} fontSize="small" />
        )}
      </div>
      <UnitMenu
        unit={{
          id: item.id,
          name: item.name,
          unitType: item.unitType,
          latitude: item.latitude,
          longitude: item.longitude,
          currentLocation: item.currentLocation,
        }}
        workOrders={item.workOrders}
        ref={menuRef}
      />
    </div>
  );
}
