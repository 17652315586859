import dayjs from 'dayjs';
import React, { forwardRef, useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import EventIcon from '@material-ui/icons/Event';
import { PlannerContext } from '@modules/planner/planner-context';
import { MobileUnit, WorkOrder, ZsdUser } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
};

export const ShowInCalendar = forwardRef<HTMLLIElement, MenuItemProps>(
  function DetailsMenuItem({ closeMenu, workOrder }, ref) {
    const planner = useContext(PlannerContext);

    const handleChange = useCallback(() => {
      closeMenu();
      if (
        !workOrder.startTime ||
        !planner.calendarRef.current ||
        !planner.calendarSource?.unitsSource ||
        !planner.calendarSource?.departmentsSource
      ) {
        return;
      }
      const unit = workOrder.user || workOrder.mobileUnit;
      const department = workOrder.department;
      if (!unit || !department) {
        return;
      }
      const workOrderDate = dayjs(workOrder.startTime).startOf('day').unix();

      const unitIndex = planner.calendarSource.unitsSource.selected?.findIndex(
        (s) => s.id === workOrder.user?.id || s.id === workOrder.mobileUnit?.id
      );

      const departmentIndex =
        planner.calendarSource.departmentsSource.selected?.findIndex(
          (d) => d.id === department?.id
        );

      if (unitIndex === -1) {
        planner.calendarSource.unitsSource.setSelected((p) => [
          ...(p ?? []),
          unit as ZsdUser | MobileUnit,
        ]);
      }

      if (departmentIndex === -1) {
        planner.calendarSource.departmentsSource.setSelected((p) => [
          ...(p ?? []),
          department,
        ]);
      }

      planner.calendarRef.current.setCurrentDate(workOrderDate);
      planner.calendarSource?.yAxis.refreshData?.();
      planner.setHighlightedWorkOrder(workOrder);
    }, [closeMenu, workOrder, planner]);

    return (
      <MenuItem ref={ref} onClick={handleChange}>
        <ListItemIcon>
          <EventIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage
          id="WORK_ORDER_MENU_ITEM_SHOW_IN_CALENDAR"
          defaultMessage="Zobraz v kalendári"
        />
      </MenuItem>
    );
  }
);
