import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  ApiFilterOperation,
  Autocomplete,
  FormContext,
  FormFieldWrapper,
  useFormSelector,
} from '@eas/common-web';
import { useCollectionSource } from '@components/collection-field/collection-api';
import { DepartmentAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { labelMapper } from '@utils';

export function useValidationSchema() {
  return Yup.object({
    id: Yup.string().required('Povinné pole'),
  });
}

async function getDepartment(id: string) {
  const response = await fetch(`${EvidenceApiUrl.DEPARTMENTS}/${id}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const json = await response.json();

  return json;
}

export const assignDialogFactory = (currentTeamId?: string) =>
  function DepartmentAssignDialog() {
    const source = useCollectionSource<DepartmentAutocomplete>(
      EvidenceApiUrl.DEPARTMENTS,
      'team.id',
      currentTeamId,
      [
        {
          field: 'team.id',
          operation: ApiFilterOperation.IS_NULL,
        },
      ]
    );

    const { setFieldValues, errors, resetValidation } = useContext(FormContext);
    const department = useFormSelector((values: DepartmentAutocomplete) =>
      values?.id ? values : null
    );

    return (
      <FormFieldWrapper
        required={true}
        disabled={false}
        label=""
        labelOptions={{ hide: true }}
        layoutOptions={{}}
        errorOptions={{}}
        errors={errors}
      >
        <Autocomplete<DepartmentAutocomplete>
          source={source}
          labelMapper={labelMapper}
          onChange={async (values) => {
            resetValidation();
            const formData = values as DepartmentAutocomplete;

            const department = await getDepartment(formData.id);

            setFieldValues({
              ...department,
              team: {
                id: currentTeamId,
              },
            });
          }}
          value={department}
        />
      </FormFieldWrapper>
    );
  };
