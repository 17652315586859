import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '500px',
  },
  divider: {
    margin: '1rem 0',
  },
  searchInput: {
    paddingRight: 0,
  },
  searchButton: {
    width: 36,
    minWidth: 36,
    height: 'calc(1.1876em + 19px)',
    marginRight: 1,
    marginLeft: 8,
  },
}));

export const useOuterStyles = makeStyles(() => ({
  summaryRoot: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  detailsRoot: {
    display: 'block',
  },
  labelRoot: {
    fontSize: 14,
  },
}));

export const useInnerStyles = makeStyles(() => ({
  root: {
    margin: '0 !important',
    borderLeft: '1px solid #e0e0e0',
    '&::after': {
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '1rem',
      left: 0,
      width: '10px',
      height: '0.8px',
      backgroundColor: '#e0e0e0',
    },
    '&:last-child': {
      borderImage:
        'linear-gradient(to bottom, #e0e0e0 1rem, transparent 1rem) 0 0 0 1',
    },
  },
  summaryRoot: {
    backgroundColor: 'inherit',
    paddingRight: 0,
  },
  detailsRoot: {
    // hides checkbox group border
    marginBottom: -2,
  },
  labelRoot: {
    fontWeight: 400,
    fontSize: 14,
  },
}));
