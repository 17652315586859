import cn from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { CSSProperties, useCallback, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { substractTime } from '@modules/plan/calendar/utils';
import {
  getCollisions,
  getPositions,
} from '@modules/plan/calendar/utils/get-collisions';
import { getScaleItemWidth } from '@modules/plan/calendar/utils/get-scale-item-width';
import { getSecondsInPixel } from '@modules/plan/calendar/utils/get-seconds-in-pixel';
import { ShiftInterval } from '@models';
import { CalendarContext } from '../../../calendar-context';
import { PlanCalendarDataType } from '../../../calendar-types';
import { AbsenceBar } from './absence/absence-bar';
import { useStyles } from './bars-row-styles';
import { SingleDayShift } from './shift/shift-single-day';
import { Task } from './task/task';

dayjs.extend(utc);
dayjs.extend(timezone);

interface BarsRowProps {
  barData: PlanCalendarDataType;
  index: number;
  style: CSSProperties;
}

export function BarsRow({ barData, style }: BarsRowProps) {
  const { settings } = useContext(CalendarContext);
  const classes = useStyles();
  const theme = useTheme();

  const isToday = dayjs().isSame(dayjs.unix(barData.date), 'day');

  const getBarStyle = useCallback(
    (isBlocked: boolean) => {
      const scaleItemWidth = getScaleItemWidth(settings);

      if (isBlocked) {
        return {
          width: scaleItemWidth,
          background: `repeating-linear-gradient(135deg, #FFF 0 3px, ${theme.palette.error.light}64 0px 5px )`,
          backgroundSize: 35,
        };
      }

      return {
        width: scaleItemWidth,
        backgroundColor: settings.colorForShiftV,
      };
    },
    [settings, theme.palette.error.light]
  );

  const getShiftStyle = useCallback(
    (shiftInterval: ShiftInterval, hour: number, multipleShifts?: boolean) => {
      const intervalSize =
        getScaleItemWidth(settings) /
        (settings.endingHour - settings.startingHour);
      const secondsInPixel = getSecondsInPixel(settings);

      let width = intervalSize;
      let left = 0;
      let right = 0;

      if (shiftInterval) {
        let diff = substractTime(
          shiftInterval.startingHour,
          `${hour + settings.startingHour}:00`
        );
        if (diff < 1 && diff > 0) {
          left = (diff * 60 * 60) / secondsInPixel;
        }

        diff = substractTime(
          shiftInterval.endingHour,
          `${hour + settings.startingHour}:00`
        );
        if (diff < 1 && diff > 0) {
          right = intervalSize - (diff * 60 * 60) / secondsInPixel;
        }

        width = intervalSize - left - right;
      }

      if (shiftInterval?.type === 'P') {
        return {
          width,
          left,
          right,
          background: settings.colorForShiftP,
        };
      }

      if (shiftInterval?.type === 'ZP') {
        return {
          width,
          left,
          right,
          background: settings.colorForShiftZP,
        };
      }

      if (shiftInterval?.type === 'Z') {
        return {
          width,
          left,
          right,
          background: settings.colorForShiftZ,
        };
      }

      if (shiftInterval?.type === 'A') {
        return {
          width,
          left,
          right,
          background: settings.colorForShiftA,
        };
      }

      if (shiftInterval?.type === 'O') {
        return {
          width,
          left,
          right,
          background: `repeating-linear-gradient(135deg, #FFF 0 3px, ${theme.palette.error.light}64 0px 5px )`,
          backgroundSize: 35,
        };
      }

      // multipleShifts: Render free time interval only if another layer has already been drawn and needs to be covered.
      if (multipleShifts && shiftInterval?.type === 'V') {
        return {
          width,
          left,
          right,
          background: settings.colorForShiftV,
        };
      }

      return {};
    },
    [settings, theme.palette.error.light]
  );

  const collisions = getCollisions(barData.workOrders ?? []);
  const positions = getPositions(barData.workOrders ?? [], collisions);

  const maxPosition = Math.max(...Object.values(positions));

  return (
    <div
      style={{
        ...style,
        height: settings.rowHeight,
      }}
      className={cn(classes.steps, { [classes.currentDay]: isToday })}
    >
      <SingleDayShift
        barData={barData}
        getBarStyle={getBarStyle}
        getShiftStyle={getShiftStyle}
      />

      {barData.workOrders?.map((workOrder) => (
        <Task
          key={workOrder.id}
          workOrder={workOrder}
          overlayCount={collisions[workOrder.id].length > 0 ? maxPosition : 1}
          position={positions[workOrder.id] ?? 1}
          date={barData.date}
        />
      ))}
      <AbsenceBar barData={barData} />
    </div>
  );
}
