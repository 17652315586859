import { ApiFilterOperation, useScrollableSource } from '@eas/common-web';
import { useWorkOrdersAbortableList } from '@modules/work-order/work-order-api';
import { WorkOrder } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useWorkOrderDataSource() {
  return useScrollableSource<WorkOrder>({
    url: `${EvidenceApiUrl.WORK_ORDERS}/list`,
    params: {
      filters: [
        {
          field: 'currentState',
          operation: ApiFilterOperation.NOT_NULL,
        },
      ],
    },
    listItems: useWorkOrdersAbortableList,
  });
}
