import DayjsUtils from '@date-io/dayjs';
import cn from 'clsx';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useStyles as useButtonStyles } from './styles';

dayjs.extend(isBetween);

const useStyles = makeStyles((theme) => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}));

export function WeekPicker({
  currentDate,
  onChange,
  labelMapper,
}: {
  currentDate: number;
  onChange: (value: number) => void;
  labelMapper: (value: number) => string;
}) {
  const [open, setOpen] = useState(false);
  const pickerClasses = useStyles();

  const classes = useButtonStyles();

  const intl = useIntl();

  const handleChange = useCallback(
    (value: MaterialUiPickersDate) => {
      if (!value) {
        return;
      }

      const date = value.unix();

      onChange(date);

      setOpen(false);
    },
    [onChange]
  );

  const renderWrappedWeekDay = useCallback(
    (
      date: MaterialUiPickersDate,
      selectedDate: MaterialUiPickersDate,
      dayInCurrentMonth: boolean
    ) => {
      const start = dayjs(selectedDate);
      const end = dayjs(selectedDate).add(6, 'day');

      const dayIsBetween = dayjs(date).isBetween(start, end, 'day', '[]');
      const isFirstDay = dayjs(date).isSame(start, 'day');
      const isLastDay = dayjs(date).isSame(end, 'day');

      const wrapperClassName = cn({
        [pickerClasses.highlight]: dayIsBetween,
        [pickerClasses.firstHighlight]: isFirstDay,
        [pickerClasses.endHighlight]: isLastDay,
      });

      const dayClassName = cn(pickerClasses.day, {
        [pickerClasses.nonCurrentMonthDay]: !dayInCurrentMonth,
        [pickerClasses.highlightNonCurrentMonthDay]:
          !dayInCurrentMonth && dayIsBetween,
      });

      return (
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span> {dayjs(date).format('D')} </span>
          </IconButton>
        </div>
      );
    },
    [
      pickerClasses.day,
      pickerClasses.endHighlight,
      pickerClasses.firstHighlight,
      pickerClasses.highlight,
      pickerClasses.highlightNonCurrentMonthDay,
      pickerClasses.nonCurrentMonthDay,
    ]
  );

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <DatePicker
        value={new Date(currentDate * 1000)}
        open={open}
        openTo="date"
        cancelLabel={intl.formatMessage({
          id: 'ZSD__DATEPICKER__BUTTON_CANCEL',
          defaultMessage: 'Zrušiť',
        })}
        okLabel={intl.formatMessage({
          id: 'ZSD__DATEPICKER__BUTTON_OK',
          defaultMessage: 'Potvrdiť',
        })}
        onClose={() => setOpen(false)}
        onChange={handleChange}
        renderDay={renderWrappedWeekDay}
        TextFieldComponent={function Input() {
          return (
            <Button
              variant="outlined"
              className={cn(classes.button, classes.basicButton)}
              endIcon={<CalendarTodayIcon fontSize="small" />}
              onClick={() => setOpen(true)}
            >
              {labelMapper(currentDate)}
            </Button>
          );
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
