import { BRATISLAVA_COORDS } from '@composite/map';
import { GPSPoint, WorkOrder } from '@models';

export function stringifyCoords(workOrders: (WorkOrder | undefined)[]) {
  return workOrders
    .filter((workOrder) => !!workOrder)
    .map((workOrder) => {
      const long = workOrder?.longitude ?? BRATISLAVA_COORDS[0];
      const lat = workOrder?.latitude ?? BRATISLAVA_COORDS[1];

      return `${long},${lat}`;
    })
    .join(';');
}

export function addStartingPointCoords(
  coords: string,
  startingPoint: GPSPoint,
  position: 'start' | 'end' | 'both'
): string {
  console.log({ position });
  const startingPointCoords = `${
    startingPoint?.longitude ?? BRATISLAVA_COORDS[0]
  },${startingPoint?.latitude ?? BRATISLAVA_COORDS[1]}`;

  switch (position) {
    case 'start':
      return `${startingPointCoords};${coords}`;
    case 'end':
      return `${coords};${startingPointCoords}`;
    default:
      return `${startingPointCoords};${coords};${startingPointCoords}`;
  }
}
