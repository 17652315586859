import React from 'react';
import {
  DatedEvidence,
  DictionaryEvidence,
  FormAutocomplete,
  FormNumberField,
} from '@eas/common-web';
import { useTeams } from '@modules/team/team-api';
import { useWorkOrderTypes } from '@modules/work-order-type/work-order-type-api';

export function Fields() {
  const workOrderTypes = useWorkOrderTypes();
  const teams = useTeams();

  return (
    <>
      <DictionaryEvidence.FieldName />
      <FormAutocomplete
        name="workOrderTypes"
        label="Druhy pracovních príkazov"
        source={workOrderTypes}
        multiple
      />
      <FormAutocomplete name="teams" label="Tímy" source={teams} multiple />
      <FormNumberField name="operationNumber" label="Poradové číslo" />
      <DatedEvidence.DatedFields />
    </>
  );
}
