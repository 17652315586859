import { useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export const useDynamicFields = (id: string) => {
  return useFetch<any[]>(
    `${EvidenceApiUrl.WORK_ORDER_DYNAMIC_ATTRIBUTES}/${id}`,
    {
      method: 'GET',
    }
  );
};
