import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormTextField } from '@eas/common-web';

export function AddressFields({ addressKey }: { addressKey: string }) {
  return (
    <>
      <FormTextField
        name={`${addressKey}.city`}
        label={
          <FormattedMessage
            id="ZSD__ADDRESS__TABLE__COLUMN__CITY"
            defaultMessage="Mesto"
          />
        }
      />
      <FormTextField
        name={`${addressKey}.street`}
        label={
          <FormattedMessage
            id="ZSD__ADDRESS__TABLE__COLUMN__STREET"
            defaultMessage="Ulica"
          />
        }
      />
      <FormTextField
        name={`${addressKey}.houseNumber`}
        label={
          <FormattedMessage
            id="ZSD__ADDRESS__TABLE__COLUMN__HOUSE_NUMBER"
            defaultMessage="Číslo popisné"
          />
        }
      />
      <FormTextField
        name={`${addressKey}.postCode`}
        label={
          <FormattedMessage
            id="ZSD__ADDRESS__TABLE__COLUMN__POST_CODE"
            defaultMessage="PSČ"
          />
        }
      />
      <FormTextField
        name={`${addressKey}.building`}
        label={
          <FormattedMessage
            id="ZSD__ADDRESS__TABLE__COLUMN__BUILDING"
            defaultMessage="Budova"
          />
        }
      />
      <FormTextField
        name={`${addressKey}.additionalInfo`}
        label={
          <FormattedMessage
            id="ZSD__ADDRESS__TABLE__COLUMN__ADDITIONAL_INFO"
            defaultMessage="Dodatok"
          />
        }
      />
    </>
  );
}
