import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { handleApiErrors } from 'src/utils/error-handler';
import {
  DatedEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
} from '@eas/common-web';
import { Department } from '@models';
import { EvidenceApiUrl, Permission, regionApiErrors } from '@enums';
import { useEvidencePermission } from '@utils';
import { ActionBar } from './department-actionbar';
import { useColumns } from './department-columns';
import { Fields } from './department-fields';
import { useDepartmentHookedSources } from './department-hooked-sources';
import { useValidationSchema } from './department-schema';

export function Departments() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.DEPARTMENT_WRITE],
      EDIT: [Permission.DEPARTMENT_WRITE],
      REMOVE: [Permission.DEPARTMENT_WRITE],
    },
  });

  const { showSnackbar } = useContext(SnackbarContext);

  const evidence = DatedEvidence.useDatedEvidence<Department>({
    version: 2,
    identifier: 'DEPARTMENT',
    apiProps: {
      url: EvidenceApiUrl.DEPARTMENTS,
      hookedSources: useDepartmentHookedSources,
      ...handleApiErrors(regionApiErrors.DEPARTMENT, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__DEPARTMENTS__TABLE__TITLE',
        defaultMessage: 'Pracoviská',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__DEPARTMENTS__DETAIL__TITLE',
          defaultMessage: 'Pracoviská',
        }),
        showBtn: btnPermissions,
        ActionBar,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
