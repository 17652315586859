import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ScrollableSource, Tooltip, useFormSelector } from '@eas/common-web';

export const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 600,
  },
}));

export function CollectionCount<T>({
  source,
  formKey,
  items = [],
}: {
  source?: ScrollableSource<T>;
  formKey?: string;
  items?: T[];
}) {
  const classes = useStyles();

  const { formItems } = useFormSelector((values: Record<string, []>) => ({
    formItems: values?.[formKey ?? ''] ?? [],
  }));

  let label = `[ ${items.length} ]`;
  if (source) {
    label = `[ ${source.items.length} / ${source.count} ]`;
  }
  if (formKey) {
    label = `[ ${formItems.length} ]`;
  }

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="ZSD__COLLECTION_COUNT"
          defaultMessage="Čítanie / Celkový počet záznamov"
        />
      }
    >
      <Typography variant="body1" display="inline" className={classes.label}>
        {label}
      </Typography>
    </Tooltip>
  );
}
