import React, { useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { CalendarContext } from '@modules/plan/calendar/calendar-context';
import { getSecondsInPixel } from '@modules/plan/calendar/utils/get-seconds-in-pixel';

export function TravelTime({
  orientation,
  seconds,
}: {
  orientation: 'left' | 'right';
  seconds: number;
}) {
  const theme = useTheme();

  const { settings } = useContext(CalendarContext);
  const secondsInPixel = getSecondsInPixel(settings);

  const pixels = seconds / secondsInPixel;

  return (
    <div
      style={{
        position: 'absolute',
        top: 2,
        zIndex: 10,
        width: `${pixels}px`,
        left: orientation === 'left' ? `${-1 * pixels}px` : undefined,
        right: orientation === 'right' ? `${-1 * pixels}px` : undefined,
        height: 'calc(100% - 4px)',
        backgroundColor: theme.palette.grey[200],
        borderRadius: '3px',
        background: `repeating-linear-gradient(135deg, #FFF 0 3px, ${theme.palette.grey[500]} 0px 5px )`,
        backgroundSize: 35,
      }}
    />
  );
}
