import { CalendarSettingsType } from '../calendar-types';

export function getScaleItems(settings: Partial<CalendarSettingsType>) {
  const { endingHour = 24, startingHour = 0 } = settings;

  const hours = endingHour - startingHour;

  return new Array(hours).fill(0).map((_, index) => {
    return `${index + startingHour}:00`;
  });
}
