import cn from 'clsx';
import React, { useCallback, useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Dialog, DialogHandle } from '@eas/common-web';
import {
  DepartmentDialog,
  DepartmentDialogHandle,
} from '@composite/department-field/department-dialog';
import { Department } from '@models';
import { CalendarContext } from '../../calendar-context';

export function DepartmentControl() {
  const intl = useIntl();
  const { classes, dataSource } = useContext(CalendarContext);

  const dialogRef = useRef<DialogHandle | null>(null);
  const dialogFieldsRef = useRef<DepartmentDialogHandle | null>(null);

  const selectedDepartments = dataSource.departmentsSource?.selected ?? [];

  const handleConfirm = useCallback(() => {
    const selected = dialogFieldsRef.current?.getValue() as Department[];

    dataSource.departmentsSource.setSelected(selected);
    dataSource.unitsSource.setSelected((unit) =>
      unit?.filter((u) =>
        selected.map((s) => s.id).includes(u.department?.id ?? '')
      )
    );

    dataSource.yAxis.refreshData();
  }, [dataSource]);

  return (
    <>
      <Dialog
        title={
          <FormattedMessage
            id="ZSD__USERS__DETAIL_FIELD_LABEL__DEPARTMENT_TITLE"
            defaultMessage="Výber oblasti"
          />
        }
        ref={dialogRef}
        showConfirm={true}
        onConfirm={handleConfirm}
      >
        {() => (
          <DepartmentDialog
            ref={dialogFieldsRef}
            defaultValue={selectedDepartments}
            multiple={true}
          />
        )}
      </Dialog>
      <Button
        variant="outlined"
        className={cn(classes.button, classes.basicButton)}
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={() => dialogRef.current?.open()}
        title={intl.formatMessage({
          id: 'ZSD__USERS__DETAIL_FIELD_LABEL__DEPARTMENT_TITLE',
          defaultMessage: 'Výber oblasti',
        })}
      >
        {selectedDepartments.length > 0 ? selectedDepartments[0].name : '-'}
        {selectedDepartments.length > 1 && (
          <span className={classes.badge}>
            +{selectedDepartments.length - 1}
          </span>
        )}
      </Button>
    </>
  );
}
