import dayjs from 'dayjs';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';
import { getSecondsInPixel } from './get-seconds-in-pixel';

export function getNowOffset(
  currentDateUnix: number,
  settings: Partial<CalendarSettingsType>
) {
  const {
    dimension = CalendarDimensions.WEEK,
    legendWidth = 200,
    startingHour = 0,
    endingHour = 24,
  } = settings;

  const secondsInPixel = getSecondsInPixel(settings);

  const currentDate = dayjs.unix(currentDateUnix);
  const now = dayjs(new Date());

  switch (dimension) {
    case CalendarDimensions.DAY: {
      if (
        !currentDate.isSame(now, 'day') ||
        now.hour() < startingHour ||
        now.hour() >= endingHour
      ) {
        return 0;
      }

      const start = dayjs(`${now.hour()}:${now.minute()}`, 'HH:mm');
      const end = dayjs('00:00', 'HH:mm');

      const diff = dayjs.duration(start.diff(end)).asHours();

      return (60 * 60 * (diff - startingHour)) / secondsInPixel + legendWidth;
    }

    case CalendarDimensions.WEEK: {
      const dayDiff = now.diff(currentDate, 'day');
      if (
        dayDiff > 7 ||
        dayDiff < 0 ||
        now.hour() < startingHour ||
        now.hour() >= endingHour
      ) {
        return 0;
      }

      const start = dayjs(new Date());
      const end = currentDate;

      const diff = dayjs.duration(start.diff(end)).asHours();

      const hiddenHours =
        (24 - (endingHour - startingHour)) * dayDiff + startingHour;

      return (60 * 60 * (diff - hiddenHours)) / secondsInPixel + legendWidth;
    }

    case CalendarDimensions.MONTH: {
      if (!currentDate.isSame(now, 'month')) {
        return 0;
      }

      const start = dayjs(new Date());
      const end = dayjs(new Date()).startOf('month');

      const diff = dayjs.duration(start.diff(end)).asHours();
      const dayDiff = now.diff(currentDate, 'day');

      const hiddenHours =
        (24 - (endingHour - startingHour)) * dayDiff + startingHour;

      return (60 * 60 * (diff - hiddenHours)) / secondsInPixel + legendWidth;
    }
  }
}
