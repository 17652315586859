import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DetailContext, NavigationContext } from '@eas/common-web';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { ZsdUser } from '@models';

export function ActionBar() {
  const intl = useIntl();
  const { isExisting, source } = useContext(DetailContext);
  const { navigate } = useContext(NavigationContext);

  if (!isExisting) {
    return null;
  }

  return (
    <>
      {(source.data as ZsdUser)?.type === 'TECHNICIAN' && (
        <>
          <TertiaryDetailActionbarButton
            label={intl.formatMessage({
              id: 'ZSD__USERS__DETAIL__ACTIONBAR__BUTTON__PLAN_WORK',
              defaultMessage: 'Naplánovať prácu',
            })}
            onClick={() => navigate('/zsd/kalendar')}
          />

          <TertiaryDetailActionbarButton
            label={intl.formatMessage({
              id: 'ZSD__USERS__DETAIL__ACTIONBAR__BUTTON__MONTH_PLAN',
              defaultMessage: 'Mesačný plán',
            })}
            onClick={() => navigate(`/zsd/plan/technik/${source.data.id}`)}
          />
          {/* <TertiaryDetailActionbarButton
            label={intl.formatMessage({
              id: 'ZSD__USERS__DETAIL__ACTIONBAR__BUTTON__CURRENT_LOCATION',
              defaultMessage: 'Zobraziť aktuálnu polohu',
            })}
            onClick={() => {}}
          /> */}
        </>
      )}
    </>
  );
}
