import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  NavigationContext,
  UserContext,
  useFirstRender,
} from '@eas/common-web';
import { LoginError } from '@modules/auth/login-error';
import { EvidenceBrowserUrl } from '@enums';
import { Login, Logout } from './modules';

export function AppPublic() {
  const { user } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);

  useFirstRender(async () => {
    console.log(user);
    if (user) {
      navigate(EvidenceBrowserUrl.HOME);
    }
  });

  return (
    <Switch>
      <Route path={EvidenceBrowserUrl.LOGOUT} exact component={Logout} />
      {/* prettier-ignore */}
      <Route path={EvidenceBrowserUrl.LOGIN_ERROR} exact component={LoginError} />
      <Route path={EvidenceBrowserUrl.LOGIN} component={Login} />
    </Switch>
  );
}
