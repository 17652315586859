import React from 'react';
import * as ReactLeaflet from 'react-leaflet';

export function CatastralTileLayer() {
  return (
    <ReactLeaflet.TileLayer
      url={`https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`}
      minZoom={0}
      maxZoom={19}
      detectRetina={true}
    />
  );
}
