import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { EvidenceBrowserUrl } from '@enums';

export function useColumns<T extends DomainObject>(
  showRedirect = true
): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    ...(showRedirect
      ? [
          {
            datakey: 'id',
            name: '',
            width: 80,
            CellComponent: (
              props: PropsWithChildren<TableFieldCellProps<T>>
            ) => (
              <Redirect
                url={EvidenceBrowserUrl.USERS}
                id={props.rowValue!.id}
              />
            ),
          },
        ]
      : []),
    {
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__USERNAME',
        defaultMessage: 'KID',
      }),
      datakey: 'username',
      sortkey: 'username',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__FIRSTNAME',
        defaultMessage: 'Meno',
      }),
      datakey: 'firstName',
      sortkey: 'firstName',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__LASTNAME',
        defaultMessage: 'Priezvisko',
      }),
      datakey: 'lastName',
      sortkey: 'lastName',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
  ];
}
