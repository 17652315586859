import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'sticky',
    left: 0,
    flexShrink: 0,
    zIndex: 100,
    backgroundColor: theme.palette.background.paper,
  },
  legendTitle: {
    display: 'flex',
    position: 'sticky',
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.background.paper,
    fontWeight: 700,
  },
  legendInnerElement: {
    boxShadow: `2px 2px 10px 0px ${theme.palette.grey[400]}`,
    minHeight: '100%',
  },
  legendInnerWrapper: {
    position: 'relative',
  },
  legendList: {
    overflow: 'visible !important',
  },
  legendListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    border: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: 0,
    paddingLeft: 10,
    fontSize: 'inherit',
    fontFamily: 'inherit',
    textAlign: 'left',
    textTransform: 'capitalize',

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  legendListItemInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  legendListItemToday: {
    borderBottom: `1px solid #d9202d9c`,
    borderTop: `1px solid #d9202d9c`,
  },
  legendIcon: {
    color: theme.palette.primary.main,
    marginRight: 10,
  },
}));
