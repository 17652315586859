import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DictionaryAutocomplete,
  ScrollableSource,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { MobileUnitAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { MobileUnitAssignmentCreateDialog } from './dialogs/mobile-unit-assignment-create-dialog';
import { useColumns } from './mobile-unit-assignment-columns';
import { useFilterFields } from './mobile-unit-assignment-filter-fields';
import { useValidationSchema } from './mobile-unit-assignment-schema';

interface MobileUnitAssignmentFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<MobileUnitAutocomplete>>
  > {
  refKey: string;
  currentRef: DictionaryAutocomplete;
  source: ScrollableSource<MobileUnitAutocomplete>;
  departmentId: string;
}

export function MobileUnitAssignmentField({
  refKey,
  currentRef,
  source,
  departmentId,
  ...overrides
}: MobileUnitAssignmentFieldProps) {
  const filters = useFilterFields();

  const useDeleteApi = (
    getUrl: (id: string, collectionIds: string | string[]) => string
  ) => {
    const callApi = useEventCallback(
      (id: string, collectionIds: string | string[]) => {
        return abortableFetch(`${getUrl(id, collectionIds)}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(collectionIds),
        });
      }
    );

    return {
      callApi,
    };
  };

  return (
    <CollectionField<MobileUnitAutocomplete>
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__MOBILE_UNIT_ASSIGNMENTS"
          defaultMessage="Priradenie technikov k MJ"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        Dialog: () =>
          MobileUnitAssignmentCreateDialog({ departmentId: departmentId }),
        useSchema: useValidationSchema,
        getUrl: () => `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/multiple`,
        dialogWidth: 700,
        initValues: () => ({
          [refKey]: currentRef,
        }),
        label: 'Pridať',
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/${collectionId}`,
      }}
      updateAction={{
        Dialog: () =>
          MobileUnitAssignmentCreateDialog({
            departmentId: departmentId,
            existing: true,
          }),
        useSchema: useValidationSchema,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/${collectionId}`,
        dialogWidth: 700,
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}`,
        useApi: useDeleteApi,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/${collectionId}`,
      }}
      filters={filters}
      multiple={true}
      {...overrides}
    />
  );
}
