import dayjs, { Dayjs } from 'dayjs';
import { CalendarSettingsType } from '../calendar-types';
import { getSecondsInPixel } from './get-seconds-in-pixel';

export function getTaskStart(
  currentDayUnix: number,
  workOrderStart: Dayjs,
  settings: Partial<CalendarSettingsType>
) {
  const { startingHour = 0 } = settings;

  const secondsInPixel = getSecondsInPixel(settings);

  const currentDay = dayjs.unix(currentDayUnix);

  const seconds = workOrderStart.diff(
    currentDay.add(startingHour, 'hour'),
    'second'
  );

  return seconds / secondsInPixel;
}
