import dayjs from 'dayjs';
import minBy from 'lodash/minBy';
import { WorkOrder } from '@models';

export function getNextWorkOrder(workOrders: WorkOrder[], currentDate: number) {
  const nextWorkOrders = workOrders?.filter(
    (wo) => dayjs(wo.startTime).unix() > currentDate
  );

  const nextWorkOrder = minBy(nextWorkOrders, (wo) =>
    dayjs(wo.startTime).unix()
  );

  return nextWorkOrder;
}
