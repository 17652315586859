import React, {
  CSSProperties,
  ReactNode,
  forwardRef,
  useCallback,
  useContext,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  VariableSizeList as List,
  ListChildComponentProps,
} from 'react-window';
import { PlannerContext } from '@modules/planner/planner-context';
import { useListenToWorkOrderChanges } from '@composite/websocket/listen-to-work-order-changes';
import { WorkOrder } from '@models';
import { CalendarContext } from '../../calendar-context';
import { CalendarDataType } from '../../calendar-types';
import { BarsRow } from './bars-row';
import { useStyles } from './bars-styles';

interface InnerElementProps {
  scaleWidth: number;
  style: CSSProperties;
  children: ReactNode;
}

interface BarsProps {
  width: number;
  data: CalendarDataType[];
}

const InnerElement: React.FC<InnerElementProps> = ({
  scaleWidth,
  style,
  children,
  ...props
}) => {
  return (
    <div
      style={{ ...style, width: scaleWidth, position: 'relative' }}
      {...props}
    >
      {children}
    </div>
  );
};

const getRowId = (workOrder: WorkOrder) =>
  workOrder?.user?.id ?? workOrder.mobileUnit?.id ?? '';

export const Bars = forwardRef<List, BarsProps>(function Bars(
  { width, data },
  ref
) {
  const classes = useStyles();
  const { settings, currentDate } = useContext(CalendarContext);
  const { calendarRef } = useContext(PlannerContext);

  const { refreshRow } = useListenToWorkOrderChanges({
    getRowId,
    currentDate,
    dimension: settings.dimension,
    calendarRef,
    variant: 'calendar',
  });

  const renderItem = useCallback(
    ({ style, data, index }: ListChildComponentProps) => {
      return (
        <BarsRow
          barData={data[index]}
          index={index}
          style={style}
          refreshRow={refreshRow}
        />
      );
    },
    [refreshRow]
  );

  const renderInnerElement = useCallback(
    (props: InnerElementProps) => {
      return <InnerElement {...props} scaleWidth={width} />;
    },
    [width]
  );

  const itemSize = useCallback(() => {
    return settings.rowHeight;
  }, []);

  return (
    <div
      className={classes.wrapper}
      style={{
        top: settings.headerHeight,
        left: settings.legendWidth,
        width: `calc(100% - ${settings.legendWidth}px)`,
        height: `calc(100% - ${settings.headerHeight}px)`,
      }}
    >
      <AutoSizer disableWidth={true} style={{ height: '100%' }}>
        {({ height }) => (
          <List<CalendarDataType[]>
            ref={ref}
            className={classes.barsList}
            layout="vertical"
            width={width}
            height={height}
            itemCount={data.length}
            itemSize={itemSize}
            itemData={data}
            innerElementType={renderInnerElement}
          >
            {renderItem}
          </List>
        )}
      </AutoSizer>
    </div>
  );
});
