import React from 'react';
import { usePrompts } from '@eas/common-web';
import { GPSPoint, WorkOrder } from '@models';
import { UnitPlanForm } from '../unit-menu/unit-plan-form';

export function useTravelTo({
  id,
  workOrders,
  unitStartingPoint,
  addStartingPoint,
}: {
  id: string;
  workOrders: (WorkOrder | undefined)[];
  unitStartingPoint?: GPSPoint;
  addStartingPoint?: boolean;
  addReturnPoint?: boolean;
}) {
  usePrompts(
    [
      {
        key: `TRAVEL_TO_${id}`,
        dialogTitle: 'Trasa cesty',
        dialogWidth: 1200,
        dialogText: (
          <UnitPlanForm
            id={id}
            workOrders={(workOrders.filter((wo) => !!wo) ?? []) as WorkOrder[]}
            unitStartingPoint={unitStartingPoint}
            addStartingPoint={addStartingPoint}
          />
        ),
      },
    ],
    [workOrders[0]?.id, workOrders[1]?.id]
  );
}
