import { useDroppable } from '@dnd-kit/core';
import React from 'react';

export function Dropppable({
  id,
  className,
  style,
  children,
  data = {},
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  data?: Record<string, unknown>;
}) {
  const { setNodeRef } = useDroppable({
    id,
    data: {
      id,
      ...data,
    },
  });

  return (
    <div ref={setNodeRef} className={className} style={style}>
      {children}
    </div>
  );
}
