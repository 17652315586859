import React from 'react';
import Typography from '@material-ui/core/Typography';
import { usePrompts } from '@eas/common-web';
import { mobileUnitErrors } from '@enums';

export function useWorkOrderExceptions({
  id,
  exceptions,
  showClose = true,
  showConfirm = true,
  showText = true,
}: {
  id?: string;
  exceptions: string[];
  showClose?: boolean;
  showConfirm?: boolean;
  showText?: boolean;
}) {
  usePrompts(
    [
      {
        key: id ? `VALIDATE_WORK_DIALOG_${id}` : 'VALIDATE_WORK_DIALOG',
        dialogTitle: 'Výsledok validácie',
        dialogShowClose: showClose,
        dialogShowConfirm: showConfirm,
        dialogText: (
          <>
            <div>
              {exceptions.map((error, index) => (
                <React.Fragment key={index}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 600, color: 'red' }}
                  >
                    Chyba {error}:
                  </Typography>
                  <Typography variant="body1" component="div" key={index}>
                    <ul style={{ marginLeft: 30 }}>
                      {(
                        mobileUnitErrors[
                          error as keyof typeof mobileUnitErrors
                        ] ?? []
                      ).map((message: string, index: number) => (
                        <li key={index}>{message}</li>
                      ))}
                    </ul>
                  </Typography>
                </React.Fragment>
              ))}
              {showText && (
                <Typography variant="body1" style={{ marginTop: 40 }}>
                  Chcete naplánovat pracovný príkaz aj napriek chybám?
                </Typography>
              )}
            </div>
          </>
        ),
      },
    ],
    [id ? id : exceptions]
  );
}
