import clsx from 'clsx';
import React from 'react';
import {
  absenceLabelMapper,
  universalUserLabelMapper,
} from 'src/utils/label-mapper';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import WarningIcon from '@material-ui/icons/Warning';
import { Tooltip } from '@eas/common-web';
import { TechnicianWarnings, ZsdUserTechnician } from '@models';
import { Draggable } from '../dnd/draggable';
import { useTechnicianTableStyles as useStyles } from '../switcher-styles';

export function TechnicianSimpleRow({
  value,
  dragOrigin,
  warnings,
  isLeader = false,
}: {
  value?: ZsdUserTechnician;
  dragOrigin: string;
  warnings?: TechnicianWarnings;
  isLeader?: boolean;
}) {
  const classes = useStyles();
  const isEmpty = !value;

  const allWarnings = [
    ...(isEmpty ? ['Chybajúci vedúci technik'] : []),
    ...(warnings?.absences.length
      ? [
          `Absencie: ${warnings?.absences
            .map((abs) => absenceLabelMapper(abs))
            .join(', ')}`,
        ]
      : []),
    ...(warnings?.expiringCertifications.length
      ? [
          `Blížiaci sa koniec certifikácie: ${warnings?.expiringCertifications
            .map((cert) => cert.name)
            .join(', ')}`,
        ]
      : []),
  ];

  const Row = (
    <div
      className={clsx(classes.rowSimple, classes.row, {
        [classes.rowLeader]: isLeader,
        [classes.rowEmpty]: isEmpty,
      })}
    >
      {!isEmpty && <OpenWithIcon className={classes.icon} />}

      {universalUserLabelMapper(value)}

      {allWarnings.length ? (
        <Tooltip
          title={allWarnings.map((warn) => (
            <div key={warn}>{warn}</div>
          ))}
        >
          <WarningIcon className={clsx(classes.icon, classes.warning)} />
        </Tooltip>
      ) : (
        <div></div>
      )}
    </div>
  );

  // Empty leader - show empty row
  if (!value) {
    return <>{Row}</>;
  }

  return (
    <Draggable
      id={value.id}
      data={value}
      dragOrigin={`${isLeader ? 'leader' : 'other'}:${dragOrigin}`}
    >
      {Row}
    </Draggable>
  );
}
