import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useTeams } from '@modules/team/team-api';
import { Department } from '@models';

export function useColumns(): TableColumn<Department>[] {
  const intl = useIntl();
  const { columnName, columnCreated, columnUpdated } =
    DictionaryEvidence.useDictionaryColumns<Department>();

  return [
    columnName,
    {
      datakey: 'team',
      sortkey: 'team.name',
      filterkey: 'team.id',
      name: intl.formatMessage({
        id: 'ZSD__DEPARTMENTS__TABLE__COLUMN__TEAM',
        defaultMessage: 'Tím',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useTeams),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
    },
    columnCreated,
    columnUpdated,
  ];
}
