/* eslint-disable react/jsx-key */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const LOGIN: MessageType<'503' | 'ERROR'> = {
  503: [
    <FormattedMessage
      id="ZSD__MESSAGE__USER__LOGIN__ERROR_503"
      defaultMessage="ERROR 503: Server dočasne nemôže spracovať požiadavok. Buď je preťažený alebo sa vykonáva údržba."
    />,
    SnackbarVariant.ERROR,
  ],
  ERROR: [
    <FormattedMessage
      id="ZSD__MESSAGE__USER__LOGIN__ERROR"
      defaultMessage="Chyba pri prihlasovaní užívateľa."
    />,
    SnackbarVariant.ERROR,
  ],
};

export default {
  LOGIN,
};
