import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryAutocomplete,
  InlineTableFieldCells,
  TableCells,
  TableColumn,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { Shift, ShiftInterval } from '@models';

export function useColumns(): TableColumn<Shift>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'name',
      sortkey: 'name',
      filterkey: 'name',
      name: intl.formatMessage({
        id: 'ZSD__SHIFT__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}

export function useIntervalTypes() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: 'Z', name: 'Pracovná zmena' },
    { id: 'P', name: 'Pohotovosť' },
    { id: 'V', name: 'Voľno' },
    { id: 'ZP', name: 'Záložná pohotovosť' },
  ]);
}

export function useIntervalColumns(
  key: string
): TableFieldColumn<ShiftInterval>[] {
  const IntervalTypeCell = InlineTableFieldCells.useInlineSelectCellFactory<
    ShiftInterval,
    DictionaryAutocomplete
  >({
    dataHook: useIntervalTypes,
    collectionDatakey: key,
    valueIsId: true,
  });

  const TimeCell =
    InlineTableFieldCells.useInlineTimeFieldFactory<ShiftInterval>({
      collectionDatakey: key,
      showSeconds: false,
    });

  return useMemo(
    () => [
      {
        datakey: 'type',
        name: 'Typ',
        width: 200,
        CellComponent: IntervalTypeCell,
      },
      {
        datakey: 'startingHour',
        name: 'Od',
        width: 200,
        CellComponent: TimeCell,
      },
      {
        datakey: 'endingHour',
        name: 'Do',
        width: 200,
        CellComponent: TimeCell,
      },
    ],
    [IntervalTypeCell, TimeCell]
  );
}
