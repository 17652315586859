import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Filter, ScrollableSource } from '@eas/common-web';
import { useValidationSchema } from '@modules/user/user-schema';
import { CollectionField } from '@components/collection-field/collection-field';
import { DictionaryAutocomplete, ZsdUser } from '@models';
import { EvidenceApiUrl } from '@enums';
import {
  assignDialogFactory,
  useValidationSchema as useAssignValidationSchema,
} from './dialogs/user-assign-dialog';
import { UserCreateDialog } from './dialogs/user-create-dialog';
import { useColumns } from './user-columns';
import { useFilterFields } from './user-filter-fields';

interface UserFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField<ZsdUser>>> {
  refKey: string;
  refFilters?: Filter[];
  currentRef: DictionaryAutocomplete;
  multiple?: boolean;
  source: ScrollableSource<ZsdUser>;
}

export function UserField({
  refKey,
  refFilters,
  currentRef,
  multiple,
  source,
  ...overrides
}: UserFieldProps) {
  const AssignDialog = useMemo(
    () => assignDialogFactory(refKey, refFilters, currentRef.id, multiple),
    [refKey, refFilters, currentRef.id, multiple]
  );

  const filters = useFilterFields();

  return (
    <CollectionField<ZsdUser>
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__USERS"
          defaultMessage="Užívatelia"
        />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: true,
        getUrl: () => EvidenceApiUrl.USERS,
        Dialog: UserCreateDialog,
        initValues: () => ({
          [refKey]: multiple ? [currentRef] : currentRef,
        }),
        useSchema: useValidationSchema,
      }}
      readAction={{
        getUrl: (collectionId) => `${EvidenceApiUrl.USERS}/${collectionId}`,
      }}
      updateAction={{
        hide: true,
        getUrl: (_, collectionId) => `${EvidenceApiUrl.USERS}/${collectionId}`,
        Dialog: UserCreateDialog,
        useSchema: useValidationSchema,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) => `${EvidenceApiUrl.USERS}/${collectionId}`,
      }}
      assignAction={{
        getUrl: (_, collectionId) => `${EvidenceApiUrl.USERS}/${collectionId}`,
        Dialog: AssignDialog,
        useSchema: useAssignValidationSchema,
      }}
      unassignAction={{
        getUrl: (_, collectionId) => `${EvidenceApiUrl.USERS}/${collectionId}`,
        initValues: (user) => ({
          ...user,
          [refKey]: multiple
            ? (user?.[refKey as keyof ZsdUser] as Array<any>)?.filter(
                (role) => role.id !== currentRef.id
              )
            : null,
        }),
      }}
      filters={filters}
      {...overrides}
    />
  );
}
