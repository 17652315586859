import { DesignerApi } from '@inqool/forms-web-api';
import { Buffer } from 'buffer';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { NavigationContext } from '@eas/common-web';
import { EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';

DesignerApi.init(`${window.location.origin}/forms-web-designer`);

export function FormDesigner() {
  const { navigate } = useContext(NavigationContext);

  const elementRef = useRef<HTMLDivElement>(null);
  const designerRef = useRef<string>();

  const { id } = useParams<{ id: string }>();

  const loadForm = useCallback(async () => {
    if (id !== null) {
      const response = await fetch(
        `${EvidenceApiUrl.FORM_TEMPLATES}/${id}/definition`,
        {
          method: 'GET',
        }
      );
      if (response.ok) {
        const value = await response.text();
        return value;
      } else if (response.status === 404) {
        return undefined;
      } else {
        return Promise.reject();
      }
    } else {
      return Promise.reject();
    }
  }, [id]);

  const handleSaveForm = useCallback(
    async (form: Buffer) => {
      if (id !== null) {
        const response = await fetch(
          `${EvidenceApiUrl.FORM_TEMPLATES}/${id}/definition`,
          {
            method: 'POST',
            body: form,
          }
        );
        if (!response.ok) {
          throw new Error('Failed to save form');
        }
      } else {
        return Promise.reject();
      }
    },
    [id]
  );

  const handlePublishForm = useCallback(async () => {
    if (id !== null) {
      const response = await fetch(
        `${EvidenceApiUrl.FORM_TEMPLATES}/${id}/publish`,
        {
          method: 'POST',
        }
      );
      if (!response.ok) {
        throw new Error('Failed to publish form');
      }
    } else {
      return Promise.reject();
    }
  }, [id]);

  const handleGetLayout = useCallback(async () => {
    try {
      const response = await fetch(`${EvidenceApiUrl.FORM_SAVED_LAYOUT}/list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          size: -1,
        }),
      });
      const value = await response.json();
      return value;
    } catch {
      return undefined;
    }
  }, []);

  const handleSaveLayout = useCallback(async (layout: string) => {
    await fetch(EvidenceApiUrl.FORM_SAVED_LAYOUT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: layout,
    });
  }, []);

  // HIDE library functions - if needed would require changes (prob. both FE and BE)
  // const handleGetOwnLibrary = useCallback(async (shared: boolean) => {
  //   try {
  //     const response = await fetch(
  //       shared
  //         ? `${EvidenceApiUrl.FORM_SAVED_LIBRARY}/shared-mine`
  //         : EvidenceApiUrl.FORM_SAVED_LIBRARY,
  //       {
  //         method: 'GET',
  //         headers: new Headers({
  //           'Content-Type': 'text/plain',
  //         }),
  //       }
  //     );
  //     const value = await response.text();
  //     return value;
  //   } catch {
  //     return undefined;
  //   }
  // }, []);

  // const handleGetOtherLibraries = useCallback(async () => {
  //   try {
  //     const response = await fetch(
  //       `${EvidenceApiUrl.FORM_SAVED_LIBRARY}/shared-others`,
  //       {
  //         method: 'GET',
  //         headers: new Headers({
  //           'Content-Type': 'text/plain',
  //         }),
  //       }
  //     );
  //     const value = await response.json();
  //     return value;
  //   } catch {
  //     return undefined;
  //   }
  // }, []);

  // const handleSaveOwnLibrary = useCallback(
  //   async (shared: boolean, library: string) => {
  //     await fetch(
  //       shared
  //         ? `${EvidenceApiUrl.FORM_SAVED_LIBRARY}/shared-mine`
  //         : EvidenceApiUrl.FORM_SAVED_LIBRARY,
  //       {
  //         method: 'POST',
  //         body: library,
  //       }
  //     );
  //   },
  //   []
  // );

  const handleClose = useCallback(async () => {
    navigate(`${EvidenceBrowserUrl.FORM_TEMPLATES}/${id}`);
  }, [id, navigate]);

  const initDesigner = useCallback(async () => {
    if (elementRef.current) {
      const designer = await DesignerApi.createDesigner(elementRef.current, {
        onClose: handleClose,
        onSave: handleSaveForm,
        onPublish: handlePublishForm,
        onGetLayout: handleGetLayout,
        onSaveLayout: handleSaveLayout,
        standalone: false,
      });
      designerRef.current = designer;

      const form = await loadForm();

      if (form) {
        await DesignerApi.openForm(designer, {
          data: new Buffer(form, 'base64'),
        });
      } else {
        await DesignerApi.newForm(designer);
      }
    }
  }, [
    handleClose,
    handleGetLayout,
    handlePublishForm,
    handleSaveForm,
    handleSaveLayout,
    loadForm,
  ]);

  useEffect(() => {
    if (elementRef.current) {
      initDesigner();
    }

    return () => {
      if (designerRef.current) {
        DesignerApi.destroyDesigner(designerRef.current);
      }
    };
  }, [initDesigner]);

  return <div style={{ height: '100%' }} ref={elementRef}></div>;
}
