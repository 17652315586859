import React, { Dispatch, SetStateAction, forwardRef, useContext } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { ScrollableSource, UserContext } from '@eas/common-web';
import { PinnedMap } from '@composite/map/pinned-map';
import { PinnedMapContext } from '@composite/map/pinned-map-context';
import { TableRowHandlersProvider } from '@composite/table/table-row-handlers-context';
import { WorkOrder } from '@models';
import { Calendar } from './calendar/calendar';
import { CalendarContext } from './calendar/calendar-context';
import { CalendarHandle } from './calendar/calendar-types';
import { PlannerContext } from './planner-context';
import { useStyles } from './planner-styles';
import { WorkOrderBar } from './work-order-table/work-order-bar';
import { WorkOrderTable } from './work-order-table/work-order-table';

type PanelProps = {
  movingWorkOrders: WorkOrder[];
  dragging: boolean;
  workOrderSource: ScrollableSource<WorkOrder>;
  setEdgeWorkOrders: Dispatch<
    SetStateAction<[WorkOrder | undefined, WorkOrder | undefined]>
  >;
};
export const PlannerCalendarPanelsLayout = forwardRef<
  CalendarHandle,
  PanelProps
>(function PlannerCalendarPanelsLayout(
  { movingWorkOrders, dragging, workOrderSource, setEdgeWorkOrders },
  ref
) {
  const classes = useStyles();
  const { updateMapContainerKey } = useContext(PinnedMapContext);
  const { calendarRef, workOrderTableRef, calendarSource } =
    useContext(PlannerContext);
  const { user } = useContext(UserContext);

  return (
    <PanelGroup
      autoSaveId={`planner-calendar-vertical-group-${user?.id}`}
      direction="vertical"
    >
      <Panel
        defaultSize={60}
        minSize={10}
        maxSize={80}
        onResize={updateMapContainerKey}
      >
        <div className={classes.root}>
          <div style={{ height: '100%' }}>
            <Calendar
              ref={ref}
              workOrderTableRef={workOrderTableRef}
              setEdgeWorkOrders={setEdgeWorkOrders}
              dataSource={calendarSource!}
            >
              <Calendar.Controls />

              <PanelGroup
                autoSaveId={`planner-calendar-horizontal-group-${user?.id}`}
                direction="horizontal"
              >
                <Panel defaultSize={60}>
                  <div className={classes.chartWrapper}>
                    <Calendar.Chart />
                  </div>
                </Panel>
                <CalendarContext.Consumer>
                  {({ settings }) =>
                    settings.pinnedMap ? (
                      <>
                        <PanelResizeHandle
                          className={classes.panelHorizontalHandle}
                        />
                        <Panel
                          defaultSize={40}
                          onResize={updateMapContainerKey}
                        >
                          <div className={classes.mapWrapper}>
                            <PinnedMap />
                          </div>
                        </Panel>
                      </>
                    ) : null
                  }
                </CalendarContext.Consumer>
              </PanelGroup>
            </Calendar>
          </div>
        </div>
      </Panel>

      <PanelResizeHandle className={classes.panelVerticalHandle} />

      <Panel defaultSize={40} minSize={10} maxSize={80}>
        <WorkOrderBar
          movingWorkOrders={movingWorkOrders}
          calendarRef={calendarRef}
        />

        <TableRowHandlersProvider rows={workOrderSource.items}>
          <WorkOrderTable
            workOrderTableRef={workOrderTableRef}
            dataSource={workOrderSource}
            dragging={dragging}
          />
        </TableRowHandlersProvider>
      </Panel>
    </PanelGroup>
  );
});
