import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { universalUserLabelMapper } from '@utils';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__LEAD',
        defaultMessage: 'Vedúci',
      }),
      datakey: 'leadTechnician',
      width: 200,
      CellComponent: (props) => (
        <TableFieldCells.TextCell
          {...props}
          value={universalUserLabelMapper(props.value)}
        />
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__TECHNICIANS',
        defaultMessage: 'Technici',
      }),
      datakey: 'technicians',
      width: 200,
      CellComponent: (props) => (
        <TableFieldCells.TextCell
          {...props}
          value={universalUserLabelMapper(props.value)}
        />
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__VALID_FROM',
        defaultMessage: 'Platnosť',
      }),
      datakey: 'validFrom',
      sortkey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}
