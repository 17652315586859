import clsx from 'clsx';
import { get } from 'lodash';
import React, {
  ReactElement,
  Ref,
  RefAttributes,
  forwardRef,
  memo,
  useContext,
} from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import {
  DomainObject,
  TableFieldCellProps,
  TableFieldContext,
  TableFieldRowProps,
} from '@eas/common-web';
import { Draggable } from '../dnd/draggable';
import { TECHNICIAN_TABLE } from '../switcher';

export const TextCell = memo(function TextCell<OBJECT>({
  value,
}: TableFieldCellProps<OBJECT>) {
  const classes = useStyles();

  return <Typography className={classes.tableCell}>{value}</Typography>;
}) as <OBJECT>(p: TableFieldCellProps<OBJECT>) => ReactElement;

const useStyles = makeStyles((theme) => ({
  row: {
    boxSizing: 'border-box',
    borderBottom: '1px solid #cdcdcd',
    minWidth: '100%',
    minHeight: 30,
    '&:hover': {
      backgroundColor: `${theme.palette.highlight} !important`,
    },
    padding: '0px 16px 1px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'grab',
  },
  highlightedRow: {
    backgroundColor: `${theme.palette.highlight} !important`,
  },
  defaultCursor: {
    cursor: 'grab',
  },
  tableCell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'top',
    width: '100%',

    '& input': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  openWithIcon: {
    color: '#cfcdcd',
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
}));

export const TechnicianRow = memo(
  forwardRef(function TableFieldRow<ZsdUserTechnician>(
    { index, value, selected }: TableFieldRowProps<ZsdUserTechnician>,
    ref: Ref<HTMLDivElement>
  ) {
    const classes = useStyles();

    const { filteredColumns, createRowStyle, highlightSelectedRow } =
      useContext<TableFieldContext<ZsdUserTechnician>>(TableFieldContext);

    const rowStyle = createRowStyle(value);

    const Row = (
      <div
        className={clsx(classes.row, classes.defaultCursor, {
          [classes.highlightedRow]: highlightSelectedRow && selected,
        })}
        style={rowStyle}
        ref={ref}
      >
        <OpenWithIcon className={classes.openWithIcon} />
        {filteredColumns.map((column, i) => {
          const { CellComponent = TextCell } = column;
          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{
                width: column.width,
              }}
            >
              <CellComponent
                index={index}
                value={get(value, column.datakey, null)}
                rowValue={value}
                column={column}
              />
            </div>
          );
        })}
      </div>
    );

    return (
      <Draggable
        id={(value as DomainObject).id}
        data={value as DomainObject}
        dragOrigin={TECHNICIAN_TABLE}
      >
        {Row}
      </Draggable>
    );
  }) as <OBJECT>(
    p: TableFieldRowProps<OBJECT> & RefAttributes<HTMLDivElement>
  ) => ReactElement
);
