import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Section } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const USERS_SOURCE = 'USERS_SOURCE';
export const TEAMS_SOURCE = 'TEAMS_SOURCE';

function useUserSource(): HookedSource<Section> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [USERS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.USER_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.USERS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'section.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useTeamsSource(): HookedSource<Section> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [TEAMS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.TEAM_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.TEAMS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              operation: ApiFilterOperation.AND,
              filters: [
                {
                  field: 'section.id',
                  operation: ApiFilterOperation.EQ,
                  value: id,
                },
                {
                  field: 'active',
                  operation: ApiFilterOperation.EQ,
                  value: true,
                },
              ],
            },
          ],
        });
      },
    },
  };
}

export function useSectionHookedSources(): HookedSource<Section> {
  const users = useUserSource();
  const teams = useTeamsSource();

  return { ...users, ...teams };
}
