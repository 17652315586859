import { useIntl } from 'react-intl';
import { TableCells, TableColumn } from '@eas/common-web';
import { Shift } from '@models';

// import { labelMapper } from '@utils';

export function useColumns(): TableColumn<Shift>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'name',
      sortkey: 'name',
      filterkey: 'name',
      name: intl.formatMessage({
        id: 'ZSD__SHIFT__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
  ];
}
