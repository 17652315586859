import React, { useCallback, useContext } from 'react';
import { WeekPicker } from '@components/pickers/week-picker';
import { CalendarContext } from '../../calendar-context';
import { getScaleLabel } from '../../utils';

export function CalendarWeekPicker() {
  const { currentDate, setCurrentDate, settings, dataSource } =
    useContext(CalendarContext);

  const handleChange = useCallback(
    (value: number) => {
      setCurrentDate(value);
      dataSource.yAxis.refreshData();
    },
    [dataSource.yAxis, setCurrentDate]
  );

  return (
    <WeekPicker
      labelMapper={(d) => getScaleLabel(d, settings, [])}
      currentDate={currentDate}
      onChange={handleChange}
    />
  );
}
