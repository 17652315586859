import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import {
  DetailContext,
  DetailMode,
  TableFieldToolbarButton,
} from '@eas/common-web';
import { TemplateShift } from '@models';

export function PlannerAddButton({
  shiftCount,
  push,
}: {
  shiftCount: number;
  push: (value: any) => void;
}) {
  const { mode } = useContext(DetailContext);

  return (
    <TableFieldToolbarButton
      IconComponent={PlusOneIcon}
      show={true}
      title={
        <FormattedMessage
          id="ZSD__CALENDAR_TEMPLATE__DETAIL__ADD_WEEK"
          defaultMessage="Pridať týždeň"
        />
      }
      disabled={mode === DetailMode.VIEW}
      onClick={() => {
        // add 7 days
        const week = [
          ...Array(7)
            .fill(0)
            .map((_, day) => ({ order: shiftCount + day } as TemplateShift)),
        ];

        week.forEach(push);
      }}
      color="primary"
      variant="contained"
    >
      <Typography variant="body2">
        <FormattedMessage
          id="ZSD__CALENDAR_TEMPLATE__DETAIL__ADD_WEEK"
          defaultMessage="Pridať týždeň"
        />
      </Typography>
    </TableFieldToolbarButton>
  );
}
