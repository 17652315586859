import dayjs, { Dayjs } from 'dayjs';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';
import { getSecondsInPixel } from './get-seconds-in-pixel';

export function getTaskWidth(
  currentDayUnix: number,
  workOrderStart: Dayjs,
  workOrderEnd: Dayjs,
  workOrderDuration: number, // TODO: wibt be needed anymore
  settings: Partial<CalendarSettingsType>
) {
  const {
    dimension = CalendarDimensions.WEEK,
    startingHour = 0,
    endingHour = 24,
  } = settings;
  const secondsInPixel = getSecondsInPixel(settings);

  const currentDay = dayjs.unix(currentDayUnix);

  switch (dimension) {
    case CalendarDimensions.DAY: {
      const seconds = workOrderEnd.diff(workOrderStart, 'second');
      return seconds / secondsInPixel;
    }
    case CalendarDimensions.WEEK:
    case CalendarDimensions.MONTH: {
      // compute days offset
      const days = workOrderStart.diff(currentDay, 'day');

      let taskDuration = workOrderEnd.diff(workOrderStart, 'second');

      // crop start time to visible hours
      if (
        workOrderStart.hour() < startingHour &&
        workOrderEnd.hour() >= startingHour
      ) {
        const startBoundary = currentDay
          .add(days, 'day')
          .add(startingHour, 'hour');

        const diff = startBoundary.diff(workOrderStart, 'second');

        taskDuration -= diff;
      }

      // crop end time to visible hours
      if (
        workOrderEnd.hour() >= endingHour &&
        workOrderStart.hour() < endingHour
      ) {
        const endBoundary = currentDay.add(days, 'day').add(endingHour, 'hour');

        const diff = workOrderEnd.diff(endBoundary, 'second');

        taskDuration -= diff;
      }

      // task is outside of visible hours
      if (
        workOrderEnd.hour() < startingHour ||
        workOrderStart.hour() >= endingHour
      ) {
        taskDuration = 0;
      }

      // multiday task
      if (workOrderStart.day() !== workOrderEnd.day()) {
        const days = workOrderEnd
          .startOf('day')
          .diff(workOrderStart.startOf('day'), 'day');

        taskDuration = 0;

        // iterate over days
        for (let i = 0; i < days; i++) {
          if (i === 0) {
            // first day
            taskDuration += getTaskWidth(
              currentDayUnix,
              workOrderStart.add(i, 'day'),
              workOrderStart.endOf('day'),
              workOrderDuration,
              settings
            );
          } else {
            // middle days
            taskDuration += getTaskWidth(
              currentDayUnix,
              workOrderStart.add(i, 'day').startOf('day'),
              workOrderStart.add(i, 'day').endOf('day'),
              workOrderDuration,
              settings
            );
          }

          if (i === days - 1) {
            // last day
            taskDuration += getTaskWidth(
              currentDayUnix,
              workOrderEnd.startOf('day'),
              workOrderEnd,
              workOrderDuration,
              settings
            );
          }
        }

        return taskDuration;
      }

      return taskDuration / secondsInPixel;
    }
    default:
      return 0;
  }
}
