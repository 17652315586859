import { FillerApi } from '@inqool/forms-web-api';
import { Buffer } from 'buffer';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { NavigationContext } from '@eas/common-web';
import { EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';

FillerApi.init(`${window.location.origin}/forms-web-filler`);

export function FormFiller() {
  const { navigate } = useContext(NavigationContext);

  const elementRef = useRef<HTMLDivElement>(null);
  const fillerRef = useRef<string>();

  const { id, workOrderId } = useParams<{ id: string; workOrderId: string }>();

  const loadForm = useCallback(async () => {
    if (id !== null) {
      const response = await fetch(
        `${EvidenceApiUrl.FILLED_FORMS}/${id}/definition`,
        {
          method: 'GET',
        }
      );
      if (response.ok) {
        const value = await response.text();
        return value;
      } else if (response.status === 404) {
        return undefined;
      } else {
        return Promise.reject();
      }
    } else {
      return Promise.reject();
    }
  }, [id]);

  const handleSaveForm = useCallback(
    async (form: Buffer) => {
      if (id !== null) {
        const response = await fetch(
          `${EvidenceApiUrl.FILLED_FORMS}/${id}/definition`,
          {
            method: 'POST',
            body: form,
          }
        );
        if (!response.ok) {
          throw new Error('Failed to save form');
        }
      } else {
        return Promise.reject();
      }
    },
    [id]
  );

  const handleFinalizeForm = useCallback(
    async (form: Buffer) => {
      if (id !== null) {
        await handleSaveForm(form);

        const response = await fetch(
          `${EvidenceApiUrl.FILLED_FORMS}/${id}/finalize`,
          {
            method: 'POST',
          }
        );
        if (!response.ok) {
          throw new Error('Failed to finalize form');
        }
      } else {
        return Promise.reject();
      }
    },
    [handleSaveForm, id]
  );

  const handleClose = useCallback(async () => {
    navigate(`${EvidenceBrowserUrl.WORK_ORDERS}/${workOrderId}?view=DETAIL`);
  }, [workOrderId, navigate]);

  const initFiller = useCallback(async () => {
    if (elementRef.current) {
      const filler = await FillerApi.createFiller(elementRef.current, {
        onClose: handleClose,
        onSave: handleSaveForm,
        onFinalize: handleFinalizeForm,
      });
      fillerRef.current = filler;

      const form = await loadForm();

      if (form) {
        FillerApi.openForm(filler, {
          data: new Buffer(form, 'base64'),
          name: 'Formular.fo',
        });
      }
    }
  }, [handleClose, handleFinalizeForm, handleSaveForm, loadForm]);

  useEffect(() => {
    if (elementRef.current) {
      initFiller();
    }

    return () => {
      if (fillerRef.current) {
        FillerApi.destroyFiller(fillerRef.current);
      }
    };
  }, [initFiller]);

  return <div style={{ height: '100%' }} ref={elementRef}></div>;
}
