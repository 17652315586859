import { Permission } from '@enums';

export const CoordinatorPermissions = [
  Permission.MOBILE_UNIT_LIST,
  Permission.USER_READ,
  Permission.USER_LIST,
  Permission.ROLE_READ,
  Permission.ROLE_LIST,
  Permission.MOBILE_UNIT_READ,
  Permission.MOBILE_UNIT_LIST,
  Permission.MOBILE_UNIT_WRITE,
  Permission.DEPARTMENT_LIST,
  Permission.DEPARTMENT_READ,
  Permission.TEAM_LIST,
  Permission.SECTION_LIST,
];

export const MobileUnitSwitcherPermissions = [
  Permission.MOBILE_UNIT_LIST,
  Permission.USER_READ,
  Permission.USER_LIST,
  Permission.ROLE_READ,
  Permission.ROLE_LIST,
  Permission.MOBILE_UNIT_READ,
  Permission.MOBILE_UNIT_LIST,
  Permission.MOBILE_UNIT_WRITE,
  Permission.DEPARTMENT_LIST,
  Permission.DEPARTMENT_READ,
  Permission.TEAM_LIST,
  Permission.SECTION_LIST,
];
