import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { Absence } from '@models';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__ABSENCES__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'absenceType.name',
      sortkey: 'absenceType.name',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__ABSENCES__TABLE__COLUMN__FROM_TO',
        defaultMessage: 'Platnosť',
      }),
      datakey: 'id',
      width: 350,
      CellComponent: (props) => {
        const { fromDate, toDate } = props.rowValue as Absence;

        //let value = '';

        const value = (
          <>
            {fromDate && (
              <>
                {new Date(fromDate).toLocaleDateString('sk-SK', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
                {' | '}
                {new Date(fromDate).toLocaleTimeString('sk-SK', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </>
            )}
            {toDate && (
              <>
                {' - '}
                {new Date(toDate).toLocaleDateString('sk-SK', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
                {' | '}
                {new Date(toDate).toLocaleTimeString('sk-SK', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </>
            )}
          </>
        );

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__ABSENCES__TABLE__COLUMN__ALLOWED',
        defaultMessage: 'Schválená',
      }),
      datakey: 'allowed',
      sortkey: 'allowed',
      width: 200,
      CellComponent: TableFieldCells.BooleanCell,
    },
  ];
}
