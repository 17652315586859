import { useIntl } from 'react-intl';
import { DatedEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Role } from '@models';

export function useColumns(): TableColumn<Role>[] {
  const intl = useIntl();

  const { columnCreated, columnUpdated } =
    DatedEvidence.useDatedColumns<Role>();

  return [
    {
      datakey: 'name',
      sortkey: 'name',
      filterkey: 'name',
      name: intl.formatMessage({
        id: 'ZSD__ROLES__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    columnCreated,
    columnUpdated,
  ];
}
