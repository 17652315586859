import dayjs from 'dayjs';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

export function getWholeWidth(
  currentDateUnix: number,
  settings: Partial<CalendarSettingsType>
) {
  const {
    dimension = CalendarDimensions.WEEK,
    stepWidth = 100,
    startingHour = 0,
    endingHour = 24,
  } = settings;
  const currentDate = dayjs.unix(currentDateUnix);

  switch (dimension) {
    case CalendarDimensions.DAY: {
      return (endingHour - startingHour) * stepWidth;
    }

    case CalendarDimensions.WEEK: {
      return 7 * (endingHour - startingHour) * stepWidth;
    }

    case CalendarDimensions.MONTH: {
      return currentDate.daysInMonth() * stepWidth;
    }

    default:
      return 0;
  }
}
