import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  customField: {
    display: 'flex',
    gap: '16px',
    width: '100%',
  },
  inputWrapper: {
    display: 'flex',
    flexShrink: 0,
  },
  input: {
    width: '50px',
  },
  button: {
    padding: '0',
    border: 'none',
    background: 'none',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    width: '100%',
  },
}));
