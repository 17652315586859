import * as Yup from 'yup';
import { CalendarTemplate } from '@models';

export function useValidationSchema() {
  return Yup.object<CalendarTemplate>().shape({
    name: Yup.string().nullable().required('Povinné pole'),
    shifts: Yup.array().of(
      Yup.object().shape({
        shift: Yup.string().nullable().required('Povinné pole'),
      })
    ),
  }) as Yup.Schema<CalendarTemplate>;
}
