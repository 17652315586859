import cn from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import RoomIcon from '@material-ui/icons/Room';
import { PromptContext, PromptProvider, usePrompts } from '@eas/common-web';
import { MapWithMarkers } from '@composite/map/map-with-markers';
import {
  PinnedMapContext,
  PointMapData,
} from '@composite/map/pinned-map-context';
import { CalendarContext } from '../../calendar-context';

export function MobileUnitCurrentLocationControl() {
  return (
    <PromptProvider>
      <MobileUnitCurrentLocation />
    </PromptProvider>
  );
}

function MobileUnitCurrentLocation() {
  const { settings, dataSource, classes } = useContext(CalendarContext);

  const { updateMapData } = useContext(PinnedMapContext);

  const [locationData, setLocationData] = useState<PointMapData[]>([]);

  useEffect(() => {
    setLocationData(
      (dataSource?.yAxis?.data ?? [])
        .filter((d) => d.currentLocation)
        .map((d) => ({
          point: {
            latitude: d.currentLocation?.latitude,
            longitude: d.currentLocation?.longitude,
          },
          pointLabel: 'A',
          pointStyle: {
            borderColor: 'blue',
          },
          id: undefined,
          tooltip: {
            content: d.name,
            permanent: false,
          },
        }))
    );
  }, [dataSource?.yAxis?.data]);

  usePrompts(
    [
      {
        key: 'ALL_MOBILE_UNITS_CURRENT_LOCATION',
        dialogTitle: 'Aktuálna poloha MJ',
        dialogShowConfirm: false,
        closeLabel: 'Zavrieť',
        dialogWidth: 1200,
        dialogText: (
          <div style={{ width: '100%', height: 500 }}>
            <MapWithMarkers markers={locationData} />
          </div>
        ),
      },
    ],
    [locationData]
  );

  return (
    <PromptContext.Consumer>
      {({ testPrompt }) => (
        <Button
          variant="outlined"
          className={cn(classes.button, classes.basicButton)}
          onClick={() => {
            if (settings.pinnedMap) {
              updateMapData({
                variant: 'points',
                points: locationData,
              });
            } else {
              testPrompt({
                key: 'ALL_MOBILE_UNITS_CURRENT_LOCATION',
                submitCallback: () => {},
              });
            }
          }}
        >
          <RoomIcon style={{ marginRight: 5 }} fontSize="small" />
          Aktuálna poloha MJ
        </Button>
      )}
    </PromptContext.Consumer>
  );
}
