import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  list: {
    overflow: 'visible !important',
  },
  item: {
    borderRight: 'none',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    display: 'flex',
    paddingLeft: 8,
    position: 'sticky',
    left: 200,
    height: 25,
  },
  steps: {
    width: '100%',
    display: 'inline-flex',
    height: 25,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    borderLeft: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    left: 200,
    backgroundColor: theme.palette.background.paper,
  },
}));
