import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DetailActionbarButton,
  DetailToolbarButtonProps,
} from '@eas/common-web';

const useStyles = makeStyles((theme) => ({
  actionbarTertiaryButton: {
    minWidth: 40,
    width: 'max-content',
    cursor: 'pointer',
    marginTop: 1,
    marginLeft: 6,
    border: 0,
    color: '#ffffff',
    backgroundColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.tertiary.main,
      },
    },
  },
}));

export function TertiaryDetailActionbarButton(props: DetailToolbarButtonProps) {
  const classes = useStyles();

  return (
    <DetailActionbarButton
      {...props}
      // @ts-expect-error does not exist on type
      classes={{
        root: classes.actionbarTertiaryButton,
      }}
    />
  );
}
