import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

const SECONDS_IN_HOUR = 60 * 60;

export function getSecondsInPixel(settings: Partial<CalendarSettingsType>) {
  const {
    stepWidth = 100,
    dimension = CalendarDimensions.WEEK,
    startingHour = 0,
    endingHour = 24,
  } = settings;

  switch (dimension) {
    case CalendarDimensions.DAY:
      return SECONDS_IN_HOUR / stepWidth;
    case CalendarDimensions.WEEK:
      return SECONDS_IN_HOUR / stepWidth;
    case CalendarDimensions.MONTH:
      return (SECONDS_IN_HOUR * (endingHour - startingHour)) / stepWidth;
    default:
      return SECONDS_IN_HOUR / stepWidth;
  }
}
