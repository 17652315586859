import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormInlineTableField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useIntervalColumns } from './shift-columns';

export function Fields() {
  const intervalsColumns = useIntervalColumns('intervals');
  const holidayIntervalsColumns = useIntervalColumns('holidayIntervals');

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__SHIFT__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__SHIFT__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
          required
        />
        <FormTextField
          name="description"
          label={
            <FormattedMessage
              id="ZSD__SHIFT__DETAIL__FIELD_LABEL__DESCRIPTION"
              defaultMessage="Popis"
            />
          }
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__SHIFT__DETAIL__PANEL__INTERVALS"
            defaultMessage="Detaily zmeny"
          />
        }
      >
        <FormInlineTableField
          name="intervals"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={intervalsColumns}
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__SHIFT__DETAIL__PANEL__HOLIDAY_INTERVALS"
            defaultMessage="Detaily zmeny počas sviatkov"
          />
        }
      >
        <FormInlineTableField
          name="holidayIntervals"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={holidayIntervalsColumns}
        />
      </FormPanel>
    </>
  );
}
