import React, { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import {
  activateCalamity,
  deactivateCalamity,
  useDepartments,
} from '@modules/department/department-api';
import {
  DepartmentDialog,
  DepartmentDialogHandle,
} from '@composite/department-field/department-dialog';
import { Department } from '@models';

export function CalamityForm() {
  const dialogFieldsRef = useRef<DepartmentDialogHandle | null>(null);

  const intl = useIntl();
  const { showSnackbar } = useContext(SnackbarContext);

  const selectedDepartments = useDepartments({
    filters: [
      {
        operation: ApiFilterOperation.EQ,
        field: 'calamity',
        value: true,
      },
    ],
    size: -1,
  });

  useEffect(() => {
    selectedDepartments.loadMore();
  }, []);

  if (selectedDepartments.loading || !selectedDepartments.items) {
    return null;
  }

  return (
    <div>
      <DepartmentDialog
        ref={dialogFieldsRef}
        defaultValue={selectedDepartments.items as Department[]}
        multiple={true}
        hideCheckAll={true}
        onSelect={async (id, checked) => {
          if (checked) {
            const res = await activateCalamity(id);
            const body = await res.json();

            if (res.ok) {
              showSnackbar(
                intl.formatMessage({
                  id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_ACTIVATED',
                  defaultMessage: 'Kalamita bola úspešne aktivovaná.',
                }),
                SnackbarVariant.SUCCESS
              );
            } else {
              showSnackbar(
                intl.formatMessage({
                  id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_ACTIVATION_FAILED',
                  defaultMessage: 'Aktivácia kalamity zlyhala.\n\n',
                }) +
                  (body.message
                    ? body.message
                    : body.code === 'UNAUTHORIZED'
                    ? intl.formatMessage({
                        id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_DEACTIVATED_UNAUTHORIZED',
                        defaultMessage:
                          'Nemáte oprávnenie na aktiváciu kalamity.',
                      })
                    : ''),
                SnackbarVariant.ERROR
              );
            }
          } else {
            const res = await deactivateCalamity(id);
            const body = await res.json();

            if (res.ok) {
              showSnackbar(
                intl.formatMessage({
                  id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_DEACTIVATED',
                  defaultMessage: 'Kalamita bola úspešne deaktivovaná.',
                }),
                SnackbarVariant.SUCCESS
              );
            } else {
              showSnackbar(
                intl.formatMessage({
                  id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_DEACTIVATION_FAILED',
                  defaultMessage: 'Deaktivácia kalamity zlyhala.\n\n',
                }) +
                  (body.message
                    ? body.message
                    : body.code === 'UNAUTHORIZED'
                    ? intl.formatMessage({
                        id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CALAMITY_DEACTIVATED_UNAUTHORIZED',
                        defaultMessage:
                          'Nemáte oprávnenie na deaktiváciu kalamity.',
                      })
                    : ''),
                SnackbarVariant.ERROR
              );
            }
          }
        }}
      />
    </div>
  );
}
