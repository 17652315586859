import { ListSource, useAutocompleteSource, useFetch } from '@eas/common-web';
import { Section, SectionAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useSections() {
  return useAutocompleteSource<SectionAutocomplete>({
    url: `${EvidenceApiUrl.SECTIONS}/autocomplete`,
  });
}

export function useSectionAutocomplete() {
  return useAutocompleteSource<SectionAutocomplete>({
    url: `${EvidenceApiUrl.SECTIONS}/autocomplete`,
  });
}

export function useSectionList() {
  const [items] = useFetch<ListSource<Section>>(
    `${EvidenceApiUrl.SECTIONS}/list`,
    {
      method: 'POST',
    }
  );
  return items;
}
