import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { handleApiErrors } from 'src/utils/error-handler';
import {
  DatedEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
} from '@eas/common-web';
import { Shift } from '@models';
import { EvidenceApiUrl, Permission, userApiErrors } from '@enums';
import { useEvidencePermission } from '@utils';
import { useColumns } from './shift-columns';
import { Fields } from './shift-fields';
import { useValidationSchema } from './shift-schema';

export function Shifts() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.SHIFT_WRITE],
      EDIT: [Permission.SHIFT_WRITE],
      REMOVE: [Permission.SHIFT_WRITE],
    },
  });

  const { showSnackbar } = useContext(SnackbarContext);

  const evidence = DatedEvidence.useDatedEvidence<Shift>({
    identifier: 'SHIFT',
    apiProps: {
      url: EvidenceApiUrl.SHIFTS,
      ...handleApiErrors(userApiErrors.SHIFT, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__SHIFT__TABLE__TITLE',
        defaultMessage: 'Zmeny',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__SHIFT__DETAIL__TITLE',
          defaultMessage: 'Zmena',
        }),
        subtitle: (unit) => unit?.name ?? '',
        showBtn: btnPermissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
