import { usePrompts } from '@eas/common-web';
import { CalendarDataSourceType } from '../calendar/calendar-types';
import { CalamityForm } from './calamity-form';
import { useCalamitySubmit } from './calamity-hook';

export function useCalamity({
  calendarSource,
}: {
  calendarSource: CalendarDataSourceType;
}) {
  usePrompts(
    [
      {
        key: 'CALAMITY_DIALOG',
        dialogTitle: 'Kalamita',
        dialogShowClose: false,
        dialogShowConfirm: true,
        confirmLabel: 'Zavrieť',
        dialogWidth: 500,
        dialogText: '',
        formInitialValues: {},
        FormFields: CalamityForm,
      },
    ],
    []
  );

  const { handleClick } = useCalamitySubmit({
    calendarSource,
  });

  return { handleClick };
}
