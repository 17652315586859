import dayjs from 'dayjs';
import React, { useCallback, useContext } from 'react';
import { MonthPicker } from '@components/pickers/month-picker';
import { CalendarContext } from '../../calendar-context';

export function CalendarMonthPicker() {
  const { currentDate, setCurrentDate, dataSource } =
    useContext(CalendarContext);

  const handleChange = useCallback(
    (value: number) => {
      setCurrentDate(value);
      dataSource.yAxis.refreshData();
    },
    [dataSource.yAxis, setCurrentDate]
  );

  return (
    <MonthPicker
      labelMapper={(d) => {
        const date = dayjs.unix(d);
        return date.format('MMMM');
      }}
      currentDate={currentDate}
      onChange={handleChange}
    />
  );
}
