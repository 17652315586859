import React, { forwardRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import ErrorIcon from '@material-ui/icons/Error';
import { PromptContext } from '@eas/common-web';
import { WorkOrder } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
};

export const ErrorsMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function ErrorsMenuItem({ closeMenu, workOrder }, ref) {
    const { testPrompt } = useContext(PromptContext);

    if ((workOrder?.validationErrors ?? [])?.length === 0) {
      return null;
    }

    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          closeMenu();

          testPrompt({
            key: `VALIDATE_WORK_DIALOG_${workOrder.id}`,
            submitCallback: () => {},
          });
        }}
      >
        <ListItemIcon>
          <ErrorIcon />
        </ListItemIcon>
        <FormattedMessage
          id="WORK_ORDER_MENU_ITEM_PROBLEMS"
          defaultMessage="Problémy"
        />
      </MenuItem>
    );
  }
);
