import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { ListSource } from '@eas/common-web';
import { Department, Team } from '@models';
import { updateParams } from '../param-utils';
import { useStyles } from '../switcher-styles';
import { SwitcherDayPicker } from './day-picker';
import { DepartmentPicker } from './department-picker';
import { TeamPicker } from './team-picker';

export const SwitchHeader = ({
  team,
  setTeam,
  date,
  setDate,
  department,
  setDepartment,
  departmentList,
}: {
  team: Team | undefined;
  setTeam?: (team: Team) => void;
  date: number;
  setDate: (date: number) => void;
  department: Department | undefined;
  setDepartment: (dep: Department) => void;
  departmentList: ListSource<Department> | undefined;
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleWrapper}>
        <Typography variant="h1" className={classes.title}>
          <FormattedMessage
            id="ZSD__MU_SWITCHER__TITLE"
            defaultMessage="Zostavovanie MJ"
          />
        </Typography>
      </div>
      <div className={classes.controlsWrapper}>
        <div>
          <SwitcherDayPicker setDate={setDate} date={date} />
        </div>
        <div className={classes.controlsRight}>
          {!setTeam &&
            (team ? (
              <Typography variant="h2" className={classes.subtitle}>
                {team.name}
              </Typography>
            ) : (
              <Typography variant="h2" className={classes.subtitle}>
                <FormattedMessage
                  id="ZSD__MU_SWITCHER__NO_TEAM"
                  defaultMessage="Potrebujete mať priradený tím"
                />
              </Typography>
            ))}
          {setTeam && (
            <TeamPicker
              team={team}
              onConfirm={(team) => {
                updateParams('tim', team.id);
                setTeam(team);
              }}
            />
          )}

          <DepartmentPicker
            department={department}
            dataList={departmentList}
            onConfirm={(dep) => {
              updateParams('pracovisko', dep.id);
              setDepartment(dep);
            }}
          />
        </div>
      </div>
    </>
  );
};
