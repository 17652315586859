import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    textTransform: 'none',
  },

  basicButton: {
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    borderColor: theme.palette.grey[300],
    backgroundColor: '#fff',
  },
}));
