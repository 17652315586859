import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormCheckbox,
  FormContext,
  FormInlineTableField,
  FormLocalDateField,
  FormPanel,
  useFormSelector,
  useUpdateEffect,
} from '@eas/common-web';
import { useIntervalColumns } from '@modules/shift/shift-columns';
import { CalendarException } from '@models';

export function CalendarExceptionUpdateDialog() {
  const intervalsColumns = useIntervalColumns('intervals');
  const { setFieldValue } = useContext(FormContext);

  const { isBlocked } = useFormSelector((values: CalendarException) => ({
    isBlocked: values.isBlockedForFixedTermsPlanning,
  }));

  useUpdateEffect(() => {
    if (isBlocked) {
      setFieldValue('intervals', []);
    }
  }, [isBlocked]);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD_CALENDAR_EXCEPTION__DETAIL__FIELD_LABEL__DETAILS"
            defaultMessage="Detaily"
          />
        }
      >
        <FormLocalDateField
          name="validFrom"
          label={
            <FormattedMessage
              id="ZSD_CALENDAR_EXCEPTION__DETAIL__FIELD_LABEL__VALID_FROM"
              defaultMessage="Dátum platnosti od"
            />
          }
          required={true}
          disabled={true}
        />
        <FormLocalDateField
          name="validTo"
          label={
            <FormattedMessage
              id="ZSD_CALENDAR_EXCEPTION__DETAIL__FIELD_LABEL__VALID_TO"
              defaultMessage="Dátum platnosti do"
            />
          }
          required={true}
          disabled={true}
        />
        <FormCheckbox
          name="isBlockedForFixedTermsPlanning"
          label={
            <FormattedMessage
              id="ZSD_CALENDAR_EXCEPTION__DETAIL__FIELD_LABEL__IS_BLOCKED_FOR_FIXED_TERMS_PLANNING"
              defaultMessage="Blokovať dátum"
            />
          }
        />
      </FormPanel>
      {!isBlocked && (
        <FormPanel
          label={
            <FormattedMessage
              id="ZSD_CALENDAR_EXCEPTION__DETAIL__FIELD_LABEL__INTERVALS"
              defaultMessage="Intervaly"
            />
          }
        >
          <FormInlineTableField
            name="intervals"
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
            columns={intervalsColumns}
          />
        </FormPanel>
      )}
    </>
  );
}
