import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { FormTemplate, Team, WorkOrderType } from '@models';

export function useColumns(): TableColumn<FormTemplate>[] {
  const { columnName, columnCreated, columnUpdated } =
    DictionaryEvidence.useDictionaryColumns<FormTemplate>();

  return [
    columnName,
    {
      datakey: 'workOrderTypes',
      name: 'Druhy pracovních príkazov',
      width: 200,
      CellComponent: function WorkOrderTypeCell(props) {
        return TableCells.TextCell({
          ...props,
          value: props.value
            .map((workOrderType: WorkOrderType) => workOrderType.name)
            .join(', '),
        });
      },
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'teams',
      name: 'Tímy',
      width: 200,
      CellComponent: function TeamCell(props) {
        return TableCells.TextCell({
          ...props,
          value: props.value.map((team: Team) => team.name).join(', '),
        });
      },
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'operationNumber',
      name: 'Poradové číslo',
      CellComponent: TableCells.TextCell,
      width: 200,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'universal',
      name: 'Univerzální',
      CellComponent: TableCells.BooleanCell,
      width: 200,
      sortable: false,
      filterable: false,
    },
    columnCreated,
    columnUpdated,
  ];
}
