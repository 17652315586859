import React, { Dispatch, SetStateAction } from 'react';
import {
  CheckboxGroup,
  RadioGroup,
  useStaticListSource,
} from '@eas/common-web';
import { Department } from '@models';

export function DepartmentGroup({
  group,
  multiple = true,
  value,
  setValue,
}: {
  group: Department[];
  multiple?: boolean;
  value: Department | Department[] | undefined;
  setValue: Dispatch<SetStateAction<Department | Department[] | undefined>>;
}) {
  const departmentSource = useStaticListSource(group);

  if (multiple) {
    return (
      <div style={{ marginLeft: 32 }}>
        <CheckboxGroup<Department>
          value={value as Department[]}
          source={departmentSource}
          valueIsId={false}
          onChange={(value) => setValue(value as Department[])}
        />
      </div>
    );
  }
  return (
    <div style={{ marginLeft: 32 }}>
      <RadioGroup<Department>
        value={value as Department}
        source={departmentSource}
        valueIsId={false}
        onChange={(value) => setValue(value as Department)}
      />
    </div>
  );
}
