import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  createContext,
} from 'react';
import { ScrollableSource, TableHandle } from '@eas/common-web';
import { WorkOrder } from '@models';
import { CalendarSource } from './calendar/calendar-data-source';
import { CalendarHandle } from './calendar/calendar-types';

type PlannerContextProps = {
  calendarSource: CalendarSource | null;
  workOrderSource: ScrollableSource<WorkOrder> | null;
  calendarRef: RefObject<CalendarHandle>;
  workOrderTableRef: RefObject<TableHandle<WorkOrder>>;
  highlightedWorkOrder?: WorkOrder;
  setHighlightedWorkOrder: Dispatch<SetStateAction<WorkOrder | undefined>>;
};

export const PlannerContext = createContext<PlannerContextProps>({
  calendarSource: null,
  workOrderSource: null,
  calendarRef: React.createRef(),
  workOrderTableRef: React.createRef(),
  setHighlightedWorkOrder: () => {},
  highlightedWorkOrder: undefined,
});
