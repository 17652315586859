import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { CalendarException, ShiftInterval } from '@models';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__CALENDAR_EXCEPTION__TABLE__COLUMN__DAY',
        defaultMessage: 'Deň',
      }),
      datakey: 'validFrom',
      sortkey: 'validFrom',
      width: 200,
      CellComponent: function DetailsCell(props) {
        const label = new Date(props.value).toLocaleDateString('sk-SK');

        return <TableFieldCells.TextCell {...props} value={label} />;
      },
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__CALENDAR_EXCEPTION__TABLE__COLUMN__DETAILS',
        defaultMessage: 'Detaily',
      }),
      datakey: 'intervals',
      width: 500,
      CellComponent: function DetailsCell(props) {
        const intervals = props.value as ShiftInterval[];

        const blocked = (props.rowValue as CalendarException)
          .isBlockedForFixedTermsPlanning;

        const label = blocked
          ? 'Blokovaný dátum'
          : intervals
              .map((interval) => {
                return `${interval.type} (${interval.startingHour} - ${interval.endingHour})`;
              })
              .join(', ');

        return <TableFieldCells.TextCell {...props} value={label} />;
      },
    },
  ];
}
