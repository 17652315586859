import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { Calendar } from '@models';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__CALENDARS__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'template.name',
      sortkey: 'template.name',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__CALENDARS__TABLE__COLUMN__FROM_TO',
        defaultMessage: 'Platnosť',
      }),
      datakey: 'id',
      width: 200,
      CellComponent: (props) => {
        const { fromDate, toDate } = props.rowValue as Calendar;

        let value = '';
        if (fromDate) {
          value = `${new Date(fromDate).toLocaleDateString('sk-SK')}`;
        }
        if (toDate) {
          value += ` - ${new Date(toDate).toLocaleDateString('sk-SK')}`;
        }

        return <TableFieldCells.TextCell {...props} value={value} />;
      },
    },
  ];
}
