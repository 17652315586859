import React from 'react';
import * as ReactLeaflet from 'react-leaflet';
import { GPSPoint } from '@models';
import { CENTER_COORDS } from './constants';

export function MapContainer({
  initialCenter,
  initialZoom,
  children,
}: {
  initialZoom?: number;
  initialCenter?: GPSPoint;
  children: React.ReactNode;
}) {
  return (
    <ReactLeaflet.MapContainer
      center={
        initialCenter
          ? [initialCenter.latitude!, initialCenter.longitude!]
          : CENTER_COORDS
      }
      zoom={initialZoom ?? 8}
      scrollWheelZoom={true}
      style={{ width: '100%', height: '100%' }}
    >
      {children}
    </ReactLeaflet.MapContainer>
  );
}
