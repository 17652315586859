export const updateParams = (
  param: 'datum' | 'pracovisko' | 'tim',
  value: string
) => {
  const params = new URLSearchParams(window.location.search);
  params.set(param, value);
  history.pushState(null, '', '?' + params.toString());
};

export const getParam = (param: 'datum' | 'pracovisko' | 'tim') => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};
