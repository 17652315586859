import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormContext,
  FormLocalDateField,
  FormLocalDateTimeField,
  useFormSelector,
  useUpdateEffect,
} from '@eas/common-web';
import { MobileUnitAssignment, ZsdUser } from '@models';
import { TechnicianDictionaryField } from '../../../modules/user/fields/technician-from-department-dictionary-field';

export function MobileUnitAssignmentCreateDialog({
  departmentId,
  existing = false,
}: {
  departmentId: string;
  existing?: boolean;
}) {
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((data: MobileUnitAssignment) => ({
    leadTechnician: data?.leadTechnician,
    technicians: data?.technicians ?? [],
  }));

  const [selectedLead, setSelectedLead] = useState(values?.leadTechnician);

  useUpdateEffect(() => {
    setSelectedLead(values?.leadTechnician);
  }, [values.leadTechnician]);

  // Remove selected lead from technicians (if present)
  useUpdateEffect(() => {
    if (values.technicians) {
      setFieldValue(
        'technicians',
        values.technicians.filter(
          (tech: ZsdUser) => tech.id !== values.leadTechnician?.id
        )
      );
    }
  }, [values.leadTechnician]);

  return (
    <>
      <TechnicianDictionaryField
        name="leadTechnician"
        label={
          <FormattedMessage
            id="ZSD__MOBILE_UNIT_ASSIGNMENT__DETAIL__FIELD_LABEL__LEAD_TECHNICIAN"
            defaultMessage="Vedúci technik"
          />
        }
        departmentId={departmentId}
      />
      <TechnicianDictionaryField
        name="technicians"
        label={
          <FormattedMessage
            id="ZSD__MOBILE_UNIT_ASSIGNMENT__DETAIL__FIELD_LABEL__TECHNICIANS"
            defaultMessage="Technici"
          />
        }
        departmentId={departmentId}
        multiple={true}
        excludeId={selectedLead?.id}
      />
      {existing ? (
        <>
          <FormLocalDateTimeField
            name="validFrom"
            label={
              <FormattedMessage
                id="ZSD__MOBILE_UNIT_ASSIGNMENT__DETAIL__FIELD_LABEL__VALID_FROM"
                defaultMessage="Platnosť od"
              />
            }
            required={true}
            disabled={true}
          />
          <FormLocalDateTimeField
            name="validTo"
            label={
              <FormattedMessage
                id="ZSD__MOBILE_UNIT_ASSIGNMENT__DETAIL__FIELD_LABEL__VALID_To"
                defaultMessage="Platnosť do"
              />
            }
            required={true}
            disabled={true}
          />
        </>
      ) : (
        <>
          <FormLocalDateField
            name="validFrom"
            label={
              <FormattedMessage
                id="ZSD__MOBILE_UNIT_ASSIGNMENT__DETAIL__FIELD_LABEL__VALID_FROM"
                defaultMessage="Platnosť od"
              />
            }
            required={true}
            representation="local-date-time"
          />
          <FormLocalDateField
            name="validTo"
            label={
              <FormattedMessage
                id="ZSD__MOBILE_UNIT_ASSIGNMENT__DETAIL__FIELD_LABEL__VALID_To"
                defaultMessage="Platnosť do"
              />
            }
            required={true}
            representation="local-date-time"
          />
        </>
      )}
    </>
  );
}
