import { useCallback, useContext } from 'react';
import { UserSettingsContext } from '@eas/common-web';
import {
  settingsId,
  settingsVersion,
} from '@modules/planner/settings/settings';
import { CalendarContext } from '../calendar-context';
import { CalendarSettingsType } from '../calendar-types';
import { getCurrentDate, getInitialScrollOffset } from '../utils';

export const useCalculate = () => {
  const { wrapRef, settings, setSettings, setCurrentDate } =
    useContext(CalendarContext);
  const { setCustomSettings } = useContext(UserSettingsContext);

  const calculate = useCallback(
    (newSettings: Partial<CalendarSettingsType>) => {
      const newCurrentDate = getCurrentDate({
        ...settings,
        ...newSettings,
      });

      const initialScrollOffset = getInitialScrollOffset(newCurrentDate, {
        ...settings,
        ...newSettings,
      });

      setCustomSettings(settingsId, {
        ...settings,
        ...newSettings,
        version: settingsVersion,
      });

      setSettings({
        ...settings,
        ...newSettings,
        initialScrollOffset,
      });
      setCurrentDate(newCurrentDate);

      requestAnimationFrame(() => {
        wrapRef.current?.scrollTo({
          left: initialScrollOffset,
        });
      });
    },
    [setCurrentDate, setCustomSettings, setSettings, settings, wrapRef]
  );

  return { calculate };
};
