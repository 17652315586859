import cn from 'clsx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useContext, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@eas/common-web';
import { CalendarContext } from '@modules/planner/calendar/calendar-context';
import {
  getPreviousWorkOrder,
  getTaskBoundaries,
} from '@modules/planner/calendar/utils';
import { getDuration } from '@modules/planner/calendar/utils/get-duration';
import { getTaskInterval } from '@modules/planner/calendar/utils/get-task-interval';
import { getTaskStart } from '@modules/planner/calendar/utils/get-task-start';
import { getTaskWidth } from '@modules/planner/calendar/utils/get-task-width';
import {
  WorkOrderMenu,
  WorkOrderMenuHandle,
} from '@modules/planner/work-order-menu/work-order-menu';
import { WorkOrder } from '@models';
import { useStyles } from './task-styles';
import { TravelTime } from './travel-time';

dayjs.extend(duration);

const filterWorkOrderFromPreviousDay = (
  workOrder: WorkOrder | undefined,
  selectedWorkOrder: WorkOrder | undefined
) => {
  if (!workOrder || !selectedWorkOrder) {
    return undefined;
  }
  if (
    dayjs(workOrder.endTime).day() === dayjs(selectedWorkOrder.startTime).day()
  ) {
    return workOrder;
  }
  return undefined;
};

export function Task({
  workOrder,
  overlayCount,
  position,
}: {
  workOrder: WorkOrder;
  overlayCount: number;
  position: number;
}) {
  const classes = useStyles();
  const { settings, currentDate, dataSource } = useContext(CalendarContext);

  const workOrderMenuRef = useRef<WorkOrderMenuHandle>(null);

  const { workOrderStart, workOrderEnd } = getTaskBoundaries(
    currentDate,
    settings,
    workOrder
  );

  const taskSize = getTaskWidth(
    currentDate,
    workOrderStart,
    workOrderEnd,
    workOrder.duration ?? 0,
    settings
  );

  if (taskSize === 0) {
    return null;
  }

  const taskStart = getTaskStart(
    currentDate,
    workOrderStart,
    workOrderEnd,
    workOrder.duration ?? 0,
    settings
  );

  const unit = dataSource.yAxis.data.find(
    (unit) =>
      unit.id === workOrder.mobileUnit?.id || unit.id === workOrder.user?.id
  );
  const previousWorkOrder = filterWorkOrderFromPreviousDay(
    getPreviousWorkOrder(
      unit?.workOrders ?? [],
      dayjs(workOrder.startTime).unix()
    ),
    workOrder
  );

  return (
    <>
      <Tooltip
        title={
          <div className={classes.tooltip}>
            {workOrder.workOrderId && (
              <div className={classes.tooltipContent}>
                Číslo zákazky: <b># {workOrder.workOrderId}</b>
              </div>
            )}
            {workOrder.operationNumber && (
              <div className={classes.tooltipContent}>
                Číslo operácie: <b># {workOrder.operationNumber}</b>
              </div>
            )}
            <div className={classes.tooltipContent}>
              Dohodnuté so zákazníkom:
              <b> {workOrder.customerAgreed ? 'Áno' : 'Nie'} </b>
            </div>

            {workOrder.workOrderType && (
              <div className={classes.tooltipContent}>
                Druh práce:
                <b> {workOrder.workOrderType.name} </b>
              </div>
            )}

            <div className={classes.tooltipContent}>
              Stav:{' '}
              <div className={classes.content}>
                <div
                  className={classes.bullet}
                  style={{
                    backgroundColor: workOrder.currentState?.color,
                  }}
                />
                <b>{workOrder.currentState?.name}</b>
              </div>
            </div>
            {workOrder.dueDate && (
              <div className={classes.tooltipContent}>
                Realizácia do:
                <b>
                  {new Date(workOrder.dueDate).toLocaleDateString('sk-SK')}{' '}
                </b>
              </div>
            )}
            {workOrder.address && (
              <div className={classes.tooltipContent}>
                Mesto:
                <b>{workOrder.address.city}</b>
              </div>
            )}
            {workOrder.address && (
              <div className={classes.tooltipContent}>
                Ulica:
                <b>
                  <b>{workOrder.address.street}</b>
                </b>
              </div>
            )}
            {!!workOrder.duration &&
              !!workOrder.startTime &&
              !!workOrder.endTime && (
                <div className={classes.tooltipContent}>
                  Trvanie:{' '}
                  <b>
                    {getTaskInterval(workOrder.startTime, workOrder.endTime)} (
                    {getDuration(workOrder.duration)})
                  </b>
                </div>
              )}
            {!!workOrder.travelPrevious && (
              <div className={classes.tooltipContent}>
                Prejazd: <b>{getDuration(workOrder.travelPrevious)}</b>
              </div>
            )}
          </div>
        }
      >
        <div
          className={cn(classes.root, {
            [classes.isMultiDay]: workOrder.isMultiDay,
          })}
          style={{
            left: taskStart,
            width: taskSize,
            height: 30 / overlayCount,
            top: (30 * (position - 1)) / overlayCount,
          }}
          onContextMenu={(e) => {
            e.preventDefault();

            workOrderMenuRef.current?.open(e);
          }}
        >
          {!!workOrder.travelPrevious && (
            <TravelTime orientation="left" seconds={workOrder.travelPrevious} />
          )}
          <div className={classes.content}>
            <div
              className={classes.bullet}
              style={{
                backgroundColor: workOrder.currentState?.color,
              }}
            />
            <Typography variant="body2" className={classes.title}>
              # {workOrder.workOrderId} {workOrder.isMultiDay && '(viacdenný)'}
            </Typography>
          </div>
          {!!workOrder.travelNext && (
            <TravelTime orientation="right" seconds={workOrder.travelNext} />
          )}
        </div>
      </Tooltip>
      <WorkOrderMenu
        previousWorkOrder={previousWorkOrder}
        workOrder={workOrder}
        ref={workOrderMenuRef}
        allDayRouteOption
      />
    </>
  );
}
