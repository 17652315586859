import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MuiInputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import MuiClearIcon from '@material-ui/icons/Clear';
import MuiSearchIcon from '@material-ui/icons/Search';
import {
  Form,
  RadioGroup,
  formFieldFactory,
  useStaticListSource,
} from '@eas/common-web';
import { useTeamList } from '@modules/team/team-api';
import { formTextFieldFactory } from '@components/form/simple-text-field/simple-text-field';
import { Team } from '@models';
import { useStyles } from './team-dialog-styles';

interface TeamDialogProps {
  defaultValue?: Team;
}

export interface TeamDialogHandle {
  getValue: () => Team | undefined;
}

export const TeamDialog = forwardRef<TeamDialogHandle, TeamDialogProps>(
  function TeamDialog(props, ref) {
    const intl = useIntl();
    const [value, setValue] = useState(props.defaultValue);

    const classes = useStyles();

    const [filter, setFilter] = useState('');
    const teams = useStaticListSource(useTeamList(filter)?.items ?? []);

    useImperativeHandle(
      ref,
      () => ({
        getValue: () => value,
      }),
      [value]
    );

    const SearchField = formFieldFactory(
      formTextFieldFactory({
        label: intl.formatMessage({
          id: 'ZSD__TEAM__FIELD_LABEL__TEAM_SEARCH_PLACEHOLDER',
          defaultMessage: 'Vyhľadať',
        }),
      }),
      ({ children }) => <>{children}</>
    );

    return (
      <div className={classes.root}>
        <Form
          onSubmit={(values) => setFilter(values.query)}
          editing={true}
          initialValues={{ query: '' }}
        >
          {(props) => (
            <SearchField
              name="query"
              size="small"
              classes={{ root: classes.searchInput }}
              endAdornment={
                <MuiInputAdornment position="end">
                  {filter && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        props.resetForm();
                        setFilter('');
                      }}
                    >
                      <MuiClearIcon />
                    </IconButton>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    className={classes.searchButton}
                    onClick={() => props.handleSubmit()}
                  >
                    <MuiSearchIcon />
                  </Button>
                </MuiInputAdornment>
              }
            />
          )}
        </Form>
        <Divider className={classes.divider} />
        {!teams?.items?.length && (
          <div>
            <FormattedMessage
              id="ZSD__TEAM__NO_RESULTS"
              defaultMessage="Žiadne výsledky"
            />
          </div>
        )}
        {!!teams?.items?.length && (
          <RadioGroup<Team>
            value={value as Team}
            source={teams}
            valueIsId={false}
            onChange={(value) => setValue(value as Team)}
          />
        )}
      </div>
    );
  }
);
