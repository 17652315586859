import React from 'react';
import { useIntl } from 'react-intl';
import { TableFieldCells, TableFieldColumn } from '@eas/common-web';
import { WorkOrderStateChange } from '@models';
import { mobileUnitErrors } from '@enums';
import { useWorkOrderStateColumn } from '../work-order-utils';

export function useColumns(): TableFieldColumn<WorkOrderStateChange>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'callId',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__ID',
        defaultMessage: 'ID úlohy',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'state',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__STATE',
        defaultMessage: 'Stav',
      }),
      width: 150,
      CellComponent: function Cell({ value }) {
        return useWorkOrderStateColumn(value);
      },
    },
    {
      datakey: 'modifiedBy',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__MODIFIED_BY',
        defaultMessage: 'Používateľ',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'validFromServer',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__VALID_FROM_SERVER',
        defaultMessage: 'Časová značka',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'validFrom',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__VALID_FROM',
        defaultMessage: 'Reálny okamih',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'objectType',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__OBJECT_TYPE',
        defaultMessage: 'Objekt',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'startTime',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__START_TIME',
        defaultMessage: 'Začiatok',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'endTime',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__END_TIME',
        defaultMessage: 'Koniec',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'resource',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__MOBILE_UNIT',
        defaultMessage: 'Priradený zdroj',
      }),
      width: 150,
      CellComponent: (props) => (
        <TableFieldCells.TextCell
          {...props}
          value={props.rowValue.mobileUnit || props.rowValue.userHrId}
        />
      ),
    },
    {
      datakey: 'validationErrorsEmpty',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__VALIDATION_ERRORS_EMPTY',
        defaultMessage: 'Porušenie pravidla?',
      }),
      width: 150,
      CellComponent: (props) => (
        <TableFieldCells.TextCell
          {...props}
          value={props.rowValue.validationErrors ? 'Áno' : ''}
        />
      ),
    },
    {
      datakey: 'earlyStart',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EARLY_START',
        defaultMessage: 'Realizácia od',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'dueDate',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DUE_DATE',
        defaultMessage: 'Termín realizácie do',
      }),
      width: 150,
      CellComponent: TableFieldCells.DateTimeCell,
    },
    {
      datakey: 'locked',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__LOCKED',
        defaultMessage: 'Uzamknutý',
      }),
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'fixed',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__FIXED',
        defaultMessage: 'Fixný',
      }),
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'validationErrors',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__VALIDATION_ERRORS',
        defaultMessage: 'Text porušenia pravidla',
      }),
      width: 200,
      CellComponent: function Cell(props) {
        return (
          <TableFieldCells.TextCell
            {...props}
            value={props.value
              ?.split(', ')
              .map(
                (err: keyof typeof mobileUnitErrors) =>
                  mobileUnitErrors[err]?.join('\n') || err
              )
              .join('\n')}
          />
        );
      },
    },
    {
      datakey: 'expectedMaterial',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__EXPECTED_MATERIAL',
        defaultMessage: 'Požadovaný materiál',
      }),
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'indivisible',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__INDIVISIBLE',
        defaultMessage: 'Nedeliteľný',
      }),
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'duration',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__DURATION',
        defaultMessage: 'Doba trvania',
      }),
      width: 150,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      datakey: 'sameMobileUnit',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__SAME_MOBILE_UNIT',
        defaultMessage: 'Rovnaká posádka',
      }),
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'maintenanceFailure',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__MAINTENANCE_FAILURE',
        defaultMessage: 'Porucha v údržbe (PVU)',
      }),
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'supplyRestored',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__SUPPLY_RESTORED',
        defaultMessage: 'Dodávka obnovená',
      }),
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
  ];
}
