import {
  Filter,
  ListSource,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { Certification, CertificationAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useCertifications() {
  return useAutocompleteSource<CertificationAutocomplete>({
    url: `${EvidenceApiUrl.CERTIFICATIONS}/autocomplete`,
  });
}

export function useCertificationsList(filters?: Filter[]) {
  return useFetch<ListSource<Certification>>(
    `${EvidenceApiUrl.CERTIFICATIONS}/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        sort: [
          {
            field: 'name',
            order: 'ASC',
            type: 'FIELD',
            missing: 'LAST',
          },
        ],
        filters: filters ?? [],
      }),
    }
  );
}
