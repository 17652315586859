import React from 'react';
import { useIntl } from 'react-intl';
import {
  // DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
// import { useCopyDialog } from './dialog-actions/copy-hook';
import { useDesignForm } from './dialog-actions/design-form-hook';

export function ActionBar() {
  const intl = useIntl();

  const {
    openFormDesigner,
    // openFormFiller,
    showBtn: showEditFormBtn,
  } = useDesignForm();

  // const { callApi: copyApiCall } = useCopyDialog();

  return (
    <>
      {showEditFormBtn && (
        <PrimaryDetailActionbarButton
          label={intl.formatMessage({
            id: 'ZSD__FORM_TEMPLATES__ACTIONBAR__EDIT_FORM',
            defaultMessage: 'Otvoriť šablónu',
          })}
          onClick={openFormDesigner}
        />
      )}
      {/* {showEditFormBtn && (
        <PrimaryDetailActionbarButton
          label={intl.formatMessage({
            id: 'ZSD__FORM_TEMPLATES__ACTIONBAR__FILL_FORM',
            defaultMessage: 'Nový zo šablony',
          })}
          onClick={openFormFiller}
        />
      )} */}
      {/* <DetailActionButton
        promptKey="COPY"
        apiCall={copyApiCall}
        buttonLabel={intl.formatMessage({
          id: 'ES__FORM_TEMPLATES__ACTIONBAR__COPY',
          defaultMessage: 'Duplikovať',
        })}
        dialogTitle={intl.formatMessage({
          id: 'ES__FORM_TEMPLATES__DIALOG_COPY__TITLE',
          defaultMessage: 'Varovanie',
        })}
        dialogText={intl.formatMessage({
          id: 'ES__FORM_TEMPLATES__DIALOG_COPY__TEXT',
          defaultMessage: 'Naozaj chcete vytvoriť kópiu šablóny?',
        })}
        ButtonComponent={PrimaryDetailActionbarButton}
        dialogWidth={450}
        isLocked={false}
      /> */}
    </>
  );
}
