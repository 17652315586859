import cn from 'clsx';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { DayPicker } from '@components/pickers/day-picker';
import { updateParams } from '../param-utils';
import { useStyles } from '../switcher-styles';

export function SwitcherDayPicker({
  date,
  setDate,
}: {
  date: number;
  setDate: (date: number) => void;
}) {
  const classes = useStyles();

  const onPrevious = useCallback(() => {
    const newDate = dayjs.unix(date).subtract(1, 'day').unix();

    setDate(newDate);
    updateParams('datum', newDate.toString());
  }, [date, setDate]);

  const onNow = useCallback(() => {
    const newDate = dayjs().unix();

    setDate(newDate);
  }, [setDate]);

  const onNext = useCallback(() => {
    const newDate = dayjs.unix(date).add(1, 'day').unix();

    setDate(newDate);
    updateParams('datum', newDate.toString());
  }, [date, setDate]);

  return (
    <>
      <Button
        variant="outlined"
        className={cn(classes.button, classes.outlineButton)}
        onClick={onNow}
      >
        <FormattedMessage
          id="ZSD__CALENDAR__TODAY"
          defaultMessage="Dnešný deň"
        />
      </Button>

      <IconButton size="small" onClick={onPrevious}>
        <KeyboardArrowLeftIcon />
      </IconButton>
      <DayPicker
        currentDate={date}
        onChange={(val) => {
          setDate(val);
          updateParams('datum', val.toString());
        }}
      />
      <IconButton size="small" onClick={onNext}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </>
  );
}
