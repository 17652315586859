import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    //height: 'calc(100% - 56.5px)', TODO: bolo tu minus 56.5, zistit ci to malo nejaky dovod
    height: '100%',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 10,
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  button: {
    borderRadius: 4,
    textTransform: 'none',
  },
  activeButton: {
    backgroundColor: theme.palette.grey[300] + ' !important',
    color:
      theme.palette.getContrastText(theme.palette.grey[300]) + ' !important',
  },
  basicButton: {
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    borderColor: theme.palette.grey[300],
    backgroundColor: '#fff',
  },
  outlineButton: {
    color: theme.palette.tertiary.main,
    borderColor: theme.palette.tertiary.main,
  },
  containedButton: {
    color: theme.palette.getContrastText(theme.palette.tertiary.main),
    backgroundColor: theme.palette.tertiary.main,

    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark,
    },
  },
  badge: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    borderRadius: 12,
    padding: '0 4px',
    marginLeft: 10,
  },
}));
