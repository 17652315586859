import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { ZsdUser } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { universalUserLabelMapper } from '@utils';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: (props) => (
        <Redirect url={EvidenceBrowserUrl.USERS} id={props.rowValue!.id} />
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__CERTIFICATIONS__TABLE__COLUMN__CERTIFICATION_NAME',
        defaultMessage: 'Technik',
      }),
      datakey: 'firstname',
      sortkey: 'firstname',
      width: 200,
      CellComponent: (props) => (
        <TableFieldCells.TextCell
          {...props}
          value={universalUserLabelMapper(props.rowValue as unknown as ZsdUser)}
        />
      ),
    },
    // Can be uncommented and fixed when BE includes certification details
    // {
    //   name: intl.formatMessage({
    //     id: 'ZSD__CERTIFICATIONS__TABLE__COLUMN__VALID_FROM',
    //     defaultMessage: 'Platnosť od',
    //   }),
    //   datakey: 'certifications.validFrom',
    //   sortkey: 'validFrom',
    //   width: 200,
    //   CellComponent: TableFieldCells.DateCell,
    // },
    // {
    //   name: intl.formatMessage({
    //     id: 'ZSD__CERTIFICATIONS__TABLE__COLUMN__VALID_TO',
    //     defaultMessage: 'Platnosť do',
    //   }),
    //   datakey: 'validTo',
    //   sortkey: 'validTo',
    //   width: 200,
    //   CellComponent: TableFieldCells.DateCell,
    // },
  ];
}
