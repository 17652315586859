import React, { useContext } from 'react';
import { CalendarContext } from '../../calendar-context';
import { CalendarDataType } from '../../calendar-types';
import { getNowOffset } from '../../utils/get-now-offset';
import { useStyles } from './now-styles';

interface NowProps {
  data: CalendarDataType[];
}

export function Now({ data }: NowProps) {
  const classes = useStyles();
  const { settings, currentDate } = useContext(CalendarContext);

  const left = getNowOffset(currentDate, settings);

  if (left === 0) {
    return null;
  }

  return (
    <div
      className={classes.root}
      style={{
        left,
        height: data.length * settings.rowHeight,
        minHeight: `calc(100% - ${settings.headerHeight}px)`,
        marginTop: settings.headerHeight,
      }}
    />
  );
}
