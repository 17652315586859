import dayjs, { Dayjs } from 'dayjs';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';
import { getSecondsInPixel } from './get-seconds-in-pixel';

export function getTaskStart(
  currentDayUnix: number,
  workOrderStart: Dayjs,
  workOrderEnd: Dayjs,
  workOrderDuration: number, // TODO: wont be needed anymore
  settings: Partial<CalendarSettingsType>
) {
  const {
    dimension = CalendarDimensions.WEEK,
    startingHour = 0,
    endingHour = 24,
  } = settings;

  const secondsInPixel = getSecondsInPixel(settings);

  const currentDay = dayjs.unix(currentDayUnix);

  switch (dimension) {
    case CalendarDimensions.DAY: {
      const seconds = workOrderStart.diff(
        currentDay.add(startingHour, 'hour'),
        'second'
      );

      return seconds / secondsInPixel;
    }
    case CalendarDimensions.WEEK:
    case CalendarDimensions.MONTH: {
      const seconds = workOrderStart.diff(currentDay, 'second');
      const days = workOrderStart.diff(currentDay, 'day');

      // hidden seconds in previous days
      let hiddenSeconds = (24 - (endingHour - startingHour)) * days * 60 * 60;

      // hidden seconds in current day
      hiddenSeconds += startingHour * 60 * 60;

      // crop start time to visible hours
      if (
        workOrderStart.isBefore(
          workOrderStart.startOf('day').add(startingHour, 'hour')
        ) &&
        workOrderEnd.isAfter(
          workOrderStart.startOf('day').add(startingHour, 'hour')
        )
      ) {
        const diff =
          workOrderStart.diff(currentDay.add(days, 'day'), 'second') -
          startingHour * 60 * 60;

        hiddenSeconds += diff;
      }

      return (seconds - hiddenSeconds) / secondsInPixel;
    }
    default:
      return 0;
  }
}
