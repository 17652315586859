import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

export function getScaleItemWidth(settings: Partial<CalendarSettingsType>) {
  const {
    dimension = CalendarDimensions.WEEK,
    stepWidth = 100,
    startingHour = 0,
    endingHour = 24,
  } = settings;

  switch (dimension) {
    case CalendarDimensions.DAY: {
      return stepWidth * (endingHour - startingHour);
    }

    case CalendarDimensions.WEEK: {
      return stepWidth * (endingHour - startingHour);
    }

    case CalendarDimensions.MONTH: {
      return stepWidth;
    }

    default:
      return 0;
  }
}
