import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';

export function useCollectionColumns<
  T extends DomainObject
>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  const columns = useMemo(
    () => [
      {
        datakey: 'created',
        name: intl.formatMessage({
          id: 'ZSD__COLLECTIONS__COLUMN__CREATED',
          defaultMessage: 'Dátum vzniku',
        }),
        width: 250,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        datakey: 'updated',
        name: intl.formatMessage({
          id: 'ZSD__COLLECTIONS__COLUMN__UPDATED',
          defaultMessage: 'Dátum editáce',
        }),
        width: 250,
        CellComponent: TableFieldCells.DateTimeCell,
      },
      {
        datakey: 'name',
        name: intl.formatMessage({
          id: 'ZSD__COLLECTIONS__COLUMN__NAME',
          defaultMessage: 'Název',
        }),
        width: 250,
        CellComponent: TableFieldCells.TextCell,
      },
      {
        datakey: 'createdBy.name',
        name: intl.formatMessage({
          id: 'ZSD__COLLECTIONS__COLUMN__CREATED_BY',
          defaultMessage: 'Autor',
        }),
        width: 250,
        CellComponent: TableFieldCells.TextCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  return columns;
}
