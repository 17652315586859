import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DatedEvidence,
  DetailContext,
  DetailHandle,
  EvidenceContext,
  FormPanel,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { UserField } from '@composite/user-collection/user-field';
import { Role } from '@models';
import { Permission } from '@enums';
import { usePermissions } from './role-api';
import { USERS_SOURCE } from './role-hooked-sources';
import { PermissionFields } from './role-permission-fields';

export function Fields() {
  const [permissions] = usePermissions();

  const { hookedSources } = useContext(EvidenceContext);
  const { source, isExisting } = useContext<DetailHandle<Role>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const userSource = hookedSources[USERS_SOURCE]?.source;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preFilters = useMemo(() => userSource.getParams().filters, []);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__ROLES__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__ROLES__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
        />
        <DatedEvidence.DatedFields />
      </FormPanel>

      {(permissions ?? []).map((group) => (
        <PermissionFields key={group.id} group={group} />
      ))}

      {isExisting && hasPermission(Permission.USER_LIST) && (
        <UserField
          multiple={true}
          refKey="roles"
          currentRef={{
            id: source.data?.id ?? '',
            label: source.data?.name ?? '',
          }}
          source={userSource}
          preFilters={preFilters}
        />
      )}
    </>
  );
}
