import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FieldArray,
  FormAutocomplete,
  FormCustomField,
  useFormSelector,
  usePrompts,
} from '@eas/common-web';
import { useShifts } from '@modules/shift/shift-api';
import { CalendarTemplate } from '@models';
import { labelMapper } from '@utils';
import { PlannerAddButton } from './button-add';
import { PlannerRemoveButton } from './button-remove';
import { useStyles } from './planner-styles';

// const days = [
//   'Pondelok',
//   'Utorok',
//   'Streda',
//   'Štvrtok',
//   'Piatok',
//   'Sobota',
//   'Nedeľa',
// ];

export function CalendarTemplatePlanner() {
  const classes = useStyles();

  const { shiftCount } = useFormSelector((values: CalendarTemplate) => ({
    shiftCount: values?.shifts?.length ?? 0,
  }));

  const shiftSource = useShifts();

  usePrompts([
    {
      key: 'REMOVE_PANEL',
      dialogTitle: 'Odstrániť týždeň',
      dialogText: 'Skutočne chcete odstrániť týždeň?',
    },
  ]);

  return (
    <FieldArray
      name="shifts"
      render={({ push, remove, form, name }) => {
        const { shifts } = form.values as CalendarTemplate;

        return (
          <>
            <div className={classes.tableActions}>
              <PlannerAddButton shiftCount={shiftCount} push={push} />
            </div>
            <div style={{ width: '100%' }}>
              {shifts?.map((shift, i) => (
                <>
                  {i % 7 === 0 && (
                    <FormCustomField
                      name={`${name}.${i}.order`}
                      label={
                        <span className={classes.title}>
                          <FormattedMessage
                            id="ZSD__CALENDAR_TEMPLATE__DETAIL__FIELD_LABEL__WEEK"
                            defaultMessage="Týždeň {week}"
                            values={{ week: Math.floor(i / 7) + 1 }}
                          />
                        </span>
                      }
                    >
                      {i > 0 && (
                        <div className={classes.button}>
                          <PlannerRemoveButton index={i} remove={remove} />
                        </div>
                      )}
                    </FormCustomField>
                  )}
                  <FormAutocomplete
                    name={`${name}.${i}.shift`}
                    source={shiftSource}
                    label={`Deň ${i + 1}`}
                    labelMapper={labelMapper}
                    required={true}
                  />
                </>
              ))}
            </div>
          </>
        );
      }}
    />
  );
}
