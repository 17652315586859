import { DictionaryEvidence, TableColumn } from '@eas/common-web';
import { SPHL } from '@models';

export function useColumns(): TableColumn<SPHL>[] {
  const dictionaryColumns = DictionaryEvidence.useDictionaryColumns<SPHL>();
  return [
    dictionaryColumns.columnName,
    dictionaryColumns.columnCreated,
    dictionaryColumns.columnUpdated,
  ];
}
