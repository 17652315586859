import * as Yup from 'yup';
import { Section } from '@models';
import { Team } from '@models';

export function useValidationSchema() {
  return Yup.object<Team>().shape({
    name: Yup.string().nullable().required(),
    section: Yup.mixed<Section>().nullable().required(),
  }) as Yup.Schema<Team>;
}
