import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  DetailContext,
  DetailHandle,
  DictionaryEvidence,
  EvidenceContext,
  Filter,
  FormAutocomplete,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useTeams } from '@modules/team/team-api';
import { MobileUnitField } from '@composite/mobile-unit-collection/mobile-unit-field';
import { UserField } from '@composite/user-collection/user-field';
import { Department } from '@models';
import {
  COORDINATORS_SOURCE,
  MOBILE_UNITS_SOURCE,
  TECHNICIANS_SOURCE,
} from './department-hooked-sources';

const technicianFilters: Filter[] = [
  {
    field: 'type',
    operation: ApiFilterOperation.EQ,
    value: 'TECHNICIAN',
  },
];

const coordinatorFilters: Filter[] = [
  {
    field: 'type',
    operation: ApiFilterOperation.EQ,
    value: 'COORDINATOR',
  },
];

export function Fields() {
  const teams = useTeams();
  const { hookedSources } = useContext(EvidenceContext);
  const { source, isExisting } =
    useContext<DetailHandle<Department>>(DetailContext);

  const mobileUnitSource = hookedSources[MOBILE_UNITS_SOURCE]?.source;
  const mobileUnitPreFilters = useMemo(
    () => mobileUnitSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const technicianSource = hookedSources[TECHNICIANS_SOURCE]?.source;
  const technicianPreFilters = useMemo(
    () => technicianSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const coordinatorsSource = hookedSources[COORDINATORS_SOURCE]?.source;
  const coordinatorPreFilters = useMemo(
    () => coordinatorsSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__DEPARTMENTS__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormAutocomplete
          name="team"
          source={teams}
          label={
            <FormattedMessage
              id="ZSD__DEPARTMENTS__DETAIL__FIELD_LABEL__TEAM"
              defaultMessage="Tím"
            />
          }
          required
        />
        <DictionaryEvidence.FieldName />
        <FormTextField
          name="code"
          label={
            <FormattedMessage
              id="ZSD__DEPARTMENTS__DETAIL__FIELD_LABEL__CODE"
              defaultMessage="Kód"
            />
          }
          required
        />
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>

      {isExisting && (
        <>
          <MobileUnitField
            source={mobileUnitSource}
            currentDepartment={{
              id: source.data?.id ?? '',
              label: source.data?.name ?? '',
            }}
            preFilters={mobileUnitPreFilters}
          />
          <UserField
            title={
              <FormattedMessage
                id="ZSD__FIELD_LABEL__TECHNICIANS"
                defaultMessage="Technici (primárne pracovisko)"
              />
            }
            source={technicianSource}
            refKey="department"
            refFilters={technicianFilters}
            currentRef={{
              id: source.data?.id ?? '',
              label: source.data?.name ?? '',
            }}
            preFilters={technicianPreFilters}
          />
          <UserField
            title={
              <FormattedMessage
                id="ZSD__FIELD_LABEL__COORDINATORS"
                defaultMessage="Koordinátori (primárne pracovisko)"
              />
            }
            source={coordinatorsSource}
            refKey="department"
            refFilters={coordinatorFilters}
            currentRef={{
              id: source.data?.id ?? '',
              label: source.data?.name ?? '',
            }}
            preFilters={coordinatorPreFilters}
          />
        </>
      )}
    </>
  );
}
