import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'ZSD__CERTIFICATIONS__TABLE__COLUMN__CERTIFICATION_NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'certification.name',
      sortkey: 'certification.name',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__CERTIFICATIONS__TABLE__COLUMN__VALID_FROM',
        defaultMessage: 'Dátum od',
      }),
      datakey: 'validFrom',
      sortkey: 'validFrom',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__CERTIFICATIONS__TABLE__COLUMN__VALID_TO',
        defaultMessage: 'Dátum do',
      }),
      datakey: 'validTo',
      sortkey: 'validTo',
      width: 200,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}
