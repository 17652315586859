import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { ZsdUser } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const ABSENCE_SOURCE = 'ABSENCE_SOURCE';
export const CALENDARS_SOURCE = 'CALENDARS_SOURCE';
export const CALENDAR_EXCEPTIONS_SOURCE = 'CALENDAR_EXCEPTIONS_SOURCE';

function useAbsenceSource(): HookedSource<ZsdUser> {
  const source = useScrollableSource();

  const { hasPermission } = useContext(UserContext);

  return {
    [ABSENCE_SOURCE]: {
      source,
      shouldLoad: (data) =>
        data.type === 'TECHNICIAN' && hasPermission(Permission.ABSENCE_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.ABSENCES}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              
              field: 'user.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useCalendarSource(): HookedSource<ZsdUser> {
  const source = useScrollableSource();

  const { hasPermission } = useContext(UserContext);

  return {
    [CALENDARS_SOURCE]: {
      source,
      shouldLoad: (data) =>
        data.type === 'TECHNICIAN' && hasPermission(Permission.CALENDAR_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.CALENDARS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'user.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useCalendarExceptionSource(): HookedSource<ZsdUser> {
  const source = useScrollableSource();

  const { hasPermission } = useContext(UserContext);

  return {
    [CALENDAR_EXCEPTIONS_SOURCE]: {
      source,
      shouldLoad: (data) =>
        data.type === 'TECHNICIAN' &&
        hasPermission(Permission.CALENDAR_EXCEPTION_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'user.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useUserHookedSources(): HookedSource<ZsdUser> {
  const absences = useAbsenceSource();
  const calendars = useCalendarSource();
  const calendarExceptions = useCalendarExceptionSource();

  return {
    ...absences,
    ...calendars,
    ...calendarExceptions,
  };
}
