import React, { useContext, useMemo } from 'react';
import { CalendarContext } from '@modules/plan/calendar/calendar-context';
import { PlanCalendarDataType } from '@modules/plan/calendar/calendar-types';
import {
  getDailyTemplate,
  getIntervals,
  getScaleItems,
} from '@modules/plan/calendar/utils';
import { ShiftInterval } from '@models';
import { useStyles } from './shift-styles';

export interface SingleDayShiftProps {
  barData: PlanCalendarDataType;
  getBarStyle: (isBlocked: boolean) => React.CSSProperties;
  getShiftStyle: (
    shiftInterval: ShiftInterval,
    hour: number,
    multipleShifts?: boolean
  ) => React.CSSProperties;
}

export function SingleDayShift({
  barData,
  getBarStyle,
  getShiftStyle,
}: SingleDayShiftProps) {
  const { settings } = useContext(CalendarContext);

  const classes = useStyles();

  const scaleItems = getScaleItems(settings);
  const items = useMemo(() => {
    const todayIntervals = getIntervals(barData.shifts?.[0]);

    const intervals = getDailyTemplate(
      scaleItems,
      getIntervals(
        barData.overflowShift,
        barData.shifts?.[0]?.isException ||
          barData.shifts?.[0]?.isBlockingException
      ),
      todayIntervals
    );

    return intervals;
  }, [barData.overflowShift, barData.shifts, scaleItems]);

  return (
    <>
      {items.map((shiftIntervals, hourIndex) => {
        const isBlocked = !!barData?.shifts?.[0]?.isBlockingException;

        return (
          <div
            key={hourIndex}
            className={classes.step}
            style={getBarStyle(!!isBlocked)}
          >
            <div
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 1,
              }}
            />
            {shiftIntervals.map((shiftInterval, indervalIndex, all) => (
              <div
                key={indervalIndex}
                style={{
                  position: 'absolute',
                  height: '100%',
                  ...getShiftStyle(shiftInterval, hourIndex, all.length > 1),
                }}
              />
            ))}
          </div>
        );
      })}
    </>
  );
}
