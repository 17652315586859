import React, { forwardRef, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { CalendarDimensions } from '@enums';
import { CalendarContext } from '../../calendar-context';
import { getScaleItems, getScaleLabel } from '../../utils';
import { getScaleDetailItems } from '../../utils/get-scale-detail-items';
import { getScaleItemWidth } from '../../utils/get-scale-item-width';
import { useStyles } from './scale-styles';

type ScaleProps = {
  width: number;
};

export const Scale = forwardRef<HTMLDivElement, ScaleProps>(function Scale(
  { width },
  ref
) {
  const { currentDate, settings, dataSource } = useContext(CalendarContext);
  const classes = useStyles();
  const theme = useTheme();

  const showDetailItems = settings.dimension === CalendarDimensions.WEEK;
  const scaleLabel = getScaleLabel(currentDate, settings, dataSource.holidays);
  const scaleItems = getScaleItems(currentDate, settings, dataSource.holidays);
  const scaleDetailItems = getScaleDetailItems(settings);

  const scaleItemWidth = getScaleItemWidth(settings);
  const scaleItemsHeight = showDetailItems ? 25 : 50;

  const isFirst = (index: number) =>
    index % (settings.endingHour - settings.startingHour) === 0;

  return (
    <div
      className={classes.wrapper}
      style={{
        marginLeft: settings.legendWidth,
      }}
    >
      <div
        className={classes.list}
        ref={ref}
        style={{
          width: width,
          height: settings.headerHeight,
        }}
      >
        <div className={classes.item}>
          <div className={classes.steps}>
            <div className={classes.title}>{scaleLabel}</div>
          </div>
          <div
            className={classes.steps}
            style={{
              height: scaleItemsHeight,
            }}
          >
            {scaleItems.map((item) => (
              <div
                className={classes.step}
                key={item}
                style={{ width: scaleItemWidth }}
              >
                {item}
              </div>
            ))}
          </div>
          {settings.dimension === CalendarDimensions.WEEK && (
            <div className={classes.steps}>
              {scaleDetailItems.map((item, i) => (
                <div
                  className={classes.step}
                  key={i}
                  style={{
                    width: settings.stepWidth,
                    color: isFirst(i) ? 'white' : 'black',
                    backgroundColor: isFirst(i)
                      ? theme.palette.primary.light
                      : 'white',
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
