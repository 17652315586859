import { DragOverlay } from '@dnd-kit/core';
import React from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { WorkOrder } from '@models';
import { CalendarHandle } from '../calendar/calendar-types';
import { getSecondsInPixel } from '../calendar/utils/get-seconds-in-pixel';
import { useWorkOrderBarStyles } from './work-order-styles';

export function WorkOrderBar({
  movingWorkOrders,
  calendarRef,
}: {
  movingWorkOrders: WorkOrder[];
  calendarRef: React.RefObject<CalendarHandle>;
}) {
  const classes = useWorkOrderBarStyles();

  const orderNumbers = movingWorkOrders.map(
    (workOrder) => workOrder.workOrderId
  );
  const getWidth = (order: WorkOrder) =>
    order.duration! / getSecondsInPixel(calendarRef.current!.settings);

  return (
    <>
      <DragOverlay dropAnimation={null}>
        <div className={classes.dndWorkOrderRoot}>
          {movingWorkOrders.map((order, i) => (
            <React.Fragment key={order.id}>
              <div
                key={i}
                className={classes.dndWorkOrderWrapper}
                style={{
                  zIndex: orderNumbers.length - i,
                  scale: `${1 - i * 0.05}`,
                  marginTop: `${(i + 1) * 5}px`,
                  width: `${getWidth(order)}px`,
                }}
              >
                {getWidth(order) > 50 ? (
                  <>
                    <div
                      className={classes.dndWorkOrderBoundary}
                      style={{ left: 2 }}
                    />
                    <div
                      className={classes.dndWorkOrder}
                      style={{ padding: '0 16px' }}
                    >
                      <p className={classes.dndWorkOrderTitle}>
                        Príkaz: {order.workOrderId}
                      </p>
                      <OpenWithIcon className={classes.dndWorkOrderIcon} />
                    </div>
                    <div
                      className={classes.dndWorkOrderBoundary}
                      style={{ right: 2 }}
                    />
                  </>
                ) : (
                  <div className={classes.dndWorkOrder} />
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </DragOverlay>
    </>
  );
}
