import React from 'react';
import { useIntl } from 'react-intl';
import {
  DomainObject,
  TableFieldCells,
  TableFieldColumn,
} from '@eas/common-web';
import { Redirect } from '@components/redirect/redirect';
import { EvidenceBrowserUrl } from '@enums';

export function useColumns<T extends DomainObject>(): TableFieldColumn<T>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'id',
      name: '',
      width: 80,
      CellComponent: (props) => (
        <Redirect
          url={EvidenceBrowserUrl.MOBILE_UNITS}
          id={props.rowValue!.id}
        />
      ),
    },
    {
      name: intl.formatMessage({
        id: 'ZSD__MOBILE_UNITS__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'name',
      sortkey: 'name',
      width: 200,
      CellComponent: TableFieldCells.TextCell,
    },
  ];
}
