import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormCheckbox,
  FormColorPickerField,
  FormContext,
  FormCustomField,
  NumberField,
  useFormSelector,
} from '@eas/common-web';
import { useStyles } from './settings-styles';

export function SettingsForm() {
  const { startingHour, endingHour, stepWidth } = useFormSelector(
    (data: any) => ({
      startingHour: data.startingHour,
      endingHour: data.endingHour,
      stepWidth: data.stepWidth,
    })
  );

  const { setFieldValue } = useContext(FormContext);

  const classes = useStyles();

  return (
    <>
      <FormCustomField
        name="stepWidth"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__STEP_WIDTH"
            defaultMessage="Šírka kroku"
          />
        }
      >
        <div className={classes.customField}>
          <div className={classes.inputWrapper}>
            <div className={classes.input}>
              <NumberField
                value={stepWidth}
                onChange={(value) => {
                  const width = Math.min(value ?? 0, 200);
                  setFieldValue('stepWidth', width);
                }}
                showClearButton={false}
              />
            </div>
            px
          </div>
          <div className={classes.actions}>
            <button
              className={classes.button}
              onClick={() => {
                setFieldValue('stepWidth', 50);
              }}
            >
              50px
            </button>
            <button
              className={classes.button}
              onClick={() => {
                setFieldValue('stepWidth', 100);
              }}
            >
              100px
            </button>
            <button
              className={classes.button}
              onClick={() => {
                setFieldValue('stepWidth', 200);
              }}
            >
              200px
            </button>
          </div>
        </div>
      </FormCustomField>

      <FormCustomField
        name="interval"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__INTERVAL"
            defaultMessage="Denný rozsah"
          />
        }
      >
        <div className={classes.customField}>
          <div className={classes.inputWrapper}>
            <div className={classes.input}>
              <NumberField
                value={startingHour}
                onChange={(value) => {
                  const hour = Math.min(value ?? 0, 24, endingHour - 1);
                  setFieldValue('startingHour', hour);
                }}
                showClearButton={false}
              />
            </div>
            h
          </div>
          <div> — </div>
          <div className={classes.inputWrapper}>
            <div className={classes.input}>
              <NumberField
                value={endingHour}
                onChange={(value) => {
                  const hour = Math.max(value ?? 0, 0, startingHour + 1);
                  setFieldValue('endingHour', hour);
                }}
                showClearButton={false}
              />
            </div>
            h
          </div>
          <div className={classes.actions}>
            <button
              className={classes.button}
              onClick={() => {
                setFieldValue('startingHour', 0);
                setFieldValue('endingHour', 24);
              }}
            >
              0 - 24
            </button>
            <button
              className={classes.button}
              onClick={() => {
                setFieldValue('startingHour', 6);
                setFieldValue('endingHour', 16);
              }}
            >
              6 - 16
            </button>
          </div>
        </div>
      </FormCustomField>

      <FormCheckbox
        name="pinnedMap"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__PINNED_MAP"
            defaultMessage={'Pripnutá mapa'}
          />
        }
      />

      <FormColorPickerField
        name="colorForShiftV"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__COLOR_FOR_SHIFT_V"
            defaultMessage={'Farba pre "Voľno"'}
          />
        }
      />
      <FormColorPickerField
        name="colorForShiftZ"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__COLOR_FOR_SHIFT_Z"
            defaultMessage={'Farba pre "Pracovná smena"'}
          />
        }
      />
      <FormColorPickerField
        name="colorForShiftP"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__COLOR_FOR_SHIFT_P"
            defaultMessage={'Farba pre "Pohotovosť"'}
          />
        }
      />
      <FormColorPickerField
        name="colorForShiftZP"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__COLOR_FOR_SHIFT_ZP"
            defaultMessage={'Farba pre "Záložná pohotovosť"'}
          />
        }
      />
      <FormColorPickerField
        name="colorForShiftA"
        label={
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__COLOR_FOR_SHIFT_A"
            defaultMessage={'Farba pre "Dostupnosť"'}
          />
        }
      />
      <FormCustomField name="defaultColors">
        <button
          className={classes.button}
          onClick={() => {
            setFieldValue('colorForShiftV', '#D9D9D940');
            setFieldValue('colorForShiftZ', '#ffffff');
            setFieldValue('colorForShiftZP', '#e2edfd');
            setFieldValue('colorForShiftA', '#bfe3c3');
            setFieldValue('colorForShiftP', '#f9dddf');
          }}
        >
          <FormattedMessage
            id="ZSD__CALENDAR_SETTINGS__RESET_COLORS"
            defaultMessage="Obnoviť predvolené farby"
          />
        </button>
      </FormCustomField>
    </>
  );
}
