import { AbortableFetch } from '@eas/common-web';

// Merges only jsons
export function multiAbortableFetch(
  requests: {
    request: RequestInfo;
    opts?: RequestInit;
  }[],
  merge: (data: any[]) => any
): AbortableFetch {
  const controller = new AbortController();
  const signal = controller.signal;

  const responses = requests.map((req) =>
    fetch(req.request, { ...req.opts, signal })
  );

  return {
    abort: () => controller.abort(),
    response: responses[0],
    raw: async () => {
      throw 'Not implemented';
    },
    json: async () => {
      const loadedResponses = await Promise.all(responses);
      const data = await Promise.all(loadedResponses.map((res) => res.json()));

      if (data.filter((d) => !d).length > 0) {
        throw data;
      }

      return merge(data);
    },
    text: async () => {
      throw 'Not implemented';
    },
    none: async () => {
      throw 'Not implemented';
    },
  };
}
