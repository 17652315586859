import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormCheckboxGroup,
  FormPanel,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { FormPanelCount } from '@components/form/form-panel-summary/form-panel-count';
import { PermissionGroup, Role } from '@models';

export function PermissionFields({ group }: { group: PermissionGroup }) {
  const permissionsSource = useStaticListSource(group.permissions);

  const { grantedPermissions } = useFormSelector(({ permissions }: Role) => {
    return {
      grantedPermissions: permissions?.filter((p) =>
        group.permissions.find((gp) => gp.id === p)
      ),
    };
  });

  return (
    <FormPanel
      label={group.description}
      key={group.id}
      expandable={true}
      defaultExpanded={false}
      summary={
        <FormPanelCount
          count={`${grantedPermissions?.length ?? 0} / ${
            group.permissions.length
          }`}
        />
      }
    >
      <FormCheckboxGroup
        label={
          <FormattedMessage
            id="ZSD__ROLES__DETAIL__FIELD_LABEL__PERMISSIONS"
            defaultMessage="Oprávnenia"
          />
        }
        source={permissionsSource}
        name="permissions"
      />
    </FormPanel>
  );
}
