import React from 'react';
import { useIntl } from 'react-intl';
import { WorkOrderDynamicAttributeName } from 'src/models/work-order-dynamic-attribute';
import { AuthoredEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils';
import { useColumns } from './work-order-attribute-columns';
import { Fields } from './work-order-attribute-fields';
import { useValidationSchema } from './work-order-attribute-schema';

export function WorkOrderAttributes() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_WRITE],
      EDIT: [Permission.WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_WRITE],
      REMOVE: [Permission.WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_WRITE],
    },
  });

  const evidence =
    AuthoredEvidence.useAuthoredEvidence<WorkOrderDynamicAttributeName>({
      identifier: 'WORK_ORDER_ATTRIBUTE',
      apiProps: {
        url: EvidenceApiUrl.WORK_ORDER_DYNAMIC_ATTRIBUTE_NAMES,
      },
      tableProps: {
        columns,
        tableName: intl.formatMessage({
          id: 'ZSD__WORK_ORDER_ATTRIBUTE__TABLE__TITLE',
          defaultMessage: 'SAP atribúty PP',
        }),
        showBulkActionButton: false,
        showReportButton: false,
        defaultSorts: [],
      },
      detailProps: {
        toolbarProps: {
          title: intl.formatMessage({
            id: 'ZSD__WORK_ORDER_ATTRIBUTE__DETAIL__TITLE',
            defaultMessage: 'SAP atribút PP',
          }),
          subtitle: (attr) => attr?.name ?? '',
          showBtn: btnPermissions,
        },
        GeneralFieldsComponent: EmptyComponent,
        FieldsComponent: Fields,
        validationSchema,
      },
    });

  return <Evidence {...evidence} />;
}
