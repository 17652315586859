import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { handleApiErrors } from 'src/utils/error-handler';
import {
  DatedEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
} from '@eas/common-web';
import { ZsdUser } from '@models';
import { EvidenceApiUrl, Permission, userApiErrors } from '@enums';
import { useEvidencePermission } from '@utils';
import { ActionBar } from './user-actionbar';
import { useColumns } from './user-columns';
import { Fields } from './user-fields';
import { useUserHookedSources } from './user-hooked-sources';
import { useValidationSchema } from './user-schema';

export function Users() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.USER_WRITE],
      EDIT: [Permission.USER_WRITE],
      REMOVE: [Permission.USER_WRITE],
    },
  });

  const { showSnackbar } = useContext(SnackbarContext);

  const evidence = DatedEvidence.useDatedEvidence<ZsdUser>({
    identifier: 'USER',
    apiProps: {
      url: EvidenceApiUrl.USERS,
      hookedSources: useUserHookedSources,
      ...handleApiErrors(userApiErrors.USER, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__TITLE',
        defaultMessage: 'Používatelia',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [
        { field: 'username', datakey: 'username', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__USERS__DETAIL__TITLE',
          defaultMessage: 'Používateľ',
        }),
        ActionBar,
        subtitle: (user) =>
          user?.firstName && user?.lastName
            ? user.firstName + ' ' + user.lastName
            : '',
        showBtn: btnPermissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
