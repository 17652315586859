import cn from 'clsx';
import React, { useCallback, useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Dialog, DialogHandle } from '@eas/common-web';
import {
  MobileUnitDialog,
  MobileUnitDialogHandle,
} from '@modules/planner/calendar/controls/mobile-unit/mobile-unit-dialog';
import { MobileUnit, ZsdUser } from '@models';
import { CalendarContext } from '../../calendar-context';

export function MobileUnitControl() {
  const intl = useIntl();
  const { classes, dataSource } = useContext(CalendarContext);

  const dialogRef = useRef<DialogHandle | null>(null);
  const dialogFieldsRef = useRef<MobileUnitDialogHandle | null>(null);

  const handleConfirm = useCallback(() => {
    const selected = dialogFieldsRef.current?.getValue();

    dataSource.unitsSource.setSelected(selected ?? []);

    dataSource.yAxis.refreshData();
  }, [dataSource]);

  const selectedUnits = dataSource.unitsSource?.selected ?? [];

  let label = '-';
  if (selectedUnits.length > 0) {
    if ((selectedUnits[0] as MobileUnit).name) {
      label = (selectedUnits[0] as MobileUnit).name;
    } else {
      label = `${(selectedUnits[0] as ZsdUser).firstName} ${
        (selectedUnits[0] as ZsdUser).lastName
      }`;
    }
  }

  return (
    <>
      <Dialog
        title={
          <FormattedMessage
            id="ZSD__USERS__DETAIL_FIELD_LABEL__MOBILE_UNIT_TITLE"
            defaultMessage="Výber mobilných jednotiek / technikov"
          />
        }
        ref={dialogRef}
        showConfirm={true}
        onConfirm={handleConfirm}
      >
        {() => (
          <MobileUnitDialog
            ref={dialogFieldsRef}
            defaultValue={selectedUnits}
          />
        )}
      </Dialog>
      <Button
        variant="outlined"
        className={cn(classes.button, classes.basicButton)}
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={() => dialogRef.current?.open()}
        title={intl.formatMessage({
          id: 'ZSD__USERS__DETAIL_FIELD_LABEL__MOBILE_UNIT_TITLE',
          defaultMessage: 'Výber mobilných jednotiek / technikov',
        })}
      >
        {label}
        {selectedUnits.length > 1 && (
          <span className={classes.badge}>+{selectedUnits.length - 1}</span>
        )}
      </Button>
    </>
  );
}
