import React, { forwardRef, useContext } from 'react';
import { CalendarContext } from '../../calendar-context';
import { getScaleItems } from '../../utils';
import { getScaleItemWidth } from '../../utils/get-scale-item-width';
import { useStyles } from './scale-styles';

type ScaleProps = {
  width: number;
};

export const Scale = forwardRef<HTMLDivElement, ScaleProps>(function Scale(
  { width },
  ref
) {
  const classes = useStyles();
  const { settings } = useContext(CalendarContext);

  const scaleItems = getScaleItems(settings);
  const scaleItemWidth = getScaleItemWidth(settings);
  return (
    <div
      className={classes.wrapper}
      style={{
        marginLeft: settings.legendWidth,
      }}
    >
      <div className={classes.list} ref={ref} style={{ width }}>
        <div className={classes.item}>
          <div
            className={classes.steps}
            style={{ height: settings.headerHeight }}
          >
            {scaleItems.map((item) => (
              <div
                className={classes.step}
                key={item}
                style={{ width: scaleItemWidth }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
