import dayjs from 'dayjs';
import { ScrollableSource, usePrompts } from '@eas/common-web';
import { WorkOrder } from '@models';
import { CalendarDataSourceType } from '../calendar/calendar-types';
import { AutoplannerForm } from './autoplanner-form';
import { useAutoPlannerSubmit } from './autoplanner-hook';

export function useAutoplannig({
  workOrderSource,
  calendarSource,
  defaultStart,
}: {
  workOrderSource: ScrollableSource<WorkOrder>;
  calendarSource: CalendarDataSourceType;
  defaultStart?: number;
}) {
  usePrompts(
    [
      {
        key: 'WORK_ORDER_AUTOPLANNER_DIALOG',
        dialogTitle: 'Automatické doplánovanie',
        dialogShowClose: true,
        dialogShowConfirm: true,
        dialogWidth: 300,
        dialogText: '',
        formInitialValues: {
          from: defaultStart ?? dayjs().startOf('day').unix(),
          to: defaultStart ?? dayjs().endOf('day').unix(),
        },
        FormFields: AutoplannerForm,
      },
    ],
    [defaultStart]
  );

  const { handleClick } = useAutoPlannerSubmit({
    workOrderSource,
    calendarSource,
  });

  return { handleClick };
}
