import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  mapWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
  },
  customControlButtons: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));
