import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import {
  DetailContext,
  DetailMode,
  PromptContext,
  TableFieldToolbarButton,
} from '@eas/common-web';

export function PlannerRemoveButton({
  index,
  remove,
}: {
  index: number;
  remove: (value: any) => void;
}) {
  const { testPrompt } = useContext(PromptContext);
  const { mode } = useContext(DetailContext);

  return (
    <TableFieldToolbarButton
      IconComponent={ClearIcon}
      show={true}
      title={
        <FormattedMessage
          id="ZSD__CALENDAR_TEMPLATE__DETAIL__REMOVE_WEEK"
          defaultMessage="Odstrániť"
        />
      }
      disabled={mode === DetailMode.VIEW}
      onClick={() => {
        testPrompt({
          key: 'REMOVE_PANEL',
          submitCallback: () => {
            for (let i = 0; i < 7; i++) {
              remove(index);
            }
          },
        });
      }}
      color="primary"
    >
      <Typography variant="body2">
        <FormattedMessage
          id="ZSD__CALENDAR_TEMPLATE__DETAIL__ADD_WEEK"
          defaultMessage="Odstrániť"
        />
      </Typography>
    </TableFieldToolbarButton>
  );
}
