import { DragOverlay } from '@dnd-kit/core';
import React from 'react';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import { ZsdUser } from '@models';
import { universalUserLabelMapper } from '@utils';
import { useTechnicianTableStyles } from '../switcher-styles';

export function TechnicianBar({
  dataSource,
}: {
  dataSource: ZsdUser | undefined;
}) {
  const classes = useTechnicianTableStyles();

  return (
    <>
      <DragOverlay dropAnimation={null}>
        <div className={classes.dndTechnicianRoot}>
          <div className={classes.dndTechnicianWrapper}>
            <div className={classes.dndTechnician}>
              <p className={classes.dndTechnicianTitle}>
                {universalUserLabelMapper(dataSource)}
              </p>
              <OpenWithIcon className={classes.dndTechnicianIcon} />
            </div>
          </div>
        </div>
      </DragOverlay>
    </>
  );
}
