import { Modifier } from '@dnd-kit/core';
import { CalendarSettingsType } from '@modules/planner/calendar/calendar-types';

export const snapToRow: Modifier = (args) => {
  const { transform } = args;

  if (args.over) {
    const settings: CalendarSettingsType = args.over.data.current!.settings;

    const isDay = settings.dimension === 'day';
    const isWeek = settings.dimension === 'week';

    const stepSize = settings.stepWidth;

    const gridSize =
      isDay || isWeek
        ? stepSize / 4
        : stepSize / (settings.endingHour - settings.startingHour);

    // detect left edge of hovered hour block
    const hourBeginPosition = args.over.rect.left - args.activeNodeRect!.left;

    // find the closest 15 minute interval
    const offset = Math.ceil((transform.x - hourBeginPosition) / gridSize) - 1;

    return {
      ...transform,
      y: args.over.rect.top - args.activeNodeRect!.top,
      x: hourBeginPosition + offset * gridSize,
    };
  } else {
    return transform;
  }
};
