import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
} from '@eas/common-web';
import { useCertifications } from '@modules/certification/certification-api';

export function CertificationCreateDialog() {
  const certificationSource = useCertifications();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__CERTIFICATIONS__DETAIL__FIELD_LABEL__DETAILS"
            defaultMessage="Detaily"
          />
        }
      >
        <FormAutocomplete
          name="certification"
          source={certificationSource}
          label={
            <FormattedMessage
              id="ZSD__CERTIFICATIONS__DETAIL__FIELD_LABEL__CERTIFICATION"
              defaultMessage="Zručnosť"
            />
          }
          required
        />
        <FormLocalDateField
          name="validFrom"
          label={
            <FormattedMessage
              id="ZSD__CERTIFICATIONS__DETAIL__FIELD_LABEL__FROM_DATE"
              defaultMessage="Dátum od"
            />
          }
          required
          representation="local-date-time"
        />
        <FormLocalDateField
          name="validTo"
          label={
            <FormattedMessage
              id="ZSD__CERTIFICATIONS__DETAIL__FIELD_LABEL__TO_DATE"
              defaultMessage="Dátum do"
            />
          }
          representation="local-date-time"
        />
      </FormPanel>
    </>
  );
}
