import {
  ApiFilterOperation,
  Filter,
  ListSource,
  useAutocompleteSource,
  useFetch,
  useScrollableSource,
  useStaticListSource,
} from '@eas/common-web';
import { MobileUnitWarnings, ZsdUser } from '@models';
import { EvidenceApiUrl } from '@enums';

export const technicianFilter = {
  field: 'type',
  operation: ApiFilterOperation.EQ,
  value: 'TECHNICIAN',
};

export const departmentFilter = (departmentId: string) => ({
  operation: ApiFilterOperation.OR,
  filters: [
    {
      field: 'department.id',
      operation: ApiFilterOperation.EQ,
      value: departmentId,
    },
    {
      field: 'additionalDepartments.id',
      operation: ApiFilterOperation.IN,
      values: [departmentId],
    },
  ],
});

export const technicianFilterByDepartment = (departmentId: string) => ({
  operation: ApiFilterOperation.AND,
  filters: [technicianFilter, departmentFilter(departmentId)],
});

export function useUsers() {
  return useAutocompleteSource<ZsdUser>({
    url: `${EvidenceApiUrl.USERS}/list`,
  });
}

export function useUsersAutocomplete() {
  return useAutocompleteSource<ZsdUser>({
    url: `${EvidenceApiUrl.USERS}/autocomplete`,
  });
}

export const useUsersScrollable = () => {
  return useScrollableSource<ZsdUser>({
    url: `${EvidenceApiUrl.USERS}/list`,
  });
};

export const useUserTypes = () => {
  return useStaticListSource([
    { id: 'TECHNICIAN', name: 'Technik' },
    { id: 'COORDINATOR', name: 'Koordinátor' },
    { id: 'DISPATCHER', name: 'Dispečer' },
    { id: 'MANAGEMENT', name: 'Manažment' },
    { id: 'ADMIN', name: 'Administrátor' },
  ]);
};

export const filterTechniciansWithDepartment = (
  departmentId: string,
  excludeTechnicianId?: string
) => ({
  operation: ApiFilterOperation.AND,
  filters: [
    technicianFilter,
    departmentFilter(departmentId),
    ...(excludeTechnicianId
      ? [
          {
            operation: ApiFilterOperation.NOT,
            filters: [
              {
                field: 'id',
                operation: ApiFilterOperation.EQ,
                value: excludeTechnicianId,
              },
            ],
          },
        ]
      : []),
  ],
});

export const useTechniciansByDepartment = (
  departmentId: string,
  excludeTechnicianId?: string
) => {
  return useAutocompleteSource<ZsdUser>({
    url: `${EvidenceApiUrl.USERS}/autocomplete`,
    params: {
      filters: [
        filterTechniciansWithDepartment(departmentId, excludeTechnicianId),
      ],
    },
  });
};

export const useTechniciansByDepartmentScrollable = (
  departmentId: string,
  excludeTechnicianId?: string
) => {
  return useScrollableSource<ZsdUser>({
    url: `${EvidenceApiUrl.USERS}/list`,
    params: {
      filters: [
        filterTechniciansWithDepartment(departmentId, excludeTechnicianId),
      ],
    },
  });
};

export function useTechnicianList({
  skip,
  query,
  departmentIds,
  userIds,
}: {
  skip: boolean;
  query?: string;
  departmentIds?: string[];
  userIds?: string[];
}) {
  const filters: Filter[] = [technicianFilter];

  if (departmentIds) {
    filters.push({
      field: 'department.id',
      operation: ApiFilterOperation.IN,
      values: departmentIds,
    });
  }

  if (userIds) {
    filters.push({
      field: 'id',
      operation: ApiFilterOperation.IN,
      values: userIds,
    });
  }

  if (query) {
    filters.push({
      operation: ApiFilterOperation.FTX,
      value: query,
    });
  }

  const [result, loading, reset, setLoading] = useFetch<ListSource<ZsdUser>>(
    `${EvidenceApiUrl.USERS}/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        filters,
        sort: [
          {
            field: 'username',
            order: 'ASC',
            type: 'FIELD',
            missing: 'LAST',
          },
        ],
      }),
      skip,
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export const useUnassignedTechniciansInDepartment = (
  departmentId: string,
  date: string
) => {
  return useFetch<ZsdUser[]>(
    `${EvidenceApiUrl.USERS}/unassigned/department/${departmentId}?date=${date}`,
    {
      method: 'GET',
    }
  );
};

export function useGetTechnicianWarnings(
  departmentId: string | undefined,
  date: string
) {
  return useFetch<{ string: MobileUnitWarnings[] }>(
    `${EvidenceApiUrl.USERS}/warning?departmentId=${departmentId}&date=${date}`,
    {
      skip: !departmentId,
    }
  );
}
