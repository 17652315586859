import cn from 'clsx';
import dayjs from 'dayjs';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { CalendarDimensions } from '@enums';
import { CalendarContext } from '../calendar-context';
import { useCalculate } from '../hooks';
import { MobileUnitCurrentLocationControl } from './current-location/mobile-unit-current-location';
import { DepartmentControl } from './department/department-control';
import { MobileUnitControl } from './mobile-unit/mobile-unit-control';
import { CalendarDayPicker } from './pickers/day-picker';
import { CalendarMonthPicker } from './pickers/month-picker';
import { CalendarWeekPicker } from './pickers/week-picker';

interface ControlsProps {
  className?: string;
}

export function Controls({ className }: ControlsProps) {
  const { calculate } = useCalculate();
  const { settings, currentDate, setCurrentDate, classes, dataSource } =
    useContext(CalendarContext);

  const onPrevious = useCallback(() => {
    const newDate = dayjs.unix(currentDate).subtract(1, settings.dimension);

    setCurrentDate(newDate.unix());

    dataSource.yAxis.refreshData();
  }, [currentDate, dataSource, setCurrentDate, settings.dimension]);

  const onNow = useCallback(() => {
    const newDate = dayjs().startOf(settings.dimension).unix();

    setCurrentDate(newDate);

    dataSource.yAxis.refreshData();
  }, [dataSource, setCurrentDate, settings.dimension]);

  const onNext = useCallback(() => {
    const newDate = dayjs.unix(currentDate).add(1, settings.dimension);

    setCurrentDate(newDate.unix());

    dataSource.yAxis.refreshData();
  }, [currentDate, dataSource, setCurrentDate, settings.dimension]);

  const onChangeDimension = useCallback(
    (value: CalendarDimensions) => {
      dataSource.yAxis.refreshData();

      calculate({
        dimension: value,
      });
    },
    [calculate, dataSource]
  );

  return (
    <div className={cn(classes.topBar, className)}>
      <div className={classes.group}>
        <Button
          variant="outlined"
          className={cn(classes.button, classes.outlineButton)}
          onClick={onNow}
        >
          {settings.dimension === CalendarDimensions.DAY && (
            <FormattedMessage
              id="ZSD__CALENDAR__TODAY"
              defaultMessage="Dnešný deň"
            />
          )}
          {settings.dimension === CalendarDimensions.WEEK && (
            <FormattedMessage
              id="ZSD__CALENDAR__THIS_WEEK"
              defaultMessage="Aktuálny týždeň"
            />
          )}
          {settings.dimension === CalendarDimensions.MONTH && (
            <FormattedMessage
              id="ZSD__CALENDAR__THIS_MONTH"
              defaultMessage="Aktuálny mesiac"
            />
          )}
        </Button>

        <IconButton size="small" onClick={onPrevious}>
          <KeyboardArrowLeftIcon />
        </IconButton>

        {settings.dimension === CalendarDimensions.DAY && <CalendarDayPicker />}
        {settings.dimension === CalendarDimensions.WEEK && (
          <CalendarWeekPicker />
        )}
        {settings.dimension === CalendarDimensions.MONTH && (
          <CalendarMonthPicker />
        )}

        <IconButton size="small" onClick={onNext}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>

      <div className={classes.group}>
        <ButtonGroup>
          <Button
            className={cn(classes.button, classes.basicButton, {
              [classes.activeButton]:
                settings.dimension === CalendarDimensions.DAY,
            })}
            onClick={() => onChangeDimension(CalendarDimensions.DAY)}
          >
            <FormattedMessage id="ZSD__CALENDAR__DAY" defaultMessage="Deň" />
          </Button>
          <Button
            className={cn(classes.button, classes.basicButton, {
              [classes.activeButton]:
                settings.dimension === CalendarDimensions.WEEK,
            })}
            onClick={() => onChangeDimension(CalendarDimensions.WEEK)}
          >
            <FormattedMessage
              id="ZSD__CALENDAR__WEEK"
              defaultMessage="Týždeň"
            />
          </Button>
          <Button
            className={cn(classes.button, classes.basicButton, {
              [classes.activeButton]:
                settings.dimension === CalendarDimensions.MONTH,
            })}
            onClick={() => onChangeDimension(CalendarDimensions.MONTH)}
          >
            <FormattedMessage
              id="ZSD__CALENDAR__MONTH"
              defaultMessage="Mesiac"
            />
          </Button>
        </ButtonGroup>

        <MobileUnitControl />
        <DepartmentControl />
        <MobileUnitCurrentLocationControl />
      </div>
    </div>
  );
}
