import React from 'react';
import { usePrompts } from '@eas/common-web';
import { MapWithMarkers } from '@composite/map/map-with-markers';
import { GPSPoint } from '@models';

/**
 * creates prompts to show unit starting point or current location on map
 */
export function useUnitLocation({
  id,
  startingPoint,
  currentLocation,
  unitName,
}: {
  id: string;
  startingPoint?: GPSPoint;
  currentLocation?: GPSPoint;
  unitName: string;
}) {
  usePrompts(
    [
      {
        key: `UNIT_STARTING_POINT_DIALOG_${id}`,
        dialogTitle: `Domovská adresa ${unitName}`,
        dialogWidth: 1200,
        dialogText: (
          <div style={{ width: '100%', height: 500 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {startingPoint?.latitude}, {startingPoint?.longitude}
            </div>
            <MapWithMarkers
              markers={[
                {
                  tooltip: {
                    content: `${unitName}`,
                    permanent: true,
                  },
                  point: startingPoint!,
                  pointLabel: 'S',
                  pointStyle: { borderColor: 'blue' },
                },
              ]}
            />
          </div>
        ),
      },
      {
        key: `UNIT_CURRENT_LOCATION_DIALOG_${id}`,
        dialogTitle: `Aktuálna poloha ${unitName}`,
        dialogWidth: 1200,
        dialogText: (
          <div style={{ width: '100%', height: 500 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {currentLocation?.latitude}, {currentLocation?.longitude}
            </div>
            <MapWithMarkers
              markers={[
                {
                  tooltip: {
                    content: `${unitName}`,
                    permanent: true,
                  },
                  point: currentLocation!,
                  pointLabel: 'A',
                  pointStyle: { borderColor: 'green' },
                },
              ]}
            />
          </div>
        ),
      },
    ],
    []
  );
}
