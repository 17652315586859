import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormCheckbox,
  FormNumberField,
  FormPanel,
} from '@eas/common-web';
import { useCertifications } from '@modules/certification/certification-api';
import { labelMapper } from '@utils';

export function RequirementsFields() {
  const certifications = useCertifications();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__PANEL__REQUIREMENTS"
            defaultMessage="Požiadavky"
          />
        }
      >
        <FormAutocomplete
          name="certifications"
          source={certifications}
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__CERTIFICATIONS"
              defaultMessage="Požadované kvalifikácie"
            />
          }
          labelMapper={labelMapper}
          multiple={true}
        />
        <FormNumberField
          name="mobileUnitSize"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__MOBILE_UNIT_SIZE"
              defaultMessage="Požadovaná veľkosť posádky"
            />
          }
        />
        <FormCheckbox
          name="sameMobileUnit"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__SAME_MOBILE_UNIT"
              defaultMessage="Dokončiť rovnakou posádkou"
            />
          }
        />
      </FormPanel>
    </>
  );
}
