import { Dayjs } from 'dayjs';
import { CalendarSettingsType } from '../calendar-types';
import { getSecondsInPixel } from './get-seconds-in-pixel';

export function getTaskWidth(
  workOrderStart: Dayjs,
  workOrderEnd: Dayjs,
  workOrderDuration: number,
  settings: Partial<CalendarSettingsType>
) {
  const secondsInPixel = getSecondsInPixel(settings);

  const seconds = workOrderEnd.diff(workOrderStart, 'second');

  return (seconds > 0 ? seconds : workOrderDuration) / secondsInPixel;
}
