import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DatedEvidence,
  FormColorPickerField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDER_STATE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormTextField
          name="name"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDER_STATE__DETAIL__FIELD_LABEL__NAME"
              defaultMessage="Názov"
            />
          }
        />
        <FormColorPickerField
          name="color"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDER_STATE__DETAIL__FIELD_LABEL__COLOR"
              defaultMessage="Farva"
            />
          }
        />
        <FormTextField
          name="code"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDER_STATE__DETAIL__FIELD_LABEL__CODE"
              defaultMessage="Kód"
            />
          }
          disabled={true}
        />
        <DatedEvidence.DatedFields />
      </FormPanel>
    </>
  );
}
