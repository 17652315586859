import {
  ClientRect,
  CollisionDescriptor,
  CollisionDetection,
} from '@dnd-kit/core';

/**
 * Returns the intersecting rectangle area between two rectangles
 */
export function getIntersectionRatio(
  entry: ClientRect,
  target: ClientRect,
  pointerCoordinates: { x: number; y: number }
): number {
  const top = Math.max(pointerCoordinates.y - target.height / 2, entry.top);
  const left = Math.max(target.left, entry.left);
  const right = Math.min(target.left + target.width, entry.left + entry.width);
  const bottom = Math.min(
    pointerCoordinates.y + target.height / 2,
    entry.top + entry.height
  );
  const width = right - left;
  const height = bottom - top;

  if (left < right && top < bottom) {
    const targetArea = target.width * target.height;
    const entryArea = entry.width * entry.height;
    const intersectionArea = width * height;
    const intersectionRatio =
      intersectionArea / (targetArea + entryArea - intersectionArea);

    return Number(intersectionRatio.toFixed(4));
  }

  // Rectangles do not overlap, or overlap has an area of zero (edge/corner overlap)
  return 0;
}

/**
 * Returns the rectangles that has the greatest intersection area with a given
 * rectangle in an array of rectangles.
 */
export const rectIntersection: CollisionDetection = ({
  collisionRect,
  droppableRects,
  droppableContainers,
  pointerCoordinates,
}) => {
  const collisions: CollisionDescriptor[] = [];

  for (const droppableContainer of droppableContainers) {
    const { id } = droppableContainer;
    const rect = droppableRects.get(id);

    if (rect) {
      const intersectionRatio = getIntersectionRatio(
        rect,
        collisionRect,
        pointerCoordinates!
      );

      if (intersectionRatio > 0) {
        collisions.push({
          id,
          data: {
            droppableContainer,
            ratio: intersectionRatio,
            value: collisionRect.left - droppableContainer.rect.current!.left,
          },
        });
      }
    }
  }

  // find the collision with the highest value and in case of multiple collisions pick the one with the highest ratio
  const collision = collisions.reduce((acc, collision) => {
    if (acc) {
      if (acc.data.value < collision.data.value) {
        return collision;
      }

      if (acc.data.value === collision.data.value) {
        if (acc.data.ratio < collision.data.ratio) {
          return collision;
        }
      }
    }

    return acc;
  }, collisions[0]);

  if (collision) {
    return [collision];
  } else {
    return [];
  }
};
