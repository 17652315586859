import { useIntl } from 'react-intl';
import { DatedEvidence, TableCells, TableColumn } from '@eas/common-web';
import { ZsdUser } from '@models';

export function useColumns(): TableColumn<ZsdUser>[] {
  const intl = useIntl();

  const { columnCreated, columnUpdated } =
    DatedEvidence.useDatedColumns<ZsdUser>();

  return [
    {
      datakey: 'hrId',
      sortkey: 'hrId',
      filterkey: 'hrId',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__HR_ID',
        defaultMessage: 'Osobné číslo',
      }),
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'firstName',
      sortkey: 'firstName',
      filterkey: 'firstName',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__FIRSTNAME',
        defaultMessage: 'Meno',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'lastName',
      sortkey: 'lastName',
      filterkey: 'lastName',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__LASTNAME',
        defaultMessage: 'Priezvisko',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'phoneNumber',
      sortkey: 'phoneNumber',
      filterkey: 'phoneNumber',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__PHONE_NUMBER',
        defaultMessage: 'Telefónne číslo',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    {
      datakey: 'username',
      sortkey: 'username',
      filterkey: 'username',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__USERNAME',
        defaultMessage: 'KID',
      }),
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
    },
    columnCreated,
    columnUpdated,
  ];
}
