import { TableCells, TableColumn } from '@eas/common-web';
import { FilledFormSapAttribute } from '@models';

export function useColumns(): TableColumn<FilledFormSapAttribute>[] {
  return [
    /* current BE indexing doesnt support sorting nor filtering */
    {
      datakey: 'formTemplate.name',
      name: 'Šablóna formulára',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'filledFormName',
      name: 'Názov formulára',
      CellComponent: TableCells.TextCell,
      width: 200,
      sortable: false,
      filterable: false,
    },
    {
      datakey: 'sapName',
      name: 'SAP názov',
      CellComponent: TableCells.TextCell,
      width: 200,
      sortable: false,
      filterable: false,
    },
  ];
}
