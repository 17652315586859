import React, { forwardRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MapIcon from '@material-ui/icons/Map';
import { PromptContext } from '@eas/common-web';
import { PlannerContext } from '@modules/planner/planner-context';
import { PinnedMapContext } from '@composite/map/pinned-map-context';
import { WorkOrder } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
  previousWorkOrder?: WorkOrder;
};

export const getUnitStartingPoint = (
  workOrder: WorkOrder
): { latitude?: number; longitude?: number } | undefined => {
  if (workOrder.user?.startingPoint) {
    return workOrder.user?.startingPoint;
  }
  if (workOrder.mobileUnit?.startingPoint) {
    return workOrder.mobileUnit?.startingPoint;
  }
  return undefined;
};

export const RouteMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function RouteMenuItem({ closeMenu, workOrder, previousWorkOrder }, ref) {
    const { testPrompt } = useContext(PromptContext);
    const { calendarRef } = useContext(PlannerContext);
    const { updateMapData } = useContext(PinnedMapContext);

    if (!workOrder?.latitude || !workOrder?.longitude) {
      return null;
    }

    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          closeMenu();
          if (calendarRef.current?.settings?.pinnedMap) {
            updateMapData({
              variant: 'travel',
              travel: {
                workOrders: [previousWorkOrder, workOrder],
                addStartingPoint: previousWorkOrder === undefined,
                startingPoint: getUnitStartingPoint(workOrder),
                unitId: workOrder.user?.id ?? workOrder.mobileUnit?.id ?? '',
              },
            });
          } else {
            testPrompt({
              key: `TRAVEL_TO_${workOrder.id}`,
              submitCallback: () => {},
            });
          }
        }}
      >
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <FormattedMessage
          id="WORK_ORDER_MENU_ITEM_TRAVEL"
          defaultMessage="Trasa"
        />
      </MenuItem>
    );
  }
);
