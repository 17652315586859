import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DatedEvidence,
  FormAutocomplete,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useFormTemplates } from '@modules/form-template/form-template-api';

export function Fields() {
  const templates = useFormTemplates();

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__FILLED_FORM_ATTRIBUTE__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormAutocomplete
          name="formTemplate"
          label={
            <FormattedMessage
              id="ZSD__FILLED_FORM_ATTRIBUTE__DETAIL__FIELD_LABEL__FORM_TEMPLATE"
              defaultMessage="Šablóna formulára"
            />
          }
          source={templates}
          required
        />

        <FormTextField
          name="filledFormName"
          label={
            <FormattedMessage
              id="ZSD__FILLED_FORM_ATTRIBUTE__DETAIL__FIELD_LABEL__FILLED_FORM_NAME"
              defaultMessage="Názov formulára"
            />
          }
          required
        />

        <FormTextField
          name="sapName"
          label={
            <FormattedMessage
              id="ZSD__FILLED_FORM_ATTRIBUTE__DETAIL__FIELD_LABEL__SAP_NAME"
              defaultMessage="SAP názov"
            />
          }
          required
        />

        <DatedEvidence.DatedFields />
      </FormPanel>
    </>
  );
}
