import React from 'react';
import { usePrompts } from '@eas/common-web';
import { MapWithMarkers } from '@composite/map/map-with-markers';
import { MobileUnit } from '@models';

export const useMobileUnitCurrentLocation = (unit?: MobileUnit) => {
  usePrompts(
    [
      {
        key: `MOBILE_UNIT_CURRENT_LOCATION_DIALOG_${unit?.id}`,
        dialogTitle: `Aktuálna poloha ${unit?.name}`,
        dialogWidth: 1200,
        dialogShowConfirm: false,
        dialogText: (
          <div style={{ width: '100%', height: 500 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {unit?.currentLocation?.latitude},{' '}
              {unit?.currentLocation?.longitude}
            </div>
            <MapWithMarkers
              markers={[
                {
                  tooltip: {
                    content: `${unit?.name}`,
                    permanent: true,
                  },
                  point: unit?.currentLocation,
                  pointLabel: 'A',
                  pointStyle: { borderColor: 'green' },
                },
              ]}
            />
          </div>
        ),
      },
    ],
    [unit?.id]
  );
};
