import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import LaunchIcon from '@material-ui/icons/Launch';
import {
  ApiFilterOperation,
  Params,
  PromptContext,
  TableField,
  usePrompts,
} from '@eas/common-web';
import { Absence, WorkOrder } from '@models';
import { EvidenceApiUrl } from '@enums';

export function AbsenceConflictsForm({
  conflicts,
}: {
  conflicts: WorkOrder[];
}) {
  if (!conflicts || conflicts.length === 0) {
    return 'Naozaj si prajete schváliť absenciu?';
  }
  return (
    <div>
      <b>
        <p style={{ fontWeight: 600, color: 'red' }}>
          Tím mobilnej jednotky / technik má v zadanom termíne naplánované alebo
          rozpracované pracovné príkazy. Chcete aj napriek tomu schváliť
          absenciu?
        </p>
      </b>
      <TableField
        columns={[
          { datakey: 'workOrderId', name: 'Číslo zákazky', width: 200 },
          { datakey: 'currentState.name', name: 'Stav', width: 200 },
          {
            datakey: 'id',
            name: 'Detail',
            width: 100,
            CellComponent: ({ value }) => (
              <Button
                onClick={() =>
                  window.open(`/zsd/pracovne-prikazy/${value}`, '_blank')
                }
              >
                <LaunchIcon fontSize="small" />
              </Button>
            ),
          },
        ]}
        onChange={() => {}}
        value={conflicts}
        visibleEdit={false}
        visibleAdd={false}
        visibleRemove={false}
        visibleActionsColumn={false}
        showToolbar={false}
        showRadioCond={() => false}
      />
    </div>
  );
}

export function useValidateAbsence() {
  const { testPrompt } = useContext(PromptContext);
  const [conflicts, setConflicts] = useState<WorkOrder[]>([]);

  usePrompts(
    [
      {
        key: 'VALIDATE_ABSENCE_DIALOG',
        dialogTitle: 'Naozaj schváliť absenciu?',
        dialogWidth: 600,
        dialogText: AbsenceConflictsForm({ conflicts }),
      },
    ],
    [conflicts]
  );

  const validate = async (absence: Absence, withPrompt?: boolean) => {
    const params: Params = {
      filters: [
        {
          field: 'user.id',
          operation: ApiFilterOperation.EQ,
          value: absence.user?.id,
        },
        {
          field: 'startTime',
          operation: ApiFilterOperation.LTE,
          value: absence.toDate,
        },
        {
          field: 'endTime',
          operation: ApiFilterOperation.GTE,
          value: absence.fromDate,
        },
        {
          operation: ApiFilterOperation.NOT,
          filters: [
            {
              field: 'isMultiDay',
              operation: ApiFilterOperation.EQ,
              value: true,
            },
          ],
        },
        {
          operation: ApiFilterOperation.NOT,
          filters: [
            {
              field: 'currentState.code',
              operation: ApiFilterOperation.EQ,
              value: 'ZRU',
            },
            {
              field: 'currentState.code',
              operation: ApiFilterOperation.EQ,
              value: 'NOV',
            },
            {
              field: 'currentState.code',
              operation: ApiFilterOperation.EQ,
              value: 'VYB',
            },
          ],
        },
      ],
      size: -1,
    };
    const response = await fetch(`${EvidenceApiUrl.WORK_ORDERS}/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
    const json = await response.json();
    const errors = json.items;

    setConflicts(errors);

    const promise = new Promise<{ ok: boolean; errors?: WorkOrder[] }>(
      (resolve) => {
        setTimeout(() => {
          if (errors.length === 0) {
            resolve({
              ok: true,
            });
            return;
          } else {
            if (!withPrompt) {
              resolve({
                ok: false,
                errors,
              });
            }
          }

          if (withPrompt) {
            testPrompt({
              key: 'VALIDATE_ABSENCE_DIALOG',
              submitCallback: () => {
                resolve({
                  ok: true,
                });
              },
              closeCallback: () => {
                resolve({
                  ok: false,
                });
              },
            });
          }
        }, 0);
      }
    );

    return promise;
  };

  return { validate };
}
