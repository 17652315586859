import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  AbortableFetch,
  DetailContext,
  DomainObject,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { Certification, DictionaryAutocomplete, ZsdUser } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './certification-columns';
import { useValidationSchema } from './certification-schema';
import { CertificationCreateDialog } from './dialogs/certification-create-dialog';

interface CertificationFieldProps {
  refKey: string;
  currentRef: DictionaryAutocomplete;
  value: Certification[];
}

export function CertificationField({
  refKey,
  currentRef,
  value,
}: CertificationFieldProps) {
  const { source } = useContext(DetailContext);
  const user = source.data as ZsdUser;

  const useCreateApi = <T extends DomainObject>(
    getUrl: (id: string) => string
  ) => {
    const callApi = useEventCallback((id: string, formData: T) => {
      return abortableFetch(`${getUrl(id)}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          ...user,
          certifications: [...(user.certifications ?? []), formData ?? []],
        }),
      });
    });

    return {
      callApi,
    };
  };

  const useReadApi = () => {
    const callApi = useEventCallback((id: string) => {
      return {
        async json() {
          const data = user.certifications?.find((c) => c.id === id);
          return data
            ? Promise.resolve(data)
            : Promise.reject(new Error('Certifikace nenalezena'));
        },
      } as AbortableFetch;
    });

    return {
      callApi,
    };
  };

  const useUpdateApi = <T extends DomainObject>(
    getUrl: (id: string, collectionId: string) => string
  ) => {
    const callApi = useEventCallback(
      (id: string, collectionId: string, formData: T) => {
        return abortableFetch(`${getUrl(id, collectionId)}`, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            ...user,
            certifications: user.certifications?.map((c) =>
              c.id === collectionId ? formData : c
            ),
          }),
        });
      }
    );

    return {
      callApi,
    };
  };

  const useDeleteApi = (
    getUrl: (id: string, collectionId: string | string[]) => string
  ) => {
    const callApi = useEventCallback(
      (id: string, collectionId: string | string[]) => {
        return abortableFetch(`${getUrl(id, collectionId)}`, {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            ...user,
            certifications: user.certifications?.filter(
              (c) => c.id !== collectionId
            ),
          }),
        });
      }
    );

    return {
      callApi,
    };
  };

  return (
    <CollectionField
      defaultExpanded={false}
      items={value}
      useColumns={useColumns}
      disabled={false}
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__CERTIFICATIONS"
          defaultMessage="Zručnosti"
        />
      }
      createAction={{
        getUrl: (id: string) => `${EvidenceApiUrl.USERS}/${id}`,
        useApi: useCreateApi,
        Dialog: CertificationCreateDialog,
        initValues: () => ({
          [refKey]: currentRef,
        }),
        useSchema: useValidationSchema,
        dialogWidth: 700,
      }}
      readAction={{
        getUrl: () => `${EvidenceApiUrl.USERS}/${user.id}`,
        useApi: useReadApi,
      }}
      updateAction={{
        getUrl: (id: string) => `${EvidenceApiUrl.USERS}/${id}`,
        useApi: useUpdateApi,
        Dialog: CertificationCreateDialog,
        initValues: () => ({
          [refKey]: currentRef,
        }),
        useSchema: useValidationSchema,
        dialogWidth: 700,
      }}
      deleteAction={{
        getUrl: (id: string) => `${EvidenceApiUrl.USERS}/${id}`,
        useApi: useDeleteApi,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USER_CERTIFICATIONS}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USER_CERTIFICATIONS}/${collectionId}`,
      }}
    />
  );
}
