import * as Yup from 'yup';
import { Calendar } from '@models';

export function useValidationSchema() {
  return Yup.object<Calendar>().shape({
    template: Yup.string().required('Povinné pole'),
    fromDate: Yup.string().required('Povinné pole'),
    toDate: Yup.string().required('Povinné pole'),
  });
}
