import dayjs from 'dayjs';
import 'dayjs/locale/sk';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TableHandle, UserSettingsContext } from '@eas/common-web';
import { PinnedMapContext } from '@composite/map/pinned-map-context';
import { WorkOrder } from '@models';
import {
  CalendarSettings,
  defualtSettings,
  settingsId,
  settingsVersion,
} from '../settings/settings';
import { CalendarContext } from './calendar-context';
import { useStyles } from './calendar-styles';
import {
  CalendarDataSourceType,
  CalendarHandle,
  CalendarSettingsType,
} from './calendar-types';
import { Chart } from './chart';
import { Controls } from './controls';
import { getCurrentDate, getInitialScrollOffset } from './utils';

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.locale('sk');

type CalendarProps = {
  children: ReactNode | ReactNode[];
  dataSource: CalendarDataSourceType;
  setEdgeWorkOrders: Dispatch<
    SetStateAction<[WorkOrder | undefined, WorkOrder | undefined]>
  >;
  workOrderTableRef: RefObject<TableHandle<WorkOrder>>;
};

export const Calendar = forwardRef<CalendarHandle, CalendarProps>(
  function Calendar(
    { children, dataSource, workOrderTableRef, setEdgeWorkOrders },
    ref
  ) {
    const classes = useStyles();

    const wrapRef = useRef<HTMLDivElement>(null);

    const { getCustomSettings } = useContext(UserSettingsContext);

    const { clearAllData } = useContext(PinnedMapContext);

    // settings
    const savedSettings = getCustomSettings(
      settingsId,
      settingsVersion
    ) as CalendarSettings;

    const resolvedSettings: CalendarSettingsType = useMemo(() => {
      return {
        ...defualtSettings,
        ...savedSettings,
        initialScrollOffset: 0,
      };
    }, [savedSettings]);

    const initialCurrentDate = useMemo(() => {
      return getCurrentDate(resolvedSettings);
    }, [resolvedSettings]);

    const initialScrollOffset = useMemo(() => {
      return getInitialScrollOffset(initialCurrentDate, resolvedSettings);
    }, [resolvedSettings, initialCurrentDate]);

    const [settings, setSettings] = useState<CalendarSettingsType>({
      ...resolvedSettings,
      initialScrollOffset,
    });

    const [currentDate, setCurrentDate] = useState(initialCurrentDate);

    useImperativeHandle(
      ref,
      () => ({
        settings,
        setSettings,
        currentDate,
        setCurrentDate,
        clearPinnedMapData: clearAllData,
        clearWebsocketCachedData: { current: () => {} },
      }),
      [settings, currentDate, clearAllData]
    );

    return (
      <CalendarContext.Provider
        value={{
          setEdgeWorkOrders,
          workOrderTableRef,
          wrapRef,
          dataSource,
          settings,
          setSettings,
          currentDate,
          setCurrentDate,
          classes,
        }}
      >
        <div className={classes.wrapper}>{children}</div>
      </CalendarContext.Provider>
    );
  }
) as unknown as React.FC<
  CalendarProps & {
    ref?: React.Ref<CalendarHandle>;
  }
> & {
  Chart: typeof Chart;
  Controls: typeof Controls;
  useStyles: typeof useStyles;
};

Calendar.Chart = Chart;
Calendar.Controls = Controls;
Calendar.useStyles = useStyles;
