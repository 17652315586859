import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  Autocomplete,
  FormContext,
  FormFieldWrapper,
  useFormSelector,
} from '@eas/common-web';
import { useCollectionSource } from '@components/collection-field/collection-api';
import { TeamAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import { labelMapper } from '@utils';

export function useValidationSchema() {
  return Yup.object({
    id: Yup.string().required('Povinné pole'),
  });
}

async function getTeam(id: string) {
  const response = await fetch(`${EvidenceApiUrl.TEAMS}/${id}`, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const json = await response.json();

  return json;
}

export const assignDialogFactory = (currentSectionId?: string) =>
  function TeamAssignDialog() {
    const source = useCollectionSource<TeamAutocomplete>(
      EvidenceApiUrl.TEAMS,
      'section.id',
      currentSectionId
    );

    const { setFieldValues, errors, resetValidation } = useContext(FormContext);
    const team = useFormSelector((values: TeamAutocomplete) =>
      values?.id ? values : null
    );

    return (
      <FormFieldWrapper
        required={true}
        disabled={false}
        label=""
        labelOptions={{ hide: true }}
        layoutOptions={{}}
        errorOptions={{}}
        errors={errors}
      >
        <Autocomplete<TeamAutocomplete>
          source={source}
          labelMapper={labelMapper}
          onChange={async (values) => {
            resetValidation();
            const formData = values as TeamAutocomplete;

            const team = await getTeam(formData.id);

            setFieldValues({
              ...team,
              section: {
                id: currentSectionId,
              },
            });
          }}
          value={team}
        />
      </FormFieldWrapper>
    );
  };
