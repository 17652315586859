import React, { ComponentPropsWithoutRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { DictionaryAutocomplete, UserCertification } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './certification-columns';
import { useFilterFields } from './certification-filter-fields';

interface CertificationFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<UserCertification>>
  > {
  currentRef: DictionaryAutocomplete;
  source: ScrollableSource<UserCertification>;
}

export function CertificationField({
  source,
  ...overrides
}: CertificationFieldProps) {
  const filters = useFilterFields();

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__TECHNICIANS_WITH_CERTIFICATION"
          defaultMessage="Technici so zručnosťou"
        />
      }
      defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={true}
      createAction={{
        hide: true,
        getUrl: () => EvidenceApiUrl.USER_CERTIFICATIONS,
      }}
      readAction={{
        getUrl: (collectionId) =>
          `${EvidenceApiUrl.USER_CERTIFICATIONS}/${collectionId}`,
      }}
      updateAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USER_CERTIFICATIONS}/${collectionId}`,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USER_CERTIFICATIONS}/${collectionId}`,
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USER_CERTIFICATIONS}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.USER_CERTIFICATIONS}/${collectionId}`,
      }}
      filters={filters}
      {...overrides}
    />
  );
}
