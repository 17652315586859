import { useCallback, useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  AbortableFetch,
  FormHandle,
  SnackbarContext,
  ValidationError,
  useEventCallback,
} from '@eas/common-web';
import { Messages } from '@enums';
import { loginUser } from './login-api';
import { LoginData } from './login-types';

export function useLogin() {
  const fetch = useRef<AbortableFetch | null>(null);
  const ref = useRef<FormHandle<LoginData>>(null);
  const { showSnackbar } = useContext(SnackbarContext);

  const [errors, setErrors] = useState<ValidationError[]>([]);

  const getFieldError = useCallback(
    (field: string) => {
      if (!errors || !errors.length) {
        return;
      }

      const currentError = errors.find((e) => e.key === field);

      if (currentError) {
        return currentError.value || `Pole "${field}" obsahuje chybu!`;
      }

      return;
    },
    [errors]
  );

  const validationSchema = Yup.object<LoginData>().shape({
    username: Yup.string()
      .nullable()
      .required('Užívateľské meno musí byť vyplnené'),
    password: Yup.string().nullable().required('Heslo musí byť vyplnené'),
  });

  const handleSubmit = useEventCallback(async (data: LoginData) => {
    if (fetch.current !== null) {
      fetch.current.abort();
    }

    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      setErrors(errors);

      if (errors.length) {
        return;
      }

      fetch.current = loginUser(data.username, data.password);

      const response = await fetch.current.response;

      if (
        response.redirected &&
        response.ok &&
        !response.url.includes('public?error')
      ) {
        window.location.href = response.url;
      } else {
        const error = response.status !== 503 ? 'ERROR' : response.status;
        showSnackbar(...Messages.User.LOGIN[error]);
      }
    }
  });

  return { ref, handleSubmit, validationSchema, getFieldError };
}
