import cn from 'clsx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Launch from '@material-ui/icons/Launch';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { EvidenceBrowserUrl } from '@enums';
import { Calendar } from './calendar/calendar';
import { usePlanCalendarDataSource } from './calendar/calendar-data-source';
import { CalendarHandle } from './calendar/calendar-types';
import { useUnitDetail } from './plan-api';
import { PlanContext } from './plan-context';
import { useStyles } from './plan-styles';
import { useSettings } from './settings/settings';
import { getUnitName } from './utils/utils';
import { useWorkOrderDataSource } from './work-orders/work-order-data-source';

dayjs.extend(duration);

export function Plan({
  match: { params: urlParams },
  unitType,
}: RouteComponentProps<{ id: string }> & { unitType: 'mobileUnit' | 'user' }) {
  const classes = useStyles();

  const calendarClasses = Calendar.useStyles();

  const calendarRef = useRef<CalendarHandle>(null);

  const { result: unitDetail, loading } = useUnitDetail(urlParams.id, unitType);

  const calendarSource = usePlanCalendarDataSource(
    unitDetail,
    unitType,
    calendarRef.current?.currentDate ?? dayjs().unix()
  );

  const workOrderSource = useWorkOrderDataSource();

  const { handleClick: handleSettings } = useSettings({
    calendarRef,
  });

  if (loading) {
    return <div />;
  }

  const name = getUnitName(unitDetail, unitType);

  return (
    <PlanContext.Provider
      value={{
        calendarSource,
        workOrderSource,
        calendarRef,
      }}
    >
      <div className={classes.root}>
        <div className={classes.topWindow}>
          <div className={calendarClasses.topBar}>
            <Typography variant="h1" className={calendarClasses.title}>
              <FormattedMessage
                id={`ZSD__CALENDAR__PLAN__TITLE__${
                  unitType === 'mobileUnit' ? 'MOBILE_UNIT' : 'USER'
                }`}
                defaultMessage={`Plán ${
                  unitType === 'mobileUnit' ? 'mobilnej jednotky' : 'technika'
                }`}
              />
              <Link
                color="textPrimary"
                href={`${
                  unitType === 'mobileUnit'
                    ? EvidenceBrowserUrl.MOBILE_UNITS
                    : EvidenceBrowserUrl.USERS
                }/${urlParams.id}`}
                target="_blank"
              >
                &nbsp;{name}
                <Launch
                  fontSize="small"
                  color="disabled"
                  style={{ marginLeft: 4, marginTop: 4 }}
                />
              </Link>
            </Typography>

            <div className={calendarClasses.group}>
              <Button
                variant="outlined"
                className={cn(
                  calendarClasses.button,
                  calendarClasses.basicButton
                )}
                onClick={handleSettings}
                title="Nastavenia"
              >
                <SettingsIcon />
              </Button>
              <Button
                variant="outlined"
                className={cn(
                  calendarClasses.button,
                  calendarClasses.basicButton
                )}
                onClick={() => {
                  calendarSource.yAxis.refreshData();
                }}
                title="Obnoviť"
              >
                <RefreshIcon />
              </Button>
            </div>
          </div>

          <Calendar ref={calendarRef} dataSource={calendarSource}>
            <Calendar.Controls />
            <Calendar.Chart />
          </Calendar>
        </div>
      </div>
    </PlanContext.Provider>
  );
}
