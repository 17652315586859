import { useMemo } from 'react';
import { darken, lighten, rgbToHex } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export function useTheme({
  primaryColor,
  secondaryColor,
  tertiaryColor,
}: {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}) {
  const theme = useMemo(
    () =>
      createMuiTheme({
        props: {
          MuiButtonBase: {
            // The properties to apply
            // disableRipple: true, // No more ripple, on the whole application 💣!
          },
        },
        typography: {
          fontFamily: ['"Open Sans"', 'Roboto'].join(','),
          // fontSize: 12,
          h1: {
            fontSize: '32px',
            fontWeight: 600,
          },
          h2: {
            fontSize: '30px',
            fontWeight: 600,
          },
          h3: {
            fontSize: '28px',
            fontWeight: 600,
          },
          h4: {
            fontSize: '24px',
            fontWeight: 600,
          },
          h5: {
            fontSize: '22px',
            fontWeight: 600,
          },
          h6: {
            fontSize: '12px',
            fontWeight: 600,
          },
          body1: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '2em',
          },
          body2: {
            fontWeight: 600,
            fontSize: '11px',
            lineHeight: '2em',
          },
        },
        palette: {
          primary: {
            light: rgbToHex(lighten(primaryColor, 0.3)),
            main: primaryColor,
            dark: rgbToHex(darken(primaryColor, 0.3)),
          },
          secondary: {
            light: rgbToHex(lighten(secondaryColor, 0.3)),
            main: secondaryColor,
            dark: rgbToHex(darken(secondaryColor, 0.3)),
          },
          tertiary: {
            light: rgbToHex(lighten(tertiaryColor, 0.3)),
            main: tertiaryColor,
            dark: rgbToHex(darken(tertiaryColor, 0.3)),
          },
          text: {
            primary: '#000000',
            secondary: '#777777',
          },
          editing: '#FEFFB2',
          highlight: '#fefeb28a',
        },
        shape: {
          borderRadius: 0,
        },
        overrides: {
          MuiInput: {
            root: {
              backgroundColor: '#FEFFB2',
              transition: 'width 0.1s ease-in-out',

              '&.Mui-disabled': {
                backgroundColor: 'inherit',
                color: 'inherit',
              },
            },
          },
          MuiCssBaseline: {
            '@global': {
              '*': {
                margin: '0px',
                padding: '0px',
                '-webkit-font-smoothing': 'auto',
              },
              '*:focus': {
                outline: 'none !important',
              },
              html: {
                height: '100%',
              },
              body: {
                height: '100%',
                overscrollBehavior: 'none',
                overflow: 'hidden',
              },
              a: {
                textDecoration: 'none',
              },
              '#root': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              },
            },
          },
          MuiMenuItem: {
            root: {
              lineHeight: '1.49em',
              minHeight: 'auto',
            },
          },
          MuiStepIcon: {
            text: {
              fill: '#fff',
            },
          },
        },
        eas: {
          shadow: ['0px 0px 10px #ccc', '0px 5px 5px #ddd'],
        },
      }),
    [primaryColor]
  );

  return theme;
}
