import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormContext,
  useFormSelector,
  useUpdateEffect,
} from '@eas/common-web';
import { useTeams } from '@modules/team/team-api';
import { ZsdUser } from '@models';

export function UserTeamField() {
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((data: ZsdUser) => ({
    team: data?.team?.id ?? undefined,
    type: data?.type,
  }));

  const isDispatcher = values?.type === 'DISPATCHER';

  const teams = useTeams();

  useUpdateEffect(() => {
    if (!isDispatcher) {
      setFieldValue('team', null);
    }
  }, [isDispatcher]);

  if (!isDispatcher) {
    return <></>;
  }

  return (
    <>
      <FormAutocomplete
        name="team"
        source={teams}
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL_FIELD_LABEL__TEAM"
            defaultMessage="Tím"
          />
        }
      />
    </>
  );
}
