import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormContext, useFormSelector } from '@eas/common-web';
import { DayPicker } from '@components/pickers/day-picker';
import { CalendarDimensions } from '@enums';
import { getScaleLabel } from '../calendar/utils';

export function AutoplannerForm() {
  const { setFieldValue } = useContext(FormContext);
  const { from, to } = useFormSelector(
    (values: { from: number; to: number }) => {
      return {
        from: values.from,
        to: values.to,
      };
    }
  );

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body1">Dátum od</Typography>
        <DayPicker
          labelMapper={(d) =>
            getScaleLabel(
              d,
              {
                dimension: CalendarDimensions.DAY,
              },
              []
            )
          }
          currentDate={from}
          onChange={(value) => {
            setFieldValue('from', value);
          }}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        <Typography variant="body1">Dátum do</Typography>
        <DayPicker
          labelMapper={(d) =>
            getScaleLabel(
              d,
              {
                dimension: CalendarDimensions.DAY,
              },
              []
            )
          }
          currentDate={to}
          onChange={(value) => {
            setFieldValue('to', value);
          }}
        />
      </div>
    </div>
  );
}
