import React, { ReactElement, ReactNode, Ref, useMemo, useRef } from 'react';
import {
  ActionButtonHandle,
  DomainObject,
  FormPanel,
  RemoteTableField,
  ScrollableSource,
  TableField,
  TableFieldColumn,
  TableFieldHandle,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { useCollectionColumns } from './collection-columns';
import { CollectionCount } from './collection-count';
import {
  CollectionToolbar,
  CollectionToolbarProps,
} from './collection-toolbar';

export interface CollectionFieldProps<T extends DomainObject>
  extends Omit<CollectionToolbarProps<T>, 'initValuesRef'> {
  source?: ScrollableSource<T>;
  items?: T[];
  title: ReactNode;
  defaultExpanded?: boolean;
  useColumns: <T extends DomainObject>() => TableFieldColumn<T>[];
  ToolbarComponent?: (
    p: TableFieldToolbarProps &
      CollectionToolbarProps<T> & {
        ref: Ref<ActionButtonHandle>;
      }
  ) => ReactElement;
  exceptions?: { [key: string]: string };
  multiple?: boolean;
}

export function CollectionField<T extends DomainObject>({
  disabled,
  source,
  items,
  title,
  defaultExpanded = true,
  useColumns = useCollectionColumns,
  ToolbarComponent = CollectionToolbar,
  multiple,
  ...toolbarProps
}: CollectionFieldProps<T>) {
  const columns = useColumns<T>();

  const tableRef = useRef<TableFieldHandle>(null);
  const tableRef2 = useRef<TableFieldHandle>(null);
  const editRef = useRef<ActionButtonHandle>(null);

  const initValuesRef = useRef<T | null>(null);

  const Toolbar = useMemo(
    () =>
      function Toolbar(props: TableFieldToolbarProps) {
        return (
          <ToolbarComponent
            {...props}
            {...toolbarProps}
            ref={editRef}
            disabled={disabled}
            initValuesRef={initValuesRef}
            staticItems={items}
          />
        );
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled, toolbarProps]
  );

  if (!source && !items) {
    return <> Missing SOURCE </>;
  }

  return (
    <FormPanel
      label={title}
      expandable={true}
      defaultExpanded={defaultExpanded}
      summary={
        source ? (
          <CollectionCount source={source} />
        ) : (
          <CollectionCount items={items} />
        )
      }
    >
      {source && (
        <RemoteTableField
          ref={tableRef}
          disabled={disabled}
          columns={columns}
          source={source}
          maxRows={15}
          ToolbarComponent={Toolbar}
          onRowClick={async (_, index) => {
            if (!disabled) {
              await tableRef.current?.setSelectedIndexes([index]);
              editRef.current?.executeAction();
            }
          }}
          showDetailBtnCond={() => false}
          showCheckboxCond={
            multiple && source.count > 0 ? () => true : () => false
          }
          showRadioCond={!multiple ? () => true : () => false}
        />
      )}
      {items && (
        <TableField
          ref={tableRef2}
          columns={columns}
          value={items}
          ToolbarComponent={Toolbar}
          onRowClick={async (_, index) => {
            if (!disabled) {
              await tableRef2.current?.setSelectedIndexes([index]);
              editRef.current?.executeAction();
            }
          }}
          onChange={(index) => {
            console.log('onChange', index);
            // if (!disabled) {
            //   await tableRef.current?.setSelectedIndex(index);
            //   editRef.current?.executeAction();
            // }
          }}
        />
      )}
    </FormPanel>
  );
}
