import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Department } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const TECHNICIANS_SOURCE = 'TECHNICIANS_SOURCE';
export const COORDINATORS_SOURCE = 'COORDINATORS_SOURCE';
export const MOBILE_UNITS_SOURCE = 'MOBILE_UNITS_SOURCE';

function useTechnicianSource(): HookedSource<Department> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [TECHNICIANS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.USER_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.USERS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              operation: ApiFilterOperation.AND,
              filters: [
                {
                  field: 'type',
                  operation: ApiFilterOperation.EQ,
                  value: 'TECHNICIAN',
                },
                {
                  field: 'department.id',
                  operation: ApiFilterOperation.EQ,
                  value: id,
                },
              ],
            },
          ],
        });
      },
    },
  };
}

function useCoordinatorSource(): HookedSource<Department> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [COORDINATORS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.USER_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.USERS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              operation: ApiFilterOperation.AND,
              filters: [
                {
                  field: 'type',
                  operation: ApiFilterOperation.EQ,
                  value: 'COORDINATOR',
                },
                {
                  field: 'coordinatorPrimaryDepartment.id',
                  operation: ApiFilterOperation.EQ,
                  value: id,
                },
              ],
            },
          ],
        });
      },
    },
  };
}

function useMobileUnitSource(): HookedSource<Department> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [MOBILE_UNITS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.MOBILE_UNIT_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.MOBILE_UNITS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              operation: ApiFilterOperation.AND,
              filters: [
                {
                  field: 'department.id',
                  operation: ApiFilterOperation.EQ,
                  value: id,
                },
                {
                  field: 'active',
                  operation: ApiFilterOperation.EQ,
                  value: true,
                },
              ],
            },
          ],
        });
      },
    },
  };
}

export function useDepartmentHookedSources(): HookedSource<Department> {
  const technicians = useTechnicianSource();
  const coordinators = useCoordinatorSource();
  const mobileUnits = useMobileUnitSource();

  return { ...technicians, ...coordinators, ...mobileUnits };
}
