import cn from 'clsx';
import dayjs from 'dayjs';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { CalendarContext } from '../calendar-context';
import { CalendarMonthPicker } from './pickers/month-picker';

interface ControlsProps {
  className?: string;
}

export function Controls({ className }: ControlsProps) {
  const { currentDate, setCurrentDate, classes, dataSource } =
    useContext(CalendarContext);

  const onPrevious = useCallback(() => {
    const newDate = dayjs.unix(currentDate).subtract(1, 'month');

    setCurrentDate(newDate.unix());

    dataSource.yAxis.refreshData();
  }, [currentDate, dataSource, setCurrentDate]);

  const onNow = useCallback(() => {
    const newDate = dayjs().startOf('month').unix();

    setCurrentDate(newDate);

    dataSource.yAxis.refreshData();
  }, [dataSource, setCurrentDate]);

  const onNext = useCallback(() => {
    const newDate = dayjs.unix(currentDate).add(1, 'month');

    setCurrentDate(newDate.unix());

    dataSource.yAxis.refreshData();
  }, [currentDate, dataSource, setCurrentDate]);

  return (
    <div className={cn(classes.topBar, className)}>
      <div className={classes.group}>
        <Button
          variant="outlined"
          className={cn(classes.button, classes.outlineButton)}
          onClick={onNow}
        >
          <FormattedMessage
            id="ZSD__CALENDAR__THIS_MONTH"
            defaultMessage="Aktuálny mesiac"
          />
        </Button>

        <IconButton size="small" onClick={onPrevious}>
          <KeyboardArrowLeftIcon />
        </IconButton>

        <CalendarMonthPicker />

        <IconButton size="small" onClick={onNext}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    </div>
  );
}
