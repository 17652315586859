import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    flexGrow: 1,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.background.paper,
    width: `calc(100% - 20px)`,
    marginLeft: 10,
    overflowX: 'auto',
  },
}));
