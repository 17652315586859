import { useContext, useState } from 'react';
import { PromptContext } from '@eas/common-web';
import { validateWorkOrder } from '@modules/work-order/work-order-api';
import { useWorkOrderExceptions } from '@composite/planner/work-order-exceptions';
import { WorkOrderDto } from '../utils/prepare-dto';

export function useValidateWorkOrder() {
  const { testPrompt } = useContext(PromptContext);
  const [result, setResult] = useState<string[]>([]);

  useWorkOrderExceptions({ exceptions: result });

  const validate = async (workOrder: WorkOrderDto, isMultiDay = false) => {
    const errors: string[] = await validateWorkOrder(
      workOrder.id,
      {
        ownerType: workOrder.unitType,
        ownerId: workOrder.unitId,
        plannedFrom: workOrder.plannedFrom,
        plannedTo: workOrder.plannedTo,
      },
      isMultiDay
    ).json();

    setResult(errors);

    const promise = new Promise<{ ok: boolean }>((resolve) => {
      setTimeout(() => {
        if (errors.length === 0) {
          resolve({
            ok: true,
          });
          return;
        }

        testPrompt({
          key: 'VALIDATE_WORK_DIALOG',
          submitCallback: () => {
            resolve({
              ok: true,
            });
          },
          closeCallback: () => {
            resolve({
              ok: false,
            });
          },
        });
      }, 0);
    });

    return promise;
  };

  return { validate };
}
