import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  logo: {
    maxWidth: '100%',
    width: 'auto',
    height: 'auto',
    shapeRendering: 'geometricPrecision',
    textRendering: 'geometricPrecision',
    imageRendering: 'auto',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
  },
  background: {
    fill: '#ececec',
    transition: 'fill 0.3s ease',

    '&:hover': {
      fill: '#fff',
    },
  },
});

export function Logo() {
  const classes = useStyles();

  return (
    <svg
      version="1.1"
      width="512px"
      height="512px"
      viewBox="0 0 512 512"
      className={classes.logo}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className={classes.background}
          d="M 93.5,-0.5 C 200.833,-0.5 308.167,-0.5 415.5,-0.5C 474.446,12.1118 506.446,48.4452 511.5,108.5C 511.5,108.833 511.5,109.167 511.5,109.5C 510.667,211.666 510.167,313.999 510,416.5C 499.654,469.346 468.154,501.013 415.5,511.5C 308.167,511.5 200.833,511.5 93.5,511.5C 43.3647,501.698 12.0314,472.032 -0.5,422.5C -0.5,311.167 -0.5,199.833 -0.5,88.5C 12.0314,38.9685 43.3647,9.30182 93.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#bd1723"
          d="M 262.5 241.5 C 233.212 234.952 201.961 227.964 172.582 221.845 C 172.582 221.845 309.592 108.119 377 52.5 C 339.547 115.819 301.381 178.819 262.5 241.5 Z"
        />
      </g>
      <g transform="matrix(1.000554, 0, 0, 0.999449, -0.176305, 0.169967)">
        <path
          fill="#e74e1c"
          d="M 172.665 221.795 C 202.419 227.964 232.858 234.84 262.52 241.442 C 281.424 263.958 299.595 285.984 318.5 308.5 C 294.887 294.749 197.647 236.918 172.665 221.795 Z"
        />
      </g>
      <g>
        <path
          fill="#bd1823"
          d="M 248.229 266.986 C 271.58 280.68 295.184 294.861 318.5 308.5 L 318.505 308.498 C 256.672 357.665 195.833 409.333 134 458.5 C 171.416 394.538 209.312 330.357 248.229 266.986 Z"
        />
      </g>
    </svg>
  );
}
