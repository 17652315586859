import { useContext } from 'react';
import { Me } from 'src/models/user';
import { DetailToolbarBtnName, UserContext } from '@eas/common-web';

export function useEvidencePermission({
  options,
}: {
  options: {
    [key in Exclude<
      DetailToolbarBtnName,
      'SHARE' | 'REFRESH' | 'CHECK' | 'CLOSE' | 'SAVE'
    >]?: string[];
  };
}) {
  return function usePermissionIntern(button: DetailToolbarBtnName) {
    const { hasPermission } = useContext<UserContext<Me, string>>(UserContext);

    if (button === 'SHARE' || button === 'REFRESH') {
      return true;
    }

    if (button === 'CHECK' || button === 'CLOSE' || button === 'SAVE') {
      return [...(options?.['NEW'] ?? []), ...(options?.['EDIT'] ?? [])].some(
        (perm) => hasPermission(perm)
      );
    }

    return (options?.[button] ?? [])?.some((perm) => hasPermission(perm));
  };
}
