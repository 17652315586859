import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

export function getScaleDetailItems(settings: Partial<CalendarSettingsType>) {
  const {
    dimension = CalendarDimensions.WEEK,
    startingHour = 0,
    endingHour = 24,
  } = settings;

  switch (dimension) {
    case CalendarDimensions.WEEK: {
      const size = 7 * (endingHour - startingHour);

      return new Array(size).fill(0).map((item, index) => {
        const hour = index % (endingHour - startingHour);

        return `${hour + startingHour}:00`;
      });
    }

    default:
      return [];
  }
}
