import React from 'react';
import { useIntl } from 'react-intl';
import { DatedEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { Role } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils';
import { useColumns } from './role-columns';
import { Fields } from './role-fields';
import { useRoleHookedSources } from './role-hooked-sources';
import { useValidationSchema } from './role-schema';

export function Roles() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.ROLE_WRITE],
      EDIT: [Permission.ROLE_WRITE],
      REMOVE: [Permission.ROLE_WRITE],
    },
  });

  const evidence = DatedEvidence.useDatedEvidence<Role>({
    identifier: 'ROLE',
    apiProps: {
      url: EvidenceApiUrl.ROLES,
      hookedSources: useRoleHookedSources,
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__ROLES__TABLE__TITLE',
        defaultMessage: 'Oprávnenia',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [
        { field: 'name', datakey: 'name', order: 'ASC', type: 'FIELD' },
      ],
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__ROLES__DETAIL__TITLE',
          defaultMessage: 'Skupina oprávnení',
        }),
        subtitle: (role) => role?.name ?? '',
        showBtn: btnPermissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
