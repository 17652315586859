import { useIntl } from 'react-intl';
import { ApiFilterOperation, FieldFilter } from '@eas/common-web';

export const useFilterFields = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__USERNAME',
        defaultMessage: 'KID',
      }),
      datakey: 'username',
      filterkey: 'username',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__FIRSTNAME',
        defaultMessage: 'Meno',
      }),
      datakey: 'firstName',
      filterkey: 'firstName',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterTextCell,
    },
    {
      label: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__LASTNAME',
        defaultMessage: 'Priezvisko',
      }),
      datakey: 'lastName',
      filterkey: 'lastName',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterTextCell,
    },
  ];
};
