import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormLocalDateTimeField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';
import { useAbsenceTypes } from '@modules/absence-type/absence-type-api';

const round = (value: number, precision: number) => {
  return Math.floor(value / precision) * precision;
};

export function AbsenceCreateDialog({ isExisting }: { isExisting: boolean }) {
  const absenceTypesSource = useAbsenceTypes();

  const { setFieldValue } = useContext(FormContext);

  return (
    <>
      <FormPanel
        defaultExpanded={true}
        label={
          <FormattedMessage
            id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__DETAILS"
            defaultMessage="Detaily"
          />
        }
      >
        <FormLocalDateTimeField
          name="fromDate"
          notifyChange={(value) => {
            if (!value) {
              return;
            }
            const unix = dayjs(value as string).unix();
            const rounded = dayjs
              .unix(round(unix, 60 * 30)) // round to 30min
              .format('YYYY-MM-DDTHH:mm');
            setFieldValue('fromDate', rounded);
          }}
          label={
            <FormattedMessage
              id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__FROM_DATE"
              defaultMessage="Dátum od"
            />
          }
          representation="local-date-time"
        />

        <FormLocalDateTimeField
          name="toDate"
          label={
            <FormattedMessage
              id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__TO_DATE"
              defaultMessage="Dátum do"
            />
          }
          representation="local-date-time"
          notifyChange={(value) => {
            if (!value) {
              return;
            }
            const unix = dayjs(value as string).unix();
            const rounded = dayjs
              .unix(round(unix, 60 * 30)) // round to 30min
              .format('YYYY-MM-DDTHH:mm');

            setFieldValue('toDate', rounded);
          }}
        />

        <FormAutocomplete
          name="absenceType"
          source={absenceTypesSource}
          label={
            <FormattedMessage
              id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__ABSENCE_TYPE"
              defaultMessage="Dôvod absencie"
            />
          }
        />
        <FormTextField
          name="note"
          label={
            <FormattedMessage
              id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__NOTE"
              defaultMessage="Poznámka"
            />
          }
        />

        {isExisting && (
          <FormCheckbox
            name="allowed"
            disabled
            label={
              <FormattedMessage
                id="ZSD__ABSENCE__DETAIL__FIELD_LABEL__ALLOWED"
                defaultMessage="Schválená"
              />
            }
          />
        )}
      </FormPanel>
    </>
  );
}
