import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import LaunchIcon from '@material-ui/icons/Launch';
import { WorkOrder } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
};

export const OpenMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function OpenMenuItem({ closeMenu, workOrder }, ref) {
    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          closeMenu();

          window.open(`/zsd/pracovne-prikazy/${workOrder.id}`, '_blank');
        }}
      >
        <ListItemIcon>
          <LaunchIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage
          id="UNIT_MENU_ITEM_OPEN"
          defaultMessage="Otvoriť na novej karte"
        />
      </MenuItem>
    );
  }
);
