import { useIntl } from 'react-intl';
import { WorkOrderDynamicAttributeName } from 'src/models/work-order-dynamic-attribute';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';

export function useColumns(): TableColumn<WorkOrderDynamicAttributeName>[] {
  const intl = useIntl();

  const dictionaryColumns =
    DictionaryEvidence.useDictionaryColumns<WorkOrderDynamicAttributeName>();

  return [
    dictionaryColumns.columnName,
    {
      datakey: 'displayName',
      name: intl.formatMessage({
        id: 'ZSD__WORK_ORDER_ATTRIBUTES__TABLE__COLUMN__DISPLAY_NAME',
        defaultMessage: 'Zobrazený názov',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
    },
  ];
}
