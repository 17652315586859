import dayjs from 'dayjs';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LaunchIcon from '@material-ui/icons/Launch';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import RoomIcon from '@material-ui/icons/Room';
import { PromptContext, UserContext } from '@eas/common-web';
import { PinnedMapContext } from '@composite/map/pinned-map-context';
import { GPSPoint, WorkOrder } from '@models';
import { Permission } from '@enums';
import { CalendarContext } from '../calendar/calendar-context';
import { useUnitAbsenceDialog } from './unit-absence-dialog';
import { useUnitLocation } from './unit-location';
import { useUnitPlan } from './unit-plan';

type UnitMenuProps = {
  unit: {
    id: string;
    name: string;
    unitType: 'mobileUnit' | 'user';
    latitude?: number;
    longitude?: number;
    currentLocation?: GPSPoint;
  };
  workOrders?: WorkOrder[];
  stickToPointer?: boolean;
};

export type UnitMenuHandle = {
  open: (event: React.MouseEvent<HTMLElement>) => void;
};

export const UnitMenu = forwardRef(function UnitMenu(
  { unit, workOrders, stickToPointer }: UnitMenuProps,
  ref: React.Ref<UnitMenuHandle>
) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { currentDate, settings } = useContext(CalendarContext);
  const { updateMapData } = useContext(PinnedMapContext);
  const { hasPermission } = useContext(UserContext);

  const [left, setLeft] = useState(0);

  const { testPrompt } = useContext(PromptContext);

  useImperativeHandle(ref, () => ({
    open: (event: React.MouseEvent<HTMLElement>) => {
      if (stickToPointer) {
        setLeft(event.nativeEvent.clientX);
      }
      setAnchorEl(event.currentTarget);
    },
  }));

  const orderedWorkOrders = workOrders
    ?.sort((a, b) => dayjs(a?.startTime).unix() - dayjs(b?.startTime).unix())
    .filter((wo) => {
      return dayjs(wo.startTime).day() === dayjs.unix(currentDate).day();
    });

  useUnitPlan({
    id: unit.id,
    startingPoint: { latitude: unit.latitude, longitude: unit.longitude },
    workOrders: orderedWorkOrders ?? [],
  });

  useUnitLocation({
    id: unit.id,
    startingPoint: { latitude: unit.latitude, longitude: unit.longitude },
    currentLocation: unit.currentLocation,
    unitName: unit.name,
  });

  useUnitAbsenceDialog({ userId: unit.id });

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => {
        setAnchorEl(null);
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: left === 0 ? 'right' : left,
      }}
    >
      {hasPermission(Permission.ABSENCE_ALLOW) && unit.unitType === 'user' && (
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            testPrompt({
              key: `UNIT_ABSENCE_DIALOG_${unit.id}`,
              submitCallback: () => {},
            });
          }}
        >
          <ListItemIcon>
            <LocalBarIcon />
          </ListItemIcon>
          <FormattedMessage
            id="UNIT_MENU_ITEM_DETAIL"
            defaultMessage="Zadať absenciu"
          />
        </MenuItem>
      )}

      {settings.dimension === 'day' && (
        <MenuItem
          disabled={(orderedWorkOrders?.length ?? []) === 0}
          onClick={() => {
            setAnchorEl(null);

            if (settings.pinnedMap) {
              updateMapData({
                variant: 'travel',
                travel: {
                  unitId: unit.id!,
                  startingPoint: {
                    latitude: unit.latitude,
                    longitude: unit.longitude,
                  },
                  workOrders: orderedWorkOrders ?? [],
                  addStartingPoint: true,
                  addReturnPoint: true,
                },
              });
            } else {
              testPrompt({
                key: `UNIT_PLAN_DIALOG_${unit.id}_${dayjs
                  .unix(currentDate)
                  .format('YYYY-MM-DD')}`,
                submitCallback: () => {},
              });
            }
          }}
        >
          <ListItemIcon />
          <FormattedMessage
            id="UNIT_MENU_ITEM_DETAIL"
            defaultMessage="Plán cesty"
          />
        </MenuItem>
      )}

      <MenuItem
        disabled={!unit.currentLocation}
        onClick={() => {
          setAnchorEl(null);
          if (settings.pinnedMap) {
            updateMapData({
              variant: 'points',
              points: [
                {
                  point: unit.currentLocation!,
                  pointLabel: 'A',
                  pointStyle: { borderColor: 'green' },
                  tooltip: {
                    content: `Aktuálna poloha ${unit.name}`,
                    permanent: true,
                  },
                },
              ],
            });
          } else {
            testPrompt({
              key: `UNIT_CURRENT_LOCATION_DIALOG_${unit.id}`,
              submitCallback: () => {},
            });
          }
        }}
      >
        <ListItemIcon>
          <RoomIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage
          id="UNIT_MENU_ITEM_CURRENT_LOCATION"
          defaultMessage="Zobraz aktuálnu polohu"
        />
      </MenuItem>

      <MenuItem
        disabled={!unit.latitude || !unit.longitude}
        onClick={() => {
          setAnchorEl(null);
          if (settings.pinnedMap) {
            updateMapData({
              variant: 'points',
              points: [
                {
                  point: {
                    latitude: unit.latitude,
                    longitude: unit.longitude,
                  },
                  pointLabel: 'S',
                  pointStyle: { borderColor: 'blue' },
                  tooltip: {
                    content: `Domovská adresa ${unit.name}`,
                    permanent: true,
                  },
                },
              ],
            });
          } else {
            testPrompt({
              key: `UNIT_STARTING_POINT_DIALOG_${unit.id}`,
              submitCallback: () => {},
            });
          }
        }}
      >
        <ListItemIcon>
          <RoomIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage
          id="UNIT_MENU_ITEM_STARTING_POINT"
          defaultMessage="Zobraz domovskú adresu"
        />
      </MenuItem>
      <MenuItem
        onClick={() => {
          setAnchorEl(null);

          if (unit.unitType === 'mobileUnit') {
            window.open(`/zsd/mobilne-jednotky/${unit.id}`, '_blank');
          }

          if (unit.unitType === 'user') {
            window.open(`/zsd/pouzivatelia/${unit.id}`, '_blank');
          }
        }}
      >
        <ListItemIcon>
          <LaunchIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage
          id="UNIT_MENU_ITEM_OPEN"
          defaultMessage="Otvoriť na novej karte"
        />
      </MenuItem>
      <MenuItem
        onClick={() => {
          setAnchorEl(null);

          if (unit.unitType === 'mobileUnit') {
            window.open(`/zsd/plan/mobilna-jednotka/${unit.id}`, '_blank');
          }

          if (unit.unitType === 'user') {
            window.open(`/zsd/plan/technik/${unit.id}`, '_blank');
          }
        }}
      >
        <ListItemIcon>
          <LaunchIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage
          id="UNIT_MENU_ITEM_PLAN_OPEN"
          defaultMessage="Otvoriť mesačný plán na novej karte"
        />
      </MenuItem>
    </Menu>
  );
});
