import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  DetailContext,
  DetailHandle,
  DictionaryEvidence,
  EvidenceContext,
  Filter,
  FormAutocomplete,
  FormCheckbox,
  FormPanel,
  UserContext,
} from '@eas/common-web';
import { useSections } from '@modules/section/section-api';
import { DepartmentField } from '@composite/department-collection/department-field';
import { UserField } from '@composite/user-collection/user-field';
import { Team } from '@models';
import { Permission } from '@enums';
import { DEPARTMENTS_SOURCE, USERS_SOURCE } from './team-hooked-sources';

const dispatcherFilters: Filter[] = [
  {
    field: 'type',
    operation: ApiFilterOperation.EQ,
    value: 'DISPATCHER',
  },
];

export function Fields() {
  const sections = useSections();
  const { hookedSources } = useContext(EvidenceContext);

  const { source, isExisting } = useContext<DetailHandle<Team>>(DetailContext);

  const { hasPermission } = useContext(UserContext);

  const departmentSource = hookedSources[DEPARTMENTS_SOURCE]?.source;
  const departmentPreFilters = useMemo(
    () => departmentSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const userSource = hookedSources[USERS_SOURCE]?.source;

  const userPreFilters = useMemo(
    () => userSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__TEAM__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <FormAutocomplete
          name="section"
          source={sections}
          label={
            <FormattedMessage
              id="ZSD__TEAM__DETAIL__FIELD_LABEL__SECTION"
              defaultMessage="Úsek"
            />
          }
          required
        />
        <DictionaryEvidence.FieldName />
        <FormCheckbox
          name="automaticallyPlan"
          label={
            <FormattedMessage
              id="ZSD__TEAM__DETAIL__FIELD_LABEL__AUTOMATICALLY_PLAN"
              defaultMessage="Podlieha automatickému plánovaniu"
            />
          }
        />
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>

      {isExisting && (
        <>
          {hasPermission(Permission.DEPARTMENT_LIST) && (
            <DepartmentField
              source={departmentSource}
              currentTeam={{
                id: source.data?.id ?? '',
                label: source.data?.name ?? '',
              }}
              preFilters={departmentPreFilters}
            />
          )}
          {hasPermission(Permission.USER_LIST) && (
            <UserField
              title={
                <FormattedMessage
                  id="ZSD__FIELD_LABEL__DISPATCHERS"
                  defaultMessage="Dispečeri"
                />
              }
              source={userSource}
              refKey="team"
              refFilters={dispatcherFilters}
              currentRef={{
                id: source.data?.id ?? '',
                label: source.data?.name ?? '',
              }}
              preFilters={userPreFilters}
            />
          )}
        </>
      )}
    </>
  );
}
