import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const exceptions: MessageType<string> = {
  MOBILE_UNIT_ALREADY_HAS_ASSIGNMENT: [
    'Mobilná jednotka už má priradenie',
    SnackbarVariant.ERROR,
  ],
};

export default exceptions;
