import dayjs from 'dayjs';
import React, { forwardRef, useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {
  PromptContext,
  SnackbarContext,
  SnackbarVariant,
} from '@eas/common-web';
import {
  getNextWorkOrder,
  getPreviousWorkOrder,
} from '@modules/planner/calendar/utils';
import { getDrivingInfo } from '@modules/planner/planner-api';
import { PlannerContext } from '@modules/planner/planner-context';
import {
  addStartingPointCoords,
  stringifyCoords,
} from '@modules/planner/unit-menu/utils';
import { useWorkOrderStatesList } from '@modules/work-order-state/work-order-state-api';
import {
  unplanWorkOrder,
  updateState,
  updateTravelTime,
} from '@modules/work-order/work-order-api';
import { GPSPoint, WorkOrder, WorkOrderState } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
};

export const useStyles = makeStyles((theme) => ({
  moreIcon: {
    color: theme.palette.grey[500],
    display: 'flex',
    fontSize: '16px',
  },
  bullet: {
    width: 12,
    height: 12,
    borderRadius: 12,
    marginRight: 8,
    marginLeft: 14,
    flexShrink: 0,
  },
}));

const ChangeStateMenuItem = forwardRef<
  HTMLLIElement,
  {
    closeMenu: () => void;
    submitCallback?: () => void;
    color?: string;
    label: string;
    nextState: string;
    workOrder: WorkOrder;
  }
>(function ChangeStateMenuItem(
  { submitCallback, color, label, nextState, workOrder },
  ref
) {
  const { testPrompt } = useContext(PromptContext);
  const { calendarSource, workOrderTableRef } = useContext(PlannerContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const intl = useIntl();

  const classes = useStyles();

  const defaultSubmitCallback = async () => {
    try {
      await updateState(workOrder.id, nextState).json();

      workOrderTableRef.current?.refresh();
      calendarSource?.yAxis.refreshData();
    } catch (error) {
      showSnackbar(
        intl.formatMessage({
          id: 'WORK_ORDER_CHANGE_STATE_ERROR',
          defaultMessage: 'Nepodarilo sa zmeniť stav pracovného príkazu',
        }),
        SnackbarVariant.ERROR
      );
    }
  };

  return (
    <MenuItem
      ref={ref}
      onClick={() => {
        testPrompt({
          key: `CHANGE_STATE_ALERT_${workOrder.id}`,
          submitCallback: submitCallback || defaultSubmitCallback,
        });
      }}
    >
      {color && (
        <ListItemIcon>
          <div
            className={classes.bullet}
            style={{
              backgroundColor: color,
            }}
          />
        </ListItemIcon>
      )}
      {label}
    </MenuItem>
  );
});

function getColor(workOrderStates: WorkOrderState[], code: string) {
  return workOrderStates.find((state) => state.code === code)?.color;
}

function hasState(codes: string[], currentState: string | undefined) {
  return codes.includes(currentState || '');
}

async function updateWorkOrderTravelTime(
  previousWorkOrder: WorkOrder | undefined,
  nextWorkOrder: WorkOrder | undefined,
  unitStartingPoint: GPSPoint
) {
  let coordsString = '';
  if (nextWorkOrder) {
    if (previousWorkOrder) {
      coordsString = stringifyCoords([previousWorkOrder, nextWorkOrder]);
    } else {
      coordsString = stringifyCoords([nextWorkOrder]);
      coordsString = addStartingPointCoords(
        coordsString,
        unitStartingPoint,
        'start'
      );
    }
    const travelTime = await getDrivingInfo(coordsString);

    await updateTravelTime(nextWorkOrder.id, {
      travelNext: nextWorkOrder.travelNext ?? 0,
      travelPrevious: Math.round(travelTime.duration),
    }).json();
  } else if (previousWorkOrder) {
    coordsString = stringifyCoords([previousWorkOrder, nextWorkOrder]);
    coordsString = addStartingPointCoords(
      coordsString,
      unitStartingPoint,
      'end'
    );
    const travelTime = await getDrivingInfo(coordsString);

    await updateTravelTime(previousWorkOrder.id, {
      travelNext: Math.round(travelTime.duration),
      travelPrevious: previousWorkOrder.travelPrevious ?? 0,
    }).json();
  }
}

export const StateMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function StateMenuItem({ closeMenu, workOrder }, ref) {
    const anchorEl = useRef(null);
    const [open, setOpen] = useState(false);

    const { calendarSource, workOrderTableRef } = useContext(PlannerContext);
    const { showSnackbar } = useContext(SnackbarContext);

    const intl = useIntl();

    const classes = useStyles();

    const [workOrderStates] = useWorkOrderStatesList();

    if (hasState(['PRE', 'VYB'], workOrder.currentState?.code)) {
      return null;
    }

    return (
      <>
        <Divider />
        <MenuItem
          ref={anchorEl}
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          <ListItemIcon>
            <AccountTreeIcon fontSize="small" />
          </ListItemIcon>
          <FormattedMessage
            id="WORK_ORDER_MENU_ITEM_CHANGE_STATE"
            defaultMessage="Manuálne zmeniť stav"
          />
          <ListItemSecondaryAction>
            <PlayArrowIcon className={classes.moreIcon} />
          </ListItemSecondaryAction>
        </MenuItem>
        <Popper
          open={open}
          anchorEl={anchorEl.current}
          placement="right-end"
          style={{ zIndex: 1300 }}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: '0 0 0' }}>
              <Paper
                elevation={20}
                onMouseEnter={() => {
                  setOpen(true);
                }}
                onMouseLeave={() => {
                  setOpen(false);
                }}
              >
                <MenuList disablePadding={true}>
                  {/* Nová */}
                  {hasState(
                    ['NAP', 'ODL', 'ODM', 'ZRU'],
                    workOrder.currentState?.code
                  ) && (
                    <ChangeStateMenuItem
                      label="Nová"
                      nextState="NOV"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'NOV')}
                      workOrder={workOrder}
                      submitCallback={async () => {
                        try {
                          await unplanWorkOrder(workOrder.id).json();
                        } catch (error) {
                          showSnackbar(
                            intl.formatMessage({
                              id: 'WORK_ORDER_CHANGE_STATE_ERROR',
                              defaultMessage:
                                'Nepodarilo sa zmeniť stav pracovného príkazu',
                            }),
                            SnackbarVariant.ERROR
                          );
                        }

                        const unit = calendarSource?.yAxis.data.find(
                          (unit) =>
                            unit.id === workOrder.mobileUnit?.id ||
                            unit.id === workOrder.user?.id
                        );

                        const previousWorkOrder = getPreviousWorkOrder(
                          unit?.workOrders ?? [],
                          dayjs(workOrder.startTime).unix()
                        );

                        const nextWorkOrder = getNextWorkOrder(
                          unit?.workOrders ?? [],
                          dayjs(workOrder.startTime).unix()
                        );

                        await updateWorkOrderTravelTime(
                          previousWorkOrder,
                          nextWorkOrder,
                          {
                            latitude: unit?.latitude,
                            longitude: unit?.longitude,
                          }
                        );
                        workOrderTableRef.current?.refresh();
                        calendarSource?.yAxis.refreshData();
                      }}
                    />
                  )}

                  {/* Doručená */}
                  {hasState(['NAP'], workOrder.currentState?.code) && (
                    <ChangeStateMenuItem
                      label="Doručená"
                      nextState="DOR"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'DOR')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Odmietnutá */}
                  {hasState(['DOR'], workOrder.currentState?.code) && (
                    <ChangeStateMenuItem
                      label="Odmietnutá"
                      nextState="ODM"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'ODM')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Prijatá */}
                  {hasState(['DOR'], workOrder.currentState?.code) && (
                    <ChangeStateMenuItem
                      label="Prijatá"
                      nextState="PRI"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'PRI')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Pozastavená */}
                  {hasState(['CES', 'VYK'], workOrder.currentState?.code) && (
                    <ChangeStateMenuItem
                      label="Pozastavená"
                      nextState="POZ"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'POZ')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Cesta */}
                  {hasState(['PRI', 'POZ'], workOrder.currentState?.code) && (
                    <ChangeStateMenuItem
                      label="Cesta"
                      nextState="CES"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'CES')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Vykonávaná */}
                  {hasState(
                    ['PRI', 'POZ', 'CES'],
                    workOrder.currentState?.code
                  ) && (
                    <ChangeStateMenuItem
                      label="Vykonávaná"
                      nextState="VYK"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'VYK')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Odložená na neskôr */}
                  {hasState(
                    ['POZ', 'PRI', 'CES', 'VYK'],
                    workOrder.currentState?.code
                  ) && (
                    <ChangeStateMenuItem
                      label="Odložená na neskôr"
                      nextState="ODL"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'PRE')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Vybavená */}
                  {hasState(['VYK'], workOrder.currentState?.code) && (
                    <ChangeStateMenuItem
                      label="Vybavená"
                      nextState="VYB"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'VYB')}
                      workOrder={workOrder}
                    />
                  )}

                  {/* Zrušená */}
                  {hasState(
                    ['NOV', 'NAP', 'ODM', 'ODL'],
                    workOrder.currentState?.code
                  ) && (
                    <ChangeStateMenuItem
                      label="Zrušená"
                      nextState="ZRU"
                      closeMenu={() => {
                        setOpen(false);
                        closeMenu();
                      }}
                      color={getColor(workOrderStates?.items ?? [], 'ZRU')}
                      workOrder={workOrder}
                    />
                  )}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
);
