export const regionApiErrors = {
  DEPARTMENT: {
    REGION_NOT_EMPTY: 'K pracovisku sú priradené mobilné jednotky.',
    USERS_ATTACHED_TO_DEPARTMENT: 'K pracovisku sú priradení používatelia.',
    WORK_ORDERS_ATTACHED_TO_DEPARTMENT:
      'K pracovisku sú priradené pracovné príkazy.',
    DUPLICATE_NAME: 'Názov pracoviska nie je jedinečný.',
  },
  SECTION: {
    REGION_NOT_EMPTY: 'K úseku sú priradené tímy.',
    DUPLICATE_NAME: 'Názov úseku nie je jedinečný.',
  },
  TEAM: {
    REGION_NOT_EMPTY: 'K tímu sú priradené pracoviská.',
    DUPLICATE_NAME: 'Názov tímu nie je jedinečný.',
  },
  MOBILE_UNIT: {
    UNAUTHORIZED_MOBILE_UNIT_MODIFICATION:
      'Prihlásený používateľ nemá oprávnenie upravovať danú mobilnú jednotku (napr. ak je koordinátorom iného tímu).',
    ENTITY_NOT_EXIST: 'Mobilná jednotka nebola nájdená.',
    DUPLICATE_NAME: 'Názov mobilnej jednotky nie je jedinečný.',
  },
  MOBILE_UNIT_ASSIGNMENT: {
    ENTITY_NOT_EXIST: 'Mobilná jednotka alebo čata nebola nájdená.',
    INACTIVE_MOBILE_UNIT: 'Mobilná jednotka čaty nie je aktívna.',
    UNAUTHORIZED_MOBILE_UNIT_MODIFICATION:
      'Prihlásený používateľ nemá oprávnenie upravovať danú čatu mobilnej jednotky (napr. ak je koordinátorom iného tímu).',
    VALIDATION_FAILED: 'Neplatný časový interval.',
    ASSIGNMENT_IS_HISTORICAL: 'Časový interval čaty je historický.',
    INVALID_USER_TYPE: 'Používateľ nie je typu technik.',
    INVALID_TECHNICIAN_DEPARTMENT:
      'Niektorý z technikov v čate nemôže pracovať na rovnakom pracovisku ako mobilná jednotka.',
    MOBILE_UNIT_ALREADY_HAS_ASSIGNMENT:
      'V danom čase už mobilná jednotka má existujúcu čatu.',
    TECHNICIAN_ALREADY_ASSIGNED_IN_TARGET_INTERVAL:
      'Technik je už v danom čase v inej čate',
    LEADER_ALSO_IN_OTHER_TECHNICIANS: 'Vedúci je už priradený k technikom',
  },
};

export const userApiErrors = {
  SHIFT: {
    DUPLICATE_NAME: 'Názov zmeny nie je jedinečný.',
    SHIFT_INTERVALS_NOT_PROVIDED: 'Zmena musí mať vyplnený aspoň jeden detail',
  },
  USER: {
    USERNAME_NOT_UNIQUE: 'Prihlasovací KID nie je jedinečný.',
    HR_ID_NOT_UNIQUE: 'Osobné číslo nie je jedinečné.',
    KID_NOT_UNIQUE: 'Informatívny KID nie je jedinečný.',
  },
};
