import { useDroppable } from '@dnd-kit/core';
import React from 'react';

export function Dropppable({
  id,
  className,
  style,
  children,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) {
  const { setNodeRef, isOver } = useDroppable({
    id,
    data: {
      id,
    },
  });

  return (
    <div
      ref={setNodeRef}
      className={className}
      style={{
        ...style,
        border: isOver ? '1px solid red' : undefined,
      }}
    >
      {children}
    </div>
  );
}
