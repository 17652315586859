import makeStyles from '@material-ui/core/styles/makeStyles';

//TODO:
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  topWindow: {
    height: '100%',
  },
  bottomWindow: {
    padding: '0 10px',
    height: '0%',
  },
}));
