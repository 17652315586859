import {
  ApiFilterOperation,
  DomainObject,
  Filter,
  Params,
  useAutocompleteSource,
} from '@eas/common-web';

export function useCollectionSource<T extends DomainObject>(
  url: string,
  collectionKey: string,
  collectionId?: string,
  staticFilters?: Filter[]
) {
  const filters: Filter[] = [];

  if (staticFilters) {
    filters.push(...staticFilters);
  }

  if (collectionId) {
    filters.push({
      operation: ApiFilterOperation.NOT,
      filters: [
        {
          operation: ApiFilterOperation.EQ,
          field: collectionKey,
          value: collectionId,
        },
      ],
    });
  }

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.AND,
        filters,
      },
    ],
  };

  return useAutocompleteSource<T>({
    url: `${url}/autocomplete`,
    params,
  });
}
