import React from 'react';
import { useIntl } from 'react-intl';
import { DatedEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { WorkOrderState } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useEvidencePermission } from '@utils';
import { useColumns } from './work-order-state-columns';
import { Fields } from './work-order-state-fields';
import { useValidationSchema } from './work-order-state-schema';

export function WorkOrderStates() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [],
      EDIT: [Permission.WORK_ORDER_STATE_WRITE],
      REMOVE: [],
    },
  });

  const evidence = DatedEvidence.useDatedEvidence<WorkOrderState>({
    identifier: 'WORK_ORDER_STATE',
    apiProps: {
      url: EvidenceApiUrl.WORK_ORDER_STATES,
    },
    tableProps: {
      columns,
      tableName: intl.formatMessage({
        id: 'ZSD__WORK_ORDER_STATE__TABLE__TITLE',
        defaultMessage: 'Stavy PP',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__WORK_ORDER_STATE__DETAIL__TITLE',
          defaultMessage: 'Stav PP',
        }),
        subtitle: (state) => state?.name ?? '',
        showBtn: btnPermissions,
      },
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
