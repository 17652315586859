/**
 * Oprávnění
 */
export enum Permission {
  /**
   * Čítanie úsekov
   */
  SECTION_READ = 'SECTION_READ',

  /**
   * Spravovanie úsekov
   */
  SECTION_WRITE = 'SECTION_WRITE',

  /**
   * Vyhľadávanie a filtrovanie úsekov
   */
  SECTION_LIST = 'SECTION_LIST',

  /**
   * Čítanie tímov
   */
  TEAM_READ = 'TEAM_READ',

  /**
   * Spravovanie tímov
   */
  TEAM_WRITE = 'TEAM_WRITE',

  /**
   * Vyhľadávanie a filtrovanie tímov
   */
  TEAM_LIST = 'TEAM_LIST',

  /**
   * Čítanie pracovísk
   */
  DEPARTMENT_READ = 'DEPARTMENT_READ',

  /**
   * Spravovanie pracovísk
   */
  DEPARTMENT_WRITE = 'DEPARTMENT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pracovísk
   */
  DEPARTMENT_LIST = 'DEPARTMENT_LIST',

  /**
   * Čítanie skupin oprávnení
   */
  ROLE_READ = 'ROLE_READ',

  /**
   * Spravovanie skupin oprávnení
   */
  ROLE_WRITE = 'ROLE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie skupin oprávnení
   */
  ROLE_LIST = 'ROLE_LIST',

  /**
   * Vyhľadávanie a filtrovanie oprávnení
   */
  PERMISSION_LIST = 'PERMISSION_LIST',

  /**
   * Čítanie používateľov
   */
  USER_READ = 'USER_READ',

  /**
   * Spravovanie používateľov
   */
  USER_WRITE = 'USER_WRITE',

  /**
   * Vyhľadávanie a filtrovanie používateľov
   */
  USER_LIST = 'USER_LIST',

  /**
   * Čítanie mobilných jednotek
   */
  MOBILE_UNIT_READ = 'MOBILE_UNIT_READ',

  /**
   * Spravovanie mobilných jednotek
   */
  MOBILE_UNIT_WRITE = 'MOBILE_UNIT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie mobilných jednotek
   */
  MOBILE_UNIT_LIST = 'MOBILE_UNIT_LIST',

  /**
   * Čítanie pracovných prikazov
   */
  WORK_ORDER_READ = 'WORK_ORDER_READ',

  /**
   * Spravovanie pracovných prikazov
   */
  WORK_ORDER_WRITE = 'WORK_ORDER_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pracovných prikazov
   */
  WORK_ORDER_LIST = 'WORK_ORDER_LIST',

  /**
   * Čítanie kalendárových šablón
   */
  CALENDAR_TEMPLATE_READ = 'CALENDAR_TEMPLATE_READ',

  /**
   * Spravovanie kalendárových šablón
   */
  CALENDAR_TEMPLATE_WRITE = 'CALENDAR_TEMPLATE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie kalendárových šablón
   */
  CALENDAR_TEMPLATE_LIST = 'CALENDAR_TEMPLATE_LIST',

  /**
   * Čítanie pridených kalendárových
   */
  CALENDAR_READ = 'CALENDAR_READ',

  /**
   * Spravovanie pridených kalendárových
   */
  CALENDAR_WRITE = 'CALENDAR_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pridených kalendárových
   */
  CALENDAR_LIST = 'CALENDAR_LIST',

  /**
   * Čítanie pracovných zmien
   */
  SHIFT_READ = 'SHIFT_READ',

  /**
   * Spravovanie pracovných zmien
   */
  SHIFT_WRITE = 'SHIFT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie pracovných zmien
   */
  SHIFT_LIST = 'SHIFT_LIST',

  /**
   * Čítanie kalendárových výnimiek
   */
  CALENDAR_EXCEPTION_READ = 'CALENDAR_EXCEPTION_READ',

  /**
   * Spravovanie kalendárových výnimiek
   */
  CALENDAR_EXCEPTION_WRITE = 'CALENDAR_EXCEPTION_WRITE',

  /**
   * Vyhľadávanie a filtrovanie kalendárových výnimiek
   */
  CALENDAR_EXCEPTION_LIST = 'CALENDAR_EXCEPTION_LIST',

  /**
   * Čítanie certifikácií
   */
  CERTIFICATION_READ = 'CERTIFICATION_READ',

  /**
   * Spravovanie certifikácií
   */
  CERTIFICATION_WRITE = 'CERTIFICATION_WRITE',

  /**
   * Vyhľadávanie a filtrovanie certifikácií
   */
  CERTIFICATION_LIST = 'CERTIFICATION_LIST',

  /**
   * Čítanie sviatkov
   */
  HOLIDAY_READ = 'HOLIDAY_READ',

  /**
   * Spravovanie sviatkov
   */
  HOLIDAY_WRITE = 'HOLIDAY_WRITE',

  /**
   * Vyhľadávanie a filtrovanie sviatkov
   */
  HOLIDAY_LIST = 'HOLIDAY_LIST',

  /**
   * Čítanie absencií
   */
  ABSENCE_READ = 'ABSENCE_READ',

  /**
   * Spravovanie absencií
   */
  ABSENCE_WRITE = 'ABSENCE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie absencií
   */
  ABSENCE_LIST = 'ABSENCE_LIST',

  /**
   * Čítanie typů absencií
   */
  ABSENCE_TYPE_READ = 'ABSENCE_TYPE_READ',

  /**
   * Spravovanie typů absencií
   */
  ABSENCE_TYPE_WRITE = 'ABSENCE_TYPE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie typů absencií
   */
  ABSENCE_TYPE_LIST = 'ABSENCE_TYPE_LIST',

  /**
   * Spravovanie vlastných absencií
   */
  ABSENCE_WRITE_OWN = 'ABSENCE_WRITE_OWN',

  /**
   * Schválenie absencie
   */
  ABSENCE_ALLOW = 'ABSENCE_ALLOW',

  /**
   * Čítanie číselníku Druh PP
   */
  WORK_ORDER_TYPE_READ = 'WORK_ORDER_TYPE_READ',

  /**
   * Spravovanie číselníku Druh PP
   */
  WORK_ORDER_TYPE_WRITE = 'WORK_ORDER_TYPE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie číselníku Druh PP
   */
  WORK_ORDER_TYPE_LIST = 'WORK_ORDER_TYPE_LIST',

  /**
   * Čítanie číselníku Stav PP
   */
  WORK_ORDER_STATE_READ = 'WORK_ORDER_STATE_READ',

  /**
   * Spravovanie číselníku Stav PP
   */
  WORK_ORDER_STATE_WRITE = 'WORK_ORDER_STATE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie číselníku Stav PP
   */
  WORK_ORDER_STATE_LIST = 'WORK_ORDER_STATE_LIST',

  /**
   * Čítanie názvov dynamických atribútov pracovných prikazov
   */
  WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_READ = 'WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_READ',

  /**
   * Spravovanie názvov dynamických atribútov pracovných prikazov
   */
  WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_WRITE = 'WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_WRITE',

  /**
   * Vyhľadávanie a filtrovanie názvov dynamických atribútov pracovných prikazov
   */
  WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_LIST = 'WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_LIST',

  /**
   * Čítanie formulárových šablón
   */
  FORM_TEMPLATE_READ = 'FORM_TEMPLATE_READ',

  /**
   * Spravovanie formulárových šablón
   */
  FORM_TEMPLATE_WRITE = 'FORM_TEMPLATE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie formulárových šablón
   */
  FORM_TEMPLATE_LIST = 'FORM_TEMPLATE_LIST',

  /**
   * Čítanie vyplnených formulárov
   */
  FILLED_FORM_READ = 'FILLED_FORM_READ',

  /**
   * Spravovanie vyplnených formulárov
   */
  FILLED_FORM_WRITE = 'FILLED_FORM_WRITE',

  /**
   * Vyhľadávanie a filtrovanie vyplnených formulárov
   */
  FILLED_FORM_LIST = 'FILLED_FORM_LIST',

  /**
   * Čítanie formulárových rozložení
   */
  FORM_LAYOUT_READ = 'FORM_LAYOUT_READ',

  /**
   * Spravovanie formulárových rozložení
   */
  FORM_LAYOUT_WRITE = 'FORM_LAYOUT_WRITE',

  /**
   * Vyhľadávanie a filtrovanie formulárových rozložení
   */
  FORM_LAYOUT_LIST = 'FORM_LAYOUT_LIST',

  /**
   * Čítanie formulárových rozložení
   */
  FORM_LIBRARY_READ = 'FORM_LIBRARY_READ',

  /**
   * Spravovanie formulárových rozložení
   */
  FORM_LIBRARY_WRITE = 'FORM_LIBRARY_WRITE',

  /**
   * Vyhľadávanie a filtrovanie formulárových rozložení
   */
  FORM_LIBRARY_LIST = 'FORM_LIBRARY_LIST',

  /**
   * Čítanie SAP atributov vyplnených formulárov
   */
  FILLED_FORM_SAP_ATTRIBUTE_READ = 'FILLED_FORM_SAP_ATTRIBUTE_READ',

  /**
   * Spravovanie SAP atributov vyplnených formulárov
   */
  FILLED_FORM_SAP_ATTRIBUTE_WRITE = 'FILLED_FORM_SAP_ATTRIBUTE_WRITE',

  /**
   * Vyhľadávanie a filtrovanie SAP atributov vyplnených formulárov
   */
  FILLED_FORM_SAP_ATTRIBUTE_LIST = 'FILLED_FORM_SAP_ATTRIBUTE_LIST',

  /**
   * Vyhľadávanie a filtrovanie SPHL
   */
  SPHL_LIST = 'SPHL_LIST',

  /**
   * Čítanie SPHL
   */

  SPHL_READ = 'SPHL_READ',
  /**
   * Spravovanie SPHL
   */
  SPHL_WRITE = 'SPHL_WRITE',
}
