import Leaflet from 'leaflet';
import React, { CSSProperties, ReactNode, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as ReactLeaflet from 'react-leaflet';
import { PromptContext } from '@eas/common-web';
import { useWorkOrderDetails } from '@modules/planner/work-order-menu/work-order-details';

interface MarkerProps {
  label: string;
  tooltip?: {
    content: string | ReactNode;
    permanent?: boolean;
  };
  point: [number, number];
  workOrderId?: string;
  markerSyle?: CSSProperties;
}

export function WorkOrderMarker({
  label,
  point,
  workOrderId,
  markerSyle,
  tooltip,
}: MarkerProps) {
  const { testPrompt } = useContext(PromptContext);

  useWorkOrderDetails({
    id: workOrderId,
    showConfirm: false,
  });

  const markerIcon = Leaflet.divIcon({
    className: 'custom-div-icon',
    html: ReactDOMServer.renderToString(
      <div
        style={{
          backgroundColor: 'white',
          color: 'black',
          border: '2px solid red',
          padding: '3px',
          borderRadius: '50%',
          width: '30px',
          height: '30px',
          textAlign: 'center',
          fontSize: 15,
          ...markerSyle,
        }}
      >
        {label}
      </div>
    ),
  });

  return (
    <ReactLeaflet.Marker
      key={label}
      position={point}
      icon={markerIcon}
      eventHandlers={{
        click: () => {
          if (workOrderId) {
            testPrompt({
              key: `WORK_ORDER_DETAILS_DIALOG_${workOrderId}`,
              submitCallback: () => {},
            });
          }
        },
      }}
    >
      {tooltip && (
        <ReactLeaflet.Tooltip
          permanent={tooltip.permanent}
          key={`${label}-${point[0]}-${point[1]}-${workOrderId}`}
          offset={Leaflet.point({ x: 10, y: 10 })}
          content={() =>
            ReactDOMServer.renderToString(
              <div
                style={{
                  color: 'black',
                  padding: '4px 8px',
                  textAlign: 'center',
                }}
              >
                {tooltip.content}
              </div>
            )
          }
        />
      )}
    </ReactLeaflet.Marker>
  );
}
