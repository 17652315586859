import dayjs from 'dayjs';
import { CalendarSettingsType } from '@modules/planner/calendar/calendar-types';
import { CalendarDimensions } from '@enums';

export function getPlannedFrom(
  currentDateUnix: number,
  collisionValue: number,
  settings: Partial<CalendarSettingsType>,
  offset = 0
) {
  const {
    dimension = CalendarDimensions.WEEK,
    stepWidth = 100,
    startingHour = 0,
    endingHour = 24,
  } = settings;

  switch (dimension) {
    case CalendarDimensions.DAY: {
      const hours = startingHour + Math.floor(collisionValue / stepWidth);

      const minutes = Math.floor(
        ((collisionValue % stepWidth) / stepWidth) * 60
      );

      const seconds = Math.floor(
        (((collisionValue % stepWidth) / stepWidth) * 3600) % 60
      );

      return dayjs
        .unix(currentDateUnix)
        .set('hour', hours)
        .set('minute', minutes)
        .set('second', seconds)
        .add(offset, 'second')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }

    case CalendarDimensions.WEEK: {
      const window = endingHour - startingHour;

      const days = Math.floor(collisionValue / (stepWidth * window));

      const hours =
        startingHour +
        Math.floor((collisionValue % (stepWidth * window)) / stepWidth);

      const minutes = Math.floor(
        (((collisionValue % (stepWidth * window)) / stepWidth) * 60) % 60
      );

      const seconds = Math.floor(
        (((collisionValue % (stepWidth * window)) / stepWidth) * 3600) % 60
      );

      return dayjs
        .unix(currentDateUnix)
        .add(days, 'day')
        .set('hour', hours)
        .set('minute', minutes)
        .set('second', seconds)
        .add(offset, 'second')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }

    case CalendarDimensions.MONTH: {
      const window = endingHour - startingHour;

      const days = Math.floor(collisionValue / stepWidth);

      const hours =
        startingHour +
        Math.floor(((collisionValue % stepWidth) / stepWidth) * window);

      const minutes = Math.floor(
        (((collisionValue % stepWidth) / stepWidth) * window * 60) % 60
      );

      const seconds = Math.floor(
        (((collisionValue % stepWidth) / stepWidth) * window * 3600) % 60
      );

      return dayjs
        .unix(currentDateUnix)
        .add(days, 'day')
        .set('hour', hours)
        .set('minute', minutes)
        .set('second', seconds)
        .add(offset, 'second')
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    }

    default:
      return '';
  }
}
