import _ from 'lodash';
import React, { ComponentPropsWithoutRef, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import {
  DetailActionButton,
  DetailMode,
  Filter,
  ScrollableSource,
  TableFieldToolbarButton,
  UserContext,
  abortableFetch,
} from '@eas/common-web';
import {
  AbsenceConflictsForm,
  useValidateAbsence,
} from '@modules/planner/unit-menu/unit-validate-absence';
import { CollectionField } from '@components/collection-field/collection-field';
import { Absence, DictionaryAutocomplete, WorkOrder } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useColumns } from './absence-columns';
import { useFilterFields } from './absence-filter-fields';
import { useValidationSchema } from './absence-schema';
import { AbsenceCreateDialog } from './dialogs/absence-create-dialog';

interface AbsenceFieldProps
  extends Partial<ComponentPropsWithoutRef<typeof CollectionField<Absence>>> {
  refKey: string;
  refFilters?: Filter[];
  currentRef: DictionaryAutocomplete;
  source: ScrollableSource<Absence>;
}

export function AbsenceField({
  refKey,
  refFilters,
  currentRef,
  source,
  ...overrides
}: AbsenceFieldProps) {
  const filters = useFilterFields();
  const { hasPermission } = useContext(UserContext);

  return (
    <CollectionField
      title={
        <FormattedMessage
          id="ZSD__FIELD_LABEL__ABSENCES"
          defaultMessage="Absencie"
        />
      }
      defaultExpanded={false}
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        getUrl: () => EvidenceApiUrl.ABSENCES,
        Dialog: (p) => AbsenceCreateDialog({ isExisting: false, ...p }),
        initValues: () => ({
          [refKey]: currentRef,
        }),
        useSchema: useValidationSchema,
        dialogWidth: 700,
        hide: !hasPermission(Permission.ABSENCE_WRITE),
      }}
      readAction={{
        getUrl: (collectionId) => `${EvidenceApiUrl.ABSENCES}/${collectionId}`,
      }}
      updateAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.ABSENCES}/${collectionId}`,
        Dialog: (p) => AbsenceCreateDialog({ isExisting: true, ...p }),
        useSchema: useValidationSchema,
        dialogWidth: 700,
        hide: !hasPermission(Permission.ABSENCE_WRITE),
      }}
      deleteAction={{
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.ABSENCES}/${collectionId}`,
        hide: !hasPermission(Permission.ABSENCE_WRITE),
      }}
      assignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.ABSENCES}/${collectionId}`,
      }}
      unassignAction={{
        hide: true,
        getUrl: (_, collectionId) =>
          `${EvidenceApiUrl.ABSENCES}/${collectionId}`,
        initValues: (calendar) => ({
          ...calendar,
          section: null,
        }),
      }}
      filters={filters}
      customActions={[AllowAbsenceAction]}
      {...overrides}
    />
  );
}

const AllowAbsenceAction = ({
  id,
  selectedItems,
  disabled,
  handleError,
  selected,
}: {
  id: string;
  selectedItems: any[];
  selected: number[];
  disabled?: boolean;
  handleError: any;
}) => {
  const { hasPermission } = useContext(UserContext);
  const { validate } = useValidateAbsence();
  const [conflicts, setConflicts] = useState<WorkOrder[]>([]);
  if (!hasPermission(Permission.ABSENCE_ALLOW)) {
    return <></>;
  }
  const actionDisabled =
    disabled ||
    selected?.length === 0 ||
    _.get(selectedItems?.[0], 'allowed') === true;
  return (
    <DetailActionButton
      key={`ABSENCE_ALLOW_${id}`}
      promptKey={`ABSENCE_ALLOW_${id}`}
      apiCall={() => {
        const itemId = _.get(selectedItems?.[0], 'id');
        return abortableFetch(`${EvidenceApiUrl.ABSENCES}/${itemId}/allow`, {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
        });
      }}
      buttonLabel="Schváliť"
      dialogTitle="Schváliť absenciu"
      dialogWidth={600}
      dialogText={AbsenceConflictsForm({ conflicts })}
      ButtonComponent={({ onClick, label }) => (
        <TableFieldToolbarButton
          IconComponent={DoneIcon}
          show={true}
          disabled={actionDisabled}
          title={label}
          onClick={async () => {
            const resp = await validate(selectedItems[0]);
            setConflicts(resp.errors ?? []);
            onClick();
          }}
          color="default"
        >
          <Typography variant="body2">Schváliť</Typography>
        </TableFieldToolbarButton>
      )}
      modes={[DetailMode.VIEW, DetailMode.EDIT]}
      onError={handleError}
    />
  );
};
