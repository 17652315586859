import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { DetailContext, FormPanel, FormTextField } from '@eas/common-web';
import { AddressFields } from '@composite/address/address-fields';

export function LocationFields() {
  // For DEV purposes
  const { isExisting } = useContext(DetailContext);

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__WORK_ORDERS__DETAIL__PANEL__LOCATION"
            defaultMessage="Lokalita"
          />
        }
      >
        <AddressFields addressKey="address" />
        <FormTextField
          name="latitude"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__LATITUDE"
              defaultMessage="Zemepisná šírka"
            />
          }
          disabled={isExisting}
        />
        <FormTextField
          name="longitude"
          label={
            <FormattedMessage
              id="ZSD__WORK_ORDERS__DETAIL__FIELD_LABEL__LONGITUDE"
              defaultMessage="Zemepisná dĺžka"
            />
          }
          disabled={isExisting}
        />
      </FormPanel>
    </>
  );
}
