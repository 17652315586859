import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ScrollableSource } from '@eas/common-web';
import { CollectionField } from '@components/collection-field/collection-field';
import { SectionAutocomplete, TeamAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';
import {
  assignDialogFactory,
  useValidationSchema as useAssignValidationSchema,
} from './dialogs/team-assign-dialog';
import { useColumns } from './team-columns';
import { useFilterFields } from './team-filter-fields';

interface TeamFieldProps
  extends Partial<
    ComponentPropsWithoutRef<typeof CollectionField<TeamAutocomplete>>
  > {
  currentSection: SectionAutocomplete;
  source: ScrollableSource<TeamAutocomplete>;
}

export function TeamField({
  currentSection,
  source,
  ...overrides
}: TeamFieldProps) {
  const AssignDialog = useMemo(
    () => assignDialogFactory(currentSection.id),
    [currentSection]
  );

  const filters = useFilterFields();

  return (
    <CollectionField<TeamAutocomplete>
      title={
        <FormattedMessage id="ZSD__FIELD_LABEL__TEAMS" defaultMessage="Tímy" />
      }
      source={source}
      useColumns={useColumns}
      disabled={false}
      createAction={{
        hide: true,
        getUrl: () => EvidenceApiUrl.TEAMS,
      }}
      readAction={{
        getUrl: (collectionId) => `${EvidenceApiUrl.TEAMS}/${collectionId}`,
      }}
      updateAction={{
        hide: true,
        getUrl: (_, collectionId) => `${EvidenceApiUrl.TEAMS}/${collectionId}`,
      }}
      deleteAction={{
        hide: true,
        getUrl: (_, collectionId) => `${EvidenceApiUrl.TEAMS}/${collectionId}`,
      }}
      assignAction={{
        getUrl: (_, collectionId) => `${EvidenceApiUrl.TEAMS}/${collectionId}`,
        Dialog: AssignDialog,
        useSchema: useAssignValidationSchema,
      }}
      unassignAction={{
        getUrl: (_, collectionId) => `${EvidenceApiUrl.TEAMS}/${collectionId}`,
        initValues: (team) => ({
          ...team,
          section: null,
        }),
      }}
      filters={filters}
      {...overrides}
    />
  );
}
