import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0 10px',
  },
  topWindow: {
    height: '60%',
  },
  bottomWindow: {
    padding: '0 10px',
    height: '100%',
  },
  panelVerticalHandle: {
    height: 4,
    backgroundColor: theme.palette.grey[300],
    margin: '0 10px',
  },
  panelHorizontalHandle: {
    width: 4,
    backgroundColor: theme.palette.grey[300],
  },
  mapWrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: '#fafafa',
  },
  chartWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));
