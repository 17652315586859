import {
  ApiFilterOperation,
  Filter,
  ListSource,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { Team, TeamAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useTeams() {
  return useAutocompleteSource<TeamAutocomplete>({
    url: `${EvidenceApiUrl.TEAMS}/autocomplete`,
  });
}

export function useTeamAutocomplete() {
  return useAutocompleteSource<TeamAutocomplete>({
    url: `${EvidenceApiUrl.TEAMS}/autocomplete`,
  });
}

export function useTeamList(query?: string) {
  const filters: Filter[] = [];

  if (query) {
    filters.push({
      field: 'name',
      operation: ApiFilterOperation.CONTAINS,
      value: query,
    });
  }
  const [items] = useFetch<ListSource<Team>>(`${EvidenceApiUrl.TEAMS}/list`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      size: -1,
      filters,
      sort: [
        {
          field: 'name',
          order: 'ASC',
          type: 'FIELD',
          missing: 'LAST',
        },
      ],
    }),
  });
  return items;
}

export function useGetTeam(id: string | null) {
  return useFetch<Team>(`${EvidenceApiUrl.TEAMS}/${id}`, {
    skip: !id,
  });
}
