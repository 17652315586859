import { useIntl } from 'react-intl';
import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Holiday } from '@models';

export function useColumns(): TableColumn<Holiday>[] {
  const intl = useIntl();
  const dictionaryColumns = DictionaryEvidence.useDictionaryColumns<Holiday>();

  return [
    dictionaryColumns.columnName,
    {
      datakey: 'day',
      sortkey: 'day',
      filterkey: 'day',
      name: intl.formatMessage({
        id: 'ZSD__HOLIDAY__TABLE__COLUMN__DAY',
        defaultMessage: 'Deň',
      }),
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
    },
  ];
}
