import React, { forwardRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { PromptContext } from '@eas/common-web';
import { WorkOrder } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  workOrder: WorkOrder;
};

export const DetailsMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function DetailsMenuItem({ closeMenu, workOrder }, ref) {
    const { testPrompt } = useContext(PromptContext);

    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          closeMenu();

          testPrompt({
            key: `WORK_ORDER_DETAILS_DIALOG_${workOrder.id}`,
            submitCallback: () => {},
          });
        }}
      >
        <ListItemIcon>
          <MoreHorizIcon fontSize="small" />
        </ListItemIcon>
        <FormattedMessage
          id="WORK_ORDER_MENU_ITEM_DETAIL"
          defaultMessage="Detaily"
        />
      </MenuItem>
    );
  }
);
