export function getTaskInterval(from: string, to: string) {
  if (!from || !to) {
    return '';
  }

  const fromTime = new Date(from);
  const toTime = new Date(to);

  const sameDate = fromTime.getDate() === toTime.getDate();

  if (sameDate) {
    return `${fromTime.toLocaleTimeString(
      'sk-SK'
    )} - ${toTime.toLocaleTimeString('sk-SK')}`;
  } else {
    return `${fromTime.toLocaleString('sk-SK')} - ${toTime.toLocaleString(
      'sk-SK'
    )}`;
  }
}
