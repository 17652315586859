import cn from 'clsx';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Dialog, DialogHandle } from '@eas/common-web';
import {
  TeamDialog,
  TeamDialogHandle,
} from '@composite/team-field/team-dialog';
import { Team } from '@models';
import { useStyles } from '../switcher-styles';

export function TeamPicker({
  team,
  onConfirm,
}: {
  team: Team | undefined;
  onConfirm: (value: Team) => void;
}) {
  // const { classes, dataSource } = useContext(CalendarContext);
  const classes = useStyles();

  const dialogRef = useRef<DialogHandle | null>(null);
  const dialogFieldsRef = useRef<TeamDialogHandle | null>(null);

  const handleConfirm = useCallback(() => {
    const selected = dialogFieldsRef.current?.getValue() as Team;
    onConfirm(selected);
  }, [onConfirm]);

  return (
    <div className={classes.selectTeamText}>
      <Dialog
        title={
          <FormattedMessage
            id="ZSD__USERS__DETAIL_FIELD_LABEL__TEAM_TITLE"
            defaultMessage="Výber tímu"
          />
        }
        ref={dialogRef}
        showConfirm={true}
        onConfirm={handleConfirm}
      >
        {() => <TeamDialog ref={dialogFieldsRef} defaultValue={team} />}
      </Dialog>
      <Button
        variant="outlined"
        className={cn(classes.button, classes.basicButton)}
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={() => dialogRef.current?.open()}
      >
        {team ? team.name : '-'}
      </Button>
    </div>
  );
}
