import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { handleApiErrors } from 'src/utils/error-handler';
import {
  DatedEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
} from '@eas/common-web';
import { Section } from '@models';
import { EvidenceApiUrl, Permission, regionApiErrors } from '@enums';
import { useEvidencePermission } from '@utils';
import { useColumns } from './section-columns';
import { Fields } from './section-fields';
import { useSectionHookedSources } from './section-hooked-sources';
import { useValidationSchema } from './section-schema';

export function Sections() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.SECTION_WRITE],
      EDIT: [Permission.SECTION_WRITE],
      REMOVE: [Permission.SECTION_WRITE],
    },
  });

  const { showSnackbar } = useContext(SnackbarContext);

  const evidence = DatedEvidence.useDatedEvidence<Section>({
    identifier: 'SECTION',
    apiProps: {
      url: EvidenceApiUrl.SECTIONS,
      hookedSources: useSectionHookedSources,
      ...handleApiErrors(regionApiErrors.SECTION, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__SECTIONS__TABLE__TITLE',
        defaultMessage: 'Zoznam úsekov',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__SECTIONS__DETAIL__TITLE',
          defaultMessage: 'Úsek',
        }),
        subtitle: (section) => section?.name ?? '',
        showBtn: btnPermissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
