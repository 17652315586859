import React, { useContext, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import {
  SnackbarContext,
  SnackbarVariant,
  useEventCallback,
  usePrompts,
} from '@eas/common-web';
import { updateWorkOrder } from '@modules/work-order/work-order-api';
import { PlannerContext } from '../planner-context';
import {
  WorkOrderDetailsForm,
  WorkOrderDetailsHandle,
} from './work-order-details-form';

export function useWorkOrderDetails({
  id,
  showClose,
  showConfirm = true,
}: {
  id?: string;
  showClose?: boolean;
  showConfirm?: boolean;
}) {
  const { calendarSource, workOrderTableRef } = useContext(PlannerContext);
  const dialogRef = useRef<WorkOrderDetailsHandle>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const intl = useIntl();

  const refresh = useEventCallback(() => {
    workOrderTableRef.current?.refresh();
    calendarSource?.yAxis.refreshData();
  });

  usePrompts(
    [
      {
        key: id
          ? `WORK_ORDER_DETAILS_DIALOG_${id}`
          : 'WORK_ORDER_DETAILS_DIALOG',
        dialogTitle: 'Detaily pracovného príkazu',
        dialogActions: [
          <Button
            key="save-detail"
            onClick={async () => {
              const data = dialogRef.current?.getData();

              if (!data) {
                return;
              }

              const response = await updateWorkOrder(data.id, data).json();

              if (response) {
                refresh();

                showSnackbar(
                  intl.formatMessage({
                    id: 'ZSD__WORK_ORDER_DETAILS_SAVE_SUCCESS',
                    defaultMessage:
                      'Detaily pracovného príkazu boli úspešne uložené.',
                  }),
                  SnackbarVariant.SUCCESS
                );
              }
            }}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage
              id="WORK_ORDER_DETAILS_SAVE"
              defaultMessage="Uložiť"
            />
          </Button>,
        ],
        dialogShowClose: showClose,
        dialogShowConfirm: showConfirm,
        dialogWidth: 700,
        dialogText: <WorkOrderDetailsForm id={id} ref={dialogRef} />,
      },
    ],
    [id]
  );
}
