import { DragStartEvent } from '@dnd-kit/core';
import { Dispatch, SetStateAction } from 'react';
import { WorkOrder } from '@models';

export function useDragStart({
  setMovingWorkOrders,
}: {
  setMovingWorkOrders: Dispatch<SetStateAction<WorkOrder[]>>;
}) {
  return (event: DragStartEvent) => {
    const workOrders = event.active.data.current as WorkOrder[];

    setMovingWorkOrders(workOrders);
  };
}
