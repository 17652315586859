import dayjs from 'dayjs';
import { ShiftInterval, ShiftIntervalType } from '@models';
import { CalendarShift } from '../calendar-types';

export function substractTime(from: string, to: string, isMultiDay = false) {
  const start = dayjs(from, 'HH:mm');
  const end = dayjs(to, 'HH:mm');

  const diff = dayjs.duration(start.diff(end)).asHours();

  return isMultiDay ? diff + 24 : diff;
}

export function addHours(date: string, hours: number) {
  const from = dayjs(date, 'HH:mm');

  return from.add(hours, 'hour').format('HH:mm');
}

export function substractDate(from: string, to: string) {
  const start = dayjs(from, 'YYYY-MM-DD');
  const end = dayjs(to, 'YYYY-MM-DD');

  return dayjs.duration(start.diff(end)).asDays();
}

export function substractDateTime(from: string, to: string) {
  const start = dayjs(from, 'YYYY-MM-DDTHH:mm:ss');
  const end = dayjs(to, 'YYYY-MM-DDTHH:mm:ss');

  return dayjs.duration(start.diff(end)).asHours();
}

export function splitInterval(interval: ShiftInterval) {
  const result: ShiftInterval[] = [];

  const NON_BREAKABLE_SHIFT_LENGTH = 4;
  const BREAK_LENGTH = 0.5;

  let isMultiDay =
    substractTime(interval.startingHour, interval.endingHour) >= 0;
  let hours = substractTime(
    interval.endingHour,
    interval.startingHour,
    isMultiDay
  );
  let start = interval.startingHour;

  while (hours > 0) {
    if (hours > 0) {
      result.push({
        ...interval,
        startingHour: start,
        endingHour: addHours(
          start,
          Math.min(NON_BREAKABLE_SHIFT_LENGTH, hours)
        ),
      });

      start = addHours(start, Math.min(NON_BREAKABLE_SHIFT_LENGTH, hours));
      isMultiDay = substractTime(interval.endingHour, start) < 0;
      hours = substractTime(interval.endingHour, start, isMultiDay);
    }

    if (hours > 0) {
      result.push({
        ...interval,
        type: 'O' as ShiftIntervalType,
        startingHour: start,
        endingHour: addHours(start, Math.min(BREAK_LENGTH, hours)),
      });

      start = addHours(start, Math.min(BREAK_LENGTH, hours));
      isMultiDay = substractTime(interval.endingHour, start) < 0;
      hours = substractTime(interval.endingHour, start, isMultiDay);
    }
  }

  return result;
}

export function getIntervals(
  shift?: CalendarShift,
  shouldCropIntervals?: boolean
) {
  if (!shift) {
    return [];
  }

  if (shift.isBlockingException) {
    return [];
  }

  const cropIntervals = (intervals: ShiftInterval[]) => {
    return intervals.map((interval) => {
      const isMultiDay =
        substractTime(interval.startingHour, interval.endingHour) >= 0;

      if (isMultiDay) {
        return {
          ...interval,
          endingHour: '24:00',
        };
      } else {
        return interval;
      }
    });
  };

  if (shift.isException) {
    if (shouldCropIntervals) {
      return [
        ...(shift.intervals ?? []),
        ...cropIntervals(shift.exceptionIntervals ?? []),
      ];
    } else {
      return shift.exceptionIntervals ?? [];
    }
  }

  if (shift.isHoliday) {
    if (shouldCropIntervals) {
      return cropIntervals(shift.holidayIntervals ?? []);
    } else {
      return shift.holidayIntervals ?? [];
    }
  }

  if (shouldCropIntervals) {
    return cropIntervals(shift.intervals ?? []);
  }

  return shift.intervals ?? [];
}

/**
 * Function to get 24 intervals of a single day
 *
 * For more information, see: `get-template.test.ts`
 */
export function getDailyTemplate(
  hours: string[],
  previousIntervals: ShiftInterval[],
  intervals: ShiftInterval[]
) {
  const isMultiDay = (start: string, end: string) =>
    substractTime(start, end) >= 0;

  const validIntervals: ShiftInterval[] = [];

  // const previousIntervalsWithBreaks = previousIntervals
  //   .map((interval) => {
  //     if (interval.type === 'Z') {
  //       // TODO: uncomment when lunch break is implemented
  //       return [interval];
  //       // return splitInterval(interval);
  //     }

  //     return [interval];
  //   })
  //   .flat();

  // const intervalsWithBreaks = intervals
  //   .map((interval) => {
  //     if (interval.type === 'Z') {
  //       // TODO: uncomment when lunch break is implemented
  //       return [interval];
  //       // return splitInterval(interval);
  //     }

  //     return [interval];
  //   })
  //   .flat();

  /**
   * Because thete can be shift 7:00 - 7:00
   */
  previousIntervals.forEach((interval) => {
    const multiDay = isMultiDay(interval.startingHour, interval.endingHour);

    if (multiDay) {
      validIntervals.push({
        ...interval,
        startingHour: '00:00',
      });
    }
  });

  intervals.forEach((interval) => {
    const multiDay = isMultiDay(interval.startingHour, interval.endingHour);

    if (!multiDay) {
      validIntervals.push(interval);
    } else {
      validIntervals.push({
        ...interval,
        endingHour: '24:00',
      });
    }
  });
  // let dayBreak = false;
  // for (const interval of previousIntervalsWithBreaks) {
  //   const multiDay = isMultiDay(interval.startingHour, interval.endingHour);

  //   if (multiDay) {
  //     dayBreak = true;
  //   }

  //   if (dayBreak) {
  //     if (multiDay) {
  //       validIntervals.push({
  //         ...interval,
  //         startingHour: '00:00',
  //       });
  //     } else {
  //       validIntervals.push(interval);
  //     }
  //   }
  // }

  // dayBreak = false;
  // for (const interval of intervalsWithBreaks) {
  //   const multiDay = isMultiDay(interval.startingHour, interval.endingHour);

  //   if (!dayBreak) {
  //     if (multiDay) {
  //       validIntervals.push({
  //         ...interval,
  //         endingHour: '24:00',
  //       });
  //     } else {
  //       validIntervals.push(interval);
  //     }
  //   }

  //   if (multiDay) {
  //     dayBreak = true;
  //   }
  // }

  const template = hours.map((intervalHour) => {
    const intervals = validIntervals?.filter((interval) => {
      return (
        substractTime(interval.startingHour, intervalHour) < 1 &&
        substractTime(interval.endingHour, intervalHour) > 0
      );
    });

    return intervals;
  });

  return template;
}
