import React, { forwardRef, useContext } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { CalendarContext } from '../../calendar-context';
import { CalendarDataType } from '../../calendar-types';
import { useStyles } from './legend-styles';
import { Unit } from './unit';

interface LegentProps {
  data: CalendarDataType[];
}

export const Legend = forwardRef<List, LegentProps>(function Legend(
  { data },
  ref
) {
  const classes = useStyles();
  const { settings } = useContext(CalendarContext);

  return (
    <div
      className={classes.wrapper}
      style={{
        width: settings.legendWidth,
        marginTop: -1 * settings.headerHeight,
        height: `calc(100% - ${settings.headerHeight}px)`,
      }}
    >
      <AutoSizer disableWidth={true} style={{ height: '100%' }}>
        {({ height }) => (
          <List
            ref={ref}
            className={classes.legendList}
            layout="vertical"
            width="100%"
            height={height + settings.headerHeight}
            itemCount={data.length}
            itemSize={() => settings.rowHeight}
            itemData={data}
            innerElementType={({ children, props }) => {
              return (
                <div
                  style={{
                    height:
                      data.length * settings.rowHeight + settings.headerHeight,
                  }}
                  className={classes.legendInnerElement}
                  {...props}
                >
                  <div
                    className={classes.legendTitle}
                    style={{ height: settings.headerHeight }}
                  />
                  <div
                    className={classes.legendInnerWrapper}
                    style={{
                      height: data.length * settings.rowHeight,
                      minHeight: `calc(100% - ${settings.headerHeight}px)`,
                    }}
                  >
                    {children}
                  </div>
                </div>
              );
            }}
          >
            {({ style, index, data }) => {
              const item = data[index];

              return <Unit key={item.id} item={item} style={style} />;
            }}
          </List>
        )}
      </AutoSizer>
    </div>
  );
});
