import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { MobileUnit } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const MOBILE_UNIT_ASSIGNMENTS_SOURCE = 'MOBILE_UNIT_ASSIGN MENTS_SOURCE';
export const CALENDARS_SOURCE = 'CALENDARS_SOURCE';
export const CALENDAR_EXCEPTIONS_SOURCE = 'CALENDAR_EXCEPTIONS_SOURCE';

function useMobileUnitAssignmentsSource(): HookedSource<MobileUnit> {
  // TODO add permissions
  const source = useScrollableSource();

  return {
    [MOBILE_UNIT_ASSIGNMENTS_SOURCE]: {
      source,
      shouldLoad: () => true,
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.MOBILE_UNIT_ASSIGNMENTS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'mobileUnit.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useCalendarSource(): HookedSource<MobileUnit> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [CALENDARS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.CALENDAR_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.CALENDARS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'mobileUnit.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useCalendarExceptionSource(): HookedSource<MobileUnit> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [CALENDAR_EXCEPTIONS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.CALENDAR_EXCEPTION_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'mobileUnit.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useMobileUnitHookedSources(): HookedSource<MobileUnit> {
  const calendars = useCalendarSource();
  const calendarExceptions = useCalendarExceptionSource();
  const mobileUnitAssignments = useMobileUnitAssignmentsSource();

  return { ...mobileUnitAssignments, ...calendars, ...calendarExceptions };
}
