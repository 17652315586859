import dayjs from 'dayjs';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

export function getCurrentDate(settings: Partial<CalendarSettingsType>) {
  const { dimension = CalendarDimensions.WEEK } = settings;

  switch (dimension) {
    case CalendarDimensions.DAY:
      return dayjs().startOf('day').unix();
    case CalendarDimensions.WEEK:
      return dayjs().subtract(1, 'day').startOf('day').unix();
    case CalendarDimensions.MONTH:
      return dayjs().startOf('month').unix();
    default:
      return dayjs().startOf(dimension).unix();
  }
}
