import dayjs from 'dayjs';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { PromptContext } from '@eas/common-web';
import { WorkOrder } from '@models';
import { useUnitPlan } from './unit-plan';

type UnitMenuProps = {
  unit: {
    id: string;
    name: string;
    unitType: 'mobileUnit' | 'user';
    latitude?: number;
    longitude?: number;
  };
  workOrders?: WorkOrder[];
  stickToPointer?: boolean;
  date: number;
};

export type UnitMenuHandle = {
  open: (event: React.MouseEvent<HTMLElement>) => void;
};

export const UnitMenu = forwardRef(function UnitMenu(
  { unit, workOrders, stickToPointer, date }: UnitMenuProps,
  ref: React.Ref<UnitMenuHandle>
) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [left, setLeft] = useState(0);

  const { testPrompt } = useContext(PromptContext);

  useImperativeHandle(ref, () => ({
    open: (event: React.MouseEvent<HTMLElement>) => {
      if (stickToPointer) {
        setLeft(event.nativeEvent.clientX);
      }
      setAnchorEl(event.currentTarget);
    },
  }));

  const orderedWorkOrders = workOrders
    ?.sort((a, b) => dayjs(a?.startTime).unix() - dayjs(b?.startTime).unix())
    .filter((wo) => {
      return dayjs(wo.startTime).day() === dayjs.unix(date).day();
    });

  useUnitPlan({
    id: unit.id,
    workOrders: orderedWorkOrders ?? [],
    startingPoint: { latitude: unit.latitude, longitude: unit.longitude },
  });

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => {
        setAnchorEl(null);
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: left === 0 ? 'right' : left,
      }}
    >
      <MenuItem
        disabled={workOrders?.length === 0}
        onClick={() => {
          setAnchorEl(null);

          testPrompt({
            key: `UNIT_PLAN_DIALOG_${unit.id}`,
            submitCallback: () => {},
          });
        }}
      >
        <ListItemIcon />
        <FormattedMessage
          id="UNIT_MENU_ITEM_DETAIL"
          defaultMessage="Plán cesty"
        />
      </MenuItem>
    </Menu>
  );
});
