import React, {
  createContext,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react';
import { DomainObject } from '@eas/common-web';

type RowHandler<T> = (newRowValue: T) => void;

type RegisteredRows<T> = Record<
  string,
  React.MutableRefObject<{
    updateRow: RowHandler<T>;
  }>
>;

type ContextType = {
  useRegisterRow: <T>(key: string, callback: RowHandler<T>) => void;
  updateRow: <T extends DomainObject>(key: string, newRowValue: T) => void;
};

export const TableRowHandlersContext = createContext<ContextType>({
  useRegisterRow: () => {},
  updateRow: () => {},
});

/**
 * Context holds an object of row handlers registered by row id.
 *
 * It can be used to update local state in table without re-rendering the whole table / component tree
 *
 */
export const TableRowHandlersProvider = <T extends DomainObject>({
  rows,
  children,
}: {
  rows: T[];
  children: React.ReactNode;
}) => {
  const registeredRows: RegisteredRows<T> = useMemo(
    () =>
      Object.fromEntries(
        rows.map(({ id }) => [
          id,
          {
            current: {
              updateRow: () =>
                console.log(`ERROR: updateRow fn uninitialized (${id})`),
            },
          },
        ])
      ),
    [rows]
  );

  const useRegisterRow = <T,>(key: string, updateRow: RowHandler<T>) =>
    useImperativeHandle(
      registeredRows[key],
      () => ({ updateRow: updateRow as RowHandler<DomainObject> }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

  const updateRow = useCallback(
    <T extends DomainObject>(key: string, newRowValue: T): void =>
      registeredRows?.[key]?.current?.updateRow?.(newRowValue as any),
    [registeredRows]
  );

  return (
    <TableRowHandlersContext.Provider value={{ useRegisterRow, updateRow }}>
      {children}
    </TableRowHandlersContext.Provider>
  );
};
