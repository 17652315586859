import { ListSource, useAutocompleteSource, useFetch } from '@eas/common-web';
import { WorkOrderState, WorkOrderStateAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useWorkOrderStates() {
  return useAutocompleteSource<WorkOrderStateAutocomplete>({
    url: `${EvidenceApiUrl.WORK_ORDER_STATES}/autocomplete`,
  });
}

export function useWorkOrderStatesList() {
  return useFetch<ListSource<WorkOrderState>>(
    `${EvidenceApiUrl.WORK_ORDER_STATES}/list`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        size: -1,
        sort: [
          {
            field: 'name',
            order: 'ASC',
            type: 'FIELD',
            missing: 'LAST',
          },
        ],
      }),
    }
  );
}
