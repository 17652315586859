import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormContext,
  useFormSelector,
  useUpdateEffect,
} from '@eas/common-web';
import { useSections } from '@modules/section/section-api';
import { ZsdUser } from '@models';

export function UserSectionFields() {
  const { setFieldValue } = useContext(FormContext);

  const values = useFormSelector((data: ZsdUser) => ({
    section: data?.section?.id ?? undefined,
    type: data?.type,
  }));

  const isManagement = values?.type === 'MANAGEMENT';

  const sections = useSections();

  useUpdateEffect(() => {
    if (!isManagement) {
      setFieldValue('section', null);
    }
  }, [isManagement]);

  if (!isManagement) {
    return <></>;
  }

  return (
    <>
      <FormAutocomplete
        name="section"
        source={sections}
        label={
          <FormattedMessage
            id="ZSD__USERS__DETAIL_FIELD_LABEL__SECTION"
            defaultMessage="Úsek"
          />
        }
      />
    </>
  );
}
