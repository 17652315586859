import dayjs from 'dayjs';
import {
  ApiFilterOperation,
  Params,
  ResultDto,
  useFetch,
} from '@eas/common-web';
import { Calendar, MobileUnit, WorkOrder, ZsdUser } from '@models';
import { EvidenceApiUrl } from '@enums';
import { DrivingInfo } from './plan-types';

function prepareFilters(day: number) {
  return {
    startDate: dayjs.unix(day).startOf('month').format('YYYY-MM-DD'),
    endDate: dayjs.unix(day).endOf('month').format('YYYY-MM-DD'),
  };
}

export interface SourceParams {
  unit: MobileUnit | ZsdUser | undefined;
  unitType: 'mobileUnit' | 'user';
  skip?: boolean;
  currentDay: number;
}

export function useCalendarList({
  unit,
  unitType,
  skip,
  currentDay,
}: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay);

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.AND,
        filters: [
          {
            field: unitType === 'mobileUnit' ? 'mobileUnit.id' : 'user.id',
            operation: ApiFilterOperation.EQ,
            value: unit?.id,
          },
          {
            field: 'fromDate',
            operation: ApiFilterOperation.LTE,
            value: endDate,
          },
          {
            field: 'toDate',
            operation: ApiFilterOperation.GTE,
            value: startDate,
          },
        ],
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<Calendar>>(
    `${EvidenceApiUrl.CALENDARS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useWorkOrderList({
  unit,
  unitType,
  skip,
  currentDay,
}: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay);

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.AND,
        filters: [
          {
            field: unitType === 'mobileUnit' ? 'mobileUnit.id' : 'user.id',
            operation: ApiFilterOperation.EQ,
            value: unit?.id,
          },
          {
            field: 'startTime',
            operation: ApiFilterOperation.LTE,
            value: endDate,
          },
          {
            operation: ApiFilterOperation.OR,
            filters: [
              {
                field: 'endTime',
                operation: ApiFilterOperation.GTE,
                value: startDate,
              },
              {
                // this is needed to get work orders which are in "VYK" state and have endTime < startDate
                // condition startTime >= startDate should be still applied however
                operation: ApiFilterOperation.AND,
                filters: [
                  {
                    field: 'startTime',
                    operation: ApiFilterOperation.GTE,
                    value: startDate,
                  },
                  {
                    field: 'currentState.code',
                    operation: ApiFilterOperation.EQ,
                    value: 'VYK',
                  },
                ],
              },
            ],
          },
          {
            field: 'currentState',
            operation: ApiFilterOperation.NOT_NULL,
          },
          {
            operation: ApiFilterOperation.NOT,
            filters: [
              {
                field: 'currentState.code',
                operation: ApiFilterOperation.EQ,
                value: 'ZRU',
              },
            ],
          },
        ],
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<WorkOrder>>(
    `${EvidenceApiUrl.WORK_ORDERS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

type AvailabilityInterval = {
  startingHour: string;
  endingHour: string;
};

export type AvailabilityResult = {
  id: string;
  intervals: AvailabilityInterval[];
};

export function useCalendarExceptionList({
  unit,
  unitType,
  skip,
  currentDay,
}: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay);

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.AND,
        filters: [
          {
            field: unitType === 'mobileUnit' ? 'mobileUnit.id' : 'user.id',
            operation: ApiFilterOperation.EQ,
            value: unit?.id,
          },
          {
            field: 'validFrom',
            operation: ApiFilterOperation.LTE,
            value: endDate,
          },
          {
            field: 'validTo',
            operation: ApiFilterOperation.GTE,
            value: startDate,
          },
        ],
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<Calendar>>(
    `${EvidenceApiUrl.CALENDAR_EXCEPTIONS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export function useAbsenceList({ unit, skip, currentDay }: SourceParams) {
  const { startDate, endDate } = prepareFilters(currentDay);

  const params: Params = {
    filters: [
      {
        field: 'user.id',
        operation: ApiFilterOperation.EQ,
        value: unit?.id,
      },
      {
        field: 'fromDate',
        operation: ApiFilterOperation.LTE,
        value: endDate,
      },
      {
        field: 'toDate',
        operation: ApiFilterOperation.GTE,
        value: startDate,
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<Calendar>>(
    `${EvidenceApiUrl.ABSENCES}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}

export async function getDrivingInfo(coords: string): Promise<DrivingInfo> {
  try {
    const response = await fetch('/api/zsd/location/route', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        coordinates: coords,
      }),
    });

    const json = await response.json();

    return {
      distance: json.routes[0].distance,
      duration: json.routes[0].duration,
      geometry: json.routes[0].geometry,
      legs: json.routes[0].legs,
    };
  } catch (error) {
    console.error(error);

    return {
      distance: 0,
      duration: 0,
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
      legs: [],
    };
  }
}

export function useUnitDetail(id: string, unitType: 'mobileUnit' | 'user') {
  const [result, loading, reset, setLoading] = useFetch<MobileUnit | ZsdUser>(
    unitType === 'mobileUnit'
      ? `${EvidenceApiUrl.MOBILE_UNITS}/${id}`
      : `${EvidenceApiUrl.USERS}/${id}`
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}
