import { useDraggable } from '@dnd-kit/core';
import React from 'react';
import { DomainObject } from '@eas/common-web';

export function Draggable<OBJECT extends DomainObject>({
  children,
  id,
  data,
  disabled = false,
  handleRightClick,
}: {
  children: React.ReactNode;
  id: string;
  data: OBJECT[];
  disabled?: boolean;
  handleRightClick?: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  const { attributes, isDragging, listeners, setNodeRef } = useDraggable({
    id,
    data,
    disabled,
  });

  return (
    <div
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      onContextMenu={(e) => {
        e.preventDefault();
        handleRightClick?.(e);
      }}
    >
      {children}
    </div>
  );
}
