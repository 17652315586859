import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { handleApiErrors } from 'src/utils/error-handler';
import {
  DatedEvidence,
  EmptyComponent,
  Evidence,
  SnackbarContext,
} from '@eas/common-web';
import { MobileUnit } from '@models';
import { EvidenceApiUrl, Permission, regionApiErrors } from '@enums';
import { useEvidencePermission } from '@utils';
import { ActionBar } from './mobile-unit-actionbar';
import { useColumns } from './mobile-unit-columns';
import { Fields } from './mobile-unit-fields';
import { useMobileUnitHookedSources } from './mobile-unit-hooked-sources';
import { useValidationSchema } from './mobile-unit-schema';

export function MobileUnits() {
  const intl = useIntl();
  const validationSchema = useValidationSchema();

  const btnPermissions = useEvidencePermission({
    options: {
      NEW: [Permission.MOBILE_UNIT_WRITE],
      EDIT: [Permission.MOBILE_UNIT_WRITE],
      REMOVE: [Permission.MOBILE_UNIT_WRITE],
    },
  });

  const { showSnackbar } = useContext(SnackbarContext);

  const evidence = DatedEvidence.useDatedEvidence<MobileUnit>({
    identifier: 'MOBILE_UNIT',
    apiProps: {
      url: EvidenceApiUrl.MOBILE_UNITS,
      hookedSources: useMobileUnitHookedSources,
      ...handleApiErrors(regionApiErrors.MOBILE_UNIT, showSnackbar),
    },
    tableProps: {
      columns: useColumns(),
      tableName: intl.formatMessage({
        id: 'ZSD__MOBILE_UNITS__TABLE__TITLE',
        defaultMessage: 'Mobilné jednotky',
      }),
      showBulkActionButton: false,
      showReportButton: false,
      defaultSorts: [],
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: intl.formatMessage({
          id: 'ZSD__MOBILE_UNITS__DETAIL__TITLE',
          defaultMessage: 'Mobilná jednotka',
        }),
        subtitle: (unit) => unit?.name ?? '',
        ActionBar,
        showBtn: btnPermissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
