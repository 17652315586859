import dayjs from 'dayjs';
import { WorkOrder } from '@models';
import { CalendarDimensions } from '@enums';
import { CalendarSettingsType } from '../calendar-types';

export function getTaskBoundaries(
  currentDayUnix: number,
  settings: CalendarSettingsType,
  workOrder: WorkOrder
) {
  let workOrderStart;
  let workOrderEnd;

  const { dimension = CalendarDimensions.WEEK } = settings;

  const dimmensionStartTime = dayjs
    .unix(currentDayUnix)
    .add(settings.startingHour, 'hour');
  const dimmensionEndTime = dayjs
    .unix(currentDayUnix)
    .add(1, dimension)
    .subtract(24 - settings.endingHour, 'hour');

  workOrderStart = dayjs(workOrder.startTime);
  workOrderEnd = dayjs(workOrder.endTime);

  if (workOrderStart.hour() < settings.startingHour) {
    workOrderStart = dayjs(
      workOrderStart.format('YYYY-MM-DD') + 'T' + settings.startingHour + ':00'
    );
  }

  if (workOrderStart.hour() > settings.endingHour) {
    workOrderStart = dayjs(
      workOrderStart.format('YYYY-MM-DD') + 'T' + settings.endingHour + ':00'
    );
  }

  // if task starts before the dimension interval starts //=> TODO: this check is probably unnecessary
  if (workOrderStart.isBefore(dimmensionStartTime)) {
    workOrderStart = dimmensionStartTime;

    const diff = dimmensionStartTime.diff(workOrderStart, 'second');
    workOrderEnd = workOrderEnd.subtract(diff, 'second');
  }

  // if task ends after the dimension interval ends
  if (workOrderEnd.isAfter(dimmensionEndTime)) {
    workOrderEnd = dimmensionEndTime;
  }

  return {
    workOrderStart,
    workOrderEnd,
  };
}
