import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import MuiInputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Typography from '@material-ui/core/Typography';
import MuiClearIcon from '@material-ui/icons/Clear';
import MuiSearchIcon from '@material-ui/icons/Search';
import {
  Checkbox,
  Form,
  ListSource,
  Panel,
  formFieldFactory,
} from '@eas/common-web';
import { useDepartmentList } from '@modules/department/department-api';
import { useSectionList } from '@modules/section/section-api';
import { useTeamList } from '@modules/team/team-api';
import { formTextFieldFactory } from '@components/form/simple-text-field/simple-text-field';
import { Department, Section, Team } from '@models';
import {
  useInnerStyles,
  useOuterStyles,
  useStyles,
} from './department-dialog-styles';
import { DepartmentGroup } from './department-group';

const mergeDepartments = ({
  sections,
  teams,
  departments,
  search,
  hideDepartment,
}: {
  sections: Section[];
  teams: Team[];
  departments: Department[];
  search: string;
  hideDepartment?: Department;
}) => {
  const teamsWithDepartments = teams.map((team) => ({
    ...team,
    departments: departments.filter(
      (dep) =>
        dep.team?.id === team.id &&
        dep.name.toLowerCase().includes(search.toLowerCase()) &&
        dep.id !== hideDepartment?.id
    ),
  }));

  // remove teams without departments
  const teamsWithDepartmentsFiltered = teamsWithDepartments.filter(
    (team) => team.departments.length
  );

  const sectionsWithTeams = sections.map((section) => ({
    ...section,
    teams: teamsWithDepartmentsFiltered.filter(
      (team) => team.section?.id === section.id
    ),
  }));

  // remove sections without teams
  const sectionsWithTeamsFiltered = sectionsWithTeams.filter(
    (section) => section.teams.length
  );

  return sectionsWithTeamsFiltered;
};

interface DepartmentDialogProps {
  multiple?: boolean;
  hideCheckAll?: boolean;
  hideDepartment?: Department;
  excludeId?: string;
  defaultValue?: Department | Department[];
  customSource?: ListSource<Department>;
  onSelect?: (id: string, checked: boolean) => void;
}

export interface DepartmentDialogHandle {
  getValue: () => Department | Department[] | undefined;
}

export const DepartmentDialog = forwardRef<
  DepartmentDialogHandle,
  DepartmentDialogProps
>(function DepartmentDialog(props, ref) {
  const intl = useIntl();
  const [value, setValue] = useState(props.defaultValue);

  const sections = useSectionList();
  const teams = useTeamList();
  const departmentsSource = useDepartmentList(props.excludeId);
  const departments =
    props.customSource?.items ?? departmentsSource?.result?.items;

  const classes = useStyles();
  const outerClasses = useOuterStyles();
  const innerClasses = useInnerStyles();

  const [filter, setFilter] = useState('');

  useImperativeHandle(
    ref,
    () => ({
      getValue: () => value,
      value,
    }),
    [value]
  );

  if (!sections || !teams || !departments) {
    return null;
  }

  const sectionsWithReferences = mergeDepartments({
    sections: sections.items,
    teams: teams.items,
    departments: departments,
    search: filter,
    hideDepartment: props.hideDepartment,
  });

  const SearchField = formFieldFactory(
    formTextFieldFactory({
      label: intl.formatMessage({
        id: 'ZSD__DEPARTMENT__FIELD_LABEL__DEPARTMENT_SEARCH_PLACEHOLDER',
        defaultMessage: 'Vyhľadať',
      }),
    }),
    ({ children }) => <>{children}</>
  );

  const allDepartments = departmentsSource?.result?.items ?? [];
  const isCheckedAll =
    Array.isArray(value) && value.length === allDepartments.length;
  const isCheckedNone = Array.isArray(value) && value.length === 0;

  return (
    <div className={classes.root}>
      <Form
        onSubmit={(values) => setFilter(values.query)}
        editing={true}
        initialValues={{ query: '' }}
      >
        {(props) => (
          <SearchField
            name="query"
            size="small"
            classes={{ root: classes.searchInput }}
            endAdornment={
              <MuiInputAdornment position="end">
                {filter && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      props.resetForm();
                      setFilter('');
                    }}
                  >
                    <MuiClearIcon />
                  </IconButton>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  className={classes.searchButton}
                  onClick={() => props.handleSubmit()}
                >
                  <MuiSearchIcon />
                </Button>
              </MuiInputAdornment>
            }
          />
        )}
      </Form>
      <Divider className={classes.divider} />
      {props.multiple && !props.hideCheckAll && (
        <>
          <MuiFormControlLabel
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              marginLeft: 0,
            }}
            label={
              <Typography variant="body1">
                <FormattedMessage
                  id="ZSD__DEPARTMENT__ALL_DEPARTMENTS"
                  defaultMessage="Všetky pracoviská"
                />
              </Typography>
            }
            control={
              <Checkbox
                threeState={true}
                value={isCheckedAll ? true : isCheckedNone ? false : undefined}
                onChange={(value) => {
                  if (value == true) {
                    setValue(allDepartments);
                  }
                  if (value === false) {
                    setValue([]);
                  }
                }}
              />
            }
          />
          <Divider className={classes.divider} />
        </>
      )}
      {sectionsWithReferences.length === 0 && (
        <div>
          <FormattedMessage
            id="ZSD__DEPARTMENT__NO_RESULTS"
            defaultMessage="Žiadne výsledky"
          />
        </div>
      )}
      {sectionsWithReferences.map((section) => (
        <Panel
          key={section.id}
          label={section.name}
          expandable={true}
          defaultExpanded={true}
          bottomBorder={false}
          classOverrides={{
            summaryRoot: outerClasses.summaryRoot,
            detailsRoot: outerClasses.detailsRoot,
            labelRoot: outerClasses.labelRoot,
          }}
        >
          <div>
            {section.teams.map((team) => (
              <Panel
                key={team.id}
                label={team.name}
                expandable={true}
                defaultExpanded={true}
                classOverrides={{
                  root: innerClasses.root,
                  summaryRoot: innerClasses.summaryRoot,
                  detailsRoot: innerClasses.detailsRoot,
                  labelRoot: innerClasses.labelRoot,
                }}
              >
                <DepartmentGroup
                  group={team.departments}
                  multiple={props.multiple ?? true}
                  // passing null for radio (= not Array) if the value is not in the concerned radioGroup
                  value={
                    Array.isArray(value) ||
                    team.departments.find((dep) => dep?.id === value?.id)
                      ? value
                      : undefined
                  }
                  setValue={(newValue) => {
                    setValue(newValue);

                    const departments = value as Department[];
                    const newDepartments = newValue as Department[];

                    // find diff
                    const checked =
                      newDepartments.length > departments.length
                        ? newDepartments.filter(
                            (dep) => !departments.find((d) => d.id === dep.id)
                          )
                        : undefined;

                    const unchecked =
                      newDepartments.length < departments.length
                        ? departments.filter(
                            (dep) =>
                              !newDepartments.find((d) => d.id === dep.id)
                          )
                        : undefined;

                    if (props.onSelect) {
                      if (checked) {
                        checked.forEach((dep) =>
                          props.onSelect?.(dep.id, true)
                        );
                      }
                      if (unchecked) {
                        unchecked.forEach((dep) =>
                          props.onSelect?.(dep.id, false)
                        );
                      }
                    }
                  }}
                />
              </Panel>
            ))}
          </div>
        </Panel>
      ))}
    </div>
  );
});
