import dayjs from 'dayjs';
import {
  ApiFilterOperation,
  Params,
  ResultDto,
  useFetch,
} from '@eas/common-web';
import { Holiday } from '@models';
import { CalendarDimensions, EvidenceApiUrl } from '@enums';

export function useHolidayList({
  skip,
  currentDay,
  dimmension,
}: {
  skip?: boolean;
  currentDay: number;
  dimmension?: CalendarDimensions;
}) {
  let startDate;
  let endDate;
  switch (dimmension) {
    case 'day':
      startDate = dayjs.unix(currentDay).format('YYYY-MM-DD');
      endDate = dayjs.unix(currentDay).add(1, 'day').format('YYYY-MM-DD');
      break;
    case 'week':
      startDate = dayjs
        .unix(currentDay)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      endDate = dayjs.unix(currentDay).add(6, 'day').format('YYYY-MM-DD');
      break;
    case 'month':
      startDate = dayjs
        .unix(currentDay)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      endDate = dayjs
        .unix(currentDay)
        .add(dayjs.unix(currentDay).daysInMonth() - 1, 'day')
        .format('YYYY-MM-DD');
      break;
    default:
      break;
  }

  const params: Params = {
    filters: [
      {
        operation: ApiFilterOperation.AND,
        filters: [
          {
            field: 'day',
            operation: ApiFilterOperation.LTE,
            value: endDate,
          },
          {
            field: 'day',
            operation: ApiFilterOperation.GTE,
            value: startDate,
          },
          {
            field: 'active',
            operation: ApiFilterOperation.EQ,
            value: true,
          },
        ],
      },
    ],
    size: -1,
  };

  const [result, loading, reset, setLoading] = useFetch<ResultDto<Holiday>>(
    `${EvidenceApiUrl.HOLIDAYS}/list`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      skip,
      body: JSON.stringify(params),
    }
  );

  return {
    result,
    loading,
    reset,
    setLoading,
  };
}
