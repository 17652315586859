import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  DetailContext,
  FieldFilter,
  TableFilterCells,
} from '@eas/common-web';
import { useTechniciansByDepartment } from '@modules/user/user-api';
import { universalUserLabelMapper } from '@utils';

export const useFilterFields = () => {
  const intl = useIntl();

  const { source } = useContext(DetailContext);
  const departmentId = source.data.department.id;
  const useTechniciansHook = () => useTechniciansByDepartment(departmentId);

  return [
    {
      label: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__LEAD',
        defaultMessage: 'Vedúci',
      }),
      datakey: 'leadTechnician',
      filterkey: 'leadTechnician.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useTechniciansHook,
        universalUserLabelMapper
      ),
    },
    {
      label: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__TECHNICIANS',
        defaultMessage: 'Technici',
      }),
      datakey: 'technicians',
      filterkey: 'technicians.id',
      FilterComponent:
        TableFilterCells.useFilterMultipleAutocompleteCellFactory(
          useTechniciansHook,
          universalUserLabelMapper
        ),
    },
    {
      label: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__VALID_FROM',
        defaultMessage: 'Platná od',
      }),
      datakey: 'validFrom',
      filterkey: 'validFrom',
      defaultOperation: ApiFilterOperation.LT,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: intl.formatMessage({
        id: 'ZSD__MOBILE_UNIT__ASSIGNMENT__TABLE__COLUMN__VALID_TO',
        defaultMessage: 'Platná od',
      }),
      datakey: 'validTo',
      filterkey: 'validTo',
      defaultOperation: ApiFilterOperation.GT,
      FilterComponent: FieldFilter.FilterDateCell,
    },
  ];
};
