import dayjs from 'dayjs';
import { Calendar } from '@models';
import { substractDate } from './get-template';

/**
 * Find calendar for current day.
 *
 * For more information, see: `./calendar-utils.test.ts`
 */
export function getCalendar(calendars: Calendar[], currentDay: string) {
  const calendar = calendars.find((calendar) => {
    const fromDate = dayjs(calendar?.fromDate).format('YYYY-MM-DD');
    const toDate = dayjs(calendar?.toDate).format('YYYY-MM-DD');

    return (
      substractDate(currentDay, fromDate) >= 0 &&
      substractDate(currentDay, toDate) <= 0
    );
  });

  return calendar;
}
