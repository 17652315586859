import { useContext } from 'react';
import { PromptContext } from '@eas/common-web';
import { CalendarDataSourceType } from '../calendar/calendar-types';

export function useCalamitySubmit({
  calendarSource,
}: {
  calendarSource: CalendarDataSourceType;
}) {
  const { testPrompt } = useContext(PromptContext);

  const handleClick = () =>
    testPrompt({
      key: 'CALAMITY_DIALOG',
      submitCallback: async () => {
        calendarSource.yAxis.refreshData();
      },
    });

  return { handleClick };
}
