import React, { ReactNode, useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {
  Dialog,
  DialogHandle,
  FormAutocomplete,
  FormContext,
  useFormSelector,
} from '@eas/common-web';
import { useDepartmentAutocomplete } from '@modules/department/department-api';
import { Department } from '@models';
import { DepartmentDialog, DepartmentDialogHandle } from './department-dialog';

const useStyles = makeStyles(() => ({
  button: {
    maxHeight: 22,
    fontSize: 13,
    marginLeft: 6,
  },
}));

export interface DepartmentFieldProps {
  fieldName: string;
  label: ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  excludeId?: string;
}

export function DepartmentField({
  fieldName,
  label,
  disabled,
  multiple,
  excludeId,
}: DepartmentFieldProps) {
  const { setFieldValue } = useContext(FormContext);

  const { defaultValue } = useFormSelector(
    (values: { [key: string]: Department[] }) => ({
      defaultValue: values[fieldName],
    })
  );

  const departments = useDepartmentAutocomplete(excludeId);
  const classes = useStyles();

  const dialogRef = useRef<DialogHandle | null>(null);
  const dialogFieldsRef = useRef<DepartmentDialogHandle | null>(null);

  return (
    <>
      <FormAutocomplete
        name={fieldName}
        source={departments}
        label={label}
        after={
          !disabled && (
            <Button
              onClick={() => dialogRef.current?.open()}
              startIcon={<SearchIcon />}
              className={classes.button}
            >
              <FormattedMessage
                id="ZSD__DEPARTMENT__FIELD_LABEL__DEPARTMENT_SELECT"
                defaultMessage="Výber"
              />
            </Button>
          )
        }
        multiple={multiple}
      />

      <Dialog
        title={
          <FormattedMessage
            id="ZSD__USERS__DETAIL_FIELD_LABEL__DEPARTMENT_TITLE"
            defaultMessage="Výber oblasti"
          />
        }
        ref={dialogRef}
        showConfirm={true}
        onConfirm={() =>
          setFieldValue(fieldName, dialogFieldsRef.current?.getValue())
        }
      >
        {() => (
          <DepartmentDialog
            ref={dialogFieldsRef}
            defaultValue={defaultValue}
            multiple={multiple}
            excludeId={excludeId}
          />
        )}
      </Dialog>
    </>
  );
}
