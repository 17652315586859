import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  AdminProvider,
  DetailToolbar2,
  EvidenceDefaultsProvider,
  Menubar,
  NamedSettingsProvider,
  NavigationContext,
  NotificationButton,
  NotificationProvider,
  UserContext,
  UserSettingsProvider,
} from '@eas/common-web';
import { Logo } from '@components/menu/logo';
import logo from '@components/menu/logo.svg';
import { useMenuItems } from '@components/menu/menu-items';
import { SecureRoute } from '@components/router/secure-route';
import { EvidenceApiUrl, EvidenceBrowserUrl } from './enums/evidence-url';
import { Permission } from './enums/permission';
import { MobileUnitSwitcherPermissions } from './enums/permission-sets';
import * as Modules from './modules';

export const useStyles = makeStyles({
  title: {
    fontWeight: 400,
    fontSize: 12,
    color: 'white',
    marginLeft: 10,
  },
  userButton: {
    fontSize: 12,
  },
  subMenuItem: {
    margin: 0,
  },
  subMenuItemIcon: {
    minWidth: 25,
    marginRight: 10,
  },
  substitutedUser: {
    textTransform: 'uppercase',
    color: '#fff',
    fontSize: 12,
    fontWeight: 700,
    marginRight: 25,
  },
});

export function AppSecured() {
  const classes = useStyles();

  const { filteredItems } = useMenuItems();

  const { pathname } = useLocation();
  const { user, hasPermission } = useContext(UserContext);
  const { navigate } = useContext(NavigationContext);

  useEffect(() => {
    const redirectToHomePage = () => {
      if (pathname) {
        window.localStorage.setItem('redirectTo', pathname);
        window.localStorage.setItem('redirectTo', pathname);
      }
      navigate('/', true);
    };

    if (!user) {
      redirectToHomePage();
    } else {
      const redirectTo = window.localStorage.getItem('redirectTo');
      window.localStorage.removeItem('redirectTo');

      if (redirectTo) {
        navigate(redirectTo, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserSettingsProvider url="/api/zsd/user-settings">
      <NotificationProvider
        notificationUrl={EvidenceApiUrl.NOTIFICATIONS}
        notificationTemplateUrl={EvidenceApiUrl.NOTIFICATION_TEMPLATES}
        notificationEventUrl={EvidenceApiUrl.NOTIFICATION_EVENTS}
        notificationPreferenceUrl={EvidenceApiUrl.NOTIFICATION_PREFERENCES}
        notificationSubscriptionTypeUrl={`${EvidenceApiUrl.NOTIFICATION_PREFERENCES}/subscription/type`}
        allowUnsubscribe={false}
      >
        <NamedSettingsProvider
          url="/api/zsd/named-settings"
          adminPermission="PERMISSION_LIST"
        >
          <EvidenceDefaultsProvider
            appName="ZSD"
            appLogo={logo}
            enableEmptyDetailContainer={true}
            ToolbarComponent={DetailToolbar2}
            defaultTableNamedSettings={false}
          >
            <Menubar
              logoUrl="/zsd/kalendar"
              logo={<Logo />}
              title={
                <Typography className={classes.title}>
                  <FormattedMessage
                    id="ZSD__APP_TITLE"
                    defaultMessage="ZSD - Systém na riadenie výkonu v teréne"
                  />
                </Typography>
              }
              items={filteredItems}
              classOverrides={{
                userButton: classes.userButton,
                subMenuItem: classes.subMenuItem,
                subMenuItemIcon: classes.subMenuItemIcon,
              }}
              beforeUserBtn={
                // TODO: Add correct permission for seeing/getting notifications
                hasPermission(Permission.MOBILE_UNIT_WRITE) ? (
                  <NotificationButton />
                ) : null
              }
            >
              <AdminProvider
                permission=""
                prefix="/zsd"
                reindexUrl="/api/zsd/reindex"
                soapMessagesUrl="/api/zsd/soap/logger/messages"
                showSoapMessages={false}
              />
              <Switch>
                {/* prettier-ignore */}
                <SecureRoute path={EvidenceBrowserUrl.CALENDAR_TEMPLATES} component={Modules.CalendarTemplates} permissions={[Permission.CALENDAR_TEMPLATE_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={EvidenceBrowserUrl.CALENDARS} component={Modules.Planner} permissions={[Permission.CALENDAR_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={EvidenceBrowserUrl.CERTIFICATIONS} component={Modules.Certifications} permissions={[Permission.CERTIFICATION_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.DEPARTMENTS}/:id?`} component={Modules.Departments} permissions={[Permission.DEPARTMENT_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.HOLIDAYS}/:id?`} component={Modules.Holidays} permissions={[Permission.HOLIDAY_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.MOBILE_UNITS_SWITCH}`} component={Modules.Switcher} permissions={MobileUnitSwitcherPermissions} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.MOBILE_UNITS}/:id?`} component={Modules.MobileUnits} permissions={[Permission.MOBILE_UNIT_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.PLAN_MOBILE_UNIT}/:id?`} component={Modules.PlanMobileUnit} permissions={[Permission.CALENDAR_READ]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.PLAN_TECHNICIAN}/:id?`} component={Modules.PlanTechnician} permissions={[Permission.CALENDAR_READ]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.ROLES}/:id?`} component={Modules.Roles} permissions={[Permission.ROLE_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.SECTIONS}/:id?`} component={Modules.Sections} permissions={[Permission.SECTION_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.SHIFTS}/:id?`} component={Modules.Shifts} permissions={[Permission.SHIFT_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.TEAMS}/:id?`} component={Modules.Teams} permissions={[Permission.TEAM_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.USERS}/:id?`} component={Modules.Users} permissions={[Permission.USER_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.WORK_ORDERS}/:id?`} component={Modules.WorkOrders} permissions={[Permission.WORK_ORDER_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.WORK_ORDER_TYPES}/:id?`} component={Modules.WorkOrderTypes} permissions={[Permission.WORK_ORDER_TYPE_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.WORK_ORDER_STATES}/:id?`} component={Modules.WorkOrderStates} permissions={[Permission.WORK_ORDER_STATE_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.WORK_ORDER_ATTRIBUTES}/:id?`} component={Modules.WorkOrderAttributes} permissions={[Permission.WORK_ORDER_DYNAMIC_ATTRIBUTE_NAME_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.ABSENCE_TYPES}/:id?`} component={Modules.AbsenceTypes} permissions={[Permission.ABSENCE_TYPE_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.FORM_TEMPLATES}/:id/designer`} component={Modules.FormDesigner} permissions={[Permission.FORM_TEMPLATE_WRITE]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.FORM_TEMPLATES}/:id?`} component={Modules.FormTemplates} permissions={[Permission.FORM_TEMPLATE_READ]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.FILLED_FORMS}/:workOrderId/:id/filler`} component={Modules.FormFiller} permissions={[Permission.FILLED_FORM_WRITE]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.FILLED_FORM_ATTRIBUTES}/:id?`} component={Modules.FilledFormAttributes} permissions={[Permission.FILLED_FORM_SAP_ATTRIBUTE_LIST]} />
                {/* prettier-ignore */}
                <SecureRoute path={`${EvidenceBrowserUrl.SPHL}/:id?`} component={Modules.SPHLEvidence} permissions={[Permission.SPHL_LIST]} />
              </Switch>
            </Menubar>
          </EvidenceDefaultsProvider>
        </NamedSettingsProvider>
      </NotificationProvider>
    </UserSettingsProvider>
  );
}
