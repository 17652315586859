import { useIntl } from 'react-intl';
import { TableFieldColumn } from '@eas/common-web';
import { ZsdUser } from '@models';

export function useColumns(): TableFieldColumn<ZsdUser>[] {
  const intl = useIntl();

  return [
    {
      datakey: 'hrId',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__HR_ID',
        defaultMessage: 'Osobné číslo',
      }),
      width: 150,
    },
    {
      datakey: 'firstName',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__FIRSTNAME',
        defaultMessage: 'Meno',
      }),
      width: 150,
    },
    {
      datakey: 'lastName',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__LASTNAME',
        defaultMessage: 'Priezvisko',
      }),
      width: 150,
    },
    {
      datakey: 'phoneNumber',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__PHONE_NUMBER',
        defaultMessage: 'Telefónne číslo',
      }),
      width: 150,
    },
    {
      datakey: 'username',
      name: intl.formatMessage({
        id: 'ZSD__USERS__TABLE__COLUMN__USERNAME',
        defaultMessage: 'KID',
      }),
      width: 150,
    },
  ];
}
