import dayjs from 'dayjs';
import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  FormContext,
  SnackbarContext,
  SnackbarVariant,
  usePrompts,
} from '@eas/common-web';
import { useValidationSchema } from '@composite/absence-collection/absence-schema';
import { AbsenceCreateDialog } from '@composite/absence-collection/dialogs/absence-create-dialog';
import { Absence } from '@models';
import { EvidenceApiUrl } from '@enums';
import { CalendarContext } from '../calendar/calendar-context';
import { useValidateAbsence } from './unit-validate-absence';

export function useUnitAbsenceDialog({ userId }: { userId: string }) {
  const { currentDate, dataSource } = useContext(CalendarContext);

  usePrompts(
    [
      {
        key: `UNIT_ABSENCE_DIALOG_${userId}`,
        dialogTitle: 'Zadať absenciu',
        dialogWidth: 800,
        dialogText: '',
        FormFields: (props) =>
          AbsenceDialogForm({
            refreshData: dataSource.yAxis.refreshData,
            ...props,
          }),
        dialogShowConfirm: false,
        dialogShowClose: false,
        formInitialValues: {
          user: {
            id: userId,
          },
          note: '',
          fromDate: dayjs
            .unix(currentDate)
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm'),
          toDate: dayjs
            .unix(currentDate)
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm'),
        },
      },
    ],
    [userId, currentDate, useValidationSchema]
  );
}

const addAndAllowAbsence = async (
  values: Partial<Absence>
): Promise<{ ok: boolean; error?: string }> => {
  const response = await fetch(EvidenceApiUrl.ABSENCES, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (!response.ok) {
    return { ok: false, error: 'Absenciu sa nepodarilo vytvoriť.' };
  }

  const absence = (await response.json()) as Absence;

  if (!absence.id) {
    return { ok: false, error: 'Absenciu sa nepodarilo vytvoriť.' };
  }

  const allowResponse = await fetch(
    `${EvidenceApiUrl.ABSENCES}/${absence.id}/allow`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (!allowResponse.ok) {
    return {
      ok: false,
      error: `Absenciu sa nepodarilo schváliť. (${allowResponse.status})`,
    };
  }
  return { ok: true };
};

const AbsenceDialogForm = ({
  onCancel,
  refreshData,
}: {
  onCancel?: () => void;
  refreshData: () => void;
}) => {
  const { getFieldValues, validateForm } = useContext(FormContext);

  // useValidateAbsence - find interfering work orders in the desired absence interval
  const { validate } = useValidateAbsence();
  const formValidationSchema = useValidationSchema();
  const { showSnackbar } = useContext(SnackbarContext);
  return (
    <>
      <AbsenceCreateDialog isExisting={false} />
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <ButtonGroup>
          <Button
            variant="contained"
            color="primary"
            onClick={async (e) => {
              e.preventDefault();
              const errors = await validateForm(formValidationSchema);
              if (errors.length > 0) {
                return;
              }
              if (!(await validate(getFieldValues(), true)).ok) {
                return;
              }
              const result = await addAndAllowAbsence(getFieldValues());
              if (!result.ok) {
                showSnackbar(result.error, SnackbarVariant.ERROR);
                return;
              }

              refreshData();
              onCancel?.();
            }}
          >
            Zadať a schváliť
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            Zrušiť
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
};
