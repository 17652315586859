import React, { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  DetailContext,
  DetailHandle,
  DictionaryEvidence,
  EvidenceContext,
  Filter,
  FormNumberField,
  FormPanel,
  UserContext,
} from '@eas/common-web';
import { TeamField } from '@composite/team-collection/team-field';
import { UserField } from '@composite/user-collection/user-field';
import { Section } from '@models';
import { Permission } from '@enums';
import { TEAMS_SOURCE, USERS_SOURCE } from './section-hooked-sources';

const managementFilters: Filter[] = [
  {
    field: 'type',
    operation: ApiFilterOperation.EQ,
    value: 'MANAGEMENT',
  },
];

export function Fields() {
  const { hookedSources } = useContext(EvidenceContext);

  const { source, isExisting } =
    useContext<DetailHandle<Section>>(DetailContext);

  const { hasPermission } = useContext(UserContext);

  const teamSource = hookedSources[TEAMS_SOURCE]?.source;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const preFilters = useMemo(() => teamSource.getParams().filters, []);

  const userSource = hookedSources[USERS_SOURCE]?.source;

  const userPreFilters = useMemo(
    () => userSource.getParams().filters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ZSD__SECTIONS__DETAIL__PANEL__GENERAL"
            defaultMessage="Všeobecné"
          />
        }
      >
        <DictionaryEvidence.FieldName />
        <FormNumberField
          name="nightPlannerDaysCount"
          label={
            <FormattedMessage
              id="ZSD__SECTIONS__FIELD_LABEL__NIGHT_PLANNER_DAYS_COUNT"
              defaultMessage="Počet dní dopredu pre nočný plánovač"
            />
          }
        />
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>

      {isExisting && (
        <>
          {hasPermission(Permission.TEAM_LIST) && (
            <TeamField
              source={teamSource}
              currentSection={{
                id: source.data?.id ?? '',
                label: source.data?.name ?? '',
              }}
              preFilters={preFilters}
            />
          )}
          {hasPermission(Permission.USER_LIST) && (
            <UserField
              title={
                <FormattedMessage
                  id="ZSD__FIELD_LABEL__MANAGEMENT"
                  defaultMessage="Manažment"
                />
              }
              source={userSource}
              refKey="section"
              refFilters={managementFilters}
              currentRef={{
                id: source.data?.id ?? '',
                label: source.data?.name ?? '',
              }}
              preFilters={userPreFilters}
            />
          )}
        </>
      )}
    </>
  );
}
