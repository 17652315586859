import dayjs from 'dayjs';
import { WorkOrder } from '@models';
import { substractDateTime } from './get-template';

const getSafeWorkOrderEndTime = (workOrder: WorkOrder): string => {
  if (dayjs(workOrder.endTime).diff(dayjs(workOrder.startTime), 'second') > 0) {
    return workOrder.endTime!;
  }
  return dayjs(workOrder.startTime)
    .add(workOrder.duration ?? 0, 'second')
    .format('YYYY-MM-DDTHH:mm:ss');
};

export function getCollisions(workOrders: WorkOrder[]) {
  const collisions: { [key: string]: string[] } = {};

  workOrders.forEach((workOrder) => {
    collisions[workOrder.id] = [];

    const workOrderStartWithTravel = workOrder.travelPrevious
      ? dayjs(workOrder.startTime!)
          .subtract(workOrder.travelPrevious, 'second')
          .format('YYYY-MM-DD HH:mm:ss')
      : workOrder.startTime!;

    workOrders.forEach((otherWorkOrder) => {
      if (workOrder.id === otherWorkOrder.id) {
        return;
      }

      const workOrderEndTime = getSafeWorkOrderEndTime(workOrder);
      const otherWorkOrderEndTime = getSafeWorkOrderEndTime(otherWorkOrder);

      const otherWorkOrderStartWithTravel = otherWorkOrder.travelPrevious
        ? dayjs(otherWorkOrder.startTime!)
            .subtract(otherWorkOrder.travelPrevious, 'second')
            .format('YYYY-MM-DD HH:mm:ss')
        : otherWorkOrder.startTime!;

      const afterCollision =
        substractDateTime(workOrderEndTime, otherWorkOrderStartWithTravel) >
          0 &&
        substractDateTime(
          workOrderStartWithTravel,
          otherWorkOrderStartWithTravel
        ) < 0;

      const beforeCollision =
        substractDateTime(workOrderStartWithTravel, otherWorkOrderEndTime) <
          0 &&
        substractDateTime(workOrderEndTime, otherWorkOrderStartWithTravel) > 0;

      if (afterCollision || beforeCollision) {
        collisions[workOrder.id].push(otherWorkOrder.id);
      }
    });
  });

  return collisions;
}

/**
 * povodny detektor kolizii
 */
export function getCollisionsWithoutTravel(workOrders: WorkOrder[]) {
  const collisions: { [key: string]: string[] } = {};

  workOrders.forEach((workOrder) => {
    collisions[workOrder.id] = [];

    workOrders.forEach((otherWorkOrder) => {
      if (workOrder.id === otherWorkOrder.id) {
        return;
      }

      const workOrderEndTime = getSafeWorkOrderEndTime(workOrder);
      const otherWorkOrderEndTime = getSafeWorkOrderEndTime(otherWorkOrder);

      const afterCollision =
        substractDateTime(workOrderEndTime, otherWorkOrder.startTime!) > 0 &&
        substractDateTime(workOrder.startTime!, otherWorkOrder.startTime!) < 0;

      const beforeCollision =
        substractDateTime(workOrder.startTime!, otherWorkOrderEndTime) < 0 &&
        substractDateTime(workOrderEndTime, otherWorkOrder.startTime!) > 0;

      if (afterCollision || beforeCollision) {
        collisions[workOrder.id].push(otherWorkOrder.id);
      }
    });
  });

  return collisions;
}

export function getPositions(
  workOrders: WorkOrder[],
  collisionMap: { [key: string]: string[] }
) {
  const positions: { [key: string]: number } = {};

  workOrders.forEach((workOrder) => {
    // find max position of workOrder collisions
    const position = Math.max(
      0,
      ...collisionMap[workOrder.id]
        .map((id) => positions[id] ?? 'NaN')
        .filter((p) => !isNaN(p))
    );

    positions[workOrder.id] = position + 1;

    let i = position + 1;

    // check open positions until 0
    while (i > 0) {
      const openPosition = position - 1;

      if (openPosition < 1) {
        break;
      }

      if (
        collisionMap[workOrder.id].every((id) => positions[id] !== openPosition)
      ) {
        positions[workOrder.id] = openPosition;
        break;
      }

      i--;
    }
  });

  return positions;
}
