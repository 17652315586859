import cn from 'clsx';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Dialog, DialogHandle, ListSource } from '@eas/common-web';
import {
  DepartmentDialog,
  DepartmentDialogHandle,
} from '@composite/department-field/department-dialog';
import { Department } from '@models';
import { useStyles } from '../switcher-styles';

export function DepartmentPicker({
  department,
  dataList,
  onConfirm,
}: {
  department: Department | undefined;
  dataList: ListSource<Department> | undefined;
  onConfirm: (value: Department) => void;
}) {
  const classes = useStyles();

  const dialogRef = useRef<DialogHandle | null>(null);
  const dialogFieldsRef = useRef<DepartmentDialogHandle | null>(null);

  const handleConfirm = useCallback(() => {
    const selected = dialogFieldsRef.current?.getValue() as Department;

    onConfirm(selected);
  }, [onConfirm]);

  if (!dataList) {
    return null;
  }

  return (
    <>
      <Dialog
        title={
          <FormattedMessage
            id="ZSD__USERS__DETAIL_FIELD_LABEL__DEPARTMENT_TITLE"
            defaultMessage="Výber oblasti"
          />
        }
        ref={dialogRef}
        showConfirm={true}
        onConfirm={handleConfirm}
      >
        {() => (
          <DepartmentDialog
            ref={dialogFieldsRef}
            multiple={false}
            defaultValue={department}
            customSource={dataList}
          />
        )}
      </Dialog>
      <Button
        variant="outlined"
        className={cn(classes.button, classes.basicButton)}
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={() => dialogRef.current?.open()}
      >
        {department ? (
          department?.name
        ) : (
          <span className={classes.opacity50}>Pracovisko</span>
        )}
      </Button>
    </>
  );
}
