export interface AuthContextType {
  type?: AuthType;
  loginUrl?: string;
  // twoFactorEnabled?: boolean;
  // logout: (automatic?: boolean, samlLogout?: boolean) => void;
}

export interface AuthTypeResponse {
  type?: AuthType;
  loginUrl?: string;
  // twoFactorEnabled?: boolean;
}

export enum AuthType {
  SAML = 'SAML',
  FORM = 'FORM',
}
