import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { CalendarContext } from '@modules/planner/calendar/calendar-context';
import { CalendarDataType } from '@modules/planner/calendar/calendar-types';
import { getAbsences } from '@modules/planner/calendar/utils';
import { getTaskStart } from '@modules/planner/calendar/utils/get-task-start';
import { getTaskWidth } from '@modules/planner/calendar/utils/get-task-width';

export function AbsenceBar({ barData }: { barData: CalendarDataType }) {
  const { settings, currentDate } = useContext(CalendarContext);
  const theme = useTheme();

  const absences = useMemo(() => {
    return _.uniqBy(
      barData.shifts
        ?.map((shift, index) => {
          return shift?.isBlockingException
            ? undefined
            : getAbsences(
                barData.absences,
                dayjs.unix(currentDate).add(index, 'day').format('YYYY-MM-DD')
              );
        })
        .flat(),
      'id'
    );
  }, [barData.absences, barData.shifts, currentDate]);

  if (!absences || absences.length === 0) {
    return <></>;
  }

  return (
    <>
      {absences.map((absence, index) => {
        if (!absence) return null;
        const start = getTaskStart(
          currentDate,
          dayjs(absence.fromDate),
          dayjs(absence.toDate),
          0,
          settings
        );
        const width = getTaskWidth(
          currentDate,
          dayjs(absence.fromDate),
          dayjs(absence.toDate),
          0,
          settings
        );
        return (
          <div
            key={index}
            style={{
              position: 'absolute',
              height: '100%',
              width: width ?? 0,
              zIndex: 1,
              left: start ?? 0,
              background: `repeating-linear-gradient(135deg, #FFF 0 3px, ${theme.palette.error.light}64 0px 5px )`,
              backgroundSize: 35,
            }}
          />
        );
      })}
    </>
  );
}
