import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormAutocomplete, FormLocalDateField } from '@eas/common-web';
import { useCalendarTemplates } from '@modules/calendar-template/calendar-template-api';
import { labelMapper } from '@utils';

export function CalendarCreateDialog() {
  const calendarTemplates = useCalendarTemplates();

  return (
    <>
      <FormAutocomplete
        name="template"
        source={calendarTemplates}
        label={
          <FormattedMessage
            id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__CALENDAR_TEMPLATE"
            defaultMessage="Šablona"
          />
        }
        labelMapper={labelMapper}
        required={true}
      />
      <FormLocalDateField
        representation="local-date-time"
        name="fromDate"
        label={
          <FormattedMessage
            id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__FROM_DATE"
            defaultMessage="Dátum platnosti od"
          />
        }
        required={true}
      />
      <FormLocalDateField
        name="toDate"
        representation="local-date-time"
        label={
          <FormattedMessage
            id="ZSD__MOBILE_UNIT__DETAIL__FIELD_LABEL__TO_DATE"
            defaultMessage="Dátum platnosti do"
          />
        }
        required={true}
      />
    </>
  );
}
