import React from 'react';
import MuiCard from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 16,
    background:
      'linear-gradient(0deg, rgb(245 246 247) 0%, rgb(255 255 255) 100%)',
    margin: '0 0 24px',
    padding: 32,
    width: 500,
    maxWidth: '100%',
  },
});

export function Card({ children }: { children: React.ReactNode }) {
  const classes = useStyles();

  return (
    <MuiCard className={classes.card} elevation={2}>
      {children}
    </MuiCard>
  );
}
