import _ from 'lodash';
import React, { forwardRef, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MapIcon from '@material-ui/icons/Map';
import { PromptContext } from '@eas/common-web';
import { CalendarContext } from '@modules/planner/calendar/calendar-context';
import { PinnedMapContext } from '@composite/map/pinned-map-context';
import { GPSPoint, WorkOrder } from '@models';

type MenuItemProps = {
  closeMenu: () => void;
  unitId: string;
  date: string;
  startingPoint?: GPSPoint;
};

export const AllDayRouteMenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  function DayRouteMenuItem({ closeMenu, unitId, date, startingPoint }, ref) {
    const { testPrompt } = useContext(PromptContext);
    const { settings } = useContext(CalendarContext);
    const { otherData, updateMapData } = useContext(PinnedMapContext);
    return (
      <MenuItem
        ref={ref}
        onClick={() => {
          closeMenu();
          if (settings.pinnedMap) {
            updateMapData({
              variant: 'travel',
              travel: {
                unitId: unitId,
                startingPoint: startingPoint,
                addStartingPoint: true,
                addReturnPoint: true,
                workOrders: _.get(
                  otherData,
                  `UNIT_PLAN_DIALOG_${unitId}_${date}`,
                  []
                ) as WorkOrder[],
              },
            });
          } else {
            testPrompt({
              key: `UNIT_PLAN_DIALOG_${unitId}_${date}`,
              submitCallback: () => {},
            });
          }
        }}
      >
        <ListItemIcon>
          <MapIcon />
        </ListItemIcon>
        <FormattedMessage
          id="WORK_ORDER_MENU_ITEM_TRAVEL_ALL_DAY"
          defaultMessage="Trasa na celý deň"
        />
      </MenuItem>
    );
  }
);
