import { useIntl } from 'react-intl';
import { ApiFilterOperation, FieldFilter } from '@eas/common-web';

export const useFilterFields = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        id: 'ZSD__CALENDAR_EXCEPTION__TABLE__COLUMN__NAME',
        defaultMessage: 'Názov',
      }),
      datakey: 'name',
      filterkey: 'name',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterTextCell,
    },
  ];
};
