import dayjs from 'dayjs';

export const formatSeconds = (seconds: number) =>
  dayjs.duration(seconds, 'seconds').format('HH:mm:ss');

export function formatMeters(meters: number) {
  const kilometers = Math.floor(meters / 1000);

  const restMeters = Math.floor(meters % 10);

  return `${kilometers},${restMeters}km`;
}
