import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Certification } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const USERS_SOURCE = 'USERS_SOURCE';

function useUserSource(): HookedSource<Certification> {
  const source = useScrollableSource();

  const { hasPermission } = useContext(UserContext);

  return {
    [USERS_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.CERTIFICATION_LIST),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.USERS}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              operation: ApiFilterOperation.NESTED,
              path: 'certifications',
              filter: {
                field: 'certifications.certification.id',
                operation: ApiFilterOperation.IN,
                values: [id],
              },
            },
          ],
        });
      },
    },
  };
}

export function useCertificationHookedSources(): HookedSource<Certification> {
  const users = useUserSource();

  return users;
}
