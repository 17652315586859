import { ReactNode } from 'react';
import { SnackbarVariant } from '@eas/common-web';

const UNEXPECTED_ERROR: [ReactNode, SnackbarVariant] = [
  'Došlo k neočekávanej chybe, kontaktujte administrátora.',
  SnackbarVariant.ERROR,
];

const SUCCESS: [ReactNode, SnackbarVariant] = [
  'Akcia bola úspešne vykonaná',
  SnackbarVariant.SUCCESS,
];

export default { UNEXPECTED_ERROR, SUCCESS };
