import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Form,
  FormSubmitButton,
  UserContext,
  formFieldFactory,
} from '@eas/common-web';
import { AuthContext } from '@components/auth/auth-context';
import { AuthType } from '@components/auth/auth-types';
import { Card } from '@components/card/card';
import { formTextFieldFactory } from '@components/form/simple-text-field/simple-text-field';
import { EvidenceBrowserUrl } from '@enums';
import { useLogin } from './login-hook';
import { LoginData } from './login-types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundImage: `url("/login_bg.jpg")`,
    backgroundPosition: 'center',
  },
  paper: {
    marginTop: theme.spacing(15),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
  },
  title: {
    color: theme.palette.primary.main,
  },
  input: {
    width: '100%',
    margin: '15px 0px',
  },
  button: {
    width: '100%',
  },
}));

const LoginFields = {
  UsernameField: formFieldFactory(
    formTextFieldFactory({ label: 'Užívateľské meno' }),
    ({ children }) => <>{children}</>
  ),
  PasswordField: formFieldFactory(
    formTextFieldFactory({ label: 'Heslo', type: 'password' }),
    ({ children }) => <>{children}</>
  ),
};

export function Login() {
  const classes = useStyles();

  const { ref, handleSubmit, validationSchema, getFieldError } = useLogin();
  const { user } = useContext(UserContext);
  const { type, loginUrl } = useContext(AuthContext);

  /*
   * SAML error detection
   */
  const params = new URLSearchParams(window.location.search);
  const isError = params.get('error') === '' || !!params.get('error');
  if (isError) {
    window.location.replace(EvidenceBrowserUrl.LOGIN_ERROR);
  }

  /**
   * Redirect to SAML login url if enabled
   */
  useEffect(() => {
    if (type === AuthType.SAML && !user) {
      window.location.replace(loginUrl ?? EvidenceBrowserUrl.LOGIN_ERROR);
    }
  }, [type, loginUrl, user]);

  if (type != AuthType.FORM) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Form<LoginData>
        onSubmit={handleSubmit}
        editing={true}
        ref={ref}
        validationSchema={validationSchema}
        initialValues={{ username: '', password: '' }}
      >
        <Container className={classes.paper}>
          <Card>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography className={classes.title} variant="h5">
              <FormattedMessage
                id="ZSD__LOGIN__TITLE"
                defaultMessage="Prihlásenie"
              />
            </Typography>
            <div className={classes.input}>
              <LoginFields.UsernameField
                name="username"
                error={!!getFieldError('username')}
                helperText={getFieldError('username')}
              />
            </div>
            <div className={classes.input}>
              <LoginFields.PasswordField
                name="password"
                error={!!getFieldError('password')}
                helperText={getFieldError('password')}
              />
            </div>
            <FormSubmitButton
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              className={classes.button}
            >
              <Typography component="span" variant="body1">
                <FormattedMessage
                  id="ZSD__LOGIN__BUTTON"
                  defaultMessage="Prihlásiť"
                />
              </Typography>
            </FormSubmitButton>
          </Card>
        </Container>
      </Form>
    </div>
  );
}
