import React, {
  CSSProperties,
  ReactNode,
  forwardRef,
  useCallback,
  useContext,
} from 'react';
import {
  VariableSizeList as List,
  ListChildComponentProps,
} from 'react-window';
import { CalendarContext } from '../../calendar-context';
import { PlanCalendarDataType } from '../../calendar-types';
import { BarsRow } from './bars-row';
import { useStyles } from './bars-styles';

interface InnerElementProps {
  scaleWidth: number;
  style: CSSProperties;
  children: ReactNode;
}

interface BarsProps {
  width: number;
  height: number;
  data: PlanCalendarDataType[];
}

const InnerElement: React.FC<InnerElementProps> = ({
  scaleWidth,
  style,
  children,
  ...props
}) => {
  return (
    <div
      style={{ ...style, width: scaleWidth, position: 'relative' }}
      {...props}
    >
      {children}
    </div>
  );
};

export const Bars = forwardRef<List, BarsProps>(function Bars(
  { width, height, data },
  ref
) {
  const classes = useStyles();
  const { settings } = useContext(CalendarContext);

  const renderItem = useCallback(
    ({ style, data, index }: ListChildComponentProps) => {
      return <BarsRow barData={data[index]} index={index} style={style} />;
    },
    []
  );

  const renderInnerElement = useCallback(
    (props: InnerElementProps) => {
      return <InnerElement {...props} scaleWidth={width} />;
    },
    [width]
  );

  const itemSize = useCallback(() => {
    return settings.rowHeight;
  }, []);

  return (
    <div
      className={classes.wrapper}
      style={{
        marginTop: settings.headerHeight,
        left: settings.legendWidth,
        width: `calc(100% - ${settings.legendWidth}px)`,
      }}
    >
      <List<PlanCalendarDataType[]>
        ref={ref}
        className={classes.barsList}
        layout="vertical"
        width={width}
        height={height}
        itemCount={data.length}
        itemSize={itemSize}
        itemData={data}
        innerElementType={renderInnerElement}
      >
        {renderItem}
      </List>
    </div>
  );
});
