import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  wrapper: {
    borderBottom: `1px solid #ECECEC`,
    backgroundSize: 2000,
  },
  steps: {
    display: 'inline-flex',
    overflow: 'hidden',
  },
  step: {
    display: 'flex',
    borderBottom: `1px solid #ECECEC`,
    borderRight: 'none',
    borderTop: 'none',
    position: 'relative',
  },
}));
