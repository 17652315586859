import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DetailContext,
  EvidenceContext,
  NavigationContext,
  PrimaryDetailActionbarButton,
  PromptContext,
  PromptProvider,
} from '@eas/common-web';
import { useGetDepartment } from '@modules/department/department-api';
import { TertiaryDetailActionbarButton } from '@components/button/tertiary-detail-actionbar-button';
import { MobileUnit } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { activateMobileUnit } from './mobile-unit-api';
import { useMobileUnitCurrentLocation } from './mobile-unit-current-location';

export function ActionBar() {
  return (
    <PromptProvider>
      <ActionBarWithPrompt />
    </PromptProvider>
  );
}

export function ActionBarWithPrompt() {
  const intl = useIntl();
  const { isExisting, source } = useContext(DetailContext);
  const { tableSource } = useContext(EvidenceContext);
  const { navigate } = useContext(NavigationContext);
  const department = source.data?.department?.id;
  const [departmentSrc] = useGetDepartment(department ?? null);
  const team = departmentSrc?.team.id;

  useMobileUnitCurrentLocation(source.data as MobileUnit);

  if (!isExisting) {
    return null;
  }

  return (
    <>
      {source.data?.id && !source.data?.active && (
        <PrimaryDetailActionbarButton
          label={intl.formatMessage({
            id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__ACTIVATE',
            defaultMessage: 'Aktivovat',
          })}
          onClick={async () => {
            await activateMobileUnit(source.data ?? undefined);
            source.refresh();
            tableSource.reset();
          }}
        />
      )}
      <TertiaryDetailActionbarButton
        label={intl.formatMessage({
          id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__PLAN_WORK',
          defaultMessage: 'Naplánovať prácu',
        })}
        onClick={() => navigate('/zsd/kalendar')}
      />
      {team && (
        <TertiaryDetailActionbarButton
          label={intl.formatMessage({
            id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__SWITCHER',
            defaultMessage: 'Rýchle zmeny',
          })}
          onClick={() =>
            navigate(
              `${EvidenceBrowserUrl.MOBILE_UNITS_SWITCH}?tim=${team}&pracovisko=${department}`
            )
          }
        />
      )}
      <TertiaryDetailActionbarButton
        label={intl.formatMessage({
          id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__MONTH_PLAN',
          defaultMessage: 'Mesačný plán',
        })}
        onClick={() => navigate(`/zsd/plan/mobilna-jednotka/${source.data.id}`)}
      />
      {source.data?.id && source.data?.currentLocation && (
        <PromptContext.Consumer>
          {({ testPrompt }) => (
            <TertiaryDetailActionbarButton
              label={intl.formatMessage({
                id: 'ZSD__MOBILE_UNIT__DETAIL__ACTIONBAR__BUTTON__CURRENT_LOCATION',
                defaultMessage: 'Zobraziť aktuálnu polohu',
              })}
              onClick={() => {
                testPrompt({
                  key: `MOBILE_UNIT_CURRENT_LOCATION_DIALOG_${source.data.id}`,
                  submitCallback: () => {},
                });
              }}
            />
          )}
        </PromptContext.Consumer>
      )}
    </>
  );
}
