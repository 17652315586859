import dayjs from 'dayjs';
import { Absence } from '@models';
import { substractDate } from './get-template';

export function getAbsence(absences: Absence[], currentDay: string) {
  const absence = absences.find((absence) => {
    const fromDate = dayjs(absence?.fromDate).format('YYYY-MM-DD');
    const toDate = dayjs(absence?.toDate).format('YYYY-MM-DD');

    return (
      substractDate(currentDay, fromDate) >= 0 &&
      substractDate(currentDay, toDate) <= 0 &&
      absence.allowed
    );
  });

  return absence;
}

/**
 * get all absences for the day
 */
export function getAbsences(absences: Absence[], currentDay: string) {
  const absencesForDay = absences.filter((absence) => {
    const fromDate = dayjs(absence?.fromDate).format('YYYY-MM-DD');
    const toDate = dayjs(absence?.toDate).format('YYYY-MM-DD');

    return (
      substractDate(currentDay, fromDate) >= 0 &&
      substractDate(currentDay, toDate) <= 0 &&
      absence.allowed
    );
  });

  return absencesForDay;
}
